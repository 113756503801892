import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_INSURANCE_COMPANY = 'EDIT_INSURANCE_COMPANY';
export const EDIT_INSURANCE_COMPANY_SUCCESS = 'EDIT_INSURANCE_COMPANY_SUCCESS';
export const EDIT_INSURANCE_COMPANY_FAIL = 'EDIT_INSURANCE_COMPANY_FAIL';
export const EDIT_INSURANCE_COMPANY_RESET = 'EDIT_INSURANCE_COMPANY_RESET';

export const editInsuranceCompany = (params) => {
	return {
		type: EDIT_INSURANCE_COMPANY,
		params,
	};
};

export const editInsuranceCompanySuccess = (response) => {
	return {
		type: EDIT_INSURANCE_COMPANY_SUCCESS,
		response,
	};
};

export const editInsuranceCompanyFail = (response) => {
	return {
		type: EDIT_INSURANCE_COMPANY_FAIL,
		response,
	};
};

export const editInsuranceCompanyReset = () => {
	return {
		type: EDIT_INSURANCE_COMPANY_RESET,
	};
};

export const editInsuranceCompanyApi = (data) => {
	return (dispatch) => {
		dispatch(editInsuranceCompany());
		axios
			.patch(
				`${config.api.base_url}/insurance-companies/${data.id}`,
				data,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const editInsuranceCompanyData = response.data;
				dispatch(editInsuranceCompanySuccess(editInsuranceCompanyData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editInsuranceCompanyFail(errorMsg));
			});
	};
};
