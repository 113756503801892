import {
	ADD_INSURANCE_COMPANY,
	ADD_INSURANCE_COMPANY_SUCCESS,
	ADD_INSURANCE_COMPANY_FAIL,
	ADD_INSURANCE_COMPANY_RESET,
} from '../actions/addInsuranceComapnyAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const addInsuranceCompanyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_INSURANCE_COMPANY:
			return {
				...state,
				apiState: 'loading',
			};
		case ADD_INSURANCE_COMPANY_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case ADD_INSURANCE_COMPANY_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case ADD_INSURANCE_COMPANY_RESET:
			return initialState;
		default:
			return state;
	}
};

export default addInsuranceCompanyReducer;
