import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Checkbox } from 'antd';
import { Logo, Title, XForm } from '../Login/LoginStyle';
import { XContainer } from '../../styles/GlobalStyle';
import { Button, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { getExpiresAt, isLogin } from '../../utils/helper';
import { consentApi, consentReset } from '../../actions/consentAction';
import { XCardsGrdBG, XPara1 } from '../AnalyseCoverage/AnalyseCoverageStyle';
import { htmlConsent } from '../../utils/constants';

const Consent = (props) => {
	const { consent, consentReset, consentState } = props;

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		email: localStorage.getItem('email'),
		userName: localStorage.getItem('username'),
	});
	const [loading, setLoading] = useState(false);
	const [agreed, setAgreed] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (consentState.apiState === 'success') {
			setLoading(false);
			localStorage.setItem('consentGiven', true);
			consentReset();
			navigate('/reset-password');
		} else if (consentState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: consentState.message });
			consentReset();
		}
	}, [consentState]);

	useEffect(() => {
		if (isLogin()) {
			let expiresAt = getExpiresAt();
			if (expiresAt !== null && new Date() < new Date(expiresAt))
				navigate('/');
		}
	}, []);

	const handleConsent = () => {
		if (!agreed) {
			notification.error({
				message: 'Please agree to the terms and conditions',
			});
			return;
		}

		setLoading(true);
		consent(formData);
	};

	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Logo>
						<img
							className="img-fluid"
							src="./images/logo.svg"
							alt="logo"
						/>
					</Logo>
				</Col>
			</Row>

			<XCardsGrdBG className="align-items-center">
				<Col lg={{ span: 24 }}>
					<XForm
						form={form}
						name="consentForm"
						layout="vertical"
						autoComplete="off"
						onFinish={handleConsent}
						style={{
							padding: '5% 10%',
						}}
					>
						<div
							style={{
								overflow: 'scroll',
								height: '80vh',
								padding: '5% 10%',
								background: '#fff',
								borderRadius: '15px',
							}}
						>
							<div
								style={{
									textAlign: 'center',
								}}
							>
								<Title className="mb-4 mb-lg-5">
									Consent to Terms and Conditions
								</Title>
							</div>
							<XPara1
								style={{
									lineHeight: '1.5rem',
									color: '#505052',
								}}
								dangerouslySetInnerHTML={{
									__html: htmlConsent,
								}}
							>
								{/* <div
									className="container"
									style={{
										lineHeight: '1.5rem',
										color: '#505052',
									}}
								>
									{htmlConsent}
								</div> */}
							</XPara1>
							<Row
								className="mt-5"
								style={{
									textAlign: 'center',
								}}
							>
								<Col
									xs={{ span: 24 }}
									md={{ span: 24, offset: 0 }}
								>
									<Checkbox
										checked={agreed}
										onChange={(e) =>
											setAgreed(e.target.checked)
										}
									>
										I have read and agree to the terms and
										conditions.
									</Checkbox>
								</Col>

								<Col
									xs={24}
									md={24}
									style={{
										textAlign: 'center',
										padding: '0 20%',
									}}
								>
									<XForm.Item className="mt-3">
										{loading ? (
											<Button type="primary" loading>
												Submitting...
											</Button>
										) : (
											<Button
												htmlType="submit"
												disabled={!agreed}
											>
												Agree and Proceed
											</Button>
										)}
									</XForm.Item>
								</Col>
							</Row>
						</div>
					</XForm>
				</Col>
			</XCardsGrdBG>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	consentState: state.consent,
});

const mapDispatchToProps = (dispatch) => ({
	consent: (params) => dispatch(consentApi(params)),
	consentReset: () => dispatch(consentReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
