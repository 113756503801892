import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CASES = 'GET_CASES';
export const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS';
export const GET_CASES_FAIL = 'GET_CASES_FAIL';
export const GET_CASES_RESET = 'GET_CASES_RESET';

export const getCases = (params) => {
	return {
		type: GET_CASES,
		params,
	};
};

export const getCasesSuccess = (response) => {
	return {
		type: GET_CASES_SUCCESS,
		response,
	};
};

export const getCasesFail = (response) => {
	return {
		type: GET_CASES_FAIL,
		response,
	};
};

export const getCasesReset = () => {
	return {
		type: GET_CASES_RESET,
	};
};

export const getCasesApi = (data) => {
	return (dispatch) => {
		dispatch(getCases());
		axios
			.get(`${config.api.base_url}/case/all`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCasesData = response.data;
				dispatch(getCasesSuccess(getCasesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCasesFail(errorMsg));
			});
	};
};
