import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Button, Select, Input, Tabs } from 'antd';

export const XcalendarBtn = styled(Button)`
	border: 1px solid ${({ theme }) => theme.colors.color6};
`;
export const XFilterSelect = styled(Select)`
	padding-right: 30px;
	min-width: 120px;
	.ant-select-selection-item {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 500 !important;
		letter-spacing: 0.28px;
	}
	.ant-select-arrow {
		margin-right: 15px;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
`;
export const XFilterInput = styled(Input)`
	.ant-input {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-family: ${({ theme }) => theme.colors.fontInter} !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		&:focus,
		&:hover {
			border-color: ${({ theme }) => theme.colors.color6};
		}
	}
`;
export const XTabs = styled(Tabs)`
	.ant-tabs-tab-btn {
		color: #464f60;
		font-size: 14px;
		font-style: normal;
		font-weight: 500 !important;
		line-height: 20px;
		letter-spacing: 0.28px;
		span {
			margin-left: 7px;
			background: ${({ theme }) => theme.colors.color6};
			padding: 3px 10px;
			border-radius: 15px;
			color: #5a6376;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0.3px;
		}
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: ${({ theme }) => theme.colors.menu2} !important;
			font-weight: 700;
			span {
				font-weight: 700;
			}
		}
	}
	.ant-tabs-nav {
		&::before {
			display: none;
		}
	}
	.ant-tabs-ink-bar {
		background-color: ${({ theme }) => theme.colors.menu2};
	}
`;
