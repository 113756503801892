import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ANALYSIS_STATUS = 'GET_ANALYSIS_STATUS';
export const GET_ANALYSIS_STATUS_SUCCESS = 'GET_ANALYSIS_STATUS_SUCCESS';
export const GET_ANALYSIS_STATUS_FAIL = 'GET_ANALYSIS_STATUS_FAIL';
export const GET_ANALYSIS_STATUS_RESET = 'GET_ANALYSIS_STATUS_RESET';

export const getAnalysisStatus = (params) => {
	return {
		type: GET_ANALYSIS_STATUS,
		params,
	};
};

export const getAnalysisStatusSuccess = (response) => {
	return {
		type: GET_ANALYSIS_STATUS_SUCCESS,
		response,
	};
};

export const getAnalysisStatusFail = (response) => {
	return {
		type: GET_ANALYSIS_STATUS_FAIL,
		response,
	};
};

export const getAnalysisStatusReset = () => {
	return {
		type: GET_ANALYSIS_STATUS_RESET,
	};
};

export const getAnalysisStatusApi = (data) => {
	return (dispatch) => {
		dispatch(getAnalysisStatus());
		axios
			.get(`${config.api.base_url}/coverage/get-analysis-status`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getAnalysisStatusData = response.data;
				dispatch(getAnalysisStatusSuccess(getAnalysisStatusData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getAnalysisStatusFail(errorMsg));
			});
	};
};
