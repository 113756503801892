import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_INSURANCE_COMPANIES_STATISTICS = 'GET_INSURANCE_COMPANIES_STATISTICS';
export const GET_INSURANCE_COMPANIES_STATISTICS_SUCCESS = 'GET_INSURANCE_COMPANIES_STATISTICS_SUCCESS';
export const GET_INSURANCE_COMPANIES_STATISTICS_FAIL = 'GET_INSURANCE_COMPANIES_STATISTICS_FAIL';
export const GET_INSURANCE_COMPANIES_STATISTICS_RESET = 'GET_INSURANCE_COMPANIES_STATISTICS_RESET';

export const getInsuranceCompaniesStatistics = (params) => {
	return {
		type: GET_INSURANCE_COMPANIES_STATISTICS,
		params,
	};
};

export const getInsuranceCompaniesStatisticsSuccess = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_STATISTICS_SUCCESS,
		response,
	};
};

export const getInsuranceCompaniesStatisticsFail = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_STATISTICS_FAIL,
		response,
	};
};

export const getInsuranceCompaniesStatisticsReset = () => {
	return {
		type: GET_INSURANCE_COMPANIES_STATISTICS_RESET,
	};
};

export const getInsuranceCompaniesStatisticsApi = (data) => {
	return (dispatch) => {
		dispatch(getInsuranceCompaniesStatistics());
		axios
			.get(`${config.api.base_url}/dashboard/get-insurance-company-statistics`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getInsuranceCompaniesStatisticsData = response.data;
				dispatch(getInsuranceCompaniesStatisticsSuccess(getInsuranceCompaniesStatisticsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getInsuranceCompaniesStatisticsFail(errorMsg));
			});
	};
};
