import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const CHECK_PAYER_CONFIGURATION = 'CHECK_PAYER_CONFIGURATION';
export const CHECK_PAYER_CONFIGURATION_SUCCESS = 'CHECK_PAYER_CONFIGURATION_SUCCESS';
export const CHECK_PAYER_CONFIGURATION_FAIL = 'CHECK_PAYER_CONFIGURATION_FAIL';
export const CHECK_PAYER_CONFIGURATION_RESET = 'CHECK_PAYER_CONFIGURATION_RESET';

export const checkPayerConfiguration = (params) => {
	return {
		type: CHECK_PAYER_CONFIGURATION,
		params,
	};
};

export const checkPayerConfigurationSuccess = (response) => {
	return {
		type: CHECK_PAYER_CONFIGURATION_SUCCESS,
		response,
	};
};

export const checkPayerConfigurationFail = (response) => {
	return {
		type: CHECK_PAYER_CONFIGURATION_FAIL,
		response,
	};
};

export const checkPayerConfigurationReset = () => {
	return {
		type: CHECK_PAYER_CONFIGURATION_RESET,
	};
};

export const checkPayerConfigurationApi = (data) => {
	return (dispatch) => {
		dispatch(checkPayerConfiguration());
		axios
			.post(`${config.api.base_url}/payer-configuration/checkPayerConfiguration`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const checkPayerConfigurationData = response.data;
				dispatch(checkPayerConfigurationSuccess(checkPayerConfigurationData));
			})
			.catch((error) => {
				const errorMsg = error.response.data;
				dispatch(checkPayerConfigurationFail(errorMsg));
			});
	};
};
