import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EXPORT_FEEDBACKS = 'EXPORT_FEEDBACKS';
export const EXPORT_FEEDBACKS_SUCCESS = 'EXPORT_FEEDBACKS_SUCCESS';
export const EXPORT_FEEDBACKS_FAIL = 'EXPORT_FEEDBACKS_FAIL';
export const EXPORT_FEEDBACKS_RESET = 'EXPORT_FEEDBACKS_RESET';

export const exportFeedbacks = (params) => {
	return {
		type: EXPORT_FEEDBACKS,
		params,
	};
};

export const exportFeedbacksSuccess = (response) => {
	return {
		type: EXPORT_FEEDBACKS_SUCCESS,
		response,
	};
};

export const exportFeedbacksFail = (response) => {
	return {
		type: EXPORT_FEEDBACKS_FAIL,
		response,
	};
};

export const exportFeedbacksReset = () => {
	return {
		type: EXPORT_FEEDBACKS_RESET,
	};
};

export const exportFeedbacksApi = (data) => {
	return (dispatch) => {
		dispatch(exportFeedbacks());
		axios
			.get(`${config.api.base_url}/feedback/get-export-feedbacks`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const exportFeedbacksData = response.data;
				dispatch(exportFeedbacksSuccess(exportFeedbacksData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(exportFeedbacksFail(errorMsg));
			});
	};
};
