import {
	GETCOUNTBYFILINGANDREVENUE,
	GETCOUNTBYFILINGANDREVENUE_SUCCESS,
	GETCOUNTBYFILINGANDREVENUE_FAIL,
	GETCOUNTBYFILINGANDREVENUE_RESET,
} from '../actions/getCountByFilingAndRevenueAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const getCountByFilingAndRevenueReducer = (state = initialState, action) => {
	switch (action.type) {
		case GETCOUNTBYFILINGANDREVENUE:
			return {
				...state,
				apiState: 'loading',
			};
		case GETCOUNTBYFILINGANDREVENUE_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GETCOUNTBYFILINGANDREVENUE_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case GETCOUNTBYFILINGANDREVENUE_RESET:
			return initialState;
		default:
			return state;
	}
};

export default getCountByFilingAndRevenueReducer;
