import {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGIN_RESET,
} from '../actions/loginAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				apiState: 'loading',
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case LOGIN_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors ? action.response.errors : null,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case LOGIN_RESET:
			return initialState;
		default:
			return state;
	}
};

export default loginReducer;
