import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_FAIL = 'GET_FEEDBACKS_FAIL';
export const GET_FEEDBACKS_RESET = 'GET_FEEDBACKS_RESET';

export const getFeedbacks = (params) => {
	return {
		type: GET_FEEDBACKS,
		params,
	};
};

export const getFeedbacksSuccess = (response) => {
	return {
		type: GET_FEEDBACKS_SUCCESS,
		response,
	};
};

export const getFeedbacksFail = (response) => {
	return {
		type: GET_FEEDBACKS_FAIL,
		response,
	};
};

export const getFeedbacksReset = () => {
	return {
		type: GET_FEEDBACKS_RESET,
	};
};

export const getFeedbacksApi = (data) => {
	return (dispatch) => {
		dispatch(getFeedbacks());
		axios
			.get(`${config.api.base_url}/feedback`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getFeedbacksData = response.data;
				dispatch(getFeedbacksSuccess(getFeedbacksData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getFeedbacksFail(errorMsg));
			});
	};
};
