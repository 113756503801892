
import { 
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL, 
    RESET_PASSWORD_RESET,
} from "../actions/resetPasswordAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD:
            return {
                ...state,
                apiState: "loading",
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case RESET_PASSWORD_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default resetPasswordReducer;
