import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsTransparentTabs, XTabs } from './ManageStyle';
import TabPane from 'antd/es/tabs/TabPane';
import { Col, Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
// Actions
import { createRoleApi, createRoleReset } from '../../actions/createRoleAction';
import Header from '../../components/Header/Header';
import { checkModulePermissions } from '../../utils/helper';
import AdminConfigurations from './AdminConfigurations';
import ModuleManagement from './ModuleManagement';
import DefaultRoleManagement from './DefaultRoleManagement';

const Manage = (props) => {
	const navigate = useNavigate();
	const [displayOrganization, setDisplayOrganization] = useState(false);

	const handleTabChange = (key) => {
		// Clear the URL search parameters when changing tabs
		navigate(`/user-role-management`);
	};

	useEffect(() => {
		if (localStorage.getItem('role') === 'super-admin')
			setDisplayOrganization(true);
	}, []);

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="User & Role Management" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="userRoleManagement" />
				</Col>
			</>
			<XContainer fluid>
				<XCardsTransparentTabs>
					<XTabs
						defaultActiveKey="1"
						type="card"
						onChange={handleTabChange}
					>
						<TabPane key="1" tab="User Management">
							{checkModulePermissions('user-management')
								.authorized ? (
								<UserManagement />
							) : (
								<Result
									status="403"
									title="403"
									subTitle="Sorry, you are not authorized to access this page."
									extra={
										<Button
											type="primary"
											onClick={() => navigate('/')}
										>
											Back Home
										</Button>
									}
								/>
							)}
						</TabPane>
						<TabPane
							key="2"
							tab="Role Management"
							disabled={
								checkModulePermissions('role-management')
									.authorized
									? false
									: true
							}
						>
							{checkModulePermissions('role-management')
								.authorized ? (
								<RoleManagement />
							) : (
								<Result
									status="403"
									title="403"
									subTitle="Sorry, you are not authorized to access this page."
									extra={
										<Button
											type="primary"
											onClick={() => navigate('/')}
										>
											Back Home
										</Button>
									}
								/>
							)}
						</TabPane>
						{checkModulePermissions('configurations').authorized ? (
							<TabPane key="3" tab="Configurations">
								{checkModulePermissions('configurations')
									.authorized ? (
									<AdminConfigurations />
								) : (
									<Result
										status="403"
										title="403"
										subTitle="Sorry, you are not authorized to access this page."
										extra={
											<Button
												type="primary"
												onClick={() => navigate('/')}
											>
												Back Home
											</Button>
										}
									/>
								)}
							</TabPane>
						) : (
							<></>
						)}
						{checkModulePermissions('module-management')
							.authorized ? (
							<TabPane key="4" tab="Modules">
								{checkModulePermissions('module-management')
									.authorized ? (
									<ModuleManagement />
								) : (
									<Result
										status="403"
										title="403"
										subTitle="Sorry, you are not authorized to access this page."
										extra={
											<Button
												type="primary"
												onClick={() => navigate('/')}
											>
												Back Home
											</Button>
										}
									/>
								)}
							</TabPane>
						) : (
							<></>
						)}
						{displayOrganization ? (
							<TabPane key="5" tab="Default Role Management">
								<DefaultRoleManagement />
							</TabPane>
						) : (
							<></>
						)}
					</XTabs>
				</XCardsTransparentTabs>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	createRoleState: state.createRole,
});

const mapDispatchToProps = (dispatch) => ({
	createRole: (params) => dispatch(createRoleApi(params)),
	createRoleReset: () => dispatch(createRoleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
