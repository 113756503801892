import {
	EDIT_ORGANIZATION_ROLE,
	EDIT_ORGANIZATION_ROLE_SUCCESS,
	EDIT_ORGANIZATION_ROLE_FAIL,
	EDIT_ORGANIZATION_ROLE_RESET,
} from '../actions/editOrganizationRoleAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const editOrganizationRoleReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_ORGANIZATION_ROLE:
			return {
				...state,
				apiState: 'loading',
			};
		case EDIT_ORGANIZATION_ROLE_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case EDIT_ORGANIZATION_ROLE_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case EDIT_ORGANIZATION_ROLE_RESET:
			return initialState;
		default:
			return state;
	}
};

export default editOrganizationRoleReducer;
