
import { 
    GET_HOSPITAL_BY_ID,
    GET_HOSPITAL_BY_ID_SUCCESS,
    GET_HOSPITAL_BY_ID_FAIL, 
    GET_HOSPITAL_BY_ID_RESET,
} from "../actions/getHospitalByIdAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getHospitalByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOSPITAL_BY_ID:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_HOSPITAL_BY_ID_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_HOSPITAL_BY_ID_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_HOSPITAL_BY_ID_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getHospitalByIdReducer;
