import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
	Tooltip,
	notification,
	Tag,
	Select,
	Upload,
	Modal,
	InputNumber,
	Card,
	DatePicker,
	Avatar,
	Image,
	Affix,
	Checkbox,
	Radio,
	List,
	Skeleton,
	FloatButton,
	Divider,
} from 'antd';
import AppealLoading from '../../json/reloading.json';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XCardsTransparent,
	XAffix,
	XSelect,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
	ChatButton,
	ChatBoxWrap,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
	FilePdfOutlined,
	InboxOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import HeaderFile from '../../components/Header/HeaderFile';
import Header from '../../components/Header/Header';
import ChatBox from '../../components/Chat/Chat';
import {
	XCardPreview,
	XCardInner,
	XTabs,
	XRadio,
} from './AppealLetterPreviewStyle';
import {
	XButtonConfirm,
	XButtonLike,
	XDivider,
	XLottieOverlay,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import { CaseTimelineTitle, XCardBackBg } from '../CaseForm/CaseFormStyle';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	getVerifyApproveAppealLetterUserListReset,
	getVerifyApproveAppealLetterUserListApi,
} from '../../actions/getVerifyApproveAppealLetterUserListAction';
import {
	saveAppealLetterApi,
	saveAppealLetterReset,
} from '../../actions/saveAppealLetterAction';
import { handleInputChange } from '../../utils/formFunctions';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import { checkModulePermissions } from '../../utils/helper';
import config from '../../config';
import { XLottie } from '../Login/LoginStyle';
const { TextArea } = Input;

const AppealLetterPreview = (props) => {
	const location = useLocation();
	// Get the id from the URL
	const { id } = useParams();

	const {
		getAppealLetter,
		getAppealLetterState,
		editCase,
		editCaseState,
		editCaseReset,
		getVerifyApproveAppealLetterUserList,
		getVerifyApproveAppealLetterUserListState,
		getVerifyApproveAppealLetterUserListReset,
		getAnalysis,
		getAnalysisState,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
	} = props;
	const navigate = useNavigate();
	const [appealForm] = XForm.useForm();
	const editorRef = useRef(null);
	const {
		appealLetterType,
		// id,
		patientDetails,
		editFlag,
		content,
		header,
		footer,
	} = location.state || {};
	console.log('location.state--------------------', location.state);
	const [sendTeamMember, setSendTeamMember] = useState('team');
	const [appealLetterContent, setAppealLetterContent] = useState(content);
	const [appealLetterHeader, setAppealLetterHeader] = useState(header);
	const [appealLetterFooter, setAppealLetterFooter] = useState(footer);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [selectMember, setSelectMember] = useState(1);
	const [appealLetterUsers, setAppealLetterUsers] = useState([]);

	const [chatOpen, setChatOpen] = useState(false);
	const [isMdDisabled, setIsMdDisabled] = useState(false);
	const [isCodingDisabled, setIsCodingDisabled] = useState(false);
	const [activeKey, setActiveKey] = useState('1');

	const sendToCodingTeam = (formValues) => {
		// setSubmitCase(false);
		setAppealLetterLoading(true);
		// setTogglePrepareLetter(false);

		editCase({
			...formValues,
			id: id,
			status: 'sent_for_verification',
			status_remarks: 'Appeal Letter sent for Verification',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			sent_to_coding_team: true,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		// setAppealLetterLoading(false)
		//
		// ApproveModalOpen(false);
	};

	const [isAppealVerifyApproveModalOpen, setIsAppealVerifyApproveModalOpen] =
		useState(
			isCodingDisabled
				? ['', '', '']
				: [
						'Send To Coding Team for Verification',
						sendToCodingTeam,
						'coding-team',
				  ],
		);
	console.log(
		'isAppealVerifyApproveModalOpen------------',
		isAppealVerifyApproveModalOpen,
	);

	const [formData, setFormData] = useState({});
	const [patientData, setPatientData] = useState(patientDetails || {});
	const [editAppealLetterSelected, setEditAppealLetterSelected] =
		useState(false);
	// const [showAppealLetterPreview, setShowAppealLetterPreview] =
	// 	useState(false);
	const [showEditAppealLetter, setShowEditAppealLetter] = useState(false);
	const [mainDataLoading, setMainDataLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	console.log('type-----------------', typeof selectMember, selectMember);

	useEffect(() => {
		if (id) {
			setLoading(true);
			getAppealLetter({
				id: id,
				type: appealLetterType,
				regenerate_appeal_letter: false,
			});
		}
		if (editFlag) {
			setLoading(true);
			editAppealLetter(appealLetterType);
		}
	}, [id]);

	useEffect(() => {
		if (getAppealLetterState.apiState === 'success') {
			setLoading(false);
			setAppealLetterLoading(false);
			// setAppealLetterEdit(false);
			getAnalysis({ id: id });
			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
			setAppealLetterHeader(getAppealLetterState.data.header);
			setAppealLetterFooter(getAppealLetterState.data.footer);
			if (editAppealLetterSelected) {
				setShowEditAppealLetter(true);
			}
		} else if (getAppealLetterState.apiState === 'error') {
			setAppealLetterLoading(false);
			setLoading(false);
			// setAppealLetterEdit(false);
			notification.error({
				message: getAppealLetterState.message
					? getAppealLetterState.message
					: 'Unable to get Appeal Letter content',
			});
		}
		getAppealLetterReset();
	}, [getAppealLetterState.apiState]);

	const getCodingTeamButtonFunction = (status, remark, value) => {
		if (patientData?.appeal_letter_status === 'coding_team_sent') {
			return codingTeamApproved(status, remark, value);
		}
	};

	const codingTeamApproved = (status, remark, coding_team_status) => {
		// setSubmitCase(false);
		setAppealLetterLoading(true);
		editCase({
			id: id,
			status: status,
			status_remarks: `Appeal letter ${remark}`,
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			coding_team_status: coding_team_status,
		});
		// setPreviewAppealLetterSelected(false);
		// setApproveModalOpen(false);
	};

	useEffect(() => {
		if (getVerifyApproveAppealLetterUserListState.apiState === 'success') {
			let dropdown = [];
			getVerifyApproveAppealLetterUserListState.data.map((ele) => {
				return dropdown.push({
					label: `${ele.name}`,
					value: ele._id,
				});
			});
			setAppealLetterUsers(dropdown);
			getVerifyApproveAppealLetterUserListReset();
		} else if (
			getVerifyApproveAppealLetterUserListState.apiState === 'error'
		) {
			setAppealLetterUsers([]);
			notification.error({
				message: 'Unable to save Appeal Letter content',
			});
			getVerifyApproveAppealLetterUserListReset();
		}
	}, [getVerifyApproveAppealLetterUserListState]);

	useEffect(() => {
		if (editCaseState.apiState === 'loading') {
			setAppealLetterLoading(true);
		}
		if (editCaseState.apiState === 'success') {
			getAnalysis({ id: id });

			appealForm.setFieldsValue({
				appeal_letter_assigned_to: '',
				appeal_letter_remarks: '',
			});
			appealForm.resetFields('');
			setSelectMember(1);
			// setIsAppealVerifyApproveModalOpen(['', '', '']);
			setAppealLetterLoading(false);
			// notification.success({
			// 	message: editCaseState.message,
			// });
			// navigate(-1);
		} else if (editCaseState.apiState === 'error') {
			setAppealLetterLoading(false);
			notification.error({
				message: 'Unable to confirm Appeal letter',
			});
		}
		editCaseReset();
	}, [editCaseState.apiState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'loading') {
			setMainDataLoading(true);
		}
		if (getAnalysisState.apiState === 'success') {
			// setAppealLetterEdit(false);
			setMainDataLoading(false);
			// setIsAppealVerifyApproveModalOpen(['', '', '']);
			// setAppealLetterEdit(false);
			// setSubmitCase(false);
			setPatientData(getAnalysisState.data.case_details);
		}
		if (getAnalysisState.apiState === 'error') {
			// setAppealLetterEdit(false);
			setMainDataLoading(false);
		}
		getAnalysisReset();
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (saveAppealLetterState.apiState === 'success') {
			// setAppealLetterEdit(false);
			setAppealLetterContent('');
			setAppealLetterHeader('');
			setAppealLetterFooter('');
			setShowEditAppealLetter(false);
			setAppealLetterLoading(false);
			// previewAppealLetter();
			setLoading(true);
			getAppealLetter({
				id: id,
				type: appealLetterType,
				regenerate_appeal_letter: false,
			});
			saveAppealLetterReset();
			// setAppealLetterEdit(true);
		} else if (saveAppealLetterState.apiState === 'error') {
			// setAppealLetterEdit(false);
			setAppealLetterLoading(false);
			notification.error({
				message: 'Unable to save Appeal Letter content',
			});
			saveAppealLetterReset();
		}
	}, [saveAppealLetterState.apiState]);

	const sendAppealLetterForApproval = (formValues) => {
		setAppealLetterLoading(true);
		// setSubmitCase(false);
		// setTogglePrepareLetter(false);
		editCase({
			...formValues,
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal Letter Sent for approval',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			send_for_approval: true,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		// setTogglePrepareLetter(false);
		// setPreviewAppealLetterSelected(false);
		// setApproveModalOpen(false);
	};

	const handleTabsChange = (key) => {
		console.log('handleTabsChangecalled');
		console.log('key-------------------------------', typeof key);
		setActiveKey(key);
		if (key === '1') {
			console.log('@1');
			setIsAppealVerifyApproveModalOpen([
				'Send To Coding Team for Verification',
				sendToCodingTeam,
				'coding-team',
			]);
		} else {
			console.log('@2');
			setIsAppealVerifyApproveModalOpen([
				'Send To MD Advisor for Approval',
				sendAppealLetterForApproval,
				'md-advisor',
			]);
		}
		setSelectMember(1);
		setAppealLetterUsers([]);
		appealForm.resetFields('');
		appealForm.setFieldsValue({});
	};

	const confirmAppealLetter = (status) => {
		// setSubmitCase(false);
		setAppealLetterLoading(true);
		// setTogglePrepareLetter(false);
		editCase({
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal letter confirmed',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			appeal_letter_status: status,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		// setAppealLetterLoading(false)
		// setPreviewAppealLetterSelected(false);
		// setApproveModalOpen(false);
	};

	const editAppealLetterFromModal = () => {
		// setPreviewAppealLetterSelected(false);
		// setShowAppealLetterPreview(false);
		// setAppealLetterContent('');
		editAppealLetter(appealLetterType);
	};

	const editAppealLetter = (type) => {
		getAppealLetter({ id: id, type: type });
		setEditAppealLetterSelected(true);
		// setAppealLetterType(type);
	};

	const closeEditAppealLetter = () => {
		setShowEditAppealLetter(false);
		setEditAppealLetterSelected(false);
		// setAppealLetterContent('');
	};

	const saveAndPreviewAppealLetter = () => {
		if (editorRef.current) {
			// setAppealLetterLoading(true);
			setEditAppealLetterSelected(false);
			saveAppealLetter({
				id: id,
				appeal_letter: editorRef.current.getContent(),
				type: appealLetterType,
			});
		}
	};
	console.log('mainDataLoading-------------', mainDataLoading);

	useEffect(() => {
		patientData?.appeal_letter_status === 'md_advisor_sent' &&
		patientData?.case_amount >=
			patientData?.appeal_letter_approval_threshold
			? setIsMdDisabled(true)
			: patientData?.case_amount <
					patientData?.appeal_letter_approval_threshold &&
			  checkModulePermissions('appeal-letter').sub_modules.includes(
					'confirm-letter',
			  )
			? setIsMdDisabled(false)
			: checkModulePermissions('appeal-letter').sub_modules.includes(
					'confirm-letter',
			  )
			? localStorage.getItem('role') !== 'coding-team' &&
			  localStorage.getItem('role') !== 'md-advisor' &&
			  [
					'md_advisor_send',
					'md_advisor_approved',
					'coding_team_sent',
			  ].includes(patientData.appeal_letter_status)
				? setIsMdDisabled(true)
				: localStorage.getItem('role') !== 'md-advisor'
				? setIsMdDisabled(false)
				: setIsMdDisabled(true)
			: setIsMdDisabled(true);
	}, [patientData]);

	useEffect(() => {
		if (
			patientData?.appeal_letter_status === 'coding_team_sent' ||
			localStorage.getItem('role') === 'coding-team' ||
			localStorage.getItem('role') === 'md-advisor' ||
			patientData?.appeal_letter_status !== null
		) {
			setIsCodingDisabled(true); // Condition met, disable
		} else {
			setIsCodingDisabled(false); // Condition not met, enable
		}
	}, [patientData]);
	console.log('isCodingDisabled', isCodingDisabled);
	console.log('isMdDisabled', isMdDisabled);

	console.log('thresh', patientData?.appeal_letter_approval_threshold);
	console.log('amount', patientData?.case_amount);

	return (
		<>
			{loading && (
				<XLottieOverlay>
					<XLottie animationData={AppealLoading} loop={true} />
				</XLottieOverlay>
			)}
			<Col lg={24} xl={24} xs={0} md={0}>
				<Header
					headerTag="Appeal Letter Preview"
					breadcrumb="true"
					preview="true"
					previewId="Appeal Letter Preview"
					caseId={patientData?.case_id}
				/>
			</Col>
			<Col lg={0} xl={0} xs={24} md={24}>
				<HeaderFile activeNav="caseManagement" />
			</Col>

			<XContainer fluid>
				<Row gutter={(0, 30)}>
					<Col xs={24} sm={24} md={24} lg={14} xl={16}>
						<XCardPreview>
							<div
								style={{
									height: '700px',
									overflowX: 'hidden',
									overflowY: 'auto',
									textAlign: 'justify',
									padding: '0 5px',
								}}
								className="appeal-letter-modal"
							>
								{/* edit button */}
								{checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('edit-letter') ? (
									<XButtonLike
										type="primary"
										style={{
											float: 'right',
										}}
										onClick={editAppealLetterFromModal}
									>
										<EditOutlined
											style={{
												fontSize: '20px',
											}}
										/>
									</XButtonLike>
								) : (
									<></>
								)}
								{/* edit button */}
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterHeader,
									}}
								/>
								<XDivider />
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterContent,
									}}
								/>
								<XDivider />
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterFooter,
									}}
								/>
								<br />
							</div>
							{/* footer start */}

							{/* <div
								style={{
									background: `${
										patientData.appeal_letter_remarks
											? '#e0e0e0'
											: ''
									}`,
									padding: '10px',
								}}
							> */}
							{/* {patientData.appeal_letter_remarks ? (
									<>
										<p
											style={{
												fontWeight: 'bold',
												fontSize: '16px',
												textAlign: 'start',
											}}
										>
											Query
										</p>
										<p
											style={{
												textAlign: 'justify',
											}}
										>
											{patientData.appeal_letter_remarks}
										</p>
									</>
								) : (
									<></>
								)} */}
							{/* <div
									key="buttonsContainer"
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										gap: '10px',
										alignItems: 'center',
										marginTop: '10px',
									}}
								>
									{patientData?.appeal_letter_status ===
									'coding_team_sent' ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'verify-coding-team',
										) ? (
											<Button
												loading={appealLetterLoading}
												key="approvedByCodingTeam"
												type="primary"
												onClick={() =>
													getCodingTeamButtonFunction(
														'sent_for_verification',
														'Verified',
														'coding_team_approved',
													)
												}
												style={{
													background: `black`,
												}}
											>
												Approve
											</Button>
										) : (
											<></>
										)
									) : (
										<></>
									)}
									{patientData?.appeal_letter_status ===
										'md_advisor_sent' &&
									patientData?.case_amount >=
										patientData?.appeal_letter_approval_threshold ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'confirm-letter',
										) ? (
											<>
												<Button
													loading={
														appealLetterLoading
													}
													key="approvedByCodingTeam"
													type="primary"
													onClick={() => {
														confirmAppealLetter(
															'md_advisor_approved',
														);
													}}
													style={{
														background: `black`,
													}}
												>
													Approve
												</Button>
											</>
										) : (
											<></>
										)
									) : patientData?.case_amount <
											patientData?.appeal_letter_approval_threshold &&
									  checkModulePermissions(
											'appeal-letter',
									  ).sub_modules.includes(
											'confirm-letter',
									  ) ? (
										<>
											<Button
												loading={appealLetterLoading}
												key="approvedByCodingTeam"
												type="primary"
												onClick={() =>
													confirmAppealLetter(
														'md_advisor_approved',
													)
												}
												style={{
													background: `black`,
												}}
											>
												Approve
											</Button>
										</>
									) : (
										<></>
									)}
								</div> */}
							{/* </div> */}
							{/* footer end */}
							{/* <Row
								gutter={[15, 15]}
								className="align-items-center"
							>
								<Col xs={12}>
									<img
										className="img-fluid"
										style={{ maxWidth: '100px' }}
										src="/images/preview-logo.png"
										alt=""
									/>
								</Col>
								<Col xs={12} className="text-end">
									<XButtonConfirm
										icon={
											<img
												src="/images/edit-clinical-icon.svg"
												style={{
													width: '20px',
												}}
												alt="Edit"
											/>
										}
										className="mb-2 mb-md-0 mb-sm-0"
										type="primary"
										size="large"
										shape="round"
										style={{
											background: '#fff',
										}}
									>
										Edit
									</XButtonConfirm>
								</Col>
							</Row> */}
							{/* <XCardInner className="mt-4">
								<div className="cardHeader">
									<Row
										gutter={[15, 15]}
										className="align-items-center"
									>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Patient :<span>{'bush'}</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												MRN :<span>{'mrn'}</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												DOS :<span>Dec 12, 2023</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Dec 12, 2023 :
												<span>Mar 9, 1939</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Visit # :<span>&nbsp;</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="hadPadding">
									<Row>
										<Col xs={24}>
											<p className="para2">
												This letter is in reference to
												the claim submitted for the
												medical services provided to a
												patient with a history of type 2
												diabetes mellitus, uncontrolled
												hypertension, and a recent
												diagnosis of ischemic heart
												disease. The patient presented
												with symptoms of chest pain and
												shortness of breath, and upon
												evaluation, a coronary
												angiography was performed,
												revealing significant stenosis
												in multiple vessels. We are
												seeking coverage for the
												coronary angiography, CPT Code:
												93458, as part of the patient's
												treatment plan.(1,2).
											</p>
											<p className="para2">
												According to the Local Coverage
												Determination (LCD) section
												L35121, coverage for coronary
												angiography is indicated when
												there is a medical necessity for
												the procedure. In this case, the
												patient's clinical presentation
												and the subsequent findings from
												the coronary angiography align
												with the policy guidelines,
												establishing the procedure as
												medically necessary and
												therefore eligible for coverage
												under the patient's insurance
												plan.
											</p>
											<p className="para2">
												The medical necessity for the
												coronary angiography is
												substantiated by the patient's
												symptoms and the significant
												stenosis found in multiple
												vessels, which required
												immediate and accurate diagnosis
												to guide further treatment. The
												documentation of the patient's
												condition and the results of the
												angiography are comprehensive
												and in accordance with the
												policy guidelines. The
												angiography was essential in
												determining the appropriate
												management and therapeutic
												interventions for the patient's
												ischemic heart disease, thereby
												justifying the claim for
												coverage.
											</p>
											<p className="para2">
												We kindly request your prompt
												attention to this matter and
												would appreciate your positive
												response to this claim. Should
												you require any additional
												information or documentation to
												process this claim, please do
												not hesitate to contact us. We
												thank you for your time and
												consideration in reviewing this
												appeal.
											</p>
											<p className="para2">
												Thank you for your time and
												reconsideration.
											</p>
											<p className="para2">Sincerely,</p>
											<p className="para2">
												Amin Aiolos <br />
												DecodeX
											</p>
										</Col>
									</Row>
									<Divider
										style={{ borderColor: '#d7dade' }}
										dashed
									/>
									<Row gutter={[15, 15]}>
										<Col xs={24}>
											<div className="listTitle">
												Supporting Documents
											</div>
										</Col>
										<Col xs={24}>
											<ul className="listItems">
												<li>Dummy Sample Doc</li>
												<li>Job Doc</li>
												<li>Denial Sample Doc</li>
											</ul>
										</Col>
									</Row>
									<Divider
										style={{ borderColor: '#d7dade' }}
										dashed
									/>
									<Row gutter={[15, 15]}>
										<Col xs={24}>
											<p className="para2">
												Mayo Clinic Laboratories 3050
												Superior Drive NW Rochester, MN
												55905 mcl@mayo.edu
											</p>
										</Col>
									</Row>
								</div>
							</XCardInner> */}
						</XCardPreview>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={10}
						xl={8}
						className="mt-4 mt-lg-0"
					>
						<XCardBackBg>
							{isMdDisabled && isCodingDisabled ? (
								''
							) : (
								<>
									<CaseTimelineTitle className="mb-3">
										Send To
									</CaseTimelineTitle>
									<XTabs
										// defaultActiveKey="1"
										activeKey={activeKey}
										centered={true}
										size="large"
										type="card"
										onChange={handleTabsChange}
										items={[
											{
												label: 'Coding Team',
												key: '1',
												// disabled: isCodingDisabled,
												// disabled:
												// 	patientData?.appeal_letter_status ===
												// 		'coding_team_sent' ||
												// 	localStorage.getItem(
												// 		'role',
												// 	) === 'coding-team' ||
												// 	localStorage.getItem(
												// 		'role',
												// 	) === 'md-advisor' ||
												// 	patientData?.appeal_letter_status !==
												// 		null,
												children: isCodingDisabled ? (
													patientData?.appeal_letter_status ===
													'md_advisor_approved' ? (
														''
													) : (
														'Awaiting for coding team to respond...'
													)
												) : (
													<>
														<XForm
															form={appealForm}
															className="w-100 mt-3"
															layout="vertical"
															autoComplete="off"
															onFinish={
																isAppealVerifyApproveModalOpen[1]
															}
														>
															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center mt-2"
															>
																<Col xs={24}>
																	<XForm.Item name="radio_group">
																		<XRadio.Group
																			name="radiogroup"
																			defaultValue={
																				1
																			}
																			value={
																				selectMember
																			}
																			onChange={(
																				e,
																			) => {
																				setSelectMember(
																					e
																						.target
																						.value,
																				);
																				appealForm.setFieldValue(
																					{
																						appeal_letter_assigned_to:
																							null,
																					},
																				);
																			}}
																		>
																			<Radio
																				value={
																					1
																				}
																			>
																				Send
																				to
																				Team
																			</Radio>
																			<Radio
																				value={
																					2
																				}
																			>
																				Assign
																				to
																				Member
																			</Radio>
																		</XRadio.Group>
																	</XForm.Item>
																	{/* <XRadio.Group
																name="radiogroup"
																onChange={(e) => {
																	console.log(
																		'typeof in onchange',
																		typeof e
																			.target
																			.value,
																		e.target
																			.value,
																	);
																	setSelectMember(
																		e.target
																			.value,
																	);
																	appealForm.setFieldValue(
																		{
																			appeal_letter_assigned_to:
																				null,
																		},
																	);
																}}
																value={selectMember}
																
															>
																<XRadio value={1}>
																	Send to Team
																</XRadio>
																<XRadio value={2}>
																	Assign to Member
																</XRadio>
															</XRadio.Group> */}
																</Col>
															</Row>

															{selectMember ===
																2 && (
																<Row
																	gutter={[
																		15, 15,
																	]}
																	className="align-items-center"
																>
																	<Col
																		xs={24}
																	>
																		<XForm.Item
																			name="appeal_letter_assigned_to"
																			label="Assign To"
																			rules={[
																				{
																					required: true,
																					message:
																						'User is required',
																				},
																			]}
																		>
																			<XSelect
																				size="large"
																				placeholder="Assign To"
																				name="appeal_letter_assigned_to"
																				allowClear
																				showSearch={
																					true
																				}
																				filterOption={(
																					input,
																					option,
																				) =>
																					option.label
																						.toLowerCase()
																						.includes(
																							input.toLowerCase(),
																						)
																				}
																				style={{
																					textAlign:
																						'left',
																				}}
																				className="demo-case-css"
																				options={
																					appealLetterUsers
																				}
																				onFocus={() => {
																					getVerifyApproveAppealLetterUserList(
																						{
																							role: isAppealVerifyApproveModalOpen[2],
																							organization_id:
																								patientData.organization,
																						},
																					);
																				}}
																				onSearch={(
																					search,
																				) => {
																					if (
																						search.length >=
																						3
																					) {
																						getVerifyApproveAppealLetterUserList(
																							{
																								role: isAppealVerifyApproveModalOpen[2],
																								search,
																								organization_id:
																									patientData.organization,
																							},
																						);
																					} else if (
																						search.length ===
																						0
																					) {
																						getVerifyApproveAppealLetterUserList(
																							{
																								role: isAppealVerifyApproveModalOpen[2],
																								organization_id:
																									patientData.organization,
																							},
																						);
																					}
																				}}
																				onChange={(
																					value,
																				) => {
																					setFormData(
																						{
																							...formData,
																							appeal_letter_assigned_to:
																								value,
																						},
																					);
																				}}
																			/>
																		</XForm.Item>
																	</Col>
																</Row>
															)}

															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center"
															>
																<Col xs={24}>
																	<XForm.Item
																		name="appeal_letter_remarks"
																		label="Enter Remarks"
																		rules={[
																			{
																				required: true,
																				message:
																					'Enter Remarks',
																			},
																		]}
																	>
																		<TextArea
																			size="large"
																			placeholder="Enter Remarks"
																			name="appeal_letter_remarks"
																			onChange={(
																				e,
																			) =>
																				handleInputChange(
																					e,
																					formData,
																					setFormData,
																				)
																			}
																		/>
																	</XForm.Item>
																</Col>
															</Row>
															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center"
															>
																<Col xs={24}>
																	<XButtonConfirm
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',
																			color: '#212226',
																		}}
																		onClick={() => {
																			// setIsAppealVerifyApproveModalOpen(
																			// 	[
																			// 		'',
																			// 		'',
																			// 		'',
																			// 	],
																			// );
																			setAppealLetterUsers(
																				[],
																			);
																			appealForm.resetFields(
																				'',
																			);
																			appealForm.setFieldsValue(
																				{},
																			);
																			setSelectMember(
																				1,
																			);
																		}}
																	>
																		Reset
																	</XButtonConfirm>
																	<XButtonConfirm
																		loading={
																			appealLetterLoading
																		}
																		htmlType="submit"
																	>
																		Submit
																	</XButtonConfirm>
																</Col>
															</Row>
														</XForm>
													</>
												),
											},

											{
												label: 'MD Advisor',
												key: '2',
												// disabled: isMdDisabled,

												// disabled:
												// 	patientData?.appeal_letter_status ===
												// 		'md_advisor_sent' &&
												// 	patientData?.case_amount >=
												// 		patientData?.appeal_letter_approval_threshold
												// 		? true
												// 		: patientData?.case_amount <
												// 				patientData?.appeal_letter_approval_threshold &&
												// 		  checkModulePermissions(
												// 				'appeal-letter',
												// 		  ).sub_modules.includes(
												// 				'confirm-letter',
												// 		  )
												// 		? false
												// 		: checkModulePermissions(
												// 				'appeal-letter',
												// 		  ).sub_modules.includes(
												// 				'confirm-letter',
												// 		  )
												// 		? localStorage.getItem(
												// 				'role',
												// 		  ) !== 'coding-team' &&
												// 		  localStorage.getItem(
												// 				'role',
												// 		  ) !== 'md-advisor' &&
												// 		  [
												// 				'md_advisor_send',
												// 				'md_advisor_approved',
												// 				'coding_team_sent',
												// 		  ].includes(
												// 				patientData.appeal_letter_status,
												// 		  )
												// 			? true
												// 			: localStorage.getItem(
												// 					'role',
												// 			  ) !== 'md-advisor'
												// 			? false
												// 			: true
												// 		: true,
												children: isMdDisabled ? (
													patientData?.appeal_letter_status ===
													'md_advisor_approved' ? (
														''
													) : (
														'Awaiting for MD advisor to respond....'
													)
												) : (
													<>
														<XForm
															form={appealForm}
															className="w-100 mt-3"
															layout="vertical"
															autoComplete="off"
															onFinish={
																isAppealVerifyApproveModalOpen[1]
															}
														>
															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center mt-2"
															>
																<Col xs={24}>
																	<XForm.Item name="radio_group">
																		<XRadio.Group
																			name="radiogroup"
																			defaultValue={
																				1
																			}
																			onChange={(
																				e,
																			) => {
																				setSelectMember(
																					e
																						.target
																						.value,
																				);
																				appealForm.setFieldValue(
																					{
																						appeal_letter_assigned_to:
																							null,
																					},
																				);
																			}}
																		>
																			<Radio
																				value={
																					1
																				}
																			>
																				Send
																				to
																				Team
																			</Radio>
																			<Radio
																				value={
																					2
																				}
																			>
																				Assign
																				to
																				Member
																			</Radio>
																		</XRadio.Group>
																	</XForm.Item>
																</Col>
															</Row>

															{selectMember ===
																2 && (
																<Row
																	gutter={[
																		15, 15,
																	]}
																	className="align-items-center"
																>
																	<Col
																		xs={24}
																	>
																		<XForm.Item
																			name="appeal_letter_assigned_to"
																			label="Assign To"
																			rules={[
																				{
																					required: true,
																					message:
																						'User is required',
																				},
																			]}
																		>
																			<XSelect
																				size="large"
																				placeholder="Assign To"
																				name="appeal_letter_assigned_to"
																				allowClear
																				showSearch={
																					true
																				}
																				filterOption={(
																					input,
																					option,
																				) =>
																					option.label
																						.toLowerCase()
																						.includes(
																							input.toLowerCase(),
																						)
																				}
																				style={{
																					textAlign:
																						'left',
																				}}
																				className="demo-case-css"
																				options={
																					appealLetterUsers
																				}
																				onFocus={() => {
																					getVerifyApproveAppealLetterUserList(
																						{
																							role: isAppealVerifyApproveModalOpen[2],
																							organization_id:
																								patientData.organization,
																						},
																					);
																				}}
																				onSearch={(
																					search,
																				) => {
																					if (
																						search.length >=
																						3
																					) {
																						getVerifyApproveAppealLetterUserList(
																							{
																								role: isAppealVerifyApproveModalOpen[2],
																								search,
																								organization_id:
																									patientData.organization,
																							},
																						);
																					} else if (
																						search.length ===
																						0
																					) {
																						getVerifyApproveAppealLetterUserList(
																							{
																								role: isAppealVerifyApproveModalOpen[2],
																								organization_id:
																									patientData.organization,
																							},
																						);
																					}
																				}}
																				onChange={(
																					value,
																				) => {
																					setFormData(
																						{
																							...formData,
																							appeal_letter_assigned_to:
																								value,
																						},
																					);
																				}}
																			/>
																		</XForm.Item>
																	</Col>
																</Row>
															)}

															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center"
															>
																<Col xs={24}>
																	<XForm.Item
																		name="appeal_letter_remarks"
																		label="Enter Remarks"
																		rules={[
																			{
																				required: true,
																				message:
																					'Enter Remarks',
																			},
																		]}
																	>
																		<TextArea
																			size="large"
																			placeholder="Enter Remarks"
																			name="appeal_letter_remarks"
																			onChange={(
																				e,
																			) =>
																				handleInputChange(
																					e,
																					formData,
																					setFormData,
																				)
																			}
																		/>
																	</XForm.Item>
																</Col>
															</Row>
															<Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center"
															>
																<Col xs={24}>
																	<XButtonConfirm
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',
																			color: '#212226',
																		}}
																		onClick={() => {
																			// setIsAppealVerifyApproveModalOpen(
																			// 	[
																			// 		'',
																			// 		'',
																			// 		'',
																			// 	],
																			// );
																			setAppealLetterUsers(
																				[],
																			);
																			appealForm.resetFields(
																				'',
																			);
																			appealForm.setFieldsValue(
																				{},
																			);
																			setSelectMember(
																				1,
																			);
																		}}
																	>
																		Reset
																	</XButtonConfirm>
																	<XButtonConfirm
																		loading={
																			appealLetterLoading
																		}
																		htmlType="submit"
																	>
																		Submit
																	</XButtonConfirm>
																</Col>
															</Row>
														</XForm>
													</>
												),
											},
										]}
									/>
								</>
							)}

							{/* <XDivider /> */}
							<div
								style={{
									background: `${
										patientData.appeal_letter_remarks
											? '#e0e0e0'
											: ''
									}`,
									padding: '10px',
								}}
							>
								{patientData.appeal_letter_remarks ? (
									<>
										<p
											style={{
												fontWeight: 'bold',
												fontSize: '16px',
												textAlign: 'start',
											}}
										>
											Query
										</p>
										<p
											style={{
												textAlign: 'justify',
											}}
										>
											{patientData.appeal_letter_remarks}
										</p>
									</>
								) : (
									<></>
								)}
								{patientData?.appeal_letter_status ===
									'md_advisor_approved' && (
									<CaseTimelineTitle className="mb-3">
										Send Apeeal Letter.....
									</CaseTimelineTitle>
								)}
								{patientData?.case_amount <
									patientData?.appeal_letter_approval_threshold &&
								checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('confirm-letter') ? (
									<>
										<XDivider />
										<CaseTimelineTitle className="mb-3">
											Approve and Send Letter
										</CaseTimelineTitle>
									</>
								) : (
									''
								)}
								<div
									key="buttonsContainer"
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										gap: '10px',
										alignItems: 'center',
										marginTop: '10px',
									}}
								>
									{patientData?.appeal_letter_status ===
									'coding_team_sent' ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'verify-coding-team',
										) ? (
											<>
												<Button
													loading={
														appealLetterLoading
													}
													key="approvedByCodingTeam"
													type="primary"
													onClick={() =>
														getCodingTeamButtonFunction(
															'sent_for_verification',
															'Verified',
															'coding_team_approved',
														)
													}
													style={{
														background: `black`,
													}}
												>
													Approve
												</Button>
											</>
										) : (
											<></>
										)
									) : (
										<></>
									)}
									{patientData?.appeal_letter_status ===
										'md_advisor_sent' &&
									patientData?.case_amount >=
										patientData?.appeal_letter_approval_threshold ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'confirm-letter',
										) ? (
											<>
												<Button
													loading={
														appealLetterLoading
													}
													key="approvedByCodingTeam"
													type="primary"
													onClick={() => {
														confirmAppealLetter(
															'md_advisor_approved',
														);
													}}
													style={{
														background: `black`,
													}}
												>
													Approve
												</Button>
											</>
										) : (
											<></>
										)
									) : patientData?.case_amount <
											patientData?.appeal_letter_approval_threshold &&
									  checkModulePermissions(
											'appeal-letter',
									  ).sub_modules.includes(
											'confirm-letter',
									  ) ? (
										<>
											<Button
												loading={appealLetterLoading}
												key="approvedByCodingTeam"
												type="primary"
												onClick={() =>
													confirmAppealLetter(
														'md_advisor_approved',
													)
												}
												style={{
													background: `black`,
												}}
											>
												Approve
											</Button>
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</XCardBackBg>

						<ChatBoxWrap>
							{chatOpen && <ChatBox case_id={id} />}

							<ChatButton
								type="text"
								onClick={() => {
									setChatOpen(!chatOpen);
								}}
							>
								{chatOpen ? (
									<>
										<span className="mobileClose">
											<img
												src="/images/chat-mob-close.svg"
												alt="Close chat Icon"
											/>
										</span>
										<span className="hideMobileClose">
											<img
												src="/images/chat-cross-icon.svg"
												alt="Close chat Icon"
												width="60px"
												height="60px"
											/>
										</span>
									</>
								) : (
									<img
										src="/images/chat-icon.svg"
										alt="Open chat Icon"
										width="60px"
										height="60px"
									/>
								)}
							</ChatButton>
						</ChatBoxWrap>
					</Col>
				</Row>
			</XContainer>

			{/* edit appeal letter modal */}
			<Modal
				title="Edit Appeal Letter"
				open={showEditAppealLetter}
				okText="Save and Preview"
				// loading={appealLetterLoading}
				onOk={saveAndPreviewAppealLetter}
				width={900}
				okButtonProps={{
					style: {
						background: 'black',
					},
				}}
				onCancel={closeEditAppealLetter}
			>
				<Editor
					apiKey={config.tiny_url_key}
					onInit={(evt, editor) => (editorRef.current = editor)}
					init={{
						menubar: false,
						plugins: ['code', 'image', 'link', 'visualblocks'],
						branding: false,
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
							'bullist numlist outdent indent | image link |' +
							'removeformat | visualblocks code | help ',
						content_style:
							'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						font_size_formats: '8px 10px 12px 14px 18px 24px 36px',
					}}
					initialValue={appealLetterContent}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getAppealLetterState: state.getAppealLetter,
	editCaseState: state.editCase,
	getVerifyApproveAppealLetterUserListState:
		state.getVerifyApproveAppealLetterUserList,
	getAnalysisState: state.getAnalysis,
	saveAppealLetterState: state.saveAppealLetter,
});

const mapDispatchToProps = (dispatch) => ({
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	editCase: (params) => dispatch(editCaseApi(params)),
	editCaseReset: (params) => dispatch(editCaseReset(params)),
	getVerifyApproveAppealLetterUserList: (params) =>
		dispatch(getVerifyApproveAppealLetterUserListApi(params)),
	getVerifyApproveAppealLetterUserListReset: () =>
		dispatch(getVerifyApproveAppealLetterUserListReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	saveAppealLetterReset: (params) => dispatch(saveAppealLetterReset(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppealLetterPreview);
