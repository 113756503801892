import styled from 'styled-components';
import '../../styles/GlobalStyle';
import PinInput from 'react-pin-input';

export const XPinInput = styled(PinInput)`
	.pincode-input-text {
		min-width: 100px !important;
	}
`;

export const XPara1 = styled.div`
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	span {
		font-weight: 500;
	}
	a {
		color: ${({ theme }) => theme.colors.menu2};
		text-decoration: none;
		font-weight: 500;
	}
	@media only screen and (max-width: 769px) {
		font-size: 14px;
	}
`;
