import React from 'react'

const Page404 = () => {
  return (
    <div>
        <h1>Page 404</h1>
    </div>
  )
}

export default Page404
