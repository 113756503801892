import {
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAIL,
	CHANGE_PASSWORD_RESET,
} from '../actions/changePasswordAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const changePasswordReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_PASSWORD:
			return {
				...state,
				apiState: 'loading',
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case CHANGE_PASSWORD_FAIL:
			console.log('action.response.message', action.response);
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case CHANGE_PASSWORD_RESET:
			return initialState;
		default:
			return state;
	}
};

export default changePasswordReducer;
