import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GETCOUNTBYFILINGANDREVENUE = 'GETCOUNTBYFILINGANDREVENUE';
export const GETCOUNTBYFILINGANDREVENUE_SUCCESS =
	'GETCOUNTBYFILINGANDREVENUE_SUCCESS';
export const GETCOUNTBYFILINGANDREVENUE_FAIL =
	'GETCOUNTBYFILINGANDREVENUE_FAIL';
export const GETCOUNTBYFILINGANDREVENUE_RESET =
	'GETCOUNTBYFILINGANDREVENUE_RESET';

export const getCountByFilingAndRevenue = (params) => {
	return {
		type: GETCOUNTBYFILINGANDREVENUE,
		params,
	};
};

export const getCountByFilingAndRevenueSuccess = (response) => {
	return {
		type: GETCOUNTBYFILINGANDREVENUE_SUCCESS,
		response,
	};
};

export const getCountByFilingAndRevenueFail = (response) => {
	return {
		type: GETCOUNTBYFILINGANDREVENUE_FAIL,
		response,
	};
};

export const getCountByFilingAndRevenueReset = () => {
	return {
		type: GETCOUNTBYFILINGANDREVENUE_RESET,
	};
};

export const getCountByFilingAndRevenueApi = (data) => {
	return (dispatch) => {
		dispatch(getCountByFilingAndRevenue());
		axios
			.get(`${config.api.base_url}/case/count`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCountByFilingAndRevenueData = response.data;
				dispatch(
					getCountByFilingAndRevenueSuccess(
						getCountByFilingAndRevenueData,
					),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCountByFilingAndRevenueFail(errorMsg));
			});
	};
};
