import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col, Input, Button, notification, Result } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsTransparent,
	XForm,
	XModal,
	XShareButton,
	XSelect,
} from '../../styles/GlobalStyle';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import axios from 'axios';

import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
	lowerCaseInput,
	upperCaseInput,
	validateMobileNumber,
} from '../../utils/formFunctions';
import config from '../../config';

// actions

import {
	getHospitalByIdApi,
	getHospitalByIdReset,
} from '../../actions/getHospitalByIdAction';
import {
	editHospitalApi,
	editHospitalReset,
} from '../../actions/editHospitalAction';
import {
	addHospitalApi,
	addHospitalReset,
} from '../../actions/addHospitalAction';
import {
	checkModulePermissions,
	getFileNameFromDocPath,
	getUserToken,
} from '../../utils/helper';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	getOrganizationsApi,
	getOrganizationsReset,
} from '../../actions/getOrganizationsAction';
import {
	deleteHospitalApi,
	deleteHospitalReset,
} from '../../actions/deleteHospitalAction';
import Header from '../../components/Header/Header';
import { XUpload } from '../Profile/ProfileStyle';
import { getRegionsApi, getRegionsReset } from '../../actions/getRegionsAction';

const HospitalForm = (props) => {
	const {
		getHospitalById,
		getHospitalByIdReset,
		getHospitalByIdState,
		editHospital,
		editHospitalReset,
		editHospitalState,
		addHospital,
		addHospitalReset,
		addHospitalState,
		getStateDropdownState,
		getStateDropdown,
		getRegions,
		getRegionsState,
		getOrganizations,
		getOrganizationsState,
		deleteHospitalState,
		deleteHospital,
		deleteHospitalReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('hospital-management');

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [uploadedFile, setUploadedFile] = useState({});
	const headerEditorRef = useRef(null);
	const footerEditorRef = useRef(null);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);

	const [displayOrganization, setDisplayOrganization] = useState(true);
	const [displayRegion, setDisplayRegion] = useState(true);
	const getDocumentObject = async (document_path) => {
		let url = `${config.s3_url}${document_path}`;
		let file_name = getFileNameFromDocPath(document_path);
		setUploadedFile({
			name: file_name,
			url: url,
			status: 'done',
		});
		setDataLoaded(true);
	};

	const uploadFile = async (currentId) => {
		if (!fileToUpload) {
			setTriggerUpdate(true);
		} else {
			const { file, onError, onSuccess, onProgress } = fileToUpload;
			let fil_name = file.name.split('.')[0];
			let get_ext = file.name.split('.');
			let ext = get_ext[get_ext.length - 1];
			let unique_id = file.uid;

			try {
				const res = await axios.get(
					`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=hospitals&file_name=${fil_name}&ext=${ext}&id=${unique_id}`,
					{
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							Authorization: 'Bearer ' + getUserToken(),
						},
					},
				);
				const result = await axios.put(res.data.data, file, {
					onUploadProgress: ({ total, loaded }) => {
						onProgress(
							{
								percent: Math.round(
									(loaded / total) * 100,
								).toFixed(0),
							},
							file,
						);
					},
				});

				if (result.status === 200) {
					let documentPath = `/hospitals/${unique_id}/${fil_name}.${ext}`;
					setFormData({
						...formData,
						logo: documentPath,
					});

					onSuccess(result, file);
				}
			} catch (error) {
				console.log('Try and catch error => ', error.message);
				notification.error({
					message: `Unable to upload file`,
				});
				return error;
			}
			setTriggerUpdate(true);
		}
	};
	const handleOnFinish = () => {
		form.validateFields().then(() => {
			uploadFile(formData._id);
		});
	};
	// use effects
	useEffect(() => {
		if (id) {
			getHospitalById({ id: id });
		} else {
			setDataLoaded(true);
		}
		localStorage.getItem('role') === 'organization-admin' && getRegions();
		getStateDropdown();
		getOrganizations();
	}, [id]);

	useEffect(() => {
		if (getHospitalByIdState.apiState === 'success') {
			setFormData({
				...getHospitalByIdState.data,
				id: getHospitalByIdState.data._id,
			});
			form.setFieldsValue({
				...getHospitalByIdState.data,
				id: getHospitalByIdState.data._id,
			});
			getDocumentObject(getHospitalByIdState.data.logo);
			getRegions({
				organization_id: getHospitalByIdState.data.organization,
			});
			getHospitalByIdReset();
		}
	}, [getHospitalByIdState]);

	useEffect(() => {
		if (addHospitalState.apiState === 'success') {
			addHospitalReset();
			navigate(-1);
		}
	}, [addHospitalState]);

	useEffect(() => {
		if (editHospitalState.apiState === 'success') {
			editHospitalReset();
			navigate(-1);
		}
	}, [editHospitalState]);

	useEffect(() => {
		if (deleteHospitalState.apiState === 'success') {
			notification.success({ message: deleteHospitalState.message });
			deleteHospitalReset();
			navigate(-1);
		}
	}, [deleteHospitalState]);

	useEffect(() => {
		if (triggerUpdate) {
			if (id)
				editHospital({
					id: id,
					...formData,
					header: headerEditorRef.current.getContent(),
					footer: footerEditorRef.current.getContent(),
				});
			else
				addHospital({
					...formData,
					header: headerEditorRef.current.getContent(),
					footer: footerEditorRef.current.getContent(),
				});
		}
	}, [triggerUpdate]);

	useEffect(() => {
		if (localStorage.getItem('role') === 'organization-admin') {
			setDisplayOrganization(false);
			getRegions();
		}
		if (localStorage.getItem('role') === 'region-admin') {
			setDisplayOrganization(false);
			setDisplayRegion(false);
		}
		getStateDropdown();
	}, []);
	
	const showDeleteConfirm = (_id, name) => {
		XModal.confirm({
			title: 'Delete Hospital?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete {name}?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteHospital({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Hospital Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="hospital" />
					</Col>
				</>
			)}

			{dataLoaded && (
				<XContainer fluid className="mt-3">
					<XCardBackBg className="mb-3">
						<Row>
							<Col xs={24}>
								<XButtonBack
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
								{id ? (
									<XEditTitle>Edit Hospital</XEditTitle>
								) : (
									<XEditTitle>Add Hospital</XEditTitle>
								)}{' '}
							</Col>
						</Row>
					</XCardBackBg>

					<XCardsTransparent>
						{modulePermissions.sub_modules.includes(
							'add-hospitals',
							'update-hospitals',
						) ? (
							<XForm
								form={form}
								name="roleForm"
								layout="vertical"
								autoComplete="off"
								onFinish={handleOnFinish}
							>
								<Row gutter={(15, 30)} className="mt-4">
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="name"
											label="Name"
											rules={[
												{
													required: true,
													message:
														'Please enter Hospital name',
												},
											]}
										>
											<Input
												size="large"
												placeholder="Enter Hospital Name"
												name="name"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="contact_person_email_address"
											label="Contact Person Email Address"
											rules={[
												{
													type: 'email',
													message:
														'Please enter a valid email address',
												},
											]}
										>
											<Input
												size="large"
												placeholder="Enter Contact Person Email Address"
												name="contact_person_email_address"
												onInput={lowerCaseInput}
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="contact_person_mobile_number"
											label="Contact Person Mobile Number"
											rules={[
												{
													validator:
														validateMobileNumber,
												},
											]}
										>
											<Input
												size="large"
												placeholder="Enter Contact Person Mobile Number"
												name="contact_person_mobile_number"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="fax_number"
											label="Fax Number"
										>
											<Input
												size="large"
												placeholder="Enter Fax Number"
												name="fax_number"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="address"
											label="Address"
										>
											<Input
												size="large"
												placeholder="Enter Address"
												name="address"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="state"
											label={'State'}
										>
											<XSelect
												size="large"
												placeholder="Select State"
												name="state"
												showSearch={true}
												className="removeBg"
												// onFocus={() => {
												// 	getStateDropdown();
												// }}
												onSearch={(search) => {
													if (search.length >= 3) {
														getStateDropdown({
															search,
														});
													} else if (
														search.length === 0
													) {
														getStateDropdown();
													}
												}}
												filterOption={filterByLabel}
												options={
													getStateDropdownState.apiState ===
														'success' &&
													getStateDropdownState.data
														.all_states?.length >
														0 &&
													getStateDropdownState.data.all_states.map(
														(itm) => {
															return {
																label: itm.state,
																value: itm.state,
																providers:
																	itm.providers,
															};
														},
													)
												}
												onSelect={(value, options) => {
													handleSelectChange(
														'state',
														value,
														formData,
														setFormData,
													);
												}}
											></XSelect>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item name="city" label="City">
											<Input
												size="large"
												placeholder="Enter City"
												name="city"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="pincode"
											label="Pincode"
										>
											<Input
												size="large"
												placeholder="Enter Pincode"
												name="pincode"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									{displayOrganization ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												name="organization"
												label={'Organization'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelect
													size="large"
													placeholder="Select Organization"
													name="organization"
													showSearch={true}
													className="removeBg"
													onSearch={(search) => {
														if (
															search.length >= 3
														) {
															getOrganizations({
																search,
															});
														} else if (
															search.length === 0
														) {
															getOrganizations();
														}
													}}
													filterOption={filterByLabel}
													options={
														getOrganizationsState.apiState ===
															'success' &&
														getOrganizationsState
															.data.list?.length >
															0 &&
														getOrganizationsState.data.list.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'organization',
															value,
															formData,
															setFormData,
														);
														getRegions({
															organization_id:
																value,
														});
														form.setFieldValue(
															'region',
															null,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									{displayRegion ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												name="region"
												label={'Region'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelect
													size="large"
													placeholder="Select Region"
													name="region"
													className="removeBg"
													filterOption={filterByLabel}
													options={
														getRegionsState.apiState ===
															'success' &&
														getRegionsState.data
															.regions?.length >
															0 &&
														getRegionsState.data.regions.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'region',
															value,
															formData,
															setFormData,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									<Col xs={24} lg={8} md={12}>
										<XForm.Item name="logo" label="Logo">
											<XUpload
												style={{ width: '100%' }}
												maxCount={1}
												defaultFileList={
													id ? [uploadedFile] : []
												}
												accept="image/png, image/jpeg"
												customRequest={(options) => {
													setFileToUpload(options);
													options.onSuccess();
												}}
											>
												<XShareButton
													icon={<UploadOutlined />}
													style={{
														height: '45px',
														width: '100%',
														margin: '0px',
													}}
												>
													Upload Logo
												</XShareButton>
											</XUpload>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={8} md={12}>
										<XForm.Item
											name="visit_types"
											label="Visit Options"
											rules={[
												{
													required: true,
													message:
														'Please add visit options',
												},
											]}
										>
											<XSelect
												mode="tags"
												placeholder="Please add visit options"
												onChange={(value) => {
													handleSelectChange(
														'visit_types',
														value,
														formData,
														setFormData,
													);
												}}
												size="large"
												style={{ width: '100%' }}
												onInput={upperCaseInput}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={24} md={24} sm={24}>
										<XForm.Item
											label="Header"
											name="header"
											rules={[
												{
													required: true,
													message:
														'Please enter Header',
												},
											]}
										>
											<Editor
												apiKey={config.tiny_url_key}
												initialValue={formData.header}
												onInit={(evt, editor) =>
													(headerEditorRef.current =
														editor)
												}
												init={{
													menubar: false,
													height: 200,
													width: '100%',
													plugins: [
														'code',
														'image',
														'link',
														'visualblocks',
													],
													branding: false,
													toolbar:
														'undo redo | formatselect | ' +
														'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
														'bullist numlist outdent indent | image link |' +
														'removeformat | visualblocks code | help ',
													content_style:
														'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
													font_size_formats:
														'8px 10px 12px 14px 18px 24px 36px',
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} lg={24} md={24} sm={24}>
										<XForm.Item
											label="Footer"
											name="footer"
											rules={[
												{
													required: true,
													message:
														'Please enter Footer',
												},
											]}
										>
											<Editor
												apiKey={config.tiny_url_key}
												onInit={(evt, editor) =>
													(footerEditorRef.current =
														editor)
												}
												initialValue={formData.footer}
												init={{
													menubar: false,
													height: 200,
													width: '100%',
													plugins: [
														'code',
														'image',
														'link',
														'visualblocks',
													],
													branding: false,
													toolbar:
														'undo redo | formatselect | ' +
														'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
														'bullist numlist outdent indent | image link |' +
														'removeformat | visualblocks code | help ',
													content_style:
														'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
													font_size_formats:
														'8px 10px 12px 14px 18px 24px 36px',
												}}
											/>
										</XForm.Item>
									</Col>
								</Row>
								<Row gutter={[15, 5]}>
									<Col
										xs={24}
										lg={{ span: 6, offset: 0 }}
										md={{ span: 6, offset: 0 }}
										sm={{ span: 6, offset: 0 }}
										className="text-start"
									>
										{id ? (
											<Button
												onClick={() =>
													showDeleteConfirm(id)
												}
											>
												Delete Hospital
											</Button>
										) : (
											<></>
										)}
									</Col>
									<Col
										xs={24}
										lg={18}
										md={18}
										sm={18}
										className="text-end"
									>
										<Button
											onClick={() => {
												form.resetFields();
												navigate('/hospital');
												setFormData({});
											}}
											className="cancel"
										>
											Cancel
										</Button>
										<Button htmlType="submit">
											Submit
										</Button>
									</Col>
								</Row>
							</XForm>
						) : (
							<Result
								status="403"
								title="403"
								subTitle="Sorry, you are not authorized to access this page."
								extra={
									<Button
										type="primary"
										onClick={() => navigate('/')}
									>
										Back Home
									</Button>
								}
							/>
						)}
					</XCardsTransparent>
				</XContainer>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getHospitalByIdState: state.getHospitalById,
	editHospitalState: state.editHospital,
	addHospitalState: state.addHospital,
	getStateDropdownState: state.getStateDropdown,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
	deleteHospitalByIdState: state.deleteHospitalById,
	deleteHospitalState: state.deleteHospital,
});

const mapDispatchToProps = (dispatch) => ({
	getHospitalById: (data) => dispatch(getHospitalByIdApi(data)),
	getHospitalByIdReset: () => dispatch(getHospitalByIdReset()),
	editHospital: (data) => dispatch(editHospitalApi(data)),
	editHospitalReset: () => dispatch(editHospitalReset()),
	addHospital: (data) => dispatch(addHospitalApi(data)),
	addHospitalReset: () => dispatch(addHospitalReset()),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getOrganizationsReset: () => dispatch(getOrganizationsReset()),
	getRegionsReset: () => dispatch(getRegionsReset()),
	deleteHospital: (data) => dispatch(deleteHospitalApi(data)),

	deleteHospitalReset: () => dispatch(deleteHospitalReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HospitalForm);
