import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';
export const GET_USER_BY_ID_RESET = 'GET_USER_BY_ID_RESET';

export const getUserById = (params) => {
	return {
		type: GET_USER_BY_ID,
		params,
	};
};

export const getUserByIdSuccess = (response) => {
	return {
		type: GET_USER_BY_ID_SUCCESS,
		response,
	};
};

export const getUserByIdFail = (response) => {
	return {
		type: GET_USER_BY_ID_FAIL,
		response,
	};
};

export const getUserByIdReset = () => {
	return {
		type: GET_USER_BY_ID_RESET,
	};
};

export const getUserByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getUserById());
		axios
			.get(
				`${config.api.base_url}/settings/get-user-details/${data.id}`,
				{
					params: data,
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const getUserByIdData = response.data;
				dispatch(getUserByIdSuccess(getUserByIdData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getUserByIdFail(errorMsg));
			});
	};
};
