
import { 
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAIL, 
    GET_ROLES_RESET,
} from "../actions/getRolesAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getRolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_ROLES_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_ROLES_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getRolesReducer;
