import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import {
	Row,
	Col,
	Input,
	Button,
	Spin,
	notification,
	Dropdown,
	Menu,
} from 'antd';
import moment from 'moment';
import {
	ChatBoxDiv,
	ChatHeader,
	HeaderIcon,
	Heading,
	ChatRight,
	SmallTextRight,
	ChatLeft,
	SmallTextLeft,
	InputDiv,
	InnerInput,
	SendButton,
	ChatWrapper,
} from './ChatStyle';
import './styles.css';
import {
	getMessagesApi,
	getMessagesReset,
} from '../../actions/getMessagesAction';
import { addMessageApi, addMessageReset } from '../../actions/addMessageAction';
import {
	getChatUsersApi,
	getChatUsersReset,
} from '../../actions/getChatUsersAction';
import { getUserId } from '../../utils/helper';
import Search from 'antd/es/transfer/search';
import { ConsoleSqlOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const ChatBox = (props) => {
	let {
		case_id,
		getMessages,
		getMessagesReset,
		getMessagesState,
		addMessage,
		addMessageState,
		addMessageReset,
		getChatUsersState,
		getChatUsers,
		getChatUsersReset,
	} = props;
	const inputRef = useRef(null);
	const chatWrapperRef = useRef(null);
	const debounceTimeout = useRef(null);
	const [message, setMessage] = useState('');
	const [messageList, setMessageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState([]);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [taggedUsersList, setTaggedUsersList] = useState([]);

	let login_user_id = getUserId();

	useEffect(() => {
		if (getMessagesState.apiState === 'success') {
			if (getMessagesState.data) {
				setMessageList(getMessagesState.data?.message);
			}
			setLoading(false);
		} else if (getMessagesState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: getMessagesState.message });
		}
		getMessagesReset();
	}, [getMessagesState]);

	useEffect(() => {
		if (case_id) {
			setLoading(true);
			getMessages({ case_id });
		}
	}, [case_id]);

	const handleInputChange = (e) => {
		const value = e.target.value;
		setMessage(value);

		const lastWord = value.split(' ').pop();

		if (lastWord.startsWith('@')) {
			console.log('in');
			const searchQuery = lastWord.slice(1);
			// Clear the previous timeout if it exists
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
			// Set a new timeout to call the API
			debounceTimeout.current = setTimeout(() => {
				getChatUsers({ search: searchQuery });
			}, 500); // 500ms debounce delay

			// getChatUsers({search:searchQuery});
			setDropdownVisible(true);
		} else {
			setDropdownVisible(false);
		}

		// Check if a mention has been removed
		const updatedTaggedUsersList = [...taggedUsersList];
		let updatedMessage = value;

		taggedUsersList.forEach((mention, index) => {
			const mentionName = `@${mention.name}`;
			const mentionIndex = value.indexOf(mentionName);

			if (mentionIndex === -1 && !value.includes(mentionName)) {
				updatedTaggedUsersList.splice(index, 1); // Remove the mention from the array
				// If part of the mention is removed, remove the entire mention
				const truncatedMention = mentionName.slice(0, -1);
				// const mentionRegex = new RegExp(`\\u200B${truncatedMention}\\u200B\\S*`, 'g');
				const mentionRegex = new RegExp(
					`\\s?${truncatedMention}\\S*`,
					'g',
				);
				updatedMessage = updatedMessage
					.replace(mentionRegex, '')
					.trim();
				updatedMessage = `${updatedMessage}${' '}`;
			}
		});

		// Update the message and tagged users list
		setMessage(updatedMessage);
		setTaggedUsersList(updatedTaggedUsersList);
	};

	// Cleanup on component unmount
	useEffect(() => {
		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
		};
	}, []);

	useEffect(() => {
		if (getChatUsersState.apiState === 'success') {
			setLoading(false);
			let all_users_list = getChatUsersState.data;
			const formattedUsers = all_users_list.map((user, index) => ({
				label: user.name, // or the appropriate field for the user's name
				key: user._id, // Use a unique identifier, such as index or user.id
			}));
			setShowSuggestions(formattedUsers);
		} else if (getChatUsersState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: getChatUsersState.message });
		}
		addMessageReset();
	}, [getChatUsersState]);

	useEffect(() => {
		if (addMessageState.apiState === 'success') {
			setMessage('');
			setTaggedUsersList([]);
			getMessages({ case_id });
		} else if (addMessageState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: addMessageState.message });
		}
		addMessageReset();
	}, [addMessageState]);

	const sendMessage = () => {
		if (message.trim() !== '') {
			let data = {
				case_id,
				message,
				taggedUsersList,
			};
			setLoading(true);
			addMessage(data);
			// setMessage('');
		}
	};

	useEffect(() => {
		if (chatWrapperRef.current) {
			chatWrapperRef.current.scrollTop =
				chatWrapperRef.current.scrollHeight;
		}
	}, [messageList]);

	// Function to handle Enter key press
	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendMessage();
		}
	};

	const handleSuggestionClick = (name) => {
		const atIndex = message.lastIndexOf('@');
		const newMessage = `${message.slice(0, atIndex)}@${name} `;
		setMessage(newMessage);
		setShowSuggestions(false);
		setDropdownVisible(false);
	};

	const renderColoredMessage = (msg) => {
		const parts = msg.split(' ');
		return parts.map((part, index) => {
			if (part.startsWith('@')) {
				return (
					<span key={index} style={{ color: 'blue' }}>
						{part}
					</span>
				);
			}
			return <span key={index}>{part}</span>;
		});
	};

	const handleMenuClick = (item) => {
		const selectedName = `@${item.label}`;

		// Remove the last @mention from the message
		const lastAtIndex = message.lastIndexOf('@');
		const lastSpaceIndex = message.lastIndexOf(' ', lastAtIndex);
		const newMessage = `${message.substring(
			0,
			lastSpaceIndex + 1,
		)}${selectedName}${' '}`;

		setMessage(newMessage);
		if (inputRef.current) {
			inputRef.current.focus();
		}
		setDropdownVisible(false);
		let obj = {
			id: item.key,
			name: item.label,
		};
		// Push the key of the selected name into the mentions array
		setTaggedUsersList([...taggedUsersList, obj]);
	};

	const menu = (
		<Menu
			style={{
				maxHeight: '300px', // Increase height
				width: '200px', // Increase width
				overflowY: 'auto', // Add scroll if content overflows
			}}
		>
			{showSuggestions.map((item) => {
				return (
					<Menu.Item
						onClick={() => {
							handleMenuClick(item);
						}}
						key={item.key}
					>
						{item.label}
					</Menu.Item>
				);
			})}
			{/* {showSuggestions.map(name => (
			
		  ))} */}
		</Menu>
	);

	return (
		<ChatBoxDiv>
			<ChatHeader>
				<Row>
					<Col xs={24}>
						<HeaderIcon>
							<img
								style={{ marginLeft: '9px' }}
								src="/images/chat-header-icon.svg"
								alt="header Icon"
							/>
						</HeaderIcon>
						<Heading>Notes</Heading>
					</Col>
				</Row>
			</ChatHeader>
			<Spin spinning={loading} size="large">
				<ChatWrapper ref={chatWrapperRef}>
					{messageList.map((item, index) => {
						return (
							<React.Fragment key={index}>
								{!login_user_id ||
								login_user_id?.toString() ===
									item.user_id?._id?.toString() ? (
									<ChatRight>
										<span
											className="Chat-Right"
											dangerouslySetInnerHTML={{
												__html: item.message,
											}}
										>
											{/* {item.message} */}
										</span>
										<br />
										<SmallTextRight>
											<span style={{ textAlign: 'left' }}>
												{moment(item.created_at).format(
													'DD MMM YYYY HH:mm',
												)}
												{/* {moment(item.created_at).format(
												'DD-MM-YYYY',
											)}{' '}
											{moment(item.created_at).format(
												'HH:mm',
											)} */}
											</span>
											<span
												style={{ textAlign: 'right' }}
											>
												{item.user_id?.name}
											</span>
										</SmallTextRight>
									</ChatRight>
								) : (
									<>
										<div className="w-100 clearfix"></div>
										<ChatLeft>
											<span
												className="Chat-Left"
												dangerouslySetInnerHTML={{
													__html: item.message,
												}}
											>
												{/* {item.message} */}
											</span>
											<br />
											<SmallTextLeft>
												<span
													style={{
														textAlign: 'left',
													}}
												>
													{moment(
														item.created_at,
													).format(
														'DD MMM YYYY HH:mm',
													)}
													{/* {moment(item.created_at).format(
													'DD-MM-YYYY',
												)}{' '}
												{moment(item.created_at).format(
													'HH:mm',
												)} */}
												</span>
												<span
													style={{
														textAlign: 'right',
													}}
												>
													{item.user_id?.name}
												</span>
											</SmallTextLeft>
										</ChatLeft>
									</>
								)}
							</React.Fragment>
						);
					})}
				</ChatWrapper>
			</Spin>

			<InputDiv>
				<Row>
					<Col xs={18} sm={18} md={18} lg={18} xl={18}>
						<Dropdown
							open={dropdownVisible}
							overlay={menu}
							placement="topLeft"
							overlayStyle={{
								overflowY: 'auto',
								zIndex: 9999, // Ensure this is higher than -1
							}}
							arrow
						>
							{/* <></> */}
							{/* <Button>top</Button> */}

							<TextArea
								ref={inputRef}
								value={message}
								onChange={handleInputChange}
								onKeyDown={handleKeyPress}
								placeholder="Enter your message"
								// autoSize = {{minRows:'4',maxRows:'5'}}
								autoSize
							/>
							{/* <div
								ref={inputRef}
								className="editableDiv"
								dangerouslySetInnerHTML={{
									__html: message,
								}}
								contenteditable="true"
								onInput={handleInputChange} // Use onInput to detect content changes
								onKeyDown={handleKeyPress}
								aria-label="History is on"
							>
								
							</div> */}
						</Dropdown>
						{/* <Input
							type="text"
							value={message}
							onChange={handleInputChange}
							size="large"
							placeholder="Enter your message"
						/> */}
					</Col>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						className="d-flex align-items-end justify-content-center text-center"
					>
						<SendButton onClick={sendMessage} size="large">
							<img src="/images/chat-send-icon.svg" />
						</SendButton>
					</Col>
				</Row>
			</InputDiv>
		</ChatBoxDiv>
	);
};

const mapStateToProps = (state) => ({
	getMessagesState: state.getMessages,
	addMessageState: state.addMessage,
	getChatUsersState: state.getChatUsers,
});

const mapDispatchToProps = (dispatch) => ({
	getMessages: (params) => dispatch(getMessagesApi(params)),
	getMessagesReset: () => dispatch(getMessagesReset()),
	getChatUsers: (params) => dispatch(getChatUsersApi(params)),
	getChatUsersReset: () => dispatch(getChatUsersReset()),
	addMessage: (data) => dispatch(addMessageApi(data)),
	addMessageReset: (params) => dispatch(addMessageReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
