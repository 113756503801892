
import { 
    ADD_HOSPITAL,
    ADD_HOSPITAL_SUCCESS,
    ADD_HOSPITAL_FAIL, 
    ADD_HOSPITAL_RESET,
} from "../actions/addHospitalAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const addHospitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_HOSPITAL:
            return {
                ...state,
                apiState: "loading",
            };
        case ADD_HOSPITAL_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case ADD_HOSPITAL_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case ADD_HOSPITAL_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default addHospitalReducer;
