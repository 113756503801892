import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ANALYSE_COVERAGE = 'ANALYSE_COVERAGE';
export const ANALYSE_COVERAGE_SUCCESS = 'ANALYSE_COVERAGE_SUCCESS';
export const ANALYSE_COVERAGE_FAIL = 'ANALYSE_COVERAGE_FAIL';
export const ANALYSE_COVERAGE_RESET = 'ANALYSE_COVERAGE_RESET';

export const analyseCoverage = (params) => {
	return {
		type: ANALYSE_COVERAGE,
		params,
	};
};

export const analyseCoverageSuccess = (response) => {
	return {
		type: ANALYSE_COVERAGE_SUCCESS,
		response,
	};
};

export const analyseCoverageFail = (response) => {
	return {
		type: ANALYSE_COVERAGE_FAIL,
		response,
	};
};

export const analyseCoverageReset = () => {
	return {
		type: ANALYSE_COVERAGE_RESET,
	};
};

export const analyseCoverageApi = (data) => {
	return (dispatch) => {
		dispatch(analyseCoverage());
		axios
			.post(`${config.api.base_url}/coverage/analyse-coverage`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
				timeout: 300000,
			})
			.then((response) => {
				const analyseCoverageData = response.data;
				dispatch(analyseCoverageSuccess(analyseCoverageData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(analyseCoverageFail(errorMsg));
			});
	};
};
