import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_INSURANCE_COMPANY = 'DELETE_INSURANCE_COMPANY';
export const DELETE_INSURANCE_COMPANY_SUCCESS =
	'DELETE_INSURANCE_COMPANY_SUCCESS';
export const DELETE_INSURANCE_COMPANY_FAIL = 'DELETE_INSURANCE_COMPANY_FAIL';
export const DELETE_INSURANCE_COMPANY_RESET = 'DELETE_INSURANCE_COMPANY_RESET';

export const deleteInsuranceCompany = (params) => {
	return {
		type: DELETE_INSURANCE_COMPANY,
		params,
	};
};

export const deleteInsuranceCompanySuccess = (response) => {
	return {
		type: DELETE_INSURANCE_COMPANY_SUCCESS,
		response,
	};
};

export const deleteInsuranceCompanyFail = (response) => {
	return {
		type: DELETE_INSURANCE_COMPANY_FAIL,
		response,
	};
};

export const deleteInsuranceCompanyReset = () => {
	return {
		type: DELETE_INSURANCE_COMPANY_RESET,
	};
};

export const deleteInsuranceCompanyApi = (data) => {
	return (dispatch) => {
		dispatch(deleteInsuranceCompany());
		axios
			.delete(`${config.api.base_url}/insurance-companies/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteInsuranceCompanyData = response.data;
				dispatch(
					deleteInsuranceCompanySuccess(deleteInsuranceCompanyData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteInsuranceCompanyFail(errorMsg));
			});
	};
};
