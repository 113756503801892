import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_HOSPITALS = 'GET_HOSPITALS';
export const GET_HOSPITALS_SUCCESS = 'GET_HOSPITALS_SUCCESS';
export const GET_HOSPITALS_FAIL = 'GET_HOSPITALS_FAIL';
export const GET_HOSPITALS_RESET = 'GET_HOSPITALS_RESET';

export const getHospitals = (params) => {
	return {
		type: GET_HOSPITALS,
		params,
	};
};

export const getHospitalsSuccess = (response) => {
	return {
		type: GET_HOSPITALS_SUCCESS,
		response,
	};
};

export const getHospitalsFail = (response) => {
	return {
		type: GET_HOSPITALS_FAIL,
		response,
	};
};

export const getHospitalsReset = () => {
	return {
		type: GET_HOSPITALS_RESET,
	};
};

export const getHospitalsApi = (data) => {
	return (dispatch) => {
		dispatch(getHospitals());
		axios
			.get(`${config.api.base_url}/hospitals/all`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getHospitalsData = response.data;
				dispatch(getHospitalsSuccess(getHospitalsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getHospitalsFail(errorMsg));
			});
	};
};
