import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
	Timeline,
	Tag,
	Row,
	Col,
	Button,
	Tooltip,
	Card,
	Space,
	Image,
	Modal,
	Divider,
	Dropdown,
	notification,
	Upload,
	DatePicker,
	InputNumber,
	Input,
	Select,
	Collapse,
} from 'antd';
import axios from 'axios';
import './index.css';
import {
	XFilled,
	EditOutlined,
	ClockCircleOutlined,
	FileOutlined,
	CheckCircleOutlined,
	FileTextOutlined,
	SmileOutlined,
	UploadOutlined,
	CaretDownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
	checkModulePermissions,
	toTitleCase,
	getUserToken,
} from '../../utils/helper';
import config from '../../config';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { XButtonRounded } from '../../pages/AppealLetter/AppealLetterViewStyle';
import {
	XButtonConfirm,
	XButtonLike,
} from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';
import {
	XCardsCaseBg,
	XForm,
	XModal,
	XShareButton,
} from '../../styles/GlobalStyle';
import { TimelineCard, TimelineEditCard, XCollapse } from './TimelineStyle';
import {
	CaseUpdateCard,
	XRadio,
	XUpload,
} from '../../pages/CaseForm/CaseFormStyle';
const { Dragger } = Upload;
const { TextArea } = Input;
// Dynamic Timeline
const CustomTimeline = ({
	handlePreviewBeforUpload,
	handleChange,
	handleDownload,
	fileList,
	selectedOption,
	filterByLabel,
	denialReasonData,
	handleSelect,
	disableFutureDates,
	handleDateChange,
	updateModalOpen,
	setUpdateModalOpen,
	handleSubmit,
	updateForm,
	status,
	setRevenueCycleStatus,
	timelineData,
	revenueCycleFormData,
	setRevenueCycleFormData,
	revenueCycleStatus,
	handleInputChange,
	id,
	togglePrepareLetter,
	setTogglePrepareLetter,
	setUpdatedFileList,
	setFileList,
	setSelectedOption,
	medicareCoverage,
	case_status,
	setFlag,
	setRevenueCycleStage,
	revenueCycleStage,
	patientData,
	setApproveModalOpen,
	setDownloadLoading,
	updateCaseLoading,
	updateModalButtonText,
	setCurrentActiveCollapse,
	setPrevActiveCollapse,
	prevActiveCollapse,
	currentActiveCollapse,
}) => {
	const navigate = useNavigate();
	const [documentText, setDocumentText] = useState(null);
	const [statusName, setStatusName] = useState(null);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [previewImage, setPreviewImage] = useState('');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [prepareButtonDisabled, setPrepareButtonDisabled] = useState(false);
	const [displayFaxUpload, setDisplayFaxUpload] = useState(false);
	const [displayEmailContent, setDisplayEmailContent] = useState(false);
	const [letterDownloaded, setLetterDownloaded] = useState(false);
	const [isShareModal, setIsShareModal] = useState(false);
	const items = [
		{
			key: '1',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
						padding: '20px auto',
					}}
					onClick={() => {
						setDisplayEmailContent(true);
					}}
				>
					<img src="/images/email-icon.svg" alt="fax" />
					Share Via Email
				</div>
			),
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
					}}
					onClick={() => {
						setDisplayEmailContent(false);
						setIsShareModal(true);
						setDisplayFaxUpload(true);
						setLetterDownloaded(false);
					}}
				>
					<img src="/images/fax-icon.svg" alt="fax" />
					Share Via Fax
				</div>
			),
			//   icon: <SmileOutlined />,
			//   disabled: true,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
					}}
					onClick={() => {
						getDownloadSignedURL();
						setDownloadLoading(true);
					}}
				>
					<img src="/images/download1-icon.svg" alt="fax" />
					Download PDF
				</div>
			),
		},
	];
	const getDownloadSignedURL = async () => {
		try {
			let length = patientData.appeal_letter_logs.length - 1;
			const response = await axios.get(
				`${config.api.base_url}/settings/get-Appeal-Signed-Url?fileName=${patientData.appeal_letter_logs[length]?.url[0]?.url}`,
				{
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			let res = response.data;
			if (res.status === 1) {
				const pdfResponse = await fetch(res.data);
				const arrayBuffer = await pdfResponse.arrayBuffer();

				const blob = new Blob([arrayBuffer], {
					type: 'application/pdf',
				});
				saveAs(blob, 'Appeal-letter.pdf');
				notification.success({
					message: 'Appeal Letter downloaded',
				});
				if (displayEmailContent) {
					setDownloadLoading(false);
					setIsShareModal(true);
					setLetterDownloaded(true);
				} else {
					setDownloadLoading(false);
					setLetterDownloaded(false);
					setDisplayEmailContent(false);
					setDisplayFaxUpload(false);
					setIsShareModal(false);
				}
				setDownloadLoading(false);
			} else {
				setDownloadLoading(false);
				setLetterDownloaded(false);
				setDisplayEmailContent(false);
				setDisplayFaxUpload(false);
				setIsShareModal(false);
				notification.error({
					message: 'Unable to download Appeal Letter',
				});
			}
		} catch (error) {
			notification.success({
				message: 'Could not upload',
			});
			console.error('Error occurred:', error);
		}
	};

	const handleEmailSend = () => {
		const subject = `Case Details: ${patientData.patient_first_name} ${patientData.patient_last_name} (MRN: ${patientData.mrn})`;
		const body = `Hi,\n\nI hope this email finds you well. Below are the case details regarding denials made by ${
			patientData.insurance_company?.name || ''
		}.\n\nMRN: ${patientData.mrn}\nName: ${
			patientData.patient_first_name
		} ${patientData.patient_last_name}\nAge: ${moment(
			patientData.date_of_birth,
		).fromNow(true)},\nGender: ${
			patientData.gender ? toTitleCase(patientData.gender) : ''
		}\nDOB: ${
			patientData.date_of_birth
				? dateOfBirth(patientData.date_of_birth)
				: ''
		}\nState: ${patientData.state}\nInsurance Company: ${
			patientData.insurance_company?.name || '-'
		}\n\nClinical Summary: ${patientData.clinical_summary}.`;
		const mailtoLink = `mailto:?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;

		// Open the email client
		window.open(mailtoLink);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
		setDownloadLoading(false);
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	const handleShareOk = () => {
		setIsShareModal(false);
	};
	const handleShareCancel = () => {
		setIsShareModal(false);
		setDisplayFaxUpload(false);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
	};

	useEffect(() => {
		if (displayEmailContent) {
			getDownloadSignedURL();
			setDownloadLoading(true);
		}
	}, [displayEmailContent]);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleModalPreviewRemarks = (text, status) => {
		setDocumentText(text);
		setStatusName(status);
		showModal();
	};
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};

	const handlePreview = async (file) => {
		setPdfPreviewOpen(true);
		setPreviewImage(file);
	};

	const getTagColor = (filingStatus) => {
		switch (filingStatus) {
			case 'In Process':
				return '#1f9254';
			case 'At Risk':
				return '#e5aa11';
			case 'Untimely':
				return '#e53711';
			case 'Escalation':
				return 'orange';
			default:
				return 'green'; // default color
		}
	};

	const revenueStatusToStage = (revenueStatus) => {
		return revenueStatus.toLowerCase().replace(/ /g, '_');
	};

	// Modify this function to handle the enabling/disabling of the Confirm Button
	const prepareButtonStatus = () => {
		// Logic to determine if the button should be disabled based on your conditions
		if (
			checkModulePermissions('appeal-letter').sub_modules.includes(
				'confirm-letter',
			)
		) {
			setPrepareButtonDisabled(false);
		} else {
			setPrepareButtonDisabled(true);
		}
	};

	useEffect(() => {
		prepareButtonStatus();
	}, []);

	// const prepareButtonText = () => {
	// 	return patientData?.appeal_letter_sent_for_approval
	// 		? 'Approve Letter'
	// 		: 'Prepare Letter';
	// };

	const prepareButtonText = () => {
		return patientData?.appeal_letter_status === 'md_advisor_approved'
			? 'Send Letter'
			: patientData?.appeal_letter_status === 'md_advisor_sent'
			? 'Approve Letter'
			: patientData?.appeal_letter_status === 'coding_team_sent'
			? 'Verify Letter'
			: 'Prepare Letter';
	};

	// Function to generate timeline items dynamically based on timelineData
	const generateTimelineItems = () => {
		return timelineData.map((item, index) => {
			let itemClassName = '';
			let itemDot = null;
			let itemChildren = null;

			// Determine className, dot, and children based on item properties
			if (item.action_taken) {
				if (item.appeal_status == 'Rejected') {
					itemClassName = 'rejected';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-rejected.svg"
						/>
					);
					itemChildren = (
						<>
							<TimelineCard
								style={{
									background: '#FFF',
									borderColor: '#FDE4E4',
								}}
							>
								<div className="hasPadding">
									<Row
										gutter={[15, 15]}
										className="align-items-center"
									>
										<Col xs={16}>
											<h6 className="title">
												{item.revenue_status}
											</h6>
										</Col>
										<Col xs={8} className="text-end">
											<Tag
												className="tagRejected"
												icon={
													<XFilled
														style={{
															fontSize: '8px',
															height: '8px',
														}}
													/>
												}
											>
												Rejected
											</Tag>
										</Col>
									</Row>
									<Row gutter={[5, 15]} className="mt-3">
										<Col xs={24}>
											<>
												{item.appeal_letter && (
													<>
														{Array.isArray(
															item.appeal_letter,
														) &&
															item.appeal_letter.map(
																(
																	item,
																	index,
																) => {
																	return (
																		<span
																			hoverable
																			className="innerButton mb-0"
																			onClick={() =>
																				handlePreview(
																					`${config.s3_url}${item.url}`,
																				)
																			}
																			id="timeline-documents-hover"
																		>
																			<Space className="flex-wrap">
																				<img
																					src="/images/appeal-letter-icon.svg"
																					style={{
																						width: '20px',
																					}}
																					alt="appeal letter"
																				/>
																				Appeal
																				Letter
																			</Space>
																		</span>
																	);
																},
															)}
													</>
												)}
												{item.remarks && (
													<>
														{Array.isArray(
															item.remarks,
														) ? (
															item.remarks.map(
																(
																	item,
																	index,
																) => {
																	return (
																		<>
																			{item.name
																				.split(
																					'.',
																				)
																				.includes(
																					'pdf',
																				) ? (
																				<>
																					<span
																						hoverable
																						className="innerButton mb-0"
																						onClick={() =>
																							handlePreview(
																								`${config.s3_url}${item.url}`,
																							)
																						}
																						id="timeline-documents-hover"
																					>
																						<Space className="flex-wrap">
																							<img
																								src="/images/denial-letter-icon.svg"
																								style={{
																									width: '20px',
																								}}
																								alt="denial letter"
																							/>
																							Denial
																							Letter
																						</Space>
																					</span>
																				</>
																			) : (
																				<Image
																					style={{
																						borderRadius:
																							'10px',
																					}}
																					height={
																						40
																					}
																					width={
																						40
																					}
																					src={`${config.s3_url}${item.url}`}
																				/>
																			)}
																		</>
																	);
																},
															)
														) : (
															<span
																hoverable
																className="innerButton mb-0"
																onClick={() => {
																	handleModalPreviewRemarks(
																		item.remarks,
																		item.revenue_status,
																	);
																}}
																id="timeline-documents-hover"
															>
																<Space className="flex-wrap">
																	{/* <FileTextOutlined
																style={{
																	fontSize:
																		'20px',
																}}
															/> */}
																	<img
																		src="/images/denial-letter-icon.svg"
																		style={{
																			width: '20px',
																		}}
																		alt="denial letter"
																	/>
																	Denial
																	Letter
																</Space>
															</span>
															// <Card
															// 	hoverable
															// 	style={{
															// 		width: 40,
															// 		height: 40,
															// 	}}
															// 	onClick={() => {
															// 		handleModalPreviewRemarks(
															// 			item.remarks,
															// 			item.revenue_status,
															// 		);
															// 	}}
															// 	className="timeline-documents-hover"
															// >
															// 	<FileTextOutlined />
															// </Card>
														)}
													</>
												)}
											</>
										</Col>
									</Row>
								</div>
								{item && item.edit_denial_case && (
									<TimelineEditCard className="mt-3">
										<Row
											gutter={[15, 15]}
											className="align-items-center"
										>
											<Col xs={24}>
												<XCollapse
													collapsible="icon"
													bordered={false}
													activeKey={
														prevActiveCollapse
													}
													expandIcon={({
														isActive,
													}) =>
														isActive ? (
															<CaretDownOutlined
																onClick={() => {
																	if (
																		!updateCaseLoading
																	) {
																		setPrevActiveCollapse(
																			null,
																		);
																		setCurrentActiveCollapse(
																			null,
																		);
																		setRevenueCycleStatus(
																			'',
																		);
																		updateForm.setFieldsValue(
																			{},
																		);
																		setRevenueCycleFormData(
																			{
																				id: id,
																				reimbursed_at:
																					'',
																				appeal_status:
																					'',
																				claim_category:
																					'',
																				claim_amount:
																					null,
																				status: '',
																				revenue_cycle_stage:
																					'',
																				is_claim_amount_settled:
																					null,
																				claim_amount_updated_at:
																					'',
																				appeal_status_updated_at:
																					'',
																				revenue_cycle_updates:
																					{
																						revenue_cycle_status:
																							'',
																						appeal_status:
																							'',
																						remarks:
																							'',
																						url: [],
																					},
																				denial_reason:
																					'',
																				denial_code1:
																					'',
																				denial_code2:
																					'',
																			},
																		);
																		setRevenueCycleStage(
																			'',
																		);
																		setFileList(
																			[],
																		);
																		setUpdatedFileList(
																			[],
																		);
																		updateForm.resetFields();
																		setSelectedOption(
																			'',
																		);
																	}
																}}
															/>
														) : (
															<img
																src="/images/case-status-edit-icon.svg"
																alt="Edit"
																style={{
																	cursor: 'pointer',
																}}
																onClick={() => {
																	updateForm.setFieldsValue(
																		{},
																	);
																	setPrevActiveCollapse(
																		'1',
																	);
																	setCurrentActiveCollapse(
																		null,
																	);
																	setRevenueCycleStage(
																		revenueStatusToStage(
																			item.revenue_status,
																		),
																	);
																	setRevenueCycleStatus(
																		'',
																	);
																	// setUpdateModalOpen(
																	// 	[
																	// 		true,
																	// 		'',
																	// 	],
																	// );
																	setRevenueCycleFormData(
																		{
																			prev_update: true,
																			id: id,
																			reimbursed_at:
																				'',
																			appeal_status:
																				'',
																			claim_category:
																				'',
																			claim_amount:
																				null,
																			status: '',
																			revenue_cycle_stage:
																				'',
																			is_claim_amount_settled:
																				null,
																			appeal_status_updated_at:
																				'',
																			revenue_cycle_updates:
																				{
																					revenue_cycle_status:
																						'',
																					appeal_status:
																						'',
																					remarks:
																						'',
																					url: [],
																				},
																		},
																	);
																	setFileList(
																		[],
																	);
																	updateForm.resetFields();
																	setUpdatedFileList(
																		[],
																	);
																	setSelectedOption(
																		'',
																	);
																}}
															/>
														)
													}
													expandIconPosition="right"
													items={[
														{
															key: '1',
															label: 'Update Previous Case Status',
															children: (
																<>
																	{/* Update status UI */}
																	<CaseUpdateCard>
																		<hr />
																		<XForm
																			form={
																				updateForm
																			}
																			name="updateRevenue"
																			layout="vertical"
																			autoComplete="off"
																			onFinish={
																				handleSubmit
																			}
																		>
																			<Row
																				gutter={[
																					5,
																					5,
																				]}
																				className="align-items-center"
																			>
																				<Col
																					xs={
																						24
																					}
																				>
																					<XForm.Item
																						name="appeal_status"
																						label="Revenue Status"
																						rules={[
																							{
																								required: true,
																								message:
																									'Please select revenue status!',
																							},
																						]}
																					>
																						<XRadio.Group
																							onChange={(
																								e,
																							) => {
																								handleInputChange(
																									e,
																									revenueCycleFormData,
																									setRevenueCycleFormData,
																								);
																								if (
																									e
																										.target
																										.value ===
																									'approved'
																								) {
																									setRevenueCycleStatus(
																										'approved',
																									);
																								} else if (
																									e
																										.target
																										.value ===
																									'rejected'
																								) {
																									setRevenueCycleStatus(
																										'rejected',
																									);
																								} else {
																									setRevenueCycleStatus(
																										'',
																									);
																								}
																							}}
																							name="appeal_status"
																						>
																							<XRadio
																								value={
																									'approved'
																								}
																							>
																								Approved
																							</XRadio>
																							<XRadio
																								value={
																									'rejected'
																								}
																							>
																								Rejected
																							</XRadio>
																						</XRadio.Group>
																					</XForm.Item>
																				</Col>
																			</Row>

																			<Row
																				gutter={[
																					5,
																					5,
																				]}
																				className="align-items-center"
																			>
																				{revenueCycleStatus ===
																					'approved' && (
																					<div class="animationSlide w-100">
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									colon={
																										false
																									}
																									className="label mb-2"
																									label="Select Approval Date"
																									name="appeal_status_updated_at"
																									rules={[
																										{
																											required: true,
																											message:
																												'Please select Date!',
																										},
																									]}
																								>
																									<DatePicker
																										size="large"
																										name="appeal_status_updated_at"
																										className="inputBox"
																										format="MM/DD/YYYY" // Set display format
																										onChange={(
																											date,
																										) =>
																											handleDateChange(
																												date,
																												'appeal_status_updated_at',
																											)
																										}
																										disabledDate={
																											disableFutureDates
																										}
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>

																						<div className="innerCardWrap">
																							<Row
																								gutter={[
																									5,
																									5,
																								]}
																								className="align-items-center"
																							>
																								<Col
																									xs={
																										24
																									}
																								>
																									<XForm.Item
																										label="Payment Received"
																										name="is_claim_amount_settled"
																										className="label mb-2"
																										rules={[
																											{
																												required: true,
																												message:
																													'Please select!',
																											},
																										]}
																									>
																										<XRadio.Group
																											onChange={(
																												e,
																											) => {
																												handleInputChange(
																													e,
																													revenueCycleFormData,
																													setRevenueCycleFormData,
																												);
																											}}
																											name="is_claim_amount_settled"
																										>
																											<XRadio
																												value={
																													true
																												}
																											>
																												Yes
																											</XRadio>
																											<XRadio
																												value={
																													false
																												}
																											>
																												No
																											</XRadio>
																										</XRadio.Group>
																									</XForm.Item>
																								</Col>
																								{revenueCycleFormData.is_claim_amount_settled ===
																									true && (
																									<>
																										<Col
																											xs={
																												24
																											}
																											className="mt-2"
																										>
																											<XForm.Item
																												className="label mb-2"
																												label="Payment Date"
																												name="reimbursed_at"
																												rules={[
																													{
																														required: true,
																														message:
																															'Please select Reimbursed At!',
																													},
																												]}
																											>
																												<DatePicker
																													name="reimbursed_at"
																													size="large"
																													className="inputBox"
																													format="MM/DD/YYYY" // Set display format
																													onChange={(
																														date,
																													) =>
																														handleDateChange(
																															date,
																															'reimbursed_at',
																														)
																													}
																													disabledDate={
																														disableFutureDates
																													}
																												/>
																											</XForm.Item>
																										</Col>
																									</>
																								)}
																								<Col
																									xs={
																										24
																									}
																								>
																									<XForm.Item
																										label="Payment Type"
																										name="claim_category"
																										className="label mb-2"
																										rules={[
																											{
																												required: true,
																												message:
																													'Please select Claim Type!',
																											},
																										]}
																									>
																										<XRadio.Group
																											onChange={(
																												e,
																											) => {
																												handleInputChange(
																													e,
																													revenueCycleFormData,
																													setRevenueCycleFormData,
																												);
																											}}
																											name="claim_category"
																										>
																											<XRadio
																												value={
																													'full'
																												}
																											>
																												Full
																											</XRadio>
																											<XRadio
																												value={
																													'partial'
																												}
																											>
																												Partial
																											</XRadio>
																										</XRadio.Group>
																									</XForm.Item>
																								</Col>
																								<Col
																									xs={
																										24
																									}
																								>
																									<XForm.Item
																										name="claim_amount"
																										label="Claim Amount"
																										className="label mb-2"
																										rules={[
																											{
																												required: true,
																												message:
																													'Please Enter Claim Amount!',
																											},
																										]}
																									>
																										<InputNumber
																											addonBefore={
																												'$'
																											}
																											size="large"
																											min={
																												0
																											}
																											placeholder="Enter Claim Amount"
																											name="claim_amount"
																											className="inputBox"
																											// disabled={
																											// 	revenueCycleFormData.claim_category ===
																											// 	'full'
																											// }
																											onChange={(
																												e,
																											) =>
																												setRevenueCycleFormData(
																													(
																														prev,
																													) => ({
																														...prev,
																														claim_amount:
																															e,
																													}),
																												)
																											}
																										/>
																									</XForm.Item>
																								</Col>
																							</Row>
																						</div>
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									name="remarks"
																									label="Enter Remarks"
																								>
																									<TextArea
																										className="w-100"
																										name="remarks"
																										showCount
																										maxLength={
																											100
																										}
																										style={{
																											height: 120,
																											marginBottom: 24,
																										}}
																										onChange={(
																											e,
																										) =>
																											handleInputChange(
																												e,
																												revenueCycleFormData,
																												setRevenueCycleFormData,
																											)
																										}
																										placeholder="Enter remarks"
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>
																					</div>
																				)}
																				{revenueCycleStatus ===
																					'rejected' && (
																					<div class="animationSlide w-100">
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									colon={
																										false
																									}
																									className="label mb-2"
																									label="Select Rejected Date"
																									name="appeal_status_updated_at"
																									rules={[
																										{
																											required: true,
																											message:
																												'Please select Date!',
																										},
																									]}
																								>
																									<DatePicker
																										size="large"
																										name="appeal_status_updated_at"
																										className="inputBox"
																										format="MM/DD/YYYY" // Set display format
																										onChange={(
																											date,
																										) =>
																											handleDateChange(
																												date,
																												'appeal_status_updated_at',
																											)
																										}
																										disabledDate={
																											disableFutureDates
																										}
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									className="label mb-2"
																									name="denial_reason"
																									label={
																										'Denial Type'
																									}
																									rules={[
																										{
																											required: true,
																											message:
																												'Please select Denial Type!',
																										},
																									]}
																								>
																									<Select
																										className="selectBox"
																										size="large"
																										placeholder="Select Denial Type"
																										name="denial_reason"
																										filterOption={
																											filterByLabel
																										}
																										options={
																											denialReasonData
																										}
																										onSelect={(
																											value,
																										) =>
																											handleSelect(
																												value,
																												'denial_reason',
																											)
																										}
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									className="label mb-2"
																									name="denial_code1"
																									label="Denial Code 1"
																								>
																									<Input
																										size="large"
																										placeholder="Enter CARC"
																										name="denial_code1"
																										className="inputBox  inputText"
																										onChange={
																											handleInputChange
																										}
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									className="label mb-2"
																									name="denial_code2"
																									label="Denial Code 2"
																								>
																									<Input
																										size="large"
																										placeholder="Enter RARC"
																										name="denial_code2"
																										className="inputBox inputText"
																										onChange={
																											handleInputChange
																										}
																									/>
																								</XForm.Item>
																							</Col>
																						</Row>
																						<Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<XForm.Item
																									className="mb-0"
																									label="Denial Reason"
																									name="rejected_category"
																									rules={[
																										{
																											required: true,
																											message:
																												'Please select option!',
																										},
																									]}
																								>
																									<XRadio.Group
																										onChange={(
																											e,
																										) => {
																											handleInputChange(
																												e,
																												revenueCycleFormData,
																												setRevenueCycleFormData,
																											);
																											updateForm.setFieldsValue(
																												{
																													remarks:
																														'',
																													revenue_cycle_supporting_documents:
																														'',
																												},
																											);
																											setFileList(
																												[],
																											);
																											setRevenueCycleFormData(
																												(
																													prevState,
																												) => ({
																													...prevState,
																													revenue_cycle_updates:
																														{
																															...prevState.revenue_cycle_updates,
																															remarks:
																																'',
																															url: [],
																														},
																												}),
																											);
																											if (
																												e
																													.target
																													.value ===
																												'revenue_cycle_supporting_text'
																											) {
																												setSelectedOption(
																													'revenue_cycle_supporting_text',
																												);
																											} else if (
																												e
																													.target
																													.value ===
																												'revenue_cycle_supporting_documents'
																											) {
																												setSelectedOption(
																													'revenue_cycle_supporting_documents',
																												);
																											}
																										}}
																										name="rejected_category"
																									>
																										<XRadio
																											className="pb-2"
																											value={
																												'revenue_cycle_supporting_text'
																											}
																										>
																											Enter
																											Reason
																										</XRadio>
																										<XRadio
																											className="pb-2"
																											value={
																												'revenue_cycle_supporting_documents'
																											}
																										>
																											Upload
																											Denial
																											Letter
																										</XRadio>
																									</XRadio.Group>
																								</XForm.Item>
																							</Col>
																						</Row>

																						{selectedOption ===
																							'revenue_cycle_supporting_text' && (
																							<Row>
																								<Col
																									xs={
																										24
																									}
																									className="text-end mt-4"
																								>
																									<XForm.Item
																										name="remarks"
																										label="Enter Reason"
																										rules={[
																											{
																												required: true,
																												message:
																													'Please enter reason',
																											},
																										]}
																									>
																										<TextArea
																											className="w-100"
																											name="remarks"
																											showCount
																											style={{
																												height: 120,
																												marginBottom: 24,
																											}}
																											onChange={(
																												e,
																											) =>
																												handleInputChange(
																													e,
																													revenueCycleFormData,
																													setRevenueCycleFormData,
																												)
																											}
																											placeholder="Enter Reason"
																										/>
																									</XForm.Item>
																								</Col>
																							</Row>
																						)}
																						{selectedOption ===
																							'revenue_cycle_supporting_documents' && (
																							<Row className="mt-3">
																								<Col
																									xs={
																										24
																									}
																								>
																									<XForm.Item
																										className="label mb-2"
																										name="revenue_cycle_supporting_documents"
																										label="Upload Denial Letter"
																										rules={[
																											{
																												required: true,
																												validator:
																													(
																														_,
																														value,
																													) => {
																														if (
																															fileList.length ===
																															0
																														) {
																															return Promise.reject(
																																new Error(
																																	'Please upload denial letter!',
																																),
																															);
																														}
																														return Promise.resolve();
																													},
																											},
																										]}
																									>
																										<XUpload
																											action={
																												null
																											}
																											showUploadList={{
																												showPreviewIcon: false,
																											}}
																											className="supporting_files timeline-upload"
																											listType="picture-card"
																											fileList={
																												fileList
																											}
																											onPreview={
																												handlePreviewBeforUpload
																											}
																											multiple={
																												false
																											}
																											maxCount={
																												1
																											}
																											onChange={
																												handleChange
																											}
																											accept=".pdf"
																											beforeUpload={(
																												file,
																											) => {
																												const isJPEG =
																													file.type ===
																													'image/jpeg';
																												const isJPG =
																													file.type ===
																													'image/jpg';
																												const isPNG =
																													file.type ===
																													'image/png';
																												const isGIF =
																													file.type ===
																													'image/gif';
																												const isVideo =
																													file.type.startsWith(
																														'video/',
																													);
																												const isAudio =
																													file.type.startsWith(
																														'audio/',
																													);

																												const isLt10M =
																													file.size /
																														1024 /
																														1024 <
																													10;

																												if (
																													!isLt10M
																												) {
																													notification.error(
																														{
																															message:
																																'File Size Exceeded',
																															description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																														},
																													);
																												}
																												if (
																													isGIF ||
																													isVideo ||
																													isAudio ||
																													isJPEG ||
																													isJPG ||
																													isPNG
																												) {
																													notification.error(
																														{
																															message:
																																'File Type Error',
																															description: `GIF, video, image and audio files are not allowed.`,
																														},
																													);
																												}

																												return (
																													isLt10M &&
																													!isGIF &&
																													!isVideo &&
																													!isAudio &&
																													!isJPEG &&
																													!isJPG &&
																													!isPNG
																												);
																											}}
																											onDownload={
																												handleDownload
																											}
																										>
																											{fileList.length >=
																											1 ? null : (
																												<div className="uploadCard w-100">
																													<UploadOutlined />
																													<br />
																													Upload
																													Denial
																													Letter
																												</div>
																											)}
																										</XUpload>
																									</XForm.Item>
																								</Col>
																							</Row>
																						)}
																					</div>
																				)}
																			</Row>
																			<Row className="my-3">
																				<Col
																					xs={
																						24
																					}
																					className="text-center"
																				>
																					<XButtonConfirm
																						type="primary"
																						htmlType="submit"
																						className="btnActive m-0 w-100"
																						loading={
																							updateCaseLoading
																						}
																					>
																						{
																							updateModalButtonText
																						}
																					</XButtonConfirm>
																				</Col>
																			</Row>
																		</XForm>
																	</CaseUpdateCard>
																</>
															),
														},
													]}
												/>
											</Col>
										</Row>
									</TimelineEditCard>
								)}
							</TimelineCard>
						</>
					);
				} else {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<TimelineCard
							style={{
								background: '#EAFFE7',
								borderColor: '#D4F5D0',
							}}
						>
							<div className="hasPadding">
								<Row
									gutter={[15, 15]}
									className="align-items-center"
								>
									<Col xs={12}>
										<h6 className="title">
											{item.revenue_status}
										</h6>
									</Col>
									<Col xs={12} className="text-end">
										<Tag
											className="tagSuccess"
											icon={
												<CheckCircleOutlined
													style={{
														fontSize: '16px',
														lineHeight: '20px',
														background: 'none',
													}}
												/>
											}
										>
											Approved
										</Tag>
									</Col>
								</Row>
							</div>
						</TimelineCard>
					);
				}
			} else {
				if (item.appeal_status == 'Sent') {
					itemClassName = 'inprogress';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<TimelineCard
							style={{
								background: '#FFF',
								borderColor: '#FDE4E4',
							}}
						>
							<div className="hasPadding">
								<Row
									gutter={[15, 15]}
									className="align-items-center"
								>
									<Col xs={8}>
										<h6 className="title">
											{item.revenue_status}
										</h6>
									</Col>
									<Col span={16} className="text-end">
										{item.days_ago && (
											<Tag
												color={getTagColor(
													item.filing_status,
												)}
												className="tagSuccess"
												// icon={
												// 	<ClockCircleOutlined
												// 		style={{
												// 			fontSize: '12px',
												// 			lineHeight: '20px',
												// 		}}
												// 	/>
												// }
											>
												{' '}
												{item.filing_status !==
												'Untimely' ? (
													<>
														{`Awaiting Payer Response.`}{' '}
														<br />
														{item.days_left}{' '}
														{item.timeline_type
															? toTitleCase(
																	item.timeline_type,
															  )
															: item.timeline_type}{' '}
														{`Until Overdue`}
													</>
												) : (
													'Awaiting Payer Response. Timeline Overdue.'
												)}
											</Tag>
										)}
									</Col>
								</Row>

								<Row
									gutter={[15, 15]}
									className="align-items-center mt-3"
								>
									<Col xs={22}>
										<div className="timeLineButton">
											{checkModulePermissions(
												'ma-plan-appeals-processing',
											).sub_modules.includes(
												'prepare-appeal-letter',
											) ||
												checkModulePermissions(
													'case-filing',
												).sub_modules.includes(
													'prepare-appeal-letter',
												)}
											<small
												style={{
													fontSize: '10px',
													color: '#7C7C7C',
												}}
											>
												{item.days_ago
													? toTitleCase(item.days_ago)
													: item.days_ago}{' '}
											</small>
										</div>
									</Col>
									<Col xs={2} className="text-end">
										<Dropdown
											size="large"
											overlayStyle={{
												left: '1155px',
											}}
											menu={{
												items,
											}}
											trigger={['click']}
										>
											<a
												onClick={(e) =>
													e.preventDefault()
												}
											>
												<Space>
													<img
														src="/images/dots-icons.svg"
														alt="fax"
													/>
												</Space>
											</a>
										</Dropdown>

										<Modal
											title="Send To"
											open={isShareModal}
											onOk={handleShareOk}
											onCancel={handleShareCancel}
											cancelButtonProps={{ hidden: true }}
											okButtonProps={{ hidden: true }}
											width={'455px'}
										>
											{displayFaxUpload ? (
												<div className="mt-3">
													<Dragger
														showUploadList={false}
													>
														<img
															src="/images/file-upload 1.svg"
															style={{
																marginBottom:
																	'8px',
															}}
															alt="file upload"
														/>
														<p
															style={{
																color: '#ACACAC',
																fontFamily:
																	'Poppins',
																fontSize:
																	'14px',
																fontStyle:
																	'normal',
																fontWeight:
																	'500',
																lineHeight:
																	'20px',
																letterSpacing:
																	'0.28px',
															}}
														>
															Upload
															Acknowledgement
														</p>
													</Dragger>
													<XForm.Item className="text-right mt-3">
														<XShareButton
															style={{
																background:
																	'#212226',
															}}
															onClick={() => {
																// setSuccessModal(true);
																//!Remove this state on funtion call
																setLetterDownloaded(
																	false,
																);
																setDisplayEmailContent(
																	false,
																);
																setDisplayFaxUpload(
																	false,
																);
																setIsShareModal(
																	false,
																);
															}}
														>
															Send
														</XShareButton>
													</XForm.Item>
												</div>
											) : (
												''
											)}
											{displayEmailContent &&
											letterDownloaded ? (
												<div className="mt-3">
													<p>
														The letter has been
														downloaded. Please
														ensure that you do not
														overlook attaching the
														document in the email
														you are about to send.
													</p>
													<XForm.Item className="text-right mt-3">
														<XShareButton
															style={{
																background:
																	'#212226',
															}}
															onClick={() => {
																handleEmailSend();
																setIsShareModal(
																	false,
																);
															}}
														>
															Send Email
														</XShareButton>
													</XForm.Item>
												</div>
											) : (
												''
											)}
										</Modal>
									</Col>
								</Row>
							</div>
							<TimelineEditCard className="mt-3">
								<Row
									gutter={[15, 15]}
									className="align-items-center"
								>
									<Col xs={24}>
										<XCollapse
											collapsible="icon"
											bordered={false}
											activeKey={currentActiveCollapse}
											expandIcon={({ isActive }) =>
												isActive ? (
													<CaretDownOutlined
														onClick={() => {
															if (
																!updateCaseLoading
															) {
																setCurrentActiveCollapse(
																	null,
																);
																setPrevActiveCollapse(
																	null,
																);
																setRevenueCycleStatus(
																	'',
																);
																updateForm.setFieldsValue(
																	{},
																);
																setRevenueCycleFormData(
																	{
																		id: id,
																		reimbursed_at:
																			'',
																		appeal_status:
																			'',
																		claim_category:
																			'',
																		claim_amount:
																			null,
																		status: '',
																		revenue_cycle_stage:
																			'',
																		is_claim_amount_settled:
																			null,
																		claim_amount_updated_at:
																			'',
																		appeal_status_updated_at:
																			'',
																		revenue_cycle_updates:
																			{
																				revenue_cycle_status:
																					'',
																				appeal_status:
																					'',
																				remarks:
																					'',
																				url: [],
																			},
																		denial_reason:
																			'',
																		denial_code1:
																			'',
																		denial_code2:
																			'',
																	},
																);
																setRevenueCycleStage(
																	'',
																);
																setFileList([]);
																setUpdatedFileList(
																	[],
																);
																updateForm.resetFields();
																setSelectedOption(
																	'',
																);
															}
														}}
													/>
												) : (
													<img
														src="/images/case-status-edit-icon.svg"
														alt="Edit"
														style={{
															cursor: 'pointer',
														}}
														onClick={() => {
															setCurrentActiveCollapse(
																'1',
															);
															setPrevActiveCollapse(
																null,
															);
															setRevenueCycleStage(
																revenueStatusToStage(
																	item.revenue_status,
																),
															);
															setRevenueCycleStatus(
																'',
															);
															// setUpdateModalOpen(
															// 	[
															// 		true,
															// 		'',
															// 	],
															// );
															setRevenueCycleFormData(
																{
																	id: id,
																	reimbursed_at:
																		'',
																	appeal_status:
																		'',
																	claim_category:
																		'',
																	claim_amount:
																		null,
																	status: '',
																	revenue_cycle_stage:
																		'',
																	is_claim_amount_settled:
																		null,
																	appeal_status_updated_at:
																		'',
																	revenue_cycle_updates:
																		{
																			revenue_cycle_status:
																				'',
																			appeal_status:
																				'',
																			remarks:
																				'',
																			url: [],
																		},
																},
															);
															setFileList([]);
															setUpdatedFileList(
																[],
															);
															setSelectedOption(
																'',
															);
														}}
													/>
												)
											}
											expandIconPosition="right"
											items={[
												{
													key: '1',
													label: 'Update Case Status',
													children: (
														<>
															{/* Update status UI */}

															<CaseUpdateCard>
																<hr />
																<XForm
																	form={
																		updateForm
																	}
																	name="updateRevenue"
																	layout="vertical"
																	autoComplete="off"
																	onFinish={
																		handleSubmit
																	}
																>
																	<Row
																		gutter={[
																			5,
																			5,
																		]}
																		className="align-items-center"
																	>
																		{/* <Col
																					xs={
																						24
																					}
																				>
																					<div className="title">
																						Revenue
																						Status
																						*
																					</div>
																				</Col> */}
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item
																				name="appeal_status"
																				label="Revenue Status"
																				rules={[
																					{
																						required: true,
																						message:
																							'Please select revenue status!',
																					},
																				]}
																			>
																				<XRadio.Group
																					onChange={(
																						e,
																					) => {
																						handleInputChange(
																							e,
																							revenueCycleFormData,
																							setRevenueCycleFormData,
																						);
																						if (
																							e
																								.target
																								.value ===
																							'approved'
																						) {
																							setRevenueCycleStatus(
																								'approved',
																							);
																						} else if (
																							e
																								.target
																								.value ===
																							'rejected'
																						) {
																							setRevenueCycleStatus(
																								'rejected',
																							);
																						} else {
																							setRevenueCycleStatus(
																								'',
																							);
																						}
																					}}
																					name="appeal_status"
																				>
																					<XRadio
																						value={
																							'approved'
																						}
																					>
																						Approved
																					</XRadio>
																					<XRadio
																						value={
																							'rejected'
																						}
																					>
																						Rejected
																					</XRadio>
																				</XRadio.Group>
																			</XForm.Item>
																		</Col>
																	</Row>

																	<Row
																		gutter={[
																			5,
																			5,
																		]}
																		className="align-items-center"
																	>
																		{revenueCycleStatus ===
																			'approved' && (
																			<div class="animationSlide w-100">
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							colon={
																								false
																							}
																							className="label mb-2"
																							label="Select Approval Date"
																							name="appeal_status_updated_at"
																							rules={[
																								{
																									required: true,
																									message:
																										'Please select Date!',
																								},
																							]}
																						>
																							<DatePicker
																								size="large"
																								name="appeal_status_updated_at"
																								className="inputBox"
																								format="MM/DD/YYYY" // Set display format
																								onChange={(
																									date,
																								) =>
																									handleDateChange(
																										date,
																										'appeal_status_updated_at',
																									)
																								}
																								disabledDate={
																									disableFutureDates
																								}
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>

																				<div className="innerCardWrap">
																					<Row
																						gutter={[
																							5,
																							5,
																						]}
																						className="align-items-center"
																					>
																						<Col
																							xs={
																								24
																							}
																						>
																							<XForm.Item
																								label="Payment Received"
																								name="is_claim_amount_settled"
																								className="label mb-2"
																								rules={[
																									{
																										required: true,
																										message:
																											'Please select!',
																									},
																								]}
																							>
																								<XRadio.Group
																									onChange={(
																										e,
																									) => {
																										handleInputChange(
																											e,
																											revenueCycleFormData,
																											setRevenueCycleFormData,
																										);
																									}}
																									name="is_claim_amount_settled"
																								>
																									<XRadio
																										value={
																											true
																										}
																									>
																										Yes
																									</XRadio>
																									<XRadio
																										value={
																											false
																										}
																									>
																										No
																									</XRadio>
																								</XRadio.Group>
																							</XForm.Item>
																						</Col>
																						{revenueCycleFormData.is_claim_amount_settled ===
																							true && (
																							<>
																								<Col
																									xs={
																										24
																									}
																									className="mt-2"
																								>
																									<XForm.Item
																										className="label mb-2"
																										label="Payment Date"
																										name="reimbursed_at"
																										rules={[
																											{
																												required: true,
																												message:
																													'Please select Reimbursed At!',
																											},
																										]}
																									>
																										<DatePicker
																											name="reimbursed_at"
																											size="large"
																											className="inputBox"
																											format="MM/DD/YYYY" // Set display format
																											onChange={(
																												date,
																											) =>
																												handleDateChange(
																													date,
																													'reimbursed_at',
																												)
																											}
																											disabledDate={
																												disableFutureDates
																											}
																										/>
																									</XForm.Item>
																								</Col>
																							</>
																						)}
																						<Col
																							xs={
																								24
																							}
																						>
																							<XForm.Item
																								label="Payment Type"
																								name="claim_category"
																								className="label mb-2"
																								rules={[
																									{
																										required: true,
																										message:
																											'Please select Claim Type!',
																									},
																								]}
																							>
																								<XRadio.Group
																									onChange={(
																										e,
																									) => {
																										handleInputChange(
																											e,
																											revenueCycleFormData,
																											setRevenueCycleFormData,
																										);
																									}}
																									name="claim_category"
																								>
																									<XRadio
																										value={
																											'full'
																										}
																									>
																										Full
																									</XRadio>
																									<XRadio
																										value={
																											'partial'
																										}
																									>
																										Partial
																									</XRadio>
																								</XRadio.Group>
																							</XForm.Item>
																						</Col>
																						<Col
																							xs={
																								24
																							}
																						>
																							<XForm.Item
																								name="claim_amount"
																								label="Claim Amount"
																								className="label mb-2"
																								rules={[
																									{
																										required: true,
																										message:
																											'Please Enter Claim Amount!',
																									},
																								]}
																							>
																								<InputNumber
																									addonBefore={
																										'$'
																									}
																									size="large"
																									min={
																										0
																									}
																									placeholder="Enter Claim Amount"
																									name="claim_amount"
																									className="inputBox"
																									// disabled={
																									// 	revenueCycleFormData.claim_category ===
																									// 	'full'
																									// }
																									onChange={(
																										e,
																									) =>
																										setRevenueCycleFormData(
																											(
																												prev,
																											) => ({
																												...prev,
																												claim_amount:
																													e,
																											}),
																										)
																									}
																								/>
																							</XForm.Item>
																						</Col>
																					</Row>
																				</div>
																				<Row className="mt-2">
																					{/* <Col
																								xs={
																									24
																								}
																							>
																								<div className="title">
																									Enter
																									Remarks
																								</div>
																							</Col> */}
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							name="remarks"
																							label="Enter Remarks"
																						>
																							<TextArea
																								className="w-100"
																								name="remarks"
																								showCount
																								maxLength={
																									100
																								}
																								style={{
																									height: 120,
																									marginBottom: 24,
																								}}
																								onChange={(
																									e,
																								) =>
																									handleInputChange(
																										e,
																										revenueCycleFormData,
																										setRevenueCycleFormData,
																									)
																								}
																								placeholder="Enter remarks"
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>
																			</div>
																		)}
																		{revenueCycleStatus ===
																			'rejected' && (
																			<div class="animationSlide w-100">
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							colon={
																								false
																							}
																							className="label mb-2"
																							label="Select Rejected Date"
																							name="appeal_status_updated_at"
																							rules={[
																								{
																									required: true,
																									message:
																										'Please select Date!',
																								},
																							]}
																						>
																							<DatePicker
																								size="large"
																								name="appeal_status_updated_at"
																								className="inputBox"
																								format="MM/DD/YYYY" // Set display format
																								onChange={(
																									date,
																								) =>
																									handleDateChange(
																										date,
																										'appeal_status_updated_at',
																									)
																								}
																								disabledDate={
																									disableFutureDates
																								}
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							className="label mb-2"
																							name="denial_reason"
																							label={
																								'Denial Type'
																							}
																							rules={[
																								{
																									required: true,
																									message:
																										'Please select Denial Type!',
																								},
																							]}
																						>
																							<Select
																								className="selectBox"
																								size="large"
																								placeholder="Select Denial Type"
																								name="denial_reason"
																								filterOption={
																									filterByLabel
																								}
																								options={
																									denialReasonData
																								}
																								onSelect={(
																									value,
																								) =>
																									handleSelect(
																										value,
																										'denial_reason',
																									)
																								}
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							className="label mb-2"
																							name="denial_code1"
																							label="Denial Code 1"
																						>
																							<Input
																								size="large"
																								placeholder="Enter CARC"
																								name="denial_code1"
																								className="inputBox  inputText"
																								onChange={
																									handleInputChange
																								}
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							className="label mb-2"
																							name="denial_code2"
																							label="Denial Code 2"
																						>
																							<Input
																								size="large"
																								placeholder="Enter RARC"
																								name="denial_code2"
																								className="inputBox inputText"
																								onChange={
																									handleInputChange
																								}
																							/>
																						</XForm.Item>
																					</Col>
																				</Row>
																				{/* <Row className="mt-2">
																							<Col
																								xs={
																									24
																								}
																							>
																								<div className="title">
																									Denial
																									Reason
																									*
																								</div>
																							</Col>
																						</Row> */}
																				<Row className="mt-2">
																					<Col
																						xs={
																							24
																						}
																					>
																						<XForm.Item
																							className="mb-0"
																							label="Denial Reason"
																							name="rejected_category"
																							rules={[
																								{
																									required: true,
																									message:
																										'Please select option!',
																								},
																							]}
																						>
																							<XRadio.Group
																								onChange={(
																									e,
																								) => {
																									handleInputChange(
																										e,
																										revenueCycleFormData,
																										setRevenueCycleFormData,
																									);
																									updateForm.setFieldsValue(
																										{
																											remarks:
																												'',
																											revenue_cycle_supporting_documents:
																												'',
																										},
																									);
																									setFileList(
																										[],
																									);
																									setRevenueCycleFormData(
																										(
																											prevState,
																										) => ({
																											...prevState,
																											revenue_cycle_updates:
																												{
																													...prevState.revenue_cycle_updates,
																													remarks:
																														'',
																													url: [],
																												},
																										}),
																									);
																									if (
																										e
																											.target
																											.value ===
																										'revenue_cycle_supporting_text'
																									) {
																										setSelectedOption(
																											'revenue_cycle_supporting_text',
																										);
																									} else if (
																										e
																											.target
																											.value ===
																										'revenue_cycle_supporting_documents'
																									) {
																										setSelectedOption(
																											'revenue_cycle_supporting_documents',
																										);
																									}
																								}}
																								name="rejected_category"
																							>
																								<XRadio
																									className="pb-2"
																									value={
																										'revenue_cycle_supporting_text'
																									}
																								>
																									Enter
																									Reason
																								</XRadio>
																								<XRadio
																									className="pb-2"
																									value={
																										'revenue_cycle_supporting_documents'
																									}
																								>
																									Upload
																									Denial
																									Letter
																								</XRadio>
																							</XRadio.Group>
																						</XForm.Item>
																					</Col>
																				</Row>

																				{selectedOption ===
																					'revenue_cycle_supporting_text' && (
																					<Row>
																						<Col
																							xs={
																								24
																							}
																							className="text-end mt-4"
																						>
																							<XForm.Item
																								name="remarks"
																								label="Enter Reason"
																								rules={[
																									{
																										required: true,
																										message:
																											'Please enter reason',
																									},
																								]}
																							>
																								<TextArea
																									className="w-100"
																									name="remarks"
																									showCount
																									style={{
																										height: 120,
																										marginBottom: 24,
																									}}
																									onChange={(
																										e,
																									) =>
																										handleInputChange(
																											e,
																											revenueCycleFormData,
																											setRevenueCycleFormData,
																										)
																									}
																									placeholder="Enter Reason"
																								/>
																							</XForm.Item>
																						</Col>
																					</Row>
																				)}
																				{selectedOption ===
																					'revenue_cycle_supporting_documents' && (
																					<Row className="mt-3">
																						<Col
																							xs={
																								24
																							}
																						>
																							<XForm.Item
																								className="label mb-2"
																								name="revenue_cycle_supporting_documents"
																								label="Upload Denial Letter"
																								rules={[
																									{
																										required: true,
																										validator:
																											(
																												_,
																												value,
																											) => {
																												if (
																													fileList.length ===
																													0
																												) {
																													return Promise.reject(
																														new Error(
																															'Please upload denial letter!',
																														),
																													);
																												}
																												return Promise.resolve();
																											},
																									},
																								]}
																							>
																								<XUpload
																									action={
																										null
																									}
																									showUploadList={{
																										showPreviewIcon: false,
																									}}
																									className="supporting_files timeline-upload"
																									listType="picture-card"
																									fileList={
																										fileList
																									}
																									onPreview={
																										handlePreviewBeforUpload
																									}
																									multiple={
																										false
																									}
																									maxCount={
																										1
																									}
																									onChange={
																										handleChange
																									}
																									accept=".pdf"
																									beforeUpload={(
																										file,
																									) => {
																										const isJPEG =
																											file.type ===
																											'image/jpeg';
																										const isJPG =
																											file.type ===
																											'image/jpg';
																										const isPNG =
																											file.type ===
																											'image/png';
																										const isGIF =
																											file.type ===
																											'image/gif';
																										const isVideo =
																											file.type.startsWith(
																												'video/',
																											);
																										const isAudio =
																											file.type.startsWith(
																												'audio/',
																											);

																										const isLt10M =
																											file.size /
																												1024 /
																												1024 <
																											10;

																										if (
																											!isLt10M
																										) {
																											notification.error(
																												{
																													message:
																														'File Size Exceeded',
																													description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																												},
																											);
																										}
																										if (
																											isGIF ||
																											isVideo ||
																											isAudio ||
																											isJPEG ||
																											isJPG ||
																											isPNG
																										) {
																											notification.error(
																												{
																													message:
																														'File Type Error',
																													description: `GIF, video, image and audio files are not allowed.`,
																												},
																											);
																										}

																										return (
																											isLt10M &&
																											!isGIF &&
																											!isVideo &&
																											!isAudio &&
																											!isJPEG &&
																											!isJPG &&
																											!isPNG
																										);
																									}}
																									onDownload={
																										handleDownload
																									}
																								>
																									{fileList.length >=
																									1 ? null : (
																										<div className="uploadCard w-100">
																											<UploadOutlined />
																											<br />
																											Upload
																											Denial
																											Letter
																										</div>
																									)}
																								</XUpload>
																							</XForm.Item>
																						</Col>
																					</Row>
																				)}
																			</div>
																		)}
																	</Row>
																	<Row className="my-3">
																		<Col
																			xs={
																				24
																			}
																			className="text-center"
																		>
																			<XButtonConfirm
																				type="primary"
																				htmlType="submit"
																				className="btnActive m-0 w-100"
																				loading={
																					updateCaseLoading
																				}
																			>
																				{
																					updateModalButtonText
																				}
																			</XButtonConfirm>
																		</Col>
																	</Row>
																</XForm>
															</CaseUpdateCard>
														</>
													),
												},
											]}
										/>
									</Col>
									{/* <Col xs={20}>
										<div className="titleText">
											Update Case Status
										</div>
									</Col>
									<Col xs={4} className="text-end">
										<img
											src="/images/case-status-edit-icon.svg"
											alt="Edit"
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												setRevenueCycleStage(
													revenueStatusToStage(
														item.revenue_status,
													),
												);
												setRevenueCycleStatus('');
												setUpdateModalOpen([true, '']);
												setRevenueCycleFormData({
													id: id,
													reimbursed_at: '',
													appeal_status: '',
													claim_category: '',
													claim_amount: null,
													status: '',
													revenue_cycle_stage: '',
													is_claim_amount_settled:
														null,
													appeal_status_updated_at:
														'',
													revenue_cycle_updates: {
														revenue_cycle_status:
															'',
														appeal_status: '',
														remarks: '',
														url: [],
													},
												});
												setFileList([]);
												setUpdatedFileList([]);
												setSelectedOption('');
											}}
										/>
									</Col> */}
								</Row>
							</TimelineEditCard>
						</TimelineCard>
					);
				} else if (item.appeal_status === null) {
					itemClassName = 'pending';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<TimelineCard
							style={{
								background: '#FFF',
								borderColor: '#FDE4E4',
							}}
						>
							<div className="hasPadding">
								<Row
									gutter={[15, 15]}
									className="align-items-center"
								>
									<Col xs={9}>
										<h6 className="title">
											{item.revenue_status}
										</h6>
									</Col>
									<Col xs={15} className="text-end">
										{item.days_left && (
											<Tag
												color={getTagColor(
													item.filing_status,
												)}
												className="tagSuccess"
												// icon={
												// 	<ClockCircleOutlined
												// 		style={{
												// 			fontSize: '12px',
												// 			lineHeight: '20px',
												// 		}}
												// 	/>
												// }
											>
												{item.filing_status !==
												'Untimely' ? (
													<>
														{item.days_left}{' '}
														{item.timeline_type
															? toTitleCase(
																	item.timeline_type,
															  )
															: item.timeline_type}{' '}
														{`Left To Respond`}
													</>
												) : (
													'Awaiting Payer Response. Timeline Overdue.'
												)}
											</Tag>
										)}
									</Col>
								</Row>
								<Row gutter={[15, 15]} className="mt-3">
									{checkModulePermissions(
										'ma-plan-appeals-processing',
									).sub_modules.includes(
										'prepare-appeal-letter',
									) ||
									checkModulePermissions(
										'case-filing',
									).sub_modules.includes(
										'prepare-appeal-letter',
									) ||
									checkModulePermissions(
										'appeal-letter',
									).sub_modules.includes(
										'verify-coding-team',
									) ? (
										<Col span={12}>
											<div className="timeLineButton">
												<Button
													// disabled={prepareButtonDisabled}
													type="primary"
													onClick={() => {
														setTogglePrepareLetter(
															true,
														);
														setFlag(false);
														if (
															patientData?.appeal_letter_status ===
															'md_advisor_sent'
														)
															setApproveModalOpen(
																true,
															);
													}}
												>
													{prepareButtonText()}
												</Button>
											</div>
										</Col>
									) : (
										<></>
									)}
								</Row>
							</div>
						</TimelineCard>
					);
				} else if (item.appeal_status == 'Approved') {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<TimelineCard>
							<div className="hasPadding">
								<Row
									gutter={[15, 15]}
									className="align-items-center"
								>
									<Col xs={12}>
										<h6 className="title">
											{item.revenue_status}
										</h6>
									</Col>
									<Col xs={12} className="text-end">
										<Tag
											className="tagSuccess"
											icon={
												<CheckCircleOutlined
													style={{
														fontSize: '12px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											Approved
										</Tag>
									</Col>
								</Row>
							</div>
						</TimelineCard>
					);
				} else if (item.appeal_status == 'Pending') {
					itemClassName = 'pending';
					itemDot = (
						<img
							class="img-fluid"
							alt=""
							src="/images/icon-pending.svg"
						/>
					);
					itemChildren = (
						<TimelineCard>
							<div className="hasPadding">
								<Row gutter={[15, 15]} className="mt-3">
									<Col span={12}>
										<h6 className="title">
											<span
												style={{
													color: '#57636F',
													fontSize: '16px',
												}}
											>
												{item.revenue_status}
											</span>
										</h6>
									</Col>
									<Col span={12} className="text-end">
										<img src="/images/pending-timeline-bg.svg" />
									</Col>
								</Row>
							</div>
						</TimelineCard>
					);
				}
			}

			// Return generated timeline item
			return {
				key: index.toString(), // Provide a unique key
				className: itemClassName,
				dot: itemDot,
				children: itemChildren,
			};
		});
	};

	// show hide approve and rejected radio button
	const [xRadioStatus, setXRadioStatus] = useState('');
	const handleRadioChange = (e) => {
		setXRadioStatus(e.target.value);
	};
	const [xRadioStatus2, setXRadioStatus2] = useState('');
	const handleRadioChange2 = (e) => {
		setXRadioStatus2(e.target.value);
	};

	return (
		<div className="scrollAfix">
			{case_status == 'allocated' ? (
				<>
					<h6
					// style={{color: "#cd4949"}}
					>
						Case Analysis Awaited ...
					</h6>
				</>
			) : medicareCoverage == 'Yes' ? (
				<>
					<Timeline items={generateTimelineItems()} />
					{/* Modal for preview */}
					<Modal
						open={pdfPreviewOpen}
						title="Preview"
						footer={null}
						onCancel={() => setPdfPreviewOpen(false)}
						width={pdfWidth} // Set the width of the modal dynamically
						// Other modal props...
					>
						<div>
							<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
								<div style={{ height: '750px' }}>
									<Viewer fileUrl={previewImage} />
								</div>
							</Worker>
							{/* <Document
								file={previewImage}
								onLoadSuccess={onDocumentLoadSuccess}
								onLoadError={onDocumentLoadError}
							>
								{Array.from(
									new Array(numPages),
									(el, index) => (
										<Page
											key={`page_${index + 1}`}
											pageNumber={index + 1}
											onLoadSuccess={(page) =>
												updateModalWidth(page.width)
											}
											renderTextLayer={false}
											renderAnnotationLayer={false}
										/>
									),
								)}
							</Document> */}
							{/* <p>
								Page {pageNumber} of {numPages}
							</p> */}
						</div>
					</Modal>
					<Modal
						title={`Denial Reason for ${statusName}`}
						open={isModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
						okButtonProps={{
							style: {
								display: 'none',
							},
						}}
						cancelButtonProps={{ style: { display: 'none' } }}
					>
						<p>{documentText}</p>
					</Modal>
				</>
			) : (
				<>
					<h6 style={{ color: '#cd4949' }}>
						This case is not covered under Medicare Coverage
					</h6>
				</>
			)}
		</div>
	);
};

export default CustomTimeline;
