import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_ORGANIZATION_ROLE = 'ADD_ORGANIZATION_ROLE';
export const ADD_ORGANIZATION_ROLE_SUCCESS = 'ADD_ORGANIZATION_ROLE_SUCCESS';
export const ADD_ORGANIZATION_ROLE_FAIL = 'ADD_ORGANIZATION_ROLE_FAIL';
export const ADD_ORGANIZATION_ROLE_RESET = 'ADD_ORGANIZATION_ROLE_RESET';

export const addOrganizationRole = (params) => {
	return {
		type: ADD_ORGANIZATION_ROLE,
		params,
	};
};

export const addOrganizationRoleSuccess = (response) => {
	return {
		type: ADD_ORGANIZATION_ROLE_SUCCESS,
		response,
	};
};

export const addOrganizationRoleFail = (response) => {
	return {
		type: ADD_ORGANIZATION_ROLE_FAIL,
		response,
	};
};

export const addOrganizationRoleReset = () => {
	return {
		type: ADD_ORGANIZATION_ROLE_RESET,
	};
};

export const addOrganizationRoleApi = (data) => {
	return (dispatch) => {
		dispatch(addOrganizationRole());
		axios
			.post(`${config.api.base_url}/organization-role-mapping`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addOrganizationRoleData = response.data;
				dispatch(addOrganizationRoleSuccess(addOrganizationRoleData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addOrganizationRoleFail(errorMsg));
			});
	};
};
