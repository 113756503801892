import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const SAVE_REFERENCES = 'SAVE_REFERENCES';
export const SAVE_REFERENCES_SUCCESS = 'SAVE_REFERENCES_SUCCESS';
export const SAVE_REFERENCES_FAIL = 'SAVE_REFERENCES_FAIL';
export const SAVE_REFERENCES_RESET = 'SAVE_REFERENCES_RESET';

export const saveReferences = (params) => {
	return {
		type: SAVE_REFERENCES,
		params,
	};
};

export const saveReferencesSuccess = (response) => {
	return {
		type: SAVE_REFERENCES_SUCCESS,
		response,
	};
};

export const saveReferencesFail = (response) => {
	return {
		type: SAVE_REFERENCES_FAIL,
		response,
	};
};

export const saveReferencesReset = () => {
	return {
		type: SAVE_REFERENCES_RESET,
	};
};

export const saveReferencesApi = (data) => {
	return (dispatch) => {
		dispatch(saveReferences());
		axios
			.post(`${config.api.base_url}/case/addReferences`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const saveReferencesData = response.data;
				dispatch(saveReferencesSuccess(saveReferencesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(saveReferencesFail(errorMsg));
			});
	};
};
