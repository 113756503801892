import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS = 'GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS';
export const GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_SUCCESS = 'GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_SUCCESS';
export const GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_FAIL = 'GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_FAIL';
export const GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_RESET = 'GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_RESET';

export const getInsuranceCompaniesFilingStatusCounts = (params) => {
	return {
		type: GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS,
		params,
	};
};

export const getInsuranceCompaniesFilingStatusCountsSuccess = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_SUCCESS,
		response,
	};
};

export const getInsuranceCompaniesFilingStatusCountsFail = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_FAIL,
		response,
	};
};

export const getInsuranceCompaniesFilingStatusCountsReset = () => {
	return {
		type: GET_INSURANCE_COMPANIES_FILING_STATUS_COUNTS_RESET,
	};
};

export const getInsuranceCompaniesFilingStatusCountsApi = (data) => {
	return (dispatch) => {
		dispatch(getInsuranceCompaniesFilingStatusCounts());
		axios
			.get(`${config.api.base_url}/dashboard/get-total-insurance-companies-filing-status-counts`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getInsuranceCompaniesFilingStatusCountsData = response.data;
				dispatch(getInsuranceCompaniesFilingStatusCountsSuccess(getInsuranceCompaniesFilingStatusCountsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getInsuranceCompaniesFilingStatusCountsFail(errorMsg));
			});
	};
};
