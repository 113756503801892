import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST =
	'GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST';
export const GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_SUCCESS =
	'GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_SUCCESS';
export const GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_FAIL =
	'GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_FAIL';
export const GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_RESET =
	'GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_RESET';

export const getVerifyApproveAppealLetterUserList = (params) => {
	return {
		type: GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST,
		params,
	};
};

export const getVerifyApproveAppealLetterUserListSuccess = (response) => {
	return {
		type: GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_SUCCESS,
		response,
	};
};

export const getVerifyApproveAppealLetterUserListFail = (response) => {
	return {
		type: GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_FAIL,
		response,
	};
};

export const getVerifyApproveAppealLetterUserListReset = () => {
	return {
		type: GET_VERIFY_APPROVE_APPEAL_LETTER_USER_LIST_RESET,
	};
};

export const getVerifyApproveAppealLetterUserListApi = (data) => {
	return (dispatch) => {
		dispatch(getVerifyApproveAppealLetterUserList());
		axios
			.get(
				`${config.api.base_url}/appeal-letter/verify-approve-user-list`,
				{
					params: data,
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const getVerifyApproveAppealLetterUserListData = response.data;
				dispatch(
					getVerifyApproveAppealLetterUserListSuccess(
						getVerifyApproveAppealLetterUserListData,
					),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getVerifyApproveAppealLetterUserListFail(errorMsg));
			});
	};
};
