
import { 
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL, 
    DELETE_USER_RESET,
} from "../actions/deleteUserAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const deleteUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_USER:
            return {
                ...state,
                apiState: "loading",
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case DELETE_USER_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case DELETE_USER_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default deleteUserReducer;
