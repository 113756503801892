import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_REIMBURSEMENTS = 'GET_REIMBURSEMENTS';
export const GET_REIMBURSEMENTS_SUCCESS = 'GET_REIMBURSEMENTS_SUCCESS';
export const GET_REIMBURSEMENTS_FAIL = 'GET_REIMBURSEMENTS_FAIL';
export const GET_REIMBURSEMENTS_RESET = 'GET_REIMBURSEMENTS_RESET';

export const getReimbursements = (params) => {
	return {
		type: GET_REIMBURSEMENTS,
		params,
	};
};

export const getReimbursementsSuccess = (response) => {
	return {
		type: GET_REIMBURSEMENTS_SUCCESS,
		response,
	};
};

export const getReimbursementsFail = (response) => {
	return {
		type: GET_REIMBURSEMENTS_FAIL,
		response,
	};
};

export const getReimbursementsReset = () => {
	return {
		type: GET_REIMBURSEMENTS_RESET,
	};
};

export const getReimbursementsApi = (data) => {
	return (dispatch) => {
		dispatch(getReimbursements());
		axios
			.get(`${config.api.base_url}/reimbursements`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getReimbursementsData = response.data;
				dispatch(getReimbursementsSuccess(getReimbursementsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getReimbursementsFail(errorMsg));
			});
	};
};
