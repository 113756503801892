import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_ORGANIZATION_ROLE = 'EDIT_ORGANIZATION_ROLE';
export const EDIT_ORGANIZATION_ROLE_SUCCESS = 'EDIT_ORGANIZATION_ROLE_SUCCESS';
export const EDIT_ORGANIZATION_ROLE_FAIL = 'EDIT_ORGANIZATION_ROLE_FAIL';
export const EDIT_ORGANIZATION_ROLE_RESET = 'EDIT_ORGANIZATION_ROLE_RESET';

export const editOrganizationRole = (params) => {
	return {
		type: EDIT_ORGANIZATION_ROLE,
		params,
	};
};

export const editOrganizationRoleSuccess = (response) => {
	return {
		type: EDIT_ORGANIZATION_ROLE_SUCCESS,
		response,
	};
};

export const editOrganizationRoleFail = (response) => {
	return {
		type: EDIT_ORGANIZATION_ROLE_FAIL,
		response,
	};
};

export const editOrganizationRoleReset = () => {
	return {
		type: EDIT_ORGANIZATION_ROLE_RESET,
	};
};

export const editOrganizationRoleApi = (data) => {
	return (dispatch) => {
		dispatch(editOrganizationRole());
		axios
			.put(
				`${config.api.base_url}/organization-role-mapping/${data.id}`,
				data,
				{
					headers: { Authorization: 'Bearer ' + getUserToken() },
				},
			)
			.then((response) => {
				const editOrganizationRoleData = response.data;
				dispatch(editOrganizationRoleSuccess(editOrganizationRoleData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editOrganizationRoleFail(errorMsg));
			});
	};
};
