import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button, Result, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsTransparent,
	XForm,
	XModal,
	XSelect,
} from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
	lowerCaseInput,
	validateMobileNumber,
} from '../../utils/formFunctions';

// actions

import { checkModulePermissions, getUserToken } from '../../utils/helper';

import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';

import Header from '../../components/Header/Header';

import {
	getOrganizationByIdApi,
	getOrganizationByIdReset,
} from '../../actions/getOrganizationByIdAction';
import {
	addOrganizationApi,
	addOrganizationReset,
} from '../../actions/addOrganizationAction';
import {
	editOrganizationApi,
	editOrganizationReset,
} from '../../actions/editOrganizationAction';
import {
	deleteOrganizationApi,
	deleteOrganizationReset,
} from '../../actions/deleteOrganizationAction';
import axios from 'axios';
import config from '../../config';
import ImgCrop from 'antd-img-crop';

const HospitalForm = (props) => {
	const {
		getOrganizationByIdState,
		getOrganizationById,
		getOrganizationByIdReset,
		addOrganizationState,
		addOrganization,
		addOrganizationReset,
		editOrganizationState,
		deleteOrganizationState,
		getStateDropdownState,
		editOrganization,
		editOrganizationReset,
		deleteOrganization,
		deleteOrganizationReset,
		getStateDropdown,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('organization-management');
	const [form] = XForm.useForm();
	const [fileList, setFileList] = useState([]);
	const [fileChanged, setFileChanged] = useState(false);
	const [formData, setFormData] = useState({});

	// const handleChange = (info) => {
	// 	let newFileList = [...info.fileList];

	// 	// 1. Limit the number of uploaded files
	// 	// Only to show two recent uploaded files, and old ones will be replaced by the new
	// 	newFileList = newFileList.slice(-2);

	// 	// 2. Read from response and show file link
	// 	newFileList = newFileList.map((file) => {
	// 		if (file.response) {
	// 			// Component will show file.url as link
	// 			file.url = file.response.url;
	// 			return { ...file, status: 'done' };
	// 		}
	// 		return file;
	// 	});
	// 	setFileChanged(true);
	// 	setFileList(newFileList);
	// };

	// use effects

	const handleChange = (info) => {
		let newFileList = [...info.fileList];

		newFileList = newFileList.slice(-1);
		newFileList = newFileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileChanged(true);
		setFileList(newFileList);
	};

	useEffect(() => {
		if (id) {
			getOrganizationById({ id: id });
		}
		getStateDropdown();
	}, [id]);

	useEffect(() => {
		if (getOrganizationByIdState.apiState === 'success') {
			setFormData({
				...getOrganizationByIdState.data,
				id: getOrganizationByIdState.data._id,
			});
			form.setFieldsValue({
				...getOrganizationByIdState.data,
				id: getOrganizationByIdState.data._id,
			});
			if (getOrganizationByIdState.data.logo.url !== null) {
				const updatedLogo = {
					...getOrganizationByIdState.data.logo,
					url: `${config.s3_url}${getOrganizationByIdState.data.logo.url}`,
				};
				setFileList([updatedLogo]);
				// setFileList([getOrganizationByIdState.data.logo]);
			}
			getOrganizationByIdReset();
		}
	}, [getOrganizationByIdState]);

	useEffect(() => {
		if (addOrganizationState.apiState === 'success') {
			setFormData({});
			setFileList([]);
			setFileChanged(false);
			addOrganizationReset();
			navigate(-1);
		}
	}, [addOrganizationState]);

	useEffect(() => {
		if (editOrganizationState.apiState === 'success') {
			setFileList([]);
			setFileChanged(false);
			editOrganizationReset();
			navigate(-1);
		}
	}, [editOrganizationState]);

	useEffect(() => {
		if (deleteOrganizationState.apiState === 'success') {
			notification.success({ message: deleteOrganizationState.message });
			deleteOrganizationReset();

			navigate('/organization');
		}
	}, [deleteOrganizationState]);

	useEffect(()=>{
		getStateDropdown();
	},[])

	const showDeleteConfirm = (_id) => {
		XModal.confirm({
			title: 'Delete Organization?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete this organization?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteOrganization({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const handleSubmit = async () => {
		try {
			let newFile = {};
			await form.validateFields(); // Wait for form validation
			if (fileList.length > 0 && fileChanged) {
				await Promise.all(
					fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=organization_logo&file_name=${formData.name}&ext=${ext}&id=${fileData.originFileObj.uid}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);

						if (result.status === 200) {
							const documentPath = `/organization_logo/${fileData.originFileObj.uid}/${formData.name}.${ext}`;
							newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
						}
					}),
				);
				console.log('Files Uploaded');
			}

			if (id) {
				await editOrganization({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: fileList[0] }),
				});
			} else {
				await addOrganization({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: fileList[0] }),
				});
			}
		} catch (error) {
			// Handle error if any
			notification.error({
				message: 'An error occurred',
			});
			console.error('Error occurred:', error);
		}
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Organization Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="organizationManagement" />
					</Col>
				</>
			)}
			<XContainer fluid className="mt-3">
				<XCardBackBg className="mb-3">
					<Row>
						<Col xs={24}>
							<XButtonBack
								onClick={() => navigate(-1)}
								icon={<ArrowLeftOutlined />}
								size="large"
							/>
							{id ? (
								<XEditTitle>Edit Organization</XEditTitle>
							) : (
								<XEditTitle>Add Organization</XEditTitle>
							)}{' '}
						</Col>
					</Row>
				</XCardBackBg>

				<XCardsTransparent>
					{modulePermissions.sub_modules.includes(
						'create-organization',
						'update-organization',
					) ? (
						<XForm
							form={form}
							name="roleForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => {
								handleSubmit();
								// if (id)
								// 	editOrganization({
								// 		id: id,
								// 		...formData,
								// 	});
								// else
								// 	addOrganization({
								// 		...formData,
								// 	});
							}}
						>
							<Row gutter={(15, 30)} className="mt-4">
								<Col xs={24} lg={8} md={12}>
									<XForm.Item
										name="name"
										label="Name"
										rules={[
											{
												required: true,
												message:
													'Please enter Organization name',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Hospital Name"
											name="name"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item
										name="contact_person"
										label="Contact Person"
										rules={[
											{
												required: true,
												message:
													'Please enter Contact Person',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Contact Person"
											name="contact_person"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item
										name="contact_person_email_address"
										label="Contact Person Email Address"
										rules={[
											{
												type: 'email',
												message:
													'Please enter a valid email address',
											},
											{
												required: true,
												message:
													'Please enter email address',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Contact Person Email Address"
											name="contact_person_email_address"
											onInput={lowerCaseInput}
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item
										name="contact_person_mobile_number"
										label="Contact Person Mobile Number"
										rules={[
											{
												validator: validateMobileNumber,
											},
											{
												required: true,
												message:
													'Please enter Mobile Number',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Contact Person Mobile Number"
											name="contact_person_mobile_number"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>

								<Col xs={24} lg={8} md={12}>
									<XForm.Item name="address" label="Address">
										<Input
											size="large"
											placeholder="Enter Address"
											name="address"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item name="state" label={'State'}>
										<XSelect
											size="large"
											placeholder="Select State"
											name="state"
											showSearch={true}
											className="removeBg"
											// onFocus={() => {
											// 	getStateDropdown();
											// }}
											onSearch={(search) => {
												if (search.length >= 3) {
													getStateDropdown({
														search,
													});
												} else if (
													search.length === 0
												) {
													getStateDropdown();
												}
											}}
											filterOption={filterByLabel}
											options={
												getStateDropdownState.apiState ===
													'success' &&
												getStateDropdownState.data
													.all_states?.length > 0 &&
												getStateDropdownState.data.all_states.map(
													(itm) => {
														return {
															label: itm.state,
															value: itm.state,
															providers:
																itm.providers,
														};
													},
												)
											}
											onSelect={(value, options) => {
												handleSelectChange(
													'state',
													value,
													formData,
													setFormData,
												);
											}}
										></XSelect>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item name="city" label="City">
										<Input
											size="large"
											placeholder="Enter City"
											name="city"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item name="pincode" label="Pincode">
										<Input
											size="large"
											placeholder="Enter Pincode"
											name="pincode"
											onChange={(e) =>
												handleInputChange(
													e,
													formData,
													setFormData,
												)
											}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={8} md={12}>
									<XForm.Item
										name="logo"
										label="Logo"
										rules={[
											{
												required: true,
												validator: (_, value) => {
													if (fileList.length === 0) {
														return Promise.reject(
															new Error(
																'Please add logo!',
															),
														);
													}
													return Promise.resolve();
												},
											},
										]}
									>
										<ImgCrop
											showReset={true}
											aspect={1}
											modalTitle="Crop Logo"
											modalOk="Save"
											modalCancel="Cancel"
											modalProps={{
												className: 'custom-crop-modal',
											}}
										>
											<Upload
												fileList={fileList}
												onChange={handleChange}
												multiple={false}
												maxCount={1}
												accept=".jpeg, .jpg, .png"
											>
												<Button
													icon={<UploadOutlined />}
													size="large"
													style={{
														width: '100%',
														borderRadius: '10px',
														height: '45px',
														marginLeft: '0',
													}}
												>
													Upload
												</Button>
											</Upload>
										</ImgCrop>
									</XForm.Item>
								</Col>
							</Row>
							<Row gutter={[15, 5]}>
								<Col
									xs={24}
									lg={{ span: 6, offset: 0 }}
									md={{ span: 6, offset: 0 }}
									sm={{ span: 6, offset: 0 }}
									className="text-start"
								>
									{id ? (
										<Button
											onClick={() =>
												showDeleteConfirm(id)
											}
										>
											Delete Organization
										</Button>
									) : (
										<></>
									)}
								</Col>
								<Col
									xs={24}
									lg={18}
									md={18}
									sm={18}
									className="text-end"
								>
									<Button
										onClick={() => {
											form.resetFields();
											navigate('/organization');
											setFormData({});
										}}
										className="cancel"
									>
										Cancel
									</Button>
									<Button htmlType="submit">Submit</Button>
								</Col>
							</Row>
						</XForm>
					) : (
						<Result
							status="403"
							title="403"
							subTitle="Sorry, you are not authorized to access this page."
							extra={
								<Button
									type="primary"
									onClick={() => navigate('/')}
								>
									Back Home
								</Button>
							}
						/>
					)}
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getOrganizationByIdState: state.getOrganizationById,
	addOrganizationState: state.addOrganization,
	editOrganizationState: state.editOrganization,
	deleteOrganizationState: state.deleteOrganization,
	getStateDropdownState: state.getStateDropdown,
});

const mapDispatchToProps = (dispatch) => ({
	getOrganizationById: (data) => dispatch(getOrganizationByIdApi(data)),
	getOrganizationByIdReset: () => dispatch(getOrganizationByIdReset()),
	addOrganization: (data) => dispatch(addOrganizationApi(data)),
	addOrganizationReset: (data) => dispatch(addOrganizationReset(data)),
	editOrganization: (data) => dispatch(editOrganizationApi(data)),
	editOrganizationReset: (data) => dispatch(editOrganizationReset(data)),
	deleteOrganization: (data) => dispatch(deleteOrganizationApi(data)),
	deleteOrganizationReset: (data) => dispatch(deleteOrganizationReset(data)),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HospitalForm);
