import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Card, Result, Radio, Modal } from 'antd';
import { Button, Input, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XModal,
	XContainer,
	XButton,
	FilterButton,
	XShareButton,
	XSelect,
	XSearch,
} from '../../styles/GlobalStyle';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';

import moment from 'moment';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions
import {
	getInsuranceCompaniesApi,
	getInsuranceCompaniesReset,
} from '../../actions/getInsuranceCompaniesAction';
import {
	addInsuranceCompanyApi,
	addInsuranceCompanyReset,
} from '../../actions/addInsuranceComapnyAction';
import {
	editInsuranceCompanyApi,
	editInsuranceCompanyReset,
} from '../../actions/editInsuranceCompanyAction';
import {
	deleteInsuranceCompanyApi,
	deleteInsuranceCompanyReset,
} from '../../actions/deleteInsuranceCompanyAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	checkModulePermissions,
	getUserToken,
	urlPagination,
} from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	filterByLabel,
	handleInputChange,
	lowerCaseInput,
	validateMobileNumber,
} from '../../utils/formFunctions';
import { getInsuranceCompanyByIdApi } from '../../actions/getInsuranceCompanyByIdAction';
import TextArea from 'antd/es/input/TextArea';
import {
	getStatesListApi,
	getStatesListReset,
} from '../../actions/getStatesListAction';
import config from '../../config';

const InsuranceCompany = (props) => {
	const {
		getInsuranceCompanies,
		getInsuranceCompaniesState,
		editInsuranceCompany,
		editInsuranceCompanyState,
		addInsuranceCompanyState,
		addInsuranceCompany,
		getInsuranceCompanyByIdState,
		getInsuranceCompanyById,
		addInsuranceCompanyReset,
		editInsuranceCompanyReset,
		deleteInsuranceCompany,
		deleteInsuranceCompanyState,
		deleteInsuranceCompanyReset,
		getStateDropdownState,
		getStateDropdown,
		getStatesList,
		getStatesListState,
	} = props;

	const navigate = useNavigate();
	const defaultFilters = {
		search: '',
		page: 1,
		sort: 'updatedAt',
		sortDirection: 'descend',
		created_on: 'all',
		functional_in_states: [],
		hq_state: '',
		limit: 10,
	};
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const [modalOpen, setModalOpen] = useState([false, '']);
	const [editCompanyActive, setEditCompanyActive] = useState(false);
	let modulePermissions = checkModulePermissions(
		'insurance-company-management',
	);

	const [form] = XForm.useForm();
	const [form2] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [fileChanged, setFileChanged] = useState(false);
	const [open, setOpen] = useState(false);
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setFileList([]);
		setFileChanged(false);
		setOpen(false);
	};

	const onChangeFilters = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const columns = [
		{
			title: 'Name',
			width: 200,
			dataIndex: 'name',
			fixed: 'left',
			render: (name) => (
				<div>
					<div>{name}</div>
				</div>
			),
		},
		{
			title: 'Email Address',
			width: 200,
			dataIndex: 'contact_person_email_address',
			render: (contact_person_email_address) => (
				<div>
					<div>{contact_person_email_address}</div>
				</div>
			),
		},
		{
			title: 'Mobile Number',
			width: 200,
			dataIndex: 'contact_person_mobile_number',
			render: (contact_person_mobile_number) => (
				<div>
					<div>{contact_person_mobile_number}</div>
				</div>
			),
		},
		{
			title: 'Fax Number',
			width: 150,
			dataIndex: 'fax_number',
			render: (fax_number) => (
				<div>
					<div>{fax_number}</div>
				</div>
			),
		},
		{
			title: 'HQ Address',
			width: 300,
			dataIndex: 'address',
			render: (address) => (
				<div>
					<div>{address}</div>
				</div>
			),
		},
		{
			title: 'HQ State',
			width: 150,
			dataIndex: 'hq_state',
			render: (hq_state) => (
				<div>
					<div>{hq_state}</div>
				</div>
			),
		},
		{
			title: 'Pincode',
			width: 150,
			dataIndex: 'pincode',
			render: (pincode) => (
				<div>
					<div>{pincode}</div>
				</div>
			),
		},
		{
			title: 'City',
			width: 150,
			dataIndex: 'city',
			render: (city) => (
				<div>
					<div>{city}</div>
				</div>
			),
		},
		{
			title: 'Last Updated Date',
			width: 200,
			dataIndex: 'updatedAt',
			sorter: (a, b) => a.updatedAt - b.updatedAt,
			sortDirections: ['ascend', 'descend'],
			render: (updatedAt) => (
				<div>
					<div>{updatedAt}</div>
				</div>
			),
		},
		{
			title: 'Action',
			width: 150,
			dataIndex: 'action',
			fixed: 'right',
		},
	];

	const showDeleteConfirm = (_id, name) => {
		XModal.confirm({
			title: 'Delete Insurance Company?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete {name}?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteInsuranceCompany({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const handleEdit = (item) => {
		setEditCompanyActive(true);

		setFormData({
			_id: item._id,
		});

		setModalOpen([true, `Edit Insurance Company`]);
		getInsuranceCompanyById({
			id: item._id,
		});
	};

	const handleState = (name, value) => {
		setFormData({
			...formData,
			[name]: value,
		});
	};
	// use effects

	useEffect(() => {
		if (getInsuranceCompaniesState.apiState === 'success') {
			if (getInsuranceCompaniesState.data.insurances.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getInsuranceCompaniesState.data.total_count);
				getInsuranceCompaniesState.data.insurances.map(
					(item, index) => {
						updatedSearchOptions.push({ value: item.name });
						updatedData.push({
							key: item._id,
							_id: item._id,
							name: item.name,
							contact_person_email_address:
								item.contact_person_email_address,
							contact_person_mobile_number:
								item.contact_person_mobile_number,
							fax_number: item.fax_number,
							address: item.address,
							hq_state: item.hq_state,
							pincode: item.pincode,
							city: item.city,
							updatedAt: (
								<div className="showDate">
									{moment(item.updatedAt).format(
										'DD-MM-YYYY',
									)}
								</div>
							),
							action: (
								<div className="text-center">
									<Row>
										<Col xs={{ span: 6, offset: 6 }}>
											{modulePermissions.sub_modules.includes(
												'view-insurance-companies',
											) && (
												<XButtonImg
													onClick={() => {
														handleEdit(item);
													}}
													type="text"
												>
													<img
														src="./images/pencil.svg"
														alt=""
													/>
												</XButtonImg>
											)}
										</Col>
										<Col xs={{ span: 9, offset: 3 }}>
											{modulePermissions.sub_modules.includes(
												'delete-insurance-companies',
											) && (
												<XButtonImg
													onClick={() =>
														showDeleteConfirm(
															item._id,
															item.name,
														)
													}
													type="text"
												>
													<img
														src="./images/delete-icon-card.svg"
														alt=""
													/>
												</XButtonImg>
											)}
										</Col>
									</Row>
								</div>
							),
						});
					},
				);
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getInsuranceCompaniesState]);

	useEffect(() => {
		if (getInsuranceCompanyByIdState.apiState === 'success') {
			setFormData({
				id: getInsuranceCompanyByIdState.data._id,
				name: getInsuranceCompanyByIdState.data.name,
				contact_person_email_address:
					getInsuranceCompanyByIdState.data
						.contact_person_email_address,
				contact_person_mobile_number:
					getInsuranceCompanyByIdState.data
						.contact_person_mobile_number,
				fax_number: getInsuranceCompanyByIdState.data.fax_number,
				address: getInsuranceCompanyByIdState.data.address,
				functional_in_states:
					getInsuranceCompanyByIdState.data.functional_in_states,
				hq_state: getInsuranceCompanyByIdState.data.hq_state,
				pincode: getInsuranceCompanyByIdState.data.pincode,
				city: getInsuranceCompanyByIdState.data.city,
				updatedAt: getInsuranceCompanyByIdState.data.updatedAt,
			});
			form.setFieldsValue({
				id: getInsuranceCompanyByIdState.data._id,
				name: getInsuranceCompanyByIdState.data.name,
				contact_person_email_address:
					getInsuranceCompanyByIdState.data
						.contact_person_email_address,
				contact_person_mobile_number:
					getInsuranceCompanyByIdState.data
						.contact_person_mobile_number,
				fax_number: getInsuranceCompanyByIdState.data.fax_number,
				address: getInsuranceCompanyByIdState.data.address,
				functional_in_states:
					getInsuranceCompanyByIdState.data.functional_in_states,
				hq_state: getInsuranceCompanyByIdState.data.hq_state,

				pincode: getInsuranceCompanyByIdState.data.pincode,
				city: getInsuranceCompanyByIdState.data.city,
				updatedAt: getInsuranceCompanyByIdState.data.updatedAt,
			});
			if (getInsuranceCompanyByIdState.data.logo)
				setFileList([getInsuranceCompanyByIdState.data.logo]);
		}
	}, [getInsuranceCompanyByIdState]);

	useEffect(() => {
		if (editInsuranceCompanyState.apiState === 'success') {
			form.resetFields();
			setFormData({});
			setFileList([]);
			setFileChanged(false);

			editInsuranceCompanyReset();
			getInsuranceCompanies();
			notification.success({
				message: editInsuranceCompanyState.message,
			});
			setModalOpen([false, '']);
		} else if (editInsuranceCompanyState.apiState === 'error') {
			notification.error({
				message: editInsuranceCompanyState.message,
			});
			editInsuranceCompanyReset();
		}
	}, [editInsuranceCompanyState]);

	useEffect(() => {
		if (addInsuranceCompanyState.apiState === 'success') {
			form.resetFields();
			setFormData({});
			setFileList([]);
			setFileChanged(false);

			addInsuranceCompanyReset();
			getInsuranceCompanies();
			notification.success({
				message: addInsuranceCompanyState.message,
			});
			setModalOpen([false, '']);
		} else if (addInsuranceCompanyState.apiState === 'error') {
			notification.error({
				message: 'Error is adding Provider.',
			});
			addInsuranceCompanyReset();
		}
	}, [addInsuranceCompanyState]);

	useEffect(() => {
		if (deleteInsuranceCompanyState.apiState === 'success') {
			deleteInsuranceCompanyReset();
			getInsuranceCompanies();
		}
	}, [deleteInsuranceCompanyState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions(
			'insurance-company-management',
		).sub_modules.includes('list-insurance-companies') &&
			getInsuranceCompanies({ ...updatedFilters });
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form2.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	const [fileList, setFileList] = useState([]);
	const handleChange = (info) => {
		let newFileList = [...info.fileList];

		// 1. Limit the number of uploaded files
		// Only to show two recent uploaded files, and old ones will be replaced by the new
		newFileList = newFileList.slice(-2);

		// 2. Read from response and show file link
		newFileList = newFileList.map((file) => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url;
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileChanged(true);
		setFileList(newFileList);
	};

	const handleSubmit = async () => {
		try {
			let newFile = {};
			await form.validateFields(); // Wait for form validation

			if (fileList.length > 0 && fileChanged) {
				await Promise.all(
					fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=insurance_companies&file_name=${formData.name}&ext=${ext}&id=${fileData.originFileObj.uid}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);

						if (result.status === 200) {
							const documentPath = `/insurance_companies/${fileData.originFileObj.uid}/${formData.name}.${ext}`;
							newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
						}
					}),
				);
				console.log('Files Uploaded');
			}

			if (formData.id) {
				await editInsuranceCompany({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: fileList[0] }),
				});
			} else {
				await addInsuranceCompany({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: fileList[0] }),
				});
			}
		} catch (error) {
			// Handle error if any
			notification.error({
				message: 'An error occurred',
			});
			console.error('Error occurred:', error);
		}
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Insurance Company" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="insuranceCompany" />
					</Col>
				</>
			)}
			<XContainer fluid>
				{checkModulePermissions(
					'insurance-company-management',
				).sub_modules.includes('list-insurance-companies') ? (
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<CaseSearch
							filters={[
								<XForm.Item
									name="hq_state"
									colon={false}
									label="HQ State"
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										allowClear
										className="filter-sub-item"
										placeholder="Select State"
										defaultValue={
											filters.hq_state
												? filters.hq_state
												: null
										}
										onFocus={() => {
											getStatesList();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getStatesList({
													search,
												});
											} else if (search.length === 0) {
												getStatesList();
											}
										}}
										onClear={() => {
											setFilters({
												...filters,
												hq_state: '',
												insurance_company: '',
											});
										}}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getStatesListState.apiState ===
												'success' &&
											getStatesListState.data.all_states
												?.length > 0 &&
											getStatesListState.data.all_states.map(
												(itm) => {
													return {
														label: itm.state,
														value: itm.state,
														providers:
															itm.providers,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setFilters({
												...filters,
												hq_state: value,
											});
										}}
									/>
								</XForm.Item>,
								<XForm.Item
									name="functional_in_states"
									colon={false}
									label="Functional in States"
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										allowClear
										mode="multiple"
										maxTagCount={(3, 'responsive')}
										showSearch={true}
										className="filter-sub-item"
										placeholder="Select States"
										defaultValue={
											filters.functional_in_states
												? filters.functional_in_states
												: null
										}
										onFocus={() => {
											getStateDropdown();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getStateDropdown({
													search,
												});
											} else if (search.length === 0) {
												getStateDropdown();
											}
										}}
										onClear={() => {
											setFilters({
												...filters,
												functional_in_states: '',
											});
										}}
										filterOption={filterByLabel}
										options={
											getStateDropdownState.apiState ===
												'success' &&
											getStateDropdownState.data
												.all_states?.length > 0 &&
											getStateDropdownState.data.all_states.map(
												(itm) => {
													return {
														label: itm.state,
														value: itm.state,
														providers:
															itm.providers,
													};
												},
											)
										}
										onChange={(value, options) => {
											setFilters({
												...filters,
												functional_in_states: value,
											});
										}}
									/>
								</XForm.Item>,

								<XForm.Item
									name="created_on"
									colon={false}
									label="Date Within"
									className="removeFormMargin"
								>
									<Radio.Group
										onChange={onChangeFilters}
										name="created_on"
										defaultValue={
											filters.created_on
												? filters.created_on
												: null
										}
									>
										<Row>
											{dateFilters.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<Radio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</Radio>
												</Col>
											))}
										</Row>
									</Radio.Group>
								</XForm.Item>,
							]}
							onClose={onClose}
							open={open}
							applyFilters={applyFilters}
							form={form2}
							clearFilters={clearFilters}
						/>
						<Row gutter={(15, 30)} style={{ padding: '20px ' }}>
							<Col
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								xs={24}
								md={10}
								lg={3}
								xl={3}
								xxl={3}
							>
								<p className="tableHeader">
									Total {totalRecords} Companies
								</p>{' '}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 6 }}
								xl={{ span: 6 }}
								xxl={{ span: 6 }}
							>
								<XForm.Item>
									<XSearch
										placeholder="Search for Insurance Companies"
										allowClear
										enterButton="Search"
										size="large"
										defaultValue={
											filters.search
												? filters.search
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'search',
													'',
												);
												setFilters({
													...filters,
													search: '',
												});
											}
										}}
										onSearch={(v) => {
											urlPagination(filters, 'search', v);
											setFilters({
												...filters,
												search: v,
											});
										}}
									/>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4, offset: 7 }}
								xl={{ span: 4, offset: 7 }}
								xxl={{ span: 4, offset: 7 }}
							>
								<XForm.Item className="text-right">
									<FilterButton
										onClick={() => showDrawer()}
										icon={
											<img
												src="/images/filter-add.svg"
												alt="bell"
												height={'20px'}
											/>
										}
									>
										Filters
									</FilterButton>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4 }}
								xl={{ span: 4 }}
								xxl={{ span: 4 }}
							>
								<XForm.Item className="text-right">
									<XButton
										onClick={() => {
											form.resetFields();
											setModalOpen([
												true,
												'Add Insurance Company',
											]);
											setEditCompanyActive(false);
										}}
									>
										Add Insurance Company
									</XButton>
								</XForm.Item>
							</Col>
						</Row>
						<DataTable
							tableData={tableData}
							columns={columns}
							totalRecords={totalRecords}
							setFilters={setFilters}
							filters={filters}
							state={getInsuranceCompaniesState.apiState}
							page={'insurance-company'}
							handleEdit={handleEdit}
							showDeleteConfirm={showDeleteConfirm}
						/>
					</Card>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
			<XModal
				title={modalOpen[1]}
				centered
				open={modalOpen[0]}
				onCancel={() => {
					form.resetFields();
					setFormData({});
					setFileList([]);
					setFileChanged(false);
					setModalOpen([false, '']);
				}}
				footer={null}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={850}
			>
				<XForm
					form={form}
					name="roleForm"
					layout="vertical"
					autoComplete="off"
					onFinish={() => {
						// editCompanyActive
						// 	? updateInsuranceComapnySubmit()
						// 	: addInsuranceComapnySubmit();
						handleSubmit();
					}}
				>
					<Row gutter={(15, 30)} className="mt-4">
						<Col xs={24} lg={12}>
							<XForm.Item
								name="name"
								label="Name"
								rules={[
									{
										required: true,
										message: 'Please enter Company name',
									},
								]}
							>
								<Input
									size="large"
									placeholder="Enter Company Name"
									name="name"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item
								name="contact_person_email_address"
								label="Contact Person Email Address"
								rules={[
									{
										type: 'email',
										message:
											'Please enter a valid email address',
									},
								]}
							>
								<Input
									size="large"
									placeholder="Enter Contact Person Email Address"
									name="contact_person_email_address"
									onInput={lowerCaseInput}
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item
								name="contact_person_mobile_number"
								label="Contact Person Mobile Number"
								rules={[
									{
										validator: validateMobileNumber,
									},
								]}
							>
								<Input
									size="large"
									placeholder="Enter Contact Person Mobile Number"
									name="contact_person_mobile_number"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item name="fax_number" label="Fax Number">
								<Input
									size="large"
									placeholder="Enter Fax Number"
									name="fax_number"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item
								name="logo"
								label="Logo"
								rules={[
									{
										required: true,
										message: 'Please Add Logo',
									},
								]}
							>
								<Upload
									fileList={fileList}
									onChange={handleChange}
									multiple={false}
									maxCount={1}
								>
									<Button
										icon={<UploadOutlined />}
										size="large"
										style={{
											width: '100%',
											borderRadius: '10px',
											height: '45px',
										}}
									>
										Upload
									</Button>
								</Upload>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item name="address" label="Address">
								<TextArea
									size="large"
									placeholder="Enter Address"
									name="address"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item name="hq_state" label={'HQ State'}>
								<XSelect
									size="large"
									placeholder="Select State"
									name="hq_state"
									showSearch={true}
									className="removeBg"
									defaultValue={formData.hq_state}
									onFocus={() => {
										getStatesList();
									}}
									onSearch={(search) => {
										if (search.length >= 3) {
											getStatesList({
												search,
											});
										} else if (search.length === 0) {
											getStatesList();
										}
									}}
									filterOption={filterByLabel}
									options={
										getStatesListState.apiState ===
											'success' &&
										getStatesListState.data.all_states
											?.length > 0 &&
										getStatesListState.data.all_states.map(
											(itm) => {
												return {
													label: itm.state,
													value: itm.state,
													providers: itm.providers,
												};
											},
										)
									}
									onChange={(value, options) => {
										handleState('hq_state', value);
									}}
								></XSelect>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item
								name="functional_in_states"
								label={'Functional In States'}
							>
								<XSelect
									size="large"
									mode="multiple"
									placeholder="Select States"
									name="functional_in_states"
									maxTagCount={(3, 'responsive')}
									showSearch={true}
									className="removeBg"
									defaultValue={formData.functional_in_states}
									onFocus={() => {
										getStatesList();
									}}
									onSearch={(search) => {
										if (search.length >= 3) {
											getStatesList({
												search,
											});
										} else if (search.length === 0) {
											getStatesList();
										}
									}}
									filterOption={filterByLabel}
									options={
										getStatesListState.apiState ===
											'success' &&
										getStatesListState.data.all_states
											?.length > 0 &&
										getStatesListState.data.all_states.map(
											(itm) => {
												return {
													label: itm.state,
													value: itm.state,
													providers: itm.providers,
												};
											},
										)
									}
									onChange={(value, options) => {
										// handleInputChange(
										// 	value,
										// 	formData,
										// 	setFormData,
										// );
										handleState(
											'functional_in_states',
											value,
										);
									}}
								></XSelect>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item name="pincode" label="Pincode">
								<Input
									size="large"
									placeholder="Enter Pincode"
									name="pincode"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item name="city" label="City">
								<Input
									size="large"
									placeholder="Enter City"
									name="city"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item className="text-right mt-3">
								<XShareButton
									style={{
										background: '#212226',
										margin: 0,
									}}
									htmlType="submit"
									size="large"
									disabled={
										(editCompanyActive &&
											!modulePermissions.sub_modules.includes(
												'update-insurance-companies',
											)) ||
										!modulePermissions.sub_modules.includes(
											'add-insurance-companies',
										)
									}
								>
									Submit
								</XShareButton>
							</XForm.Item>
						</Col>
						<Col xs={24} lg={12}>
							<XForm.Item className="text-right mt-3">
								<XShareButton
									style={{
										background: '#fff',
										margin: 0,
										color: '#000',
									}}
									size="large"
									onClick={() => {
										form.resetFields();
										setFormData({});
										setModalOpen([false, '']);
									}}
								>
									Cancel
								</XShareButton>
							</XForm.Item>
						</Col>{' '}
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getInsuranceCompaniesState: state.getInsuranceCompanies,
	addInsuranceCompanyState: state.addInsuranceCompany,
	editInsuranceCompanyState: state.editInsuranceCompany,
	getInsuranceCompanyByIdState: state.getInsuranceCompanyById,
	deleteInsuranceCompanyByIdState: state.deleteInsuranceCompanyById,
	deleteInsuranceCompanyState: state.deleteInsuranceCompany,
	getStateDropdownState: state.getStateDropdown,
	getStatesListState: state.getStatesList,
});

const mapDispatchToProps = (dispatch) => ({
	getInsuranceCompanies: (data) => dispatch(getInsuranceCompaniesApi(data)),
	addInsuranceCompany: (data) => dispatch(addInsuranceCompanyApi(data)),
	editInsuranceCompany: (data) => dispatch(editInsuranceCompanyApi(data)),
	getInsuranceCompanyById: (data) =>
		dispatch(getInsuranceCompanyByIdApi(data)),
	deleteInsuranceCompany: (data) => dispatch(deleteInsuranceCompanyApi(data)),
	getInsuranceCompaniesReset: () => dispatch(getInsuranceCompaniesReset()),
	addInsuranceCompanyReset: () => dispatch(addInsuranceCompanyReset()),
	editInsuranceCompanyReset: () => dispatch(editInsuranceCompanyReset()),
	deleteInsuranceCompanyReset: () => dispatch(deleteInsuranceCompanyReset()),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStatesList: (params) => dispatch(getStatesListApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getStatesListReset: () => dispatch(getStatesListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCompany);
