import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button, Radio, Avatar, Upload } from 'antd';

export const XCardBackBg = styled(Card)`
	// background: var(
	// 	--Linear,
	// 	linear-gradient(
	// 		90deg,
	// 		rgba(105, 234, 203, 0.15) 0%,
	// 		rgba(234, 204, 248, 0.15) 48%,
	// 		rgba(102, 84, 241, 0.15) 100%
	// 	)
	// );
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		border-radius: 10px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XButtonBack = styled(Button)`
	color: ${({ theme }) => theme.colors.menu2};
	border-radius: 20px !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
	svg {
		font-size: 16px;
		font-weight: 600;
	}
	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XEditTitle = styled.span`
	margin-left: 15px;
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.36px;
`;

export const XRadio = styled(Radio)`
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 400;
	font-size: 12px !important;
	line-height: 16px !important;
	.ant-form-item-control-input {
		min-height: auto !important;
	}
	.ant-radio-checked .ant-radio-inner {
		background: #007299;
		border-color: #007299;
	}
`;
export const XAvatarName = styled(Avatar)`
	background: #007299;
	color: ${({ theme }) => theme.colors.white};
`;
export const MainTitle = styled.span`
	margin-left: 10px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.color11};
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	display: inline-block;
	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.color11};
	}
`;
export const SubTitle = styled.span`
	margin-left: 10px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.color11};
`;
export const ParagraphPage = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.colors.color11};
	font-family: ${({ theme }) => theme.colors.fontPoppins};
`;
export const AddressWrap = styled(Card)`
	width: 100%;
	position: relative;
	background: #f7f8fa;
	border-radius: 10px;
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	.heading {
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.menu2};
		span {
			padding-left: 5px;
			font-weight: 500;
			color: #57636f;
		}
	}
`;
export const CaseFillingTitle = styled.p`
	margin: 0px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -0.02em;
	color: ${({ theme }) => theme.colors.menu2};
`;

export const CaseTimelineTitle = styled.h1`
	margon: 0px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.menu2};
`;
export const CaseUpdateCard = styled.section`
	width: 100%;
	position: relative;
	hr {
		background: ${({ theme }) => theme.colors.menu2};
	}
	.title {
		font-weight: 400;
		font-size: 13px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-form-item-label label,
	label.ant-form-item-no-colon,
	.ant-form-item-label > label,
	.ant-form-item-label {
		font-family: ${({ theme }) => theme.colors.fontInter};
		font-weight: 400 !important;
		font-size: 13px !important;
		line-height: 16px !important;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-form-item-control-input {
		min-height: 24px !important;
	}
	.inputBox.ant-picker,
	.ant-input-affix-wrapper,
	.selectBox.ant-select {
		width: 100%;
		background: #ffffff !important;
		border: 1px solid rgba(0, 33, 65, 0.1) !important;
		border-radius: 10px !important;
		font-size: 12px !important;
		color: ${({ theme }) => theme.colors.menu2};
	}
	input.inputText {
		background: #ffffff !important;
	}
	${
		'' /* .ant-form-item-control-input {
		background: #ffffff !important;
		border-radius: 10px !important;
	} */
	}
	.innerCardWrap {
		width: 100%;
		position: relative;
		background: ${({ theme }) => theme.colors.white};
		padding: 15px;
		border: 1px solid rgba(0, 33, 65, 0.1);
		border-radius: 10px;
	}
`;
export const XUpload = styled(Upload)`
	width: 100%;
	positioon: relative;
	cursor: pointer;
	.ant-upload.ant-upload-select {
		width: 100% !important;
		positioon: relative;
		height: auto !important;
	}
	.uploadCard {
		width: 100%;
		position: relative;
		background: rgba(15, 115, 238, 0.05);
		border: 1px solid rgba(0, 33, 65, 0.1);
		border-radius: 10px;
		padding: 20px 10px;
		text-align: center;
	}
`;
