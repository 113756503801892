import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';
export const GET_ORGANIZATIONS_RESET = 'GET_ORGANIZATIONS_RESET';

export const getOrganizations = (params) => {
	return {
		type: GET_ORGANIZATIONS,
		params,
	};
};

export const getOrganizationsSuccess = (response) => {
	return {
		type: GET_ORGANIZATIONS_SUCCESS,
		response,
	};
};

export const getOrganizationsFail = (response) => {
	return {
		type: GET_ORGANIZATIONS_FAIL,
		response,
	};
};

export const getOrganizationsReset = () => {
	return {
		type: GET_ORGANIZATIONS_RESET,
	};
};

export const getOrganizationsApi = (data) => {
	return (dispatch) => {
		dispatch(getOrganizations());
		axios
			.get(`${config.api.base_url}/organization`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getOrganizationsData = response.data;
				dispatch(getOrganizationsSuccess(getOrganizationsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getOrganizationsFail(errorMsg));
			});
	};
};
