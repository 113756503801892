// DonutChart.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DonutChart = (props) => {
	const chartRef = useRef(null);

	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');

		const data = props.data;

		// Chart configuration
		const options = {
			animation: {
				animateRotate:false,
			},
			cutout: '60%',
			plugins: {
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						boxWidth: 10,
					},
				},
			},
		};

		// Create the donut chart
		const donutChart = new Chart(ctx, {
			type: 'doughnut',
			data,
			options,
		});

		return () => {
			// Cleanup when the component is unmounted
			donutChart.destroy();
		};
	}, [props]); // Empty dependency array ensures that the effect runs only once

	return <canvas ref={chartRef} className="donut-chart" />;
};

export default DonutChart;
