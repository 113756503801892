import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CASE_AMOUNT_BUCKET = 'GET_CASE_AMOUNT_BUCKET';
export const GET_CASE_AMOUNT_BUCKET_SUCCESS = 'GET_CASE_AMOUNT_BUCKET_SUCCESS';
export const GET_CASE_AMOUNT_BUCKET_FAIL = 'GET_CASE_AMOUNT_BUCKET_FAIL';
export const GET_CASE_AMOUNT_BUCKET_RESET = 'GET_CASE_AMOUNT_BUCKET_RESET';

export const getCaseAmountBucket = (params) => {
	return {
		type: GET_CASE_AMOUNT_BUCKET,
		params,
	};
};

export const getCaseAmountBucketSuccess = (response) => {
	return {
		type: GET_CASE_AMOUNT_BUCKET_SUCCESS,
		response,
	};
};

export const getCaseAmountBucketFail = (response) => {
	return {
		type: GET_CASE_AMOUNT_BUCKET_FAIL,
		response,
	};
};

export const getCaseAmountBucketReset = () => {
	return {
		type: GET_CASE_AMOUNT_BUCKET_RESET,
	};
};

export const getCaseAmountBucketApi = (data) => {
	return (dispatch) => {
		dispatch(getCaseAmountBucket());
		axios
			.get(`${config.api.base_url}/dashboard/get-case-amount-bucket`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCaseAmountBucketData = response.data;
				dispatch(getCaseAmountBucketSuccess(getCaseAmountBucketData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCaseAmountBucketFail(errorMsg));
			});
	};
};
