import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Editor, EditorContent } from '@tinymce/tinymce-react';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Card,
	Result,
	Modal,
	Tooltip,
	Tag,
	Image,
	Upload,
	Form,
	List,
	Popconfirm,
	Typography,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { Router, useNavigate, useParams } from 'react-router-dom';
import ReloadingAnimation from '../../json/reloading.json';
import DownloadAnimation from '../../json/download.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XShareButton,
	XTag,
	XCardsCriteriaBg,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
} from '../CaseForm/CaseFormStyle';

import { XButtonRounded } from './AppealLetterViewStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XTitleHead,
	XDivider,
	NDivider,
	XButtonLike,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonAdd,
	XButtonConfirm,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	EditOutlined,
	ShareAltOutlined,
	EyeOutlined,
	ArrowLeftOutlined,
	PlusOutlined,
	WarningOutlined,
	FileTextOutlined,
	FilePdfOutlined,
	FileSyncOutlined,
	InboxOutlined,
	DeleteOutlined,
	UploadOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import {
	saveAppealLetterApi,
	saveAppealLetterReset,
} from '../../actions/saveAppealLetterAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	checkModulePermissions,
	getUserToken,
	toTitleCase,
} from '../../utils/helper';
import moment from 'moment';
import Header from '../../components/Header/Header';
import {
	generateAppealLetterApi,
	generateAppealLetterReset,
} from '../../actions/generateAppealLetterAction';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import html2pdf from 'html2pdf.js';
import config from '../../config';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { handleInputChange } from '../../utils/formFunctions';
import {
	saveReferencesApi,
	saveReferencesReset,
} from '../../actions/saveReferencesAction';
import { XListItem } from '../../components/HorizontalTimeline/PrepareLetterStyle';
const { Dragger } = Upload;
const { Text } = Typography;

// import Dragger from 'antd/es/upload/Dragger';
const { TextArea } = Input;
// Set workerSrc URL to worker script provided by react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CaseAnalysisView = (props) => {
	const {
		getAnalysis,
		getAnalysisState,
		getAppealLetter,
		getAppealLetterState,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
		editCase,
		editCaseState,
		editCaseReset,
		generateAppealLetterState,
		generateAppealLetter,
		generateAppealLetterReset,
		saveReferences,
		saveReferencesState,
		saveReferencesReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	const [patientData, setPatientData] = useState({});
	const [form] = XForm.useForm();
	const [previewAppealLetterSelected, setPreviewAppealLetterSelected] =
		useState(false);
	const [editAppealLetterSelected, setEditAppealLetterSelected] =
		useState(false);
	const [showAppealLetterPreview, setShowAppealLetterPreview] =
		useState(false);
	const [showEditAppealLetter, setShowEditAppealLetter] = useState(false);
	const [appealLetterContent, setAppealLetterContent] = useState('');
	const [appealLetterType, setAppealLetterType] = useState('');
	const [appealLetterHeader, setAppealLetterHeader] = useState('');
	const [appealLetterFooter, setAppealLetterFooter] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [displayFaxUpload, setDisplayFaxUpload] = useState(false);
	const [displayEmailContent, setDisplayEmailContent] = useState(false);
	const [letterDownloaded, setLetterDownloaded] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [fileList, setFileList] = useState([]);
	const editorRef = useRef(null);
	const [matchDateOfBirth, setMatchDateOfbirth] = useState(true);
	const [matchGender, setMatchGender] = useState(true);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
	});
	const [loading, setLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [isShareModalOpen, setIsShareModalOpen] = useState([false, '', '']);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false); // State to manage the disabled state of the Confirm Button
	const [confirmButtonBackground, setConfirmButtonBackground] =
		useState('black');
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [documentName, setDocumentName] = useState('');
	const [updatedFileList, setUpdatedFileList] = useState([]);
	const [submitCase, setSubmitCase] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
	const [
		isConfirmAppealLetterNoitifcationOpen,
		setIsConfirmAppealLetterNoitifcationOpen,
	] = useState(false);
	const [AddReferencesform] = XForm.useForm();
	const [
		disableAppealLetterActionButton,
		setDisableAppealLetterActionButton,
	] = useState(false);
	const [denialLetterActionModal, setDenialLetterActionModal] = useState({
		key: 0,
		data: {},
		open: false,
	});
	const [denialLetterActionLoading, setDenialLetterActionLoading] =
		useState(false);
	const [showSaveReferencesButton, setShowSaveReferencesButton] =
		useState(false);
	const [supportingDocsLoading, setSupportingDocsLoading] = useState(false);
	const [
		isDeleteSupportingDocsModalVisible,
		setIsDeleteSupportingDocsModalVisible,
	] = useState(false);
	const [isDeletingSupportingDocs, setIsDeletingSupportingDocs] =
		useState(false);
	const [supportingDocsFileToDelete, setSupportingDocsFileToDelete] =
		useState(null);

	const showUploadModal = () => {
		setIsUploadModalOpen(true);
	};
	const handleUploadOk = () => {
		setIsUploadModalOpen(false);
	};
	const handleUploadCancel = () => {
		setFileList([]);
		setUpdatedFileList([]);
		form.setFieldsValue(
			'document_name',
			'denial_action_docs',
			'supporting_docs',
		);
		setIsUploadModalOpen(false);
	};
	const showShareModal = (bool, id, type) => {
		setIsShareModalOpen([bool, id, type]);
	};
	const handleShareOk = () => {
		setIsShareModalOpen(false);
	};
	const handleShareCancel = () => {
		setIsShareModalOpen(false);
		setDisplayFaxUpload(false);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	const previewAppealLetter = (type, regenerate = false) => {
		getAppealLetter({
			id: id,
			type: type,
			regenerate_appeal_letter: regenerate,
		});
		setPreviewAppealLetterSelected(true);
		setAppealLetterType(type);
	};

	const closePreviewAppealLetter = () => {
		setShowAppealLetterPreview(false);
		setPreviewAppealLetterSelected(false);
		setAppealLetterContent('');
		setAppealLetterHeader('');
		setAppealLetterFooter('');
	};
	const confirmAppealLetter = () => {
		setAppealLetterLoading(true);
		editCase({
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal letter confirmed',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		setPreviewAppealLetterSelected(false);
	};

	const handleConfirmAppealLetterNoitifcationOk = () => {
		confirmAppealLetter();
		setIsConfirmAppealLetterNoitifcationOpen(false);
	};
	const handleConfirmAppealLetterNoitifcationCancel = () => {
		setIsConfirmAppealLetterNoitifcationOpen(false);
	};
	const showConfirmAppealLetterNoitifcation = () => {
		setIsConfirmAppealLetterNoitifcationOpen(true);
	};

	const editAppealLetter = (type) => {
		getAppealLetter({ id: id, type: type });
		setEditAppealLetterSelected(true);
		setAppealLetterType(type);
	};

	const closeEditAppealLetter = () => {
		setShowEditAppealLetter(false);
		setEditAppealLetterSelected(false);
		setAppealLetterContent('');
	};

	const saveAndPreviewAppealLetter = () => {
		if (editorRef.current) {
			setEditAppealLetterSelected(false);
			saveAppealLetter({
				id: id,
				appeal_letter: editorRef.current.getContent(),
				type: appealLetterType,
			});
		}
	};

	const editAppealLetterFromModal = () => {
		setPreviewAppealLetterSelected(false);
		setShowAppealLetterPreview(false);
		setAppealLetterContent('');
		editAppealLetter(appealLetterType);
	};

	const handleEmailSend = () => {
		const subject = `Case Details: ${patientData.patient_first_name} ${patientData.patient_last_name} (MRN: ${patientData.mrn})`;
		const body = `Hi,\n\nI hope this email finds you well. Below are the case details regarding denials made by ${
			patientData.insurance_company?.name || ''
		}.\n\nMRN: ${patientData.mrn}\nName: ${
			patientData.patient_first_name
		} ${patientData.patient_last_name}\nAge: ${moment(
			patientData.date_of_birth,
		).fromNow(true)},\nGender: ${
			patientData.gender ? toTitleCase(patientData.gender) : ''
		}\nDOB: ${
			patientData.date_of_birth
				? dateOfBirth(patientData.date_of_birth)
				: ''
		}\nState: ${patientData.state}\nInsurance Company: ${
			patientData.insurance_company?.name || '-'
		}\n\nClinical Summary: ${patientData.clinical_summary}.`;

		const mailtoLink = `mailto:?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;

		// Open the email client
		window.open(mailtoLink);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
		editCase({
			id: id,
			appeal_status: 'sent',
			appeal_status_updated_at: moment(),
		});
		generateAppealLetterReset();
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const uploadButton = (
		<Button
			onClick={() => {
				showUploadModal();
			}}
			style={{
				border: 0,
				display: 'block',
				height: '100px',
				width: '100px',
				margin: '10px',
				background: 'rgb(229 227 227 / 69%)',
			}}
		>
			<PlusOutlined />
			<div>Upload</div>
		</Button>
	);

	const handleDownload = (file) => {
		// Assuming 'file' contains the URL of the file to download
		window.open(`${config.s3_url}${file}`, '_blank');
	};

	function compareDates(dateString, yearNumber) {
		// Parse the date string using Moment.js
		const dateOfBirth = moment(dateString);

		// Calculate the current year
		const currentYear = moment().year();

		// Extract the year from the parsed date of birth
		const birthYear = dateOfBirth.year();

		// Calculate the age by subtracting the birth year from the current year
		const age = currentYear - birthYear;
		// Extract the last two digits of the year number
		const yearDigits = parseInt(yearNumber.toString().slice(-2));
		// Compare the years
		return age === yearDigits;
	}
	function compareGender(patientGender, analysisGender) {
		// Get the first character of the analysisGender string and normalize to uppercase
		const firstCharAnalysis = analysisGender.charAt(0).toUpperCase();

		// Check if patientGender is "M" (Male) or "F" (Female)
		if (
			patientGender.toUpperCase() === 'MALE' ||
			patientGender.toUpperCase() === 'FEMALE'
		) {
			// Compare the first character of analysisGender with patientGender
			return firstCharAnalysis === patientGender.charAt(0).toUpperCase();
		} else {
			// Match patientGender with "O" (Other)
			return firstCharAnalysis === 'O';
		}
	}
	// use effects
	useEffect(() => {
		if (id) {
			getAnalysis({ id: id });
		}
	}, [id]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setSubmitCase(false);
			setPatientData(getAnalysisState.data.case_details);
			AddReferencesform.setFieldsValue({
				references: getAnalysisState.data.case_details.references,
			});
			if (getAnalysisState.data.case_details?.denial_letter_action) {
				if (
					getAnalysisState.data.case_details?.denial_letter_action
						.revenue_cycle_stage ==
					getAnalysisState.data.case_details.revenue_cycle_stage
				) {
					if (
						getAnalysisState.data.case_details?.denial_letter_action
							.actions.length > 0
					) {
						let action_pending =
							getAnalysisState.data.case_details?.denial_letter_action.actions.filter(
								(item) => item.marked_as_done == false,
							);

						if (action_pending.length > 0) {
							setDisableAppealLetterActionButton(true);
						} else {
							setDisableAppealLetterActionButton(false);
						}
					}
				}
			}
			const supportingDocs =
				getAnalysisState.data.case_details.supporting_docs.length > 0 &&
				getAnalysisState.data.case_details.supporting_docs.map(
					(doc) => doc,
				);
			setDocumentsToDisplay(supportingDocs);

			setPageContent({
				...pageContent,
				originalQuery:
					getAnalysisState.data.coverage_feedback_response[
						'original_query'
					] ?? '-',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
			});
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.age
			)
				setMatchDateOfbirth(
					compareDates(
						getAnalysisState.data.case_details.date_of_birth,
						getAnalysisState.data.coverage_feedback_response.age,
					),
				);
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.gender
			)
				setMatchGender(
					compareGender(
						getAnalysisState.data.case_details.gender,
						getAnalysisState.data.coverage_feedback_response.gender,
					),
				);

			if (denialLetterActionModal.open) {
				setDenialLetterActionLoading(false);
				setDenialLetterActionModal({
					key: 0,
					data: {},
					open: false,
				});
			}
		}
		getAnalysisReset();
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			setSupportingDocsLoading(false);
			setIsDeleteSupportingDocsModalVisible(false); // Close modal after success
			setIsDeletingSupportingDocs(false);
			setSubmitCase(false);
			setShowAppealLetterPreview(false);
			setAppealLetterContent('');
			setAppealLetterFooter('');
			setAppealLetterHeader('');
			getAnalysis({ id: id });
			setIsUploadModalOpen(false);
			setFileList([]);
			setUpdatedFileList([]);
			form.setFieldsValue('document_name', '');
			setAppealLetterLoading(false);
			notification.success({
				message: editCaseState.message,
			});
		} else if (editCaseState.apiState === 'error') {
			setSupportingDocsLoading(false);
			setIsDeleteSupportingDocsModalVisible(false); // Close modal after success
			setIsDeletingSupportingDocs(false);
			setAppealLetterLoading(false);
			notification.error({
				message: 'Unable to confirm Appeal letter',
			});
		}
		editCaseReset();
	}, [editCaseState]);

	useEffect(() => {
		if (getAppealLetterState.apiState === 'success') {
			setLoading(false);
			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
			setAppealLetterHeader(getAppealLetterState.data.header);
			setAppealLetterFooter(getAppealLetterState.data.footer);
			if (previewAppealLetterSelected) {
				setShowAppealLetterPreview(true);
			} else if (editAppealLetterSelected) {
				setShowEditAppealLetter(true);
			}
			getAnalysis({ id: id });
		} else if (getAppealLetterState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message: getAppealLetterState.message
					? getAppealLetterState.message
					: 'Unable to get Appeal Letter content',
			});
		}
		getAppealLetterReset();
	}, [getAppealLetterState.apiState]);

	useEffect(() => {
		if (generateAppealLetterState.apiState === 'success') {
			notification.success({
				message: 'Appeal Letter downloaded',
			});
			if (displayEmailContent) {
				setDisplayEmailContent(true);
				setLetterDownloaded(true);
				setDownloadLoading(false);
			} else {
				showShareModal(false, '', '');
				editCase({
					id: id,
					appeal_status: 'sent',
					appeal_status_updated_at: moment(),
				});
				setDownloadLoading(false);
				setLetterDownloaded(false);
				setDisplayEmailContent(false);
				setDisplayFaxUpload(false);
				setIsShareModalOpen(false);
			}
			setDownloadLoading(false);
		} else if (generateAppealLetterState.apiState === 'error') {
			setDownloadLoading(false);
			setLetterDownloaded(false);
			setDisplayEmailContent(false);
			setDisplayFaxUpload(false);
			setIsShareModalOpen(false);
			notification.error({
				message: 'Unable to download Appeal Letter',
			});
		}
		generateAppealLetterReset();
	}, [generateAppealLetterState.apiState]);

	useEffect(() => {
		if (saveAppealLetterState.apiState === 'success') {
			setAppealLetterContent('');
			setAppealLetterHeader('');
			setAppealLetterFooter('');
			setShowEditAppealLetter(false);
			previewAppealLetter();
			saveAppealLetterReset();
		} else if (saveAppealLetterState.apiState === 'error') {
			notification.error({
				message: 'Unable to save Appeal Letter content',
			});
			saveAppealLetterReset();
		}
		setShowAppealLetterPreview(false);
	}, [saveAppealLetterState.apiState]);

	const sendAppealLetterForApproval = () => {
		setSubmitCase(false);
		// setTogglePrepareLetter(false);
		editCase({
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal Letter Sent for approval',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			send_for_approval: true,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		setPreviewAppealLetterSelected(false);
	};

	const getAppealLetterButtonText = () => {
		return patientData?.appeal_letter_approval_threshold !== 0
			? patientData?.case_amount >=
			  patientData?.appeal_letter_approval_threshold
				? patientData?.appeal_letter_sent_for_approval
					? 'Approve'
					: 'Send for Approval'
				: patientData?.appeal_letter_sent_for_approval
				? 'Approve'
				: 'Send for Approval'
			: patientData?.appeal_letter_sent_for_approval
			? 'Approve'
			: 'Send for Approval';
	};

	const getAppealLetterButtonFunction = () => {
		return patientData?.appeal_letter_approval_threshold !== 0
			? patientData?.case_amount >=
			  patientData?.appeal_letter_approval_threshold
				? patientData?.appeal_letter_sent_for_approval
					? documentsToDisplay.length > 0
						? confirmAppealLetter
						: showConfirmAppealLetterNoitifcation
					: sendAppealLetterForApproval
				: patientData?.appeal_letter_sent_for_approval
				? documentsToDisplay.length > 0
					? confirmAppealLetter
					: showConfirmAppealLetterNoitifcation
				: sendAppealLetterForApproval
			: patientData?.appeal_letter_sent_for_approval
			? documentsToDisplay.length > 0
				? confirmAppealLetter
				: showConfirmAppealLetterNoitifcation
			: sendAppealLetterForApproval;
	};

	// Modify this function to handle the enabling/disabling of the Confirm Button
	const AppealLetterConfirmButtonStatus = () => {
		// Logic to determine if the button should be disabled based on your conditions
		const shouldDisableButton =
			patientData.revenue_cycle_stage === 'organization_determination' ||
			patientData.appeal_status === 'rejected' ||
			patientData.appeal_status == null;
		setConfirmButtonDisabled(shouldDisableButton);
		if (shouldDisableButton) setConfirmButtonBackground('');
		else setConfirmButtonBackground('black');

		if (
			patientData.revenue_cycle_stage === 'judicial_review' &&
			patientData.appeal_status === 'rejected'
		) {
			setConfirmButtonDisabled(!shouldDisableButton);

			if (shouldDisableButton) setConfirmButtonBackground('');
			else setConfirmButtonBackground('black');

			return;
		}

		let approve_appeal_letter = false;

		if (!patientData.appeal_letter_approved) {
			if (patientData.appeal_letter_approval_threshold !== 0) {
				if (
					patientData?.case_amount >=
					patientData?.appeal_letter_approval_threshold
				) {
					if (patientData.appeal_letter_sent_for_approval)
						approve_appeal_letter = true;
					else {
						setConfirmButtonDisabled(false);
						setConfirmButtonBackground('black');
					}
				} else approve_appeal_letter = true;
			} else if (patientData.appeal_letter_sent_for_approval)
				approve_appeal_letter = true;
			else {
				setConfirmButtonDisabled(false);
				setConfirmButtonBackground('black');
			}
		} else {
			setConfirmButtonDisabled(true);
			setConfirmButtonBackground('');
		}

		if (approve_appeal_letter) {
			if (
				checkModulePermissions('appeal-letter').sub_modules.includes(
					'confirm-letter',
				)
			) {
				setConfirmButtonDisabled(false);
				setConfirmButtonBackground('black');
			} else {
				setConfirmButtonDisabled(true);
				setConfirmButtonBackground('');
			}
		}
	};

	useEffect(() => {
		if (showAppealLetterPreview) {
			AppealLetterConfirmButtonStatus();
		}
	}, [showAppealLetterPreview]);

	useEffect(() => {
		AppealLetterConfirmButtonStatus();
	}, [patientData]);

	const handleChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileList(updatedFileList);
		form.validateFields(['supporting_docs', 'denial_action_docs']);
	};

	const handleSubmit = async () => {
		setSupportingDocsLoading(true);
		try {
			form.validateFields()
				.then(async () => {
					if (fileList.length > 0) {
						await Promise.all(
							fileList.map(async (fileData) => {
								let get_ext =
									fileData.originFileObj.name.split('.');
								let ext = get_ext[get_ext.length - 1];
								const res = await axios.get(
									`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${patientData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${patientData.case_id}`,
									{
										headers: {
											'Content-type':
												'application/json; charset=UTF-8',
											Authorization:
												'Bearer ' + getUserToken(),
										},
									},
								);

								const result = await axios.put(
									res.data.data,
									fileData.originFileObj,
								);

								if (result.status === 200) {
									const documentPath = `/case_supporting_documents/${patientData.case_id}/${patientData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
									const newFile = {
										uid: fileData.originFileObj.uid,
										name: fileData.originFileObj.name,
										status: 'done',
										url: documentPath,
									};
									setUpdatedFileList(newFile);
								}
							}),
						).then((res) => {
							setSubmitCase(true);
						});
					}
					setSubmitCase(true);
				})
				.catch((err) => {
					setSupportingDocsLoading(false);
					notification.error({
						message: 'Could not upload',
					});
				});
		} catch (error) {
			setSupportingDocsLoading(false);
			// Handle error if any
			notification.success({
				message: 'Could not upload',
			});
			console.error('Error occurred:', error);
		}
	};

	// const handleRemoveFile = async (file) => {
	// 	// Show confirmation modal
	// 	Modal.confirm({
	// 		title: 'Confirm Deletion',
	// 		content: 'Are you sure you want to delete this file?',
	// 		okText: 'Yes',
	// 		cancelText: 'No',
	// 		onOk: async () => {
	// 			// Filter out the removed file from the fileList
	// 			const updatedFileList = documentsToDisplay.filter(
	// 				(item) => item.uid !== file.uid,
	// 			);
	// 			setFileList(updatedFileList);
	// 			// Make an API call to delete the file from S3 and your collection
	// 			try {
	// 				// Extract file name and extension from the file URL
	// 				const urlParts = file.url.split('/');
	// 				const fileName = urlParts[urlParts.length - 1];
	// 				const case_id = urlParts[urlParts.length - 2];
	// 				const [patientName, ext] = fileName.split('.');

	// 				// Make API calls to delete the file from S3 and collection
	// 				await axios.delete(
	// 					`${config.api.base_url}/settings/delete-signed-url?folder_name=case_supporting_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
	// 					{
	// 						headers: {
	// 							Authorization: 'Bearer ' + getUserToken(),
	// 						},
	// 					},
	// 				);

	// 				// Remove the file from supporting_docs array
	// 				const updatedSupportingDocs = documentsToDisplay.filter(
	// 					(item) => item.url.uid === file.uid,
	// 				);

	// 				// Make API call to update the supporting_docs field in your case
	// 				await editCase({
	// 					id: id,
	// 					supporting_docs: updatedSupportingDocs,
	// 					remove: true,
	// 				});

	// 				console.log(
	// 					'File deleted successfully from S3 and collection',
	// 				);
	// 			} catch (error) {
	// 				console.error('Error deleting file:', error);
	// 				// Handle error
	// 			}
	// 		},
	// 		onCancel: () => {
	// 			console.log('Deletion cancelled');
	// 		},
	// 	});
	// };

	const handleRemoveFile = async () => {
		setIsDeletingSupportingDocs(true);
		// Show confirmation modal
		if (!supportingDocsFileToDelete) return;

		// Modal.confirm({
		// 	title: 'Confirm Deletion',
		// 	content: 'Are you sure you want to delete this file?',
		// 	okText: 'Yes',
		// 	cancelText: 'No',
		// 	onOk: async () => {
		// Filter out the removed file from the fileList
		const updatedFileList = documentsToDisplay.filter(
			(item) => item.url?.uid !== supportingDocsFileToDelete.uid,
		);

		setFileList(updatedFileList);
		// Make an API call to delete the file from S3 and your collection
		try {
			// Extract file name and extension from the file URL
			const urlParts = supportingDocsFileToDelete.url.split('/');
			const fileName = urlParts[urlParts.length - 1];
			const case_id = urlParts[urlParts.length - 2];
			const [patientName, ext] = fileName.split('.');

			// Make API calls to delete the file from S3 and collection
			await axios.delete(
				`${config.api.base_url}/settings/delete-signed-url?folder_name=case_supporting_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);

			// Remove the file from supporting_docs array
			const updatedSupportingDocs = documentsToDisplay.filter(
				(item) => item.url.uid === supportingDocsFileToDelete.uid,
			);

			// Make API call to update the supporting_docs field in your case
			await editCase({
				id: id,
				supporting_docs: updatedSupportingDocs,
				remove: true,
			});

			console.log('File deleted successfully from S3 and collection');
		} catch (error) {
			setIsDeleteSupportingDocsModalVisible(false); // Close modal after success
			setIsDeletingSupportingDocs(false);

			console.error('Error deleting file:', error);
			// Handle error
		}
	};

	const showSupportingDocsDeleteConfirm = (file) => {
		setSupportingDocsFileToDelete(file);
		setIsDeleteSupportingDocsModalVisible(true);
	};

	const handleCancelSupportingDocsDelete = () => {
		setIsDeleteSupportingDocsModalVisible(false);
		setSupportingDocsFileToDelete(null);
	};

	useEffect(() => {
		if (saveReferencesState.apiState == 'success') {
			notification.success({
				message: 'References added successfully',
			});
			setShowSaveReferencesButton(false);
		} else if (saveReferencesState.apiState == 'error') {
			notification.error({
				message: 'Unable to save references',
			});
		}
		saveReferencesReset();
	}, [saveReferencesState]);

	useEffect(() => {
		if (submitCase) {
			let submit_data = {
				id: id,
				supporting_docs: [
					{
						url: updatedFileList,
						document_name: documentName,
						is_denial_letter_action: denialLetterActionModal.open,
						revenue_cycle_stage: denialLetterActionModal.open
							? patientData.revenue_cycle_stage
							: '',
						denial_letter_action_key: denialLetterActionModal.open
							? denialLetterActionModal.key
							: '',
					},
				],
				upload_documents: true,
			};

			if (denialLetterActionModal.open) {
				submit_data.denial_letter_action = processDenialLetterAction(
					true,
					false,
				);
				submit_data.denial_letter_action_status = true;
				submit_data.denial_letter_action_key =
					denialLetterActionModal.key;

				setDenialLetterActionLoading(true);
			}
			editCase(submit_data);
		}
	}, [submitCase]);

	const processDenialLetterAction = (
		actionStatus,
		updateDenialLetterAction,
	) => {
		let denial_letter_action = JSON.parse(
			JSON.stringify(patientData.denial_letter_action),
		);
		denial_letter_action.actions[
			denialLetterActionModal.key
		].marked_as_done = actionStatus;

		if (updateDenialLetterAction) {
			setDenialLetterActionLoading(true);
			editCase({
				id: id,
				denial_letter_action,
				denial_letter_action_status: actionStatus,
				denial_letter_action_key: denialLetterActionModal.key,
			});
		} else return denial_letter_action;
	};

	const DenialLetterAction = ({
		patientData,
		setDenialLetterActionModal,
	}) => {
		if (
			!patientData?.denial_letter_action ||
			patientData.denial_letter_action.revenue_cycle_stage !==
				patientData.revenue_cycle_stage ||
			patientData.denial_letter_action.actions.length === 0
		) {
			return null;
		}

		return (
			<Col xs={24}>
				<XPara1
					className="mb-3"
					style={{
						fontSize: '14px',
						lineHeight: '20px',
					}}
				>
					Please take these actions before generating appeal letter
				</XPara1>
				<List
					dataSource={patientData.denial_letter_action.actions}
					renderItem={(item, key) => (
						<XListItem
							style={{
								backgroundColor: item.marked_as_done
									? '#FFFFFF'
									: '#F6F7FB',
								boxShadow: item.marked_as_done
									? '0px -5px 2px 0px rgba(238, 239, 247, 1) inset'
									: 'none',
							}}
						>
							<Row
								className="w-100"
								style={{
									backgroundColor: item.marked_as_done
										? '#FFFFFF'
										: '#F6F7FB',
								}}
							>
								<Col
									xs={21}
									style={{
										alignSelf: 'center',
									}}
								>
									{item.reason.length < 40
										? item.reason
										: item.reason.slice(0, 40) + '...'}
								</Col>
								<Col xs={3}>
									{item.marked_as_done ? (
										<Button
											style={{
												float: 'right',
												padding: '0px',
											}}
											icon={
												<img
													height="30px"
													src="/images/checked.svg"
												/>
											}
											onClick={() => {
												setDenialLetterActionModal({
													key,
													data: item,
													open: true,
												});
											}}
										></Button>
									) : (
										<XButtonAdd
											style={{
												float: 'right',
												padding: '0px',
											}}
											icon={<UploadOutlined />}
											onClick={() => {
												setDenialLetterActionModal({
													key,
													data: item,
													open: true,
												});
											}}
										></XButtonAdd>
									)}
								</Col>
							</Row>
						</XListItem>
					)}
				></List>
				<br />
			</Col>
		);
	};

	return (
		<>
			{/* Notification Bar to raeanalyse */}
			{loading && (
				<XLottieOverlay>
					<XLottie animationData={ReloadingAnimation} loop={true} />
				</XLottieOverlay>
			)}
			{downloadLoading && (
				<XLottieOverlay>
					<XLottie animationData={DownloadAnimation} loop={true} />
				</XLottieOverlay>
			)}
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Appeal Letter" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="appealLetter" />
				</Col>
			</>
			<XContainer fluid>
				{checkModulePermissions('appeal-letter').sub_modules.includes(
					'view-letter',
				) ? (
					<>
						<XCardBackBg className="mb-3">
							<Row>
								<Col xs={24}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>
									<XEditTitle>View Case</XEditTitle>
								</Col>
							</Row>
						</XCardBackBg>
						<XCardBackBg className="mb-3">
							<Row
								justify={'start'}
								align={'middle'}
								style={{ rowGap: '10px' }}
							>
								<Col xs={24} lg={12} md={12} xl={6}>
									<XPara1>
										MRN:&nbsp;
										{patientData.mrn}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={8}>
									<XPara1>
										Name: {patientData.patient_name}
									</XPara1>
								</Col>
								{/* //todo age is redundant and commented after amin told */}
								{/* <Col xs={24} lg={12} md={12} xl={5}>
									<XPara1>
										Age:{' '}
										{moment(
											patientData.date_of_birth,
										).fromNow(true)}
										&nbsp;
										{matchDateOfBirth ? (
											<> </>
										) : (
											<Tooltip
												title="The age of the patient does not align with the age provided in the Clinical Summary."
												trigger={'hover'}
											>
												<WarningOutlined
													style={{ color: 'red' }}
												/>
											</Tooltip>
										)}
									</XPara1>
								</Col> */}
								<Col xs={24} lg={12} md={12} xl={5}>
									<XPara1>
										Gender:{' '}
										{patientData.gender
											? toTitleCase(patientData.gender)
											: ''}
										&nbsp;
										{matchGender ? (
											<> </>
										) : (
											<Tooltip
												title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
												trigger={'hover'}
											>
												<WarningOutlined
													style={{ color: 'red' }}
												/>
											</Tooltip>
										)}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={6}>
									<XPara1>
										DOB:{' '}
										{patientData.date_of_birth
											? dateOfBirth(
													patientData.date_of_birth,
											  )
											: ''}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={8}>
									<XPara1>
										Revenue Cycle:{' '}
										{patientData.revenue_cycle ===
											'pre_service' && 'Pre Service'}
										{patientData.revenue_cycle ===
											'post_claim' && 'Post Claim'}
										{patientData.revenue_cycle ===
											'part_b_drugs' && 'Part B Drugs'}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={10}>
									<XPara1>State: {patientData.state}</XPara1>
								</Col>
								<Col xs={24} lg={12} md={24} xl={24}>
									<XPara1>
										Insurance Company:{' '}
										{patientData.insurance_company?.name ??
											'-'}
									</XPara1>
								</Col>
							</Row>
							{patientData.status === 'letter_sent' ? (
								<Row className="mt-2">
									<Col xl={18} lg={18}>
										<XTag className={patientData.status}>
											{toTitleCase(patientData.status)}
										</XTag>
									</Col>
								</Row>
							) : (
								''
							)}
						</XCardBackBg>

						<div
							id="getAns"
							className="customScroll slideUp top-100 mt-4"
						>
							<XCardsGrdBG>
								<Row className="align-items-center">
									<Col xs={20} lg={22}>
										{patientData.clinical_docs &&
										patientData.clinical_docs.length > 0 ? (
											<>
												<Form.Item
													labelCol={{ span: 24 }}
													label="Clinical Summary"
													className="labelStyle"
												>
													{patientData.clinical_docs
														.length > 0 &&
														patientData.clinical_docs.map(
															(ele) => {
																return (
																	<>
																		{ele.url.name
																			.split(
																				'.',
																			)
																			.includes(
																				'pdf',
																			) ? (
																			<>
																				<div className="image-container_case_filing">
																					<Card
																						hoverable
																						style={{
																							width: 75,
																							height: 70,
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																						}}
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						id="timeline-documents-hover"
																					>
																						<div
																							style={{
																								display:
																									'flex',
																								justifyContent:
																									'center',
																								alignItems:
																									'center',
																								flexDirection:
																									'column',
																								textAlign:
																									'center',
																							}}
																						>
																							<FilePdfOutlined
																								style={{
																									fontSize:
																										'30px',
																									border: 'none',
																									background:
																										'transparent',
																								}}
																							/>
																						</div>
																					</Card>
																				</div>
																			</>
																		) : (
																			<>
																				<div className="image-container_case_filing">
																					<Image
																						width={
																							75
																						}
																						height={
																							70
																						}
																						style={{
																							borderRadius:
																								'10px',
																						}}
																						className="supporting-docs-hover"
																						src={`${config.s3_url}${ele.url.url}`}
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																					/>
																					<div
																						className="overlay_case_filing"
																						style={{
																							height: '75%',
																						}}
																					>
																						<EyeOutlined
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																							className="icon eye-icon"
																						/>
																					</div>
																				</div>
																			</>
																		)}
																	</>
																);
															},
														)}
												</Form.Item>
											</>
										) : (
											<XPara1>
												{patientData.clinical_summary}
											</XPara1>
										)}
									</Col>
								</Row>
							</XCardsGrdBG>

							{/* Extracted Supporting documents */}
							{patientData && patientData.summary_docs ? (
								<XCardsCaseBg className="mt-4">
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										<Col xs={20} lg={20}>
											<Space align="center">
												<img
													className="iconResp"
													src="/images/extracted-summary.svg"
													alt=""
												/>
												<XTitleHead>
													<span className="color6 d-none d-sm-block">
														Summary From Uploaded
														Documents
													</span>
													<span className="color6 d-block d-sm-none text-start">
														Summary From Uploaded{' '}
														<br />
														Documents
													</span>
												</XTitleHead>
											</Space>
										</Col>
									</Row>
									{patientData && patientData.summary_docs ? (
										<Row className="align-items-center">
											<Col xs={20} lg={22}>
												<XPara1>
													{patientData.summary_docs}
												</XPara1>
											</Col>
										</Row>
									) : (
										''
									)}
								</XCardsCaseBg>
							) : (
								''
							)}
							<XCardsCaseBg className="mt-4">
								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/past-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color1 d-none d-sm-block">
													Past Clinical History
												</span>

												<span className="color1 d-block d-sm-none text-start">
													Past Clinical <br /> History
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								{pageContent.pastHistory.length > 0
									? pageContent.pastHistory.map(
											(row, index) => {
												const splitPastHistory =
													row.split('- ICD-10 Code:');

												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitPastHistory.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitPastHistory[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		ICD-10:
																		{
																			splitPastHistory[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/present-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color2 d-none d-sm-block">
													Present Complaint
												</span>

												<span className="color2 d-block d-sm-none text-start">
													Present <br /> Complaint
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								{pageContent.presentComplaint.length > 0
									? pageContent.presentComplaint.map(
											(row, index) => {
												const splitPresentComplaint =
													row.split('- ICD-10 Code:');
												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitPresentComplaint.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitPresentComplaint[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		ICD-10:
																		{
																			splitPresentComplaint[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/procedure-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color3 d-none d-sm-block">
													Denial Procedure
												</span>

												<span className="color3 d-block d-sm-none text-start">
													Denial <br /> Procedure
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								{pageContent.recommendedProcedure.length > 0
									? pageContent.recommendedProcedure.map(
											(row, index) => {
												const splitRecommendedProcedure =
													row.split('- CPT Code:');
												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitRecommendedProcedure.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitRecommendedProcedure[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		CPT:
																		{
																			splitRecommendedProcedure[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={24}>
										<Space align="center">
											<img
												className="iconResp"
												src={
													pageContent
														.medicalCoverage[0] ===
													'Yes'
														? '/images/coverage-icon.svg'
														: '/images/cross-icon.svg'
												}
												alt=""
											/>
											<XTitleHead>
												<span
													className={
														pageContent
															.medicalCoverage[0] ===
														'Yes'
															? 'color4'
															: 'color5'
													}
												>
													Medicare Coverage
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								{pageContent.medicalCoverage.length > 0 ? (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24}>
											<XPara1>
												{pageContent.medicalCoverage[1]}
												{pageContent
													.medicalCoverage[2] &&
												Object.keys(
													pageContent
														.medicalCoverage[2],
												).length > 0 ? (
													<>
														<br />
														<br />
														<i>
															Reference:{' '}
															<a
																href={
																	pageContent
																		.medicalCoverage[2][
																		'target'
																	]
																}
																//useful for privacy and security reasons, as it prevents the destination website from knowing the exact URL of the page that referred the user
																rel="noreferrer"
																target="_blank"
															>
																{
																	pageContent
																		.medicalCoverage[2][
																		'display_text'
																	]
																}
															</a>
														</i>
													</>
												) : (
													''
												)}
											</XPara1>
										</Col>
									</Row>
								) : (
									'-'
								)}
							</XCardsCaseBg>
							{pageContent.missing_points_for_coverage &&
								pageContent.missing_points_for_coverage.length >
									0 && (
									<XCardsCriteriaBg className="mt-4">
										<div className="criteria_list">
											<Col xs={24}>
												<Space align="center">
													<img
														src={
															'/images/criteria.svg'
														}
														alt=""
													/>
													<XTitleHead>
														<span className="criteria_list_title">
															Missing Inclusion
															Criteria
														</span>
														&nbsp;
														<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
															<Tag
																style={{
																	border: '1px solid #9B68BF',
																	color: '#9B68BF',
																	fontSize:
																		'11px',
																}}
															>
																Alpha
															</Tag>
														</Tooltip>
													</XTitleHead>
												</Space>
											</Col>
										</div>

										<Row
											gutter={(15, 15)}
											className="mt-3 align-items-center criteria_sub_list"
										>
											<Col xs={24}>
												<ul className="listCircule">
													{pageContent.missing_points_for_coverage?.map(
														(row, index) => {
															return (
																<li>{row}</li>
															);
														},
													)}
												</ul>
											</Col>
										</Row>
									</XCardsCriteriaBg>
								)}
							<XCardsCaseBg className="mt-4">
								<label className="headerStyle mb-4">
									Upload supporting documents
								</label>
								<Row>
									<Col>
										<Space size={[15, 15]}>
											{documentsToDisplay.length > 0 &&
												documentsToDisplay.map(
													(ele) => {
														return (
															<>
																{ele.url.name
																	.split('.')
																	.includes(
																		'pdf',
																	) ? (
																	<>
																		<div className="image-container">
																			<Card
																				hoverable
																				style={{
																					width: 100,
																					height: 100,
																					display:
																						'flex',
																					justifyContent:
																						'center',
																					alignItems:
																						'center',
																					// margin: '20px',
																				}}
																				id="timeline-documents-hover"
																			>
																				<div
																					style={{
																						display:
																							'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						flexDirection:
																							'column',
																						textAlign:
																							'center',
																					}}
																				>
																					<FilePdfOutlined
																						style={{
																							fontSize:
																								'30px',
																							border: 'none',
																							background:
																								'transparent',
																						}}
																					/>
																					<span
																						style={{
																							fontSize:
																								'14px',
																							marginTop:
																								'5px',
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</span>
																				</div>
																			</Card>
																			<div className="overlay">
																				<EyeOutlined
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					className="icon eye-icon"
																				/>
																				<DeleteOutlined
																					onClick={() =>
																						showSupportingDocsDeleteConfirm(
																							ele.url,
																						)
																					}
																					className="icon download-icon"
																				/>
																			</div>
																		</div>
																	</>
																) : (
																	<div className="image-container">
																		<Image
																			width={
																				100
																			}
																			height={
																				100
																			}
																			style={{
																				borderRadius:
																					'10px',
																			}}
																			preview={
																				false
																			}
																			className="supporting-docs-hover"
																			src={`${config.s3_url}${ele.url.url}`}
																		/>
																		<div className="overlay">
																			<EyeOutlined
																				onClick={() =>
																					handlePreview(
																						ele.url,
																					)
																				}
																				className="icon eye-icon"
																			/>
																			<DeleteOutlined
																				onClick={() =>
																					showSupportingDocsDeleteConfirm(
																						ele.url,
																					)
																				}
																				className="icon download-icon"
																			/>
																		</div>
																	</div>
																)}
															</>
														);
													},
												)}
											{documentsToDisplay.length > 25
												? null
												: uploadButton}
										</Space>
									</Col>
								</Row>
								{/* delete supporting docs modal */}
								<Modal
									title="Confirm Deletion"
									visible={isDeleteSupportingDocsModalVisible}
									onOk={handleRemoveFile}
									onCancel={() => {
										if (!isDeletingSupportingDocs) {
											handleCancelSupportingDocsDelete();
										}
									}}
									okButtonProps={{
										loading: isDeletingSupportingDocs,
									}}
									cancelText="No"
								>
									Are you sure you want to delete this file?
								</Modal>
								{/* Upload supporting docs modal */}
								<Modal
									title="Upload Supporting Documents"
									open={isUploadModalOpen}
									onOk={handleSubmit}
									okButtonProps={{
										loading: supportingDocsLoading,
									}}
									onCancel={() => {
										if (!supportingDocsLoading) {
											handleUploadCancel();
										}
									}}
								>
									<XForm
										form={form}
										layout="vertical"
										autoComplete="off"
									>
										<XForm.Item
											name="Supporting_docs"
											rules={[
												{
													required: true,
													validator: (_, value) => {
														if (
															fileList.length ===
															0
														) {
															return Promise.reject(
																new Error(
																	'Please Select file!',
																),
															);
														}
														return Promise.resolve();
													},
												},
											]}
										>
											<Dragger
												fileList={fileList}
												maxCount={1}
												beforeUpload={(file) => {
													const isJPEG =
														file.type ===
														'image/jpeg';
													const isJPG =
														file.type ===
														'image/jpg';
													const isPNG =
														file.type ===
														'image/png';
													const isGIF =
														file.type ===
														'image/gif';
													const isVideo =
														file.type.startsWith(
															'video/',
														);
													const isAudio =
														file.type.startsWith(
															'audio/',
														);

													const isLt10M =
														file.size /
															1024 /
															1024 <
														10;

													if (!isLt10M) {
														notification.error({
															message:
																'File Size Exceeded',
															description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
														});
													}
													if (
														isGIF ||
														isVideo ||
														isAudio ||
														isJPEG ||
														isJPG ||
														isPNG
													) {
														notification.error({
															message:
																'File Type Error',
															description: `GIF, video, image and audio files are not allowed.`,
														});
													}

													return (
														isLt10M &&
														!isGIF &&
														!isVideo &&
														!isAudio &&
														!isJPEG &&
														!isJPG &&
														!isPNG
													);
												}}
												// accept=".pdf,.jpeg,.jpg,.png"
												accept=".pdf"
												onChange={handleChange}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>
												<p className="ant-upload-text">
													Click or drag file to this
													area to upload
												</p>
											</Dragger>
										</XForm.Item>
										<XForm.Item
											name="document_name"
											label="Document Name"
											className="mt-3"
											rules={[
												{
													required: true,
													message:
														'Document Name is required',
												},
											]}
										>
											<Input
												placeholder="Enter Document Name"
												name="document_name"
												onChange={(e) => {
													setDocumentName(
														e.target.value,
													);
												}}
											/>
										</XForm.Item>
									</XForm>
								</Modal>
								<Modal
									open={previewOpen}
									title={previewTitle}
									footer={null}
									onCancel={handleCancel}
								>
									{previewImage ? (
										<img
											alt="example"
											style={{
												width: '100%',
											}}
											src={previewImage}
										/>
									) : null}
								</Modal>
								{/* Modal for preview */}
								<Modal
									open={pdfPreviewOpen}
									title="Preview"
									footer={null}
									onCancel={() => setPdfPreviewOpen(false)}
									width={pdfWidth} // Set the width of the modal dynamically
									// Other modal props...
								>
									<div>
										{/* <Document
											file={previewImage}
											onLoadSuccess={
												onDocumentLoadSuccess
											}
											onLoadError={onDocumentLoadError}
										>
											<Page
												pageNumber={pageNumber}
												onLoadSuccess={(page) =>
													updateModalWidth(page.width)
												}
											/>
										</Document>
										<p>
											Page {pageNumber} of {numPages}
										</p> */}
										<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
											<div style={{ height: '750px' }}>
												<Viewer
													fileUrl={previewImage}
												/>
											</div>
										</Worker>
									</div>
								</Modal>
							</XCardsCaseBg>
							{/* References Div */}
							<Row className="my-4" gutter={[30, 30]}>
								<Col xs={24}>
									<Card
										style={{
											borderRadius: '20px',
											border: '1px solid rgba(218, 222, 227, 0.50)',
											background: '#FFF',
										}}
									>
										<Form
											form={AddReferencesform}
											onFinish={(values) => {
												saveReferences({
													id,
													...values,
												});
											}}
										>
											<Form.List name="references">
												{(fields, { add, remove }) => (
													<>
														<Row>
															<Col xs={20}>
																<h5 className="headerStyle mb-4">
																	References
																</h5>
															</Col>
															<Col
																xs={{
																	offset: 1,
																	span: 2,
																}}
															>
																<XButtonAdd
																	shape="circle"
																	style={{
																		float: 'right',
																	}}
																	icon={
																		<PlusOutlined />
																	}
																	onClick={() => {
																		setShowSaveReferencesButton(
																			true,
																		);
																		add();
																	}}
																></XButtonAdd>
															</Col>
														</Row>
														{fields.map((field) => (
															<Row>
																<Col
																	xs={18}
																	md={21}
																	lg={19}
																>
																	<Form.Item
																		name={[
																			field.name,
																		]}
																		rules={[
																			{
																				required: true,
																				message:
																					'Enter references',
																			},
																		]}
																	>
																		<Input
																			onChange={() =>
																				setShowSaveReferencesButton(
																					true,
																				)
																			}
																		></Input>
																	</Form.Item>
																</Col>
																<Col
																	xs={{
																		offset: 1,
																		span: 4,
																	}}
																	md={{
																		offset: 1,
																		span: 2,
																	}}
																	lg={{
																		offset: 1,
																		span: 3,
																	}}
																>
																	<Button
																		onClick={() => {
																			setShowSaveReferencesButton(
																				true,
																			);
																			remove(
																				field.name,
																			);
																			if (
																				field.name ==
																				0
																			) {
																				saveReferences(
																					{
																						id,
																						references:
																							[],
																					},
																				);
																			}
																		}}
																	>
																		<DeleteOutlined />
																	</Button>
																</Col>
															</Row>
														))}
													</>
												)}
											</Form.List>
											{showSaveReferencesButton && (
												<XButtonConfirm
													type="primary"
													shape="round"
													icon={
														<img
															src="/images/check 1.svg"
															alt=""
															height={15}
														/>
													}
													style={{
														background: '#000',
														color: '#fff',
														float: 'right',
													}}
													className="confirmCoverage"
													htmlType="submit"
												>
													Save
												</XButtonConfirm>
											)}
										</Form>
									</Card>
								</Col>
							</Row>
							<Row className="my-4" gutter={[30, 30]}>
								{/* Pre service */}
								{(patientData.revenue_cycle == null ||
									patientData.revenue_cycle ===
										'pre_service' ||
									(patientData.revenue_cycle ===
										'part_b_drugs' &&
										new Date(patientData.treatment_date) >
											new Date())) && (
									<Col xs={24}>
										<Card
											style={{
												borderRadius: '20px',
												border: '1px solid rgba(218, 222, 227, 0.50)',
												background: '#FFF',
											}}
										>
											<Row>
												<Col xs={24}>
													<Space>
														<img
															src="/images/document-signed-1.svg"
															style={{
																fontSize:
																	'20px',
															}}
															alt="document-signed"
														/>
														<p
															style={{
																marginBottom: 0,
																width: 'max-content',
																textAlign:
																	'start',
															}}
														>
															Pre Service Appeal
															Letter
														</p>
													</Space>
													<Divider
														style={{
															margin: '10px 0px',
														}}
													/>
												</Col>
												{/* Reuse DenialLetterAction Component */}
												<DenialLetterAction
													patientData={patientData}
													setDenialLetterActionModal={
														setDenialLetterActionModal
													}
												/>
											</Row>
											<Space className="flex-wrap">
												<Space>
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'view-letter',
													) ? (
														<Tooltip title="Appeal Letter Preview">
															<XButtonRounded
																onClick={() => {
																	setLoading(
																		true,
																	);
																	previewAppealLetter(
																		'pre',
																	);
																}}
																type="primary"
																shape="round"
																size="large"
																disabled={
																	disableAppealLetterActionButton
																}
															>
																<EyeOutlined
																	style={{
																		fontSize:
																			'20px',
																	}}
																></EyeOutlined>
															</XButtonRounded>
														</Tooltip>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'edit-letter',
													) ? (
														<Tooltip title="Edit Appeal Letter">
															<XButtonRounded
																type="primary"
																shape="round"
																size="large"
																onClick={() => {
																	setLoading(
																		true,
																	);
																	editAppealLetter(
																		'pre',
																	);
																}}
																disabled={
																	disableAppealLetterActionButton
																}
															>
																<EditOutlined
																	style={{
																		fontSize:
																			'20px',
																	}}
																/>
															</XButtonRounded>
														</Tooltip>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'share-letter',
													) ? (
														<>
															<Tooltip title="Send Appeal Letter">
																<XButtonRounded
																	disabled={
																		patientData.status !==
																			'letter_prepared' ||
																		patientData.appeal_letter_approved ==
																			false ||
																		patientData.appeal_status ===
																			'sent' ||
																		disableAppealLetterActionButton
																	}
																	type="primary"
																	shape="round"
																	size="large"
																	onClick={() => {
																		showShareModal(
																			true,
																			id,
																			'pre',
																		);
																	}}
																>
																	<ShareAltOutlined
																		style={{
																			fontSize:
																				'20px',
																		}}
																	/>
																</XButtonRounded>
															</Tooltip>
														</>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'edit-letter',
													) ? (
														<Popconfirm
															title="Re-Generate Appeal Letter"
															description="Are you sure to Re-Generate this Appeal Letter?"
															onConfirm={() => {
																setLoading(
																	true,
																);
																previewAppealLetter(
																	'pre',
																	true,
																);
															}}
															onCancel={() => {
																return;
															}}
															okText="Yes"
															cancelText="No"
															okButtonProps={{
																style: {
																	background: `black`,
																},
															}}
														>
															<Tooltip title="Re-Generate Appeal Letter">
																<XButtonRounded
																	type="primary"
																	shape="round"
																	size="large"
																	// onClick={() => {
																	// 	setLoading(
																	// 		true,
																	// 	);
																	// 	previewAppealLetter(
																	// 		'pre',
																	// 		true,
																	// 	);
																	// }}
																	disabled={
																		disableAppealLetterActionButton
																	}
																>
																	<FileSyncOutlined
																		style={{
																			fontSize:
																				'20px',
																		}}
																	/>
																</XButtonRounded>
															</Tooltip>
														</Popconfirm>
													) : (
														<></>
													)}
												</Space>
											</Space>
										</Card>
									</Col>
								)}
								{/* Post service */}
								{(patientData.revenue_cycle == null ||
									patientData.revenue_cycle ===
										'post_claim' ||
									(patientData.revenue_cycle ===
										'part_b_drugs' &&
										new Date(patientData.treatment_date) <=
											new Date())) && (
									<Col xs={24}>
										<Card
											style={{
												borderRadius: '20px',
												border: '1px solid rgba(218, 222, 227, 0.50)',
												background: '#FFF',
											}}
										>
											<Row>
												<Col xs={24}>
													<Space>
														<img
															src="/images/document-signed-1.svg"
															style={{
																fontSize:
																	'20px',
															}}
															alt="document-signed"
														/>
														<p
															style={{
																marginBottom: 0,
																width: 'max-content',
															}}
														>
															Post Service Appeal
															Letter
														</p>
													</Space>
													<Divider
														style={{
															margin: '10px 0px',
														}}
													/>
												</Col>
												{/* Reuse DenialLetterAction Component */}
												<DenialLetterAction
													patientData={patientData}
													setDenialLetterActionModal={
														setDenialLetterActionModal
													}
												/>
											</Row>
											<Space className="flex-wrap">
												<Space>
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'view-letter',
													) ? (
														<Tooltip title="Appeal Letter Preview">
															<XButtonRounded
																onClick={() => {
																	setLoading(
																		true,
																	);
																	previewAppealLetter(
																		'post',
																	);
																}}
																type="primary"
																shape="round"
																size="large"
																disabled={
																	disableAppealLetterActionButton ||
																	new Date(
																		patientData.treatment_date,
																	) >
																		new Date()
																}
															>
																<EyeOutlined
																	style={{
																		fontSize:
																			'20px',
																	}}
																></EyeOutlined>
															</XButtonRounded>
														</Tooltip>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'edit-letter',
													) ? (
														<Tooltip title="Edit Appeal Letter">
															<XButtonRounded
																type="primary"
																shape="round"
																size="large"
																onClick={() => {
																	setLoading(
																		true,
																	);
																	editAppealLetter(
																		'post',
																	);
																}}
																disabled={
																	disableAppealLetterActionButton ||
																	new Date(
																		patientData.treatment_date,
																	) >
																		new Date()
																}
															>
																<EditOutlined
																	style={{
																		fontSize:
																			'20px',
																	}}
																/>
															</XButtonRounded>
														</Tooltip>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'share-letter',
													) ? (
														<Tooltip title="Send Appeal Letter">
															<XButtonRounded
																type="primary"
																shape="round"
																size="large"
																onClick={() => {
																	showShareModal(
																		true,
																		id,
																		'post',
																	);
																}}
																disabled={
																	disableAppealLetterActionButton ||
																	new Date(
																		patientData.treatment_date,
																	) >
																		new Date() ||
																	(patientData.status !==
																		'letter_prepared' &&
																		patientData.appeal_letter_approved ==
																			false) ||
																	patientData.appeal_status ===
																		'sent'
																}
															>
																<ShareAltOutlined
																	style={{
																		fontSize:
																			'20px',
																	}}
																/>
															</XButtonRounded>
														</Tooltip>
													) : (
														<></>
													)}
													{checkModulePermissions(
														'appeal-letter',
													).sub_modules.includes(
														'edit-letter',
													) ? (
														<Popconfirm
															title="Re-Generate Appeal Letter"
															description="Are you sure to Re-Generate this Appeal Letter?"
															onConfirm={() => {
																setLoading(
																	true,
																);
																previewAppealLetter(
																	'post',
																	true,
																);
															}}
															onCancel={() => {
																return;
															}}
															okText="Yes"
															cancelText="No"
															okButtonProps={{
																style: {
																	background: `black`,
																},
															}}
														>
															<Tooltip title="Re-Generate Appeal Letter">
																<XButtonRounded
																	type="primary"
																	shape="round"
																	size="large"
																	disabled={
																		disableAppealLetterActionButton ||
																		new Date(
																			patientData.treatment_date,
																		) >
																			new Date()
																	}
																	// onClick={() => {
																	// 	setLoading(
																	// 		true,
																	// 	);
																	// 	previewAppealLetter(
																	// 		'post',
																	// 		true,
																	// 	);
																	// }}
																>
																	<FileSyncOutlined
																		style={{
																			fontSize:
																				'20px',
																		}}
																	/>
																</XButtonRounded>
															</Tooltip>
														</Popconfirm>
													) : (
														<></>
													)}
												</Space>
											</Space>
										</Card>
									</Col>
								)}
							</Row>
						</div>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>

			<Modal
				title="Appeal Letter Preview"
				open={showAppealLetterPreview}
				// okText="Confirm Letter"
				// onOk={
				// 	documentsToDisplay.length > 0
				// 		? confirmAppealLetter
				// 		: showConfirmAppealLetterNoitifcation
				// }
				okText={getAppealLetterButtonText()}
				onOk={getAppealLetterButtonFunction()}
				okButtonProps={{
					loading: appealLetterLoading,
					style: {
						background: ` ${confirmButtonBackground}`,
					},
					disabled: confirmButtonDisabled,
				}}
				onCancel={closePreviewAppealLetter}
				width={900}
			>
				{checkModulePermissions('appeal-letter').sub_modules.includes(
					'edit-letter',
				) ? (
					<XButtonLike
						type="primary"
						style={{
							float: 'right',
						}}
						onClick={editAppealLetterFromModal}
					>
						<EditOutlined
							style={{
								fontSize: '20px',
							}}
						/>
					</XButtonLike>
				) : (
					<></>
				)}
				<div dangerouslySetInnerHTML={{ __html: appealLetterHeader }} />
				<XDivider />
				<div
					dangerouslySetInnerHTML={{ __html: appealLetterContent }}
				/>
				<XDivider />
				<div dangerouslySetInnerHTML={{ __html: appealLetterFooter }} />
				<br />
			</Modal>
			<Modal
				title="Edit Appeal Letter"
				open={showEditAppealLetter}
				okText="Save and Preview"
				onOk={saveAndPreviewAppealLetter}
				width={900}
				okButtonProps={{
					style: {
						background: 'black',
					},
				}}
				onCancel={closeEditAppealLetter}
			>
				<Editor
					apiKey={config.tiny_url_key}
					onInit={(evt, editor) => (editorRef.current = editor)}
					init={{
						menubar: false,
						plugins: ['code', 'image', 'link', 'visualblocks'],
						branding: false,
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
							'bullist numlist outdent indent | image link |' +
							'removeformat | visualblocks code | help ',
						content_style:
							'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						font_size_formats: '8px 10px 12px 14px 18px 24px 36px',
					}}
					initialValue={appealLetterContent}
				/>
			</Modal>
			<Modal
				title="Send To"
				open={isShareModalOpen[0]}
				onOk={handleShareOk}
				onCancel={handleShareCancel}
				cancelButtonProps={{ hidden: true }}
				okButtonProps={{ hidden: true }}
				width={'455px'}
			>
				<Row gutter={[15, 30]}>
					<Col>
						{displayEmailContent ? (
							<Card
								className="text-center shareModalActive"
								onClick={() => {
									setDisplayFaxUpload(false);
									if (!letterDownloaded) {
										generateAppealLetter({
											id: isShareModalOpen[1],
											type: isShareModalOpen[2],
										});
										setDownloadLoading(true);
										setDisplayEmailContent(true);
									}
								}}
							>
								<img
									src="/images/envelope 1 copy.svg"
									style={{ marginBottom: '8px' }}
									alt="envelope"
								/>
								<p className="m-0">Send Via Email</p>
							</Card>
						) : (
							<Card
								className="text-center shareModal"
								onClick={() => {
									setDisplayFaxUpload(false);
									if (!letterDownloaded)
										generateAppealLetter({
											id: isShareModalOpen[1],
											type: isShareModalOpen[2],
										});
									setDownloadLoading(true);
									setDisplayEmailContent(true);
								}}
							>
								<img
									src="/images/envelope 1.svg"
									style={{ marginBottom: '8px' }}
									alt="emvelope"
								/>
								<p className="m-0">Send Via Email</p>
							</Card>
						)}
					</Col>
					<Col>
						{displayFaxUpload ? (
							<Card
								className="text-center shareModalActive"
								onClick={() => {
									setDisplayEmailContent(false);
									setDisplayFaxUpload(true);
									setLetterDownloaded(false);
								}}
							>
								<img
									src="/images/fax-active.svg"
									style={{
										marginBottom: '8px',
										color: 'red',
									}}
									alt="fax"
								/>
								<p className="m-0">Share Via Fax</p>
							</Card>
						) : (
							<Card
								className="text-center shareModal"
								onClick={() => {
									setDisplayFaxUpload(true);
									setDisplayEmailContent(false);
									setLetterDownloaded(false);
								}}
							>
								<img
									src="/images/fax 1.svg"
									style={{
										marginBottom: '8px',
									}}
									alt="fax"
								/>
								<p className="m-0">Share Via Fax</p>
							</Card>
						)}
					</Col>
					<Col>
						<Card
							className="text-center shareModal"
							onClick={() => {
								setDisplayFaxUpload(false);
								setDisplayEmailContent(false);
								generateAppealLetter({
									id: isShareModalOpen[1],
									type: isShareModalOpen[2],
								});
								setDownloadLoading(true);
							}}
						>
							<img
								src="/images/download 1.svg"
								style={{ marginBottom: '8px' }}
								alt="download"
							/>
							<p className="m-0">Download PDF</p>
						</Card>
					</Col>
				</Row>
				{displayFaxUpload ? (
					<div className="mt-3">
						<Dragger showUploadList={false}>
							<img
								src="/images/file-upload 1.svg"
								style={{ marginBottom: '8px' }}
								alt="file upload"
							/>
							<p
								style={{
									color: '#ACACAC',
									fontFamily: 'Poppins',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: '500',
									lineHeight: '20px',
									letterSpacing: '0.28px',
								}}
							>
								{previewTitle}
							</p>
						</Dragger>
						<XForm.Item className="text-right mt-3">
							<XShareButton
								style={{
									background: '#212226',
								}}
								onClick={() => {
									setSuccessModal(true);
									//!Remove this state on funtion call
									showShareModal(false, '', '');
									setLetterDownloaded(false);
									setDisplayEmailContent(false);
									setDisplayFaxUpload(false);
									setIsShareModalOpen(false);
									editCase({
										id: id,
										appeal_status: 'sent',
										appeal_status_updated_at: moment(),
									});
								}}
							>
								Send
							</XShareButton>
						</XForm.Item>
					</div>
				) : (
					''
				)}
				{displayEmailContent && letterDownloaded ? (
					<div className="mt-3">
						<p>
							The letter has been downloaded. Please ensure that
							you do not overlook attaching the document in the
							email you are about to send.
						</p>
						<XForm.Item className="text-right mt-3">
							<XShareButton
								style={{
									background: '#212226',
								}}
								onClick={() => {
									handleEmailSend();
									showShareModal(false, '', '');
								}}
							>
								Send Email
							</XShareButton>
						</XForm.Item>
					</div>
				) : (
					''
				)}
			</Modal>
			<XModal
				centered
				open={successModal}
				onOk={() => setSuccessModal(false)}
				onCancel={() => {
					setSuccessModal(false);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
				width={400}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col
						xs={24}
						lg={{ span: 18, offset: 3 }}
						className="text-center"
					>
						<img
							src="/images/fax-success.svg"
							style={{ marginBottom: '8px' }}
							alt="fax"
						/>

						<XPara1 className="mt-5 mb-4">
							The letter has been successfully sent via fax.
						</XPara1>
					</Col>
				</Row>
			</XModal>
			<XModal
				title="Confirm Letter"
				open={isConfirmAppealLetterNoitifcationOpen}
				onOk={handleConfirmAppealLetterNoitifcationOk}
				onCancel={handleConfirmAppealLetterNoitifcationCancel}
			>
				<p>
					Are you sure you want to confirm letter without Uploading
					supporting documents?
				</p>
			</XModal>

			{/* denial letter Action */}
			<XModal
				centered
				title="Denial Letter Action"
				open={denialLetterActionModal.open}
				onOk={() => {
					console.log('successs');
				}}
				onCancel={() => {
					if (!denialLetterActionLoading) {
						setDenialLetterActionModal({
							key: 0,
							data: {},
							open: false,
						});
					}
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
				width={400}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24}>
						<XPara1>{denialLetterActionModal.data.reason}</XPara1>
					</Col>
					{!denialLetterActionModal.data.marked_as_done && (
						<>
							<Col xs={24}>
								<XForm
									form={form}
									layout="vertical"
									autoComplete="off"
									onFinish={handleSubmit}
								>
									<XForm.Item
										name="denial_action_docs"
										rules={[
											{
												required: true,
												validator: (_, value) => {
													if (fileList.length === 0) {
														return Promise.reject(
															new Error(
																'Please Select file!',
															),
														);
													}
													return Promise.resolve();
												},
											},
										]}
									>
										<Dragger
											fileList={fileList}
											maxCount={1}
											beforeUpload={(file) => {
												const isJPEG =
													file.type === 'image/jpeg';
												const isJPG =
													file.type === 'image/jpg';
												const isPNG =
													file.type === 'image/png';
												const isGIF =
													file.type === 'image/gif';
												const isVideo =
													file.type.startsWith(
														'video/',
													);
												const isAudio =
													file.type.startsWith(
														'audio/',
													);

												const isLt10M =
													file.size / 1024 / 1024 <
													10;

												if (!isLt10M) {
													notification.error({
														message:
															'File Size Exceeded',
														description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
													});
												}
												if (
													isGIF ||
													isVideo ||
													isAudio ||
													isJPEG ||
													isJPG ||
													isPNG
												) {
													notification.error({
														message:
															'File Type Error',
														description: `GIF, video, image and audio files are not allowed.`,
													});
												}

												return (
													isLt10M &&
													!isGIF &&
													!isVideo &&
													!isAudio &&
													!isJPEG &&
													!isJPG &&
													!isPNG
												);
											}}
											accept=".pdf"
											onChange={(e) => {
												if (
													!denialLetterActionLoading
												) {
													handleChange(e);
												}
											}}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Click or drag file to this area
												to upload
											</p>
										</Dragger>
									</XForm.Item>
									<XForm.Item
										name="document_name"
										label="Document Name"
										className="mt-3"
										rules={[
											{
												required: true,
												message:
													'Document Name is required',
											},
										]}
									>
										<Input
											placeholder="Enter Document Name"
											name="document_name"
											onChange={(e) => {
												setDocumentName(e.target.value);
											}}
										/>
									</XForm.Item>
									<Row>
										<Col xs={{ span: 4, offset: 5 }}>
											<XButtonConfirm
												type="primary"
												shape="round"
												htmlType="submit"
												icon={
													<img
														src="/images/check 1.svg"
														alt=""
														height={15}
													/>
												}
												style={{
													background: '#000',
													color: '#fff',
													float: 'center',
												}}
												className="confirmCoverage"
												loading={
													denialLetterActionLoading
												}
											>
												Upload Document
											</XButtonConfirm>
										</Col>
									</Row>
								</XForm>
							</Col>
							<Col xs={24}>
								<XPara1 className="mt-1 text-center">OR</XPara1>
							</Col>
						</>
					)}
					<Col xs={24}>
						{denialLetterActionModal.data.marked_as_done ? (
							<Row>
								<Col xs={{ span: 4, offset: 5 }}>
									<Popconfirm
										className="m-3"
										title="Mark as Undone"
										description={
											<p>
												Are you sure to mark this action
												as done?
												<br />
												<Text type="danger">
													(Any supporting document
													uploaded will be deleted for
													this action)
												</Text>
											</p>
										}
										onConfirm={() =>
											processDenialLetterAction(
												false,
												true,
											)
										}
										onCancel={() => {
											return;
										}}
										okText="Yes"
										cancelText="No"
										okButtonProps={{
											style: {
												background: `black`,
											},
										}}
									>
										<XButtonConfirm
											type="primary"
											shape="round"
											icon={
												<img
													src="/images/check 1.svg"
													alt=""
													height={15}
												/>
											}
											style={{
												background: '#000',
												color: '#fff',
												alignSelf: 'center',
											}}
											className="confirmCoverage"
											loading={denialLetterActionLoading}
										>
											Mark as undone
										</XButtonConfirm>
									</Popconfirm>
								</Col>
							</Row>
						) : (
							// </Space>
							<Space direction="vertical" align="center">
								<Popconfirm
									title="Mark As Done"
									description="Are you sure to mark this action as done?"
									onConfirm={() =>
										processDenialLetterAction(true, true)
									}
									onCancel={() => {
										return;
									}}
									okText="Yes"
									cancelText="No"
									okButtonProps={{
										style: {
											background: `black`,
										},
									}}
								>
									<XButtonConfirm
										type="primary"
										shape="round"
										icon={
											<img
												src="/images/check 1.svg"
												alt=""
												height={15}
											/>
										}
										style={{
											background: '#000',
											color: '#fff',
										}}
										className="confirmCoverage"
										loading={denialLetterActionLoading}
									>
										Mark as Done
									</XButtonConfirm>
								</Popconfirm>
								<XPara1 className="mt-1 text-center">
									(If the action is already done/ document
									uploaded)
								</XPara1>
							</Space>
						)}
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
	getAppealLetterState: state.getAppealLetter,
	saveAppealLetterState: state.saveAppealLetter,
	editCaseState: state.editCase,
	generateAppealLetterState: state.generateAppealLetter,
	saveReferencesState: state.saveReferences,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	editCase: (params) => dispatch(editCaseApi(params)),
	editCaseReset: (params) => dispatch(editCaseReset(params)),
	saveAppealLetterReset: (params) => dispatch(saveAppealLetterReset(params)),
	generateAppealLetter: (params) => dispatch(generateAppealLetterApi(params)),
	generateAppealLetterReset: () => dispatch(generateAppealLetterReset()),
	saveReferences: (params) => dispatch(saveReferencesApi(params)),
	saveReferencesReset: () => dispatch(saveReferencesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAnalysisView);
