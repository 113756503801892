import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Radio, Input, Divider, Typography, notification } from 'antd';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XContainer,
	XButton,
	FilterButton,
	XSearch,
	XSelect,
} from '../../styles/GlobalStyle';

import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions
import {
	addPayerConfigurationApi,
	addPayerConfigurationReset,
} from '../../actions/addPayerConfigurationAction';

import {
	editPayerConfigurationApi,
	editPayerConfigurationReset,
} from '../../actions/editPayerConfigurationAction';

import { checkModulePermissions, truncateString } from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import { filterByLabel } from '../../utils/formFunctions';
import { FormDataContext } from './PayerConfigurationForm';
const { Text, Link } = Typography;

const Step4 = (props) => {
    
    const navigate = useNavigate();

    const {
        addPayerConfiguration,
        addPayerConfigurationState,
        addPayerConfigurationReset,
        editPayerConfiguration,
        editPayerConfigurationReset,
        editPayerConfigurationState,
    } = props;
    
	const { form, formDataState, currentState, editModeState } = useContext(FormDataContext);
    const [createConfigurations, setCreateConfigurations] = useState(false);

    useEffect(()=>{
        if(editPayerConfigurationState.apiState == "success")
        {
            notification.success({ message: editPayerConfigurationState.message });
			editPayerConfigurationReset();
        }
    },[editPayerConfigurationState])

    const handlePrev = () => {
        currentState.setCurrent((old)=>{
            return old-1;
        });
    }

	const handleSubmit = () => {
        
        form.validateFields(['part_b_drugs-rc-organization_determination-timeline',
        'part_b_drugs-rc-organization_determination-timeline_type',
        'part_b_drugs-rc-organization_determination-in_process',
        'part_b_drugs-rc-organization_determination-at_risk',
        'part_b_drugs-rc-organization_determination-escalation',
        'part_b_drugs-rc-organization_determination-untimely',
        'part_b_drugs-rc-first_appeal-timeline',
        'part_b_drugs-rc-first_appeal-timeline_type',
        'part_b_drugs-rc-first_appeal-in_process',
        'part_b_drugs-rc-first_appeal-at_risk',
        'part_b_drugs-rc-first_appeal-escalation',
        'part_b_drugs-rc-first_appeal-untimely',
        'part_b_drugs-rc-second_appeal-timeline',
        'part_b_drugs-rc-second_appeal-timeline_type',
        'part_b_drugs-rc-second_appeal-in_process',
        'part_b_drugs-rc-second_appeal-at_risk',
        'part_b_drugs-rc-second_appeal-escalation',
        'part_b_drugs-rc-second_appeal-untimely',
        'part_b_drugs-rc-third_appeal-timeline',
        'part_b_drugs-rc-third_appeal-timeline_type',
        'part_b_drugs-rc-third_appeal-in_process',
        'part_b_drugs-rc-third_appeal-at_risk',
        'part_b_drugs-rc-third_appeal-escalation',
        'part_b_drugs-rc-third_appeal-untimely',
        'part_b_drugs-rc-fourth_appeal-timeline',
        'part_b_drugs-rc-fourth_appeal-timeline_type',
        'part_b_drugs-rc-fourth_appeal-in_process',
        'part_b_drugs-rc-fourth_appeal-at_risk',
        'part_b_drugs-rc-fourth_appeal-escalation',
        'part_b_drugs-rc-fourth_appeal-untimely',
        'part_b_drugs-rc-judicial_review-timeline',
        'part_b_drugs-rc-judicial_review-timeline_type',
        'part_b_drugs-rc-judicial_review-in_process',
        'part_b_drugs-rc-judicial_review-at_risk',
        'part_b_drugs-rc-judicial_review-escalation',
        'part_b_drugs-rc-judicial_review-untimely',
        'part_b_drugs-ma_standard-organization_determination-timeline',
        'part_b_drugs-ma_standard-organization_determination-timeline_type',
        'part_b_drugs-ma_standard-organization_determination-in_process',
        'part_b_drugs-ma_standard-organization_determination-at_risk',
        'part_b_drugs-ma_standard-organization_determination-untimely',
        'part_b_drugs-ma_standard-first_appeal-timeline',
        'part_b_drugs-ma_standard-first_appeal-timeline_type',
        'part_b_drugs-ma_standard-first_appeal-in_process',
        'part_b_drugs-ma_standard-first_appeal-at_risk',
        'part_b_drugs-ma_standard-first_appeal-untimely',
        'part_b_drugs-ma_standard-second_appeal-timeline',
        'part_b_drugs-ma_standard-second_appeal-timeline_type',
        'part_b_drugs-ma_standard-second_appeal-in_process',
        'part_b_drugs-ma_standard-second_appeal-at_risk',
        'part_b_drugs-ma_standard-second_appeal-untimely',
        'part_b_drugs-ma_standard-third_appeal-timeline',
        'part_b_drugs-ma_standard-third_appeal-timeline_type',
        'part_b_drugs-ma_standard-third_appeal-in_process',
        'part_b_drugs-ma_standard-third_appeal-at_risk',
        'part_b_drugs-ma_standard-third_appeal-untimely',
        'part_b_drugs-ma_standard-fourth_appeal-timeline',
        'part_b_drugs-ma_standard-fourth_appeal-timeline_type',
        'part_b_drugs-ma_standard-fourth_appeal-in_process',
        'part_b_drugs-ma_standard-fourth_appeal-at_risk',
        'part_b_drugs-ma_standard-fourth_appeal-untimely',
        'part_b_drugs-ma_standard-judicial_review-timeline',
        'part_b_drugs-ma_standard-judicial_review-timeline_type',
        'part_b_drugs-ma_standard-judicial_review-in_process',
        'part_b_drugs-ma_standard-judicial_review-at_risk',
        'part_b_drugs-ma_standard-judicial_review-untimely'])
        .then((values)=>{
            formDataState.setFormData((old)=>{
                return {...old, ...values}
            });
            setCreateConfigurations(true);
        })
        .catch((error)=>{
            return;
        });
        
    }

    useEffect(()=>{
        if(createConfigurations)
        {
            addPayerConfiguration(formDataState.formData);
        }
    },[createConfigurations])


    useEffect(()=>{
        if(addPayerConfigurationState.apiState=="success")
        {
            addPayerConfigurationReset();
            setCreateConfigurations(false);
			navigate('/payer-configuration');
        }
    },[addPayerConfigurationState])

    const handleUpdate = () => {

        form.validateFields([
            'part_b_drugs-rc-organization_determination-timeline',
            'part_b_drugs-rc-organization_determination-timeline_type',
            'part_b_drugs-rc-organization_determination-in_process',
            'part_b_drugs-rc-organization_determination-at_risk',
            'part_b_drugs-rc-organization_determination-escalation',
            'part_b_drugs-rc-organization_determination-untimely',
            'part_b_drugs-rc-first_appeal-timeline',
            'part_b_drugs-rc-first_appeal-timeline_type',
            'part_b_drugs-rc-first_appeal-in_process',
            'part_b_drugs-rc-first_appeal-at_risk',
            'part_b_drugs-rc-first_appeal-escalation',
            'part_b_drugs-rc-first_appeal-untimely',
            'part_b_drugs-rc-second_appeal-timeline',
            'part_b_drugs-rc-second_appeal-timeline_type',
            'part_b_drugs-rc-second_appeal-in_process',
            'part_b_drugs-rc-second_appeal-at_risk',
            'part_b_drugs-rc-second_appeal-escalation',
            'part_b_drugs-rc-second_appeal-untimely',
            'part_b_drugs-rc-third_appeal-timeline',
            'part_b_drugs-rc-third_appeal-timeline_type',
            'part_b_drugs-rc-third_appeal-in_process',
            'part_b_drugs-rc-third_appeal-at_risk',
            'part_b_drugs-rc-third_appeal-escalation',
            'part_b_drugs-rc-third_appeal-untimely',
            'part_b_drugs-rc-fourth_appeal-timeline',
            'part_b_drugs-rc-fourth_appeal-timeline_type',
            'part_b_drugs-rc-fourth_appeal-in_process',
            'part_b_drugs-rc-fourth_appeal-at_risk',
            'part_b_drugs-rc-fourth_appeal-escalation',
            'part_b_drugs-rc-fourth_appeal-untimely',
            'part_b_drugs-rc-judicial_review-timeline',
            'part_b_drugs-rc-judicial_review-timeline_type',
            'part_b_drugs-rc-judicial_review-in_process',
            'part_b_drugs-rc-judicial_review-at_risk',
            'part_b_drugs-rc-judicial_review-escalation',
            'part_b_drugs-rc-judicial_review-untimely',
            'part_b_drugs-ma_standard-organization_determination-timeline',
            'part_b_drugs-ma_standard-organization_determination-timeline_type',
            'part_b_drugs-ma_standard-organization_determination-in_process',
            'part_b_drugs-ma_standard-organization_determination-at_risk',
            'part_b_drugs-ma_standard-organization_determination-untimely',
            'part_b_drugs-ma_standard-first_appeal-timeline',
            'part_b_drugs-ma_standard-first_appeal-timeline_type',
            'part_b_drugs-ma_standard-first_appeal-in_process',
            'part_b_drugs-ma_standard-first_appeal-at_risk',
            'part_b_drugs-ma_standard-first_appeal-untimely',
            'part_b_drugs-ma_standard-second_appeal-timeline',
            'part_b_drugs-ma_standard-second_appeal-timeline_type',
            'part_b_drugs-ma_standard-second_appeal-in_process',
            'part_b_drugs-ma_standard-second_appeal-at_risk',
            'part_b_drugs-ma_standard-second_appeal-untimely',
            'part_b_drugs-ma_standard-third_appeal-timeline',
            'part_b_drugs-ma_standard-third_appeal-timeline_type',
            'part_b_drugs-ma_standard-third_appeal-in_process',
            'part_b_drugs-ma_standard-third_appeal-at_risk',
            'part_b_drugs-ma_standard-third_appeal-untimely',
            'part_b_drugs-ma_standard-fourth_appeal-timeline',
            'part_b_drugs-ma_standard-fourth_appeal-timeline_type',
            'part_b_drugs-ma_standard-fourth_appeal-in_process',
            'part_b_drugs-ma_standard-fourth_appeal-at_risk',
            'part_b_drugs-ma_standard-fourth_appeal-untimely',
            'part_b_drugs-ma_standard-judicial_review-timeline',
            'part_b_drugs-ma_standard-judicial_review-timeline_type',
            'part_b_drugs-ma_standard-judicial_review-in_process',
            'part_b_drugs-ma_standard-judicial_review-at_risk',
            'part_b_drugs-ma_standard-judicial_review-untimely'
        ])
        .then((values)=>{

            formDataState.setFormData((old)=>{
                return {...old, ...values}
            })

            editPayerConfiguration({
                update_type: "part_b_drugs",
                id: formDataState.formData.id,
                ...values,
            });
        })
        .catch((error)=>{
            return;
        });
    }

    return (
        <>
            <XForm
                form={form}
                name="timelineConfigFrom"
                layout="vertical"
                autoComplete="off"
            >
                <Row gutter={(15, 30)} className="mt-4">
                    <Col xs={24}>
                        <Divider orientation="left">
                            <Text strong>RC</Text>
                        </Divider>
                    </Col>
                    <Col xs={24}>
                        <Text strong>Organization Determination</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={16}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-organization_determination-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-organization_determination-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-organization_determination-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-organization_determination-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-organization_determination-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-organization_determination-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>First Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-first_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-first_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-first_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-first_appeal-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-first_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-first_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Second Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-second_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-second_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-second_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-second_appeal-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-second_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-second_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Third Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-third_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-third_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-third_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-third_appeal-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-third_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-third_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Fourth Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-fourth_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-fourth_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-fourth_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-fourth_appeal-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-fourth_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-fourth_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Judicial Review</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-rc-judicial_review-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-rc-judicial_review-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-rc-judicial_review-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-escalation"
                                    label="Escalation"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Escalation start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Escalation Start"
                                        name="part_b_drugs-rc-judicial_review-escalation"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-rc-judicial_review-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-rc-judicial_review-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Col xs={24}>
                        <Divider orientation="left">
                            <Text strong>MA Standard</Text>
                        </Divider>
                    </Col>
                    <Col xs={24}>
                        <Text strong>Organization Determination</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={16}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-organization_determination-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-organization_determination-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-organization_determination-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-organization_determination-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-organization_determination-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-organization_determination-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-organization_determination-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-organization_determination-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-organization_determination-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>First Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-first_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-first_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-first_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-first_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-first_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-first_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-first_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-first_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-first_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Second Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-second_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-second_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-second_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-second_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-second_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-second_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-second_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-second_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-second_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Third Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-third_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-third_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-third_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-third_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-third_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-third_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-third_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-third_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-third_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Fourth Appeal</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-fourth_appeal-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-fourth_appeal-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-fourth_appeal-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-fourth_appeal-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-fourth_appeal-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-fourth_appeal-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-fourth_appeal-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-fourth_appeal-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-fourth_appeal-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={(15, 30)} className="mt-4">
                    <Divider />
                    <Col xs={24}>
                        <Text strong>Judicial Review</Text>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={(15,30)}>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-judicial_review-timeline"
                                    label="Timeline"
                                    rules={[{
                                        required: true,
                                        message: "Please enter timeline",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Timeline"
                                        name="part_b_drugs-ma_standard-judicial_review-timeline"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-judicial_review-timeline_type"
                                    label="Timeline Type"
                                    rules={[{
                                        required: true,
                                        message: "Please select timeline type",
                                    }]}
                                >
                                    
                                    <Radio.Group 
                                        size="large"  
                                        value={'days'}
                                    >
                                        <Radio value={'hours'}>Hours</Radio>
                                        <Radio value={'days'}>Days</Radio>
                                    </Radio.Group>
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-judicial_review-in_process"
                                    label="In Progress"
                                    rules={[{
                                        required: true,
                                        message: "Please enter In progress start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter In Progress Start"
                                        name="part_b_drugs-ma_standard-judicial_review-in_process"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-judicial_review-at_risk"
                                    label="At Risk"
                                    rules={[{
                                        required: true,
                                        message: "Please enter At Risk start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter At Risk Start"
                                        name="part_b_drugs-ma_standard-judicial_review-at_risk"
                                    />
                                </XForm.Item>
                            </Col>
                            <Col xs={24} md={8} lg={4}>
                                <XForm.Item
                                    name="part_b_drugs-ma_standard-judicial_review-untimely"
                                    label="Untimely"
                                    rules={[{
                                        required: true,
                                        message: "Please enter Untimely start",
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Enter Untimely Start"
                                        name="part_b_drugs-ma_standard-judicial_review-untimely"
                                    />
                                </XForm.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[15, 5]}>
                    {editModeState.editMode ?
                        <Col
                            xs={24}
                            className="text-end"
                        >
                            <Button onClick={handlePrev}>Prev</Button>
                            <Button onClick={handleUpdate}>Update</Button>
                        </Col>
                     :
                        <Col
                            xs={24}
                            className="text-end"
                        >
                            <Button onClick={handlePrev}>Prev</Button>
                            <Button onClick={handleSubmit}>Submit</Button>
                        </Col>
                    }
                </Row>
            </XForm>
        </>
    );
}

const mapStateToProps = (state) => ({
	addPayerConfigurationState: state.addPayerConfiguration,
    editPayerConfigurationState: state.editPayerConfiguration,
});

const mapDispatchToProps = (dispatch) => ({
	addPayerConfiguration: (data) => dispatch(addPayerConfigurationApi(data)),
	addPayerConfigurationReset: () => dispatch(addPayerConfigurationReset()),
    editPayerConfiguration: (data)=>dispatch(editPayerConfigurationApi(data)),
    editPayerConfigurationReset: () => dispatch(editPayerConfigurationReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Step4);