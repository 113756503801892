import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';
export const GET_ROLES_RESET = 'GET_ROLES_RESET';

export const getRoles = (params) => {
	return {
		type: GET_ROLES,
		params,
	};
};

export const getRolesSuccess = (response) => {
	return {
		type: GET_ROLES_SUCCESS,
		response,
	};
};

export const getRolesFail = (response) => {
	return {
		type: GET_ROLES_FAIL,
		response,
	};
};

export const getRolesReset = () => {
	return {
		type: GET_ROLES_RESET,
	};
};

export const getRolesApi = (params) => {
	return (dispatch) => {
		dispatch(getRoles());
		axios
			.get(`${config.api.base_url}/organization-role-mapping`, {
				params: params,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getRolesData = response.data;
				dispatch(getRolesSuccess(getRolesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getRolesFail(errorMsg));
			});
	};
};
