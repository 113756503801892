import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
	Image,
	Card,
	Modal,
	Tooltip,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsCaseBg,
	XCardsTransparent,
	XForm,
	XTag,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	FilePdfOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';

// actions
import {
	getUserDropdownApi,
	getUserDropdownReset,
} from '../../actions/getUserDropdownAction';
import {
	getCaseByIdApi,
	getCaseByIdReset,
} from '../../actions/getCaseByIdAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import { analyseCoverageApi } from '../../actions/analyseCoverageAction';
import { reviseCoverageApi } from '../../actions/reviseCoverageAction';
import { sendFeedbackApi } from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import {
	XDivider,
	XPara1,
	XTitleHead,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import Timeline from '../../components/HorizontalTimeline/Timeline';
import config from '../../config';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { revenueCycleOptions, revenueCycleStage } from '../../utils/constants';

const CaseForm = (props) => {
	const {
		getUserDropdown,
		getCaseById,
		getCaseByIdReset,
		getCaseByIdState,
		editCaseReset,
		editCaseState,
		getAnalysisState,
		getAnalysis,
		getAnalysisReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	const [formData, setFormData] = useState({
		status: 'success',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: null,
		successful_claim_commission_percentage_value: null,
		rejected_claim_commission_criteria: 'percentage',
		rejected_claim_commission_fixed_value: null,
		rejected_claim_commission_percentage_value: null,
		case_amount: null,
		claim_amount: null,
		status_logs: [],
	});

	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [pdfWidth, setPdfWidth] = useState(700);
	const [previewTitle, setPreviewTitle] = useState('');
	const [items, setItems] = useState({});
	const [mainDataLoading, setMainDataLoading] = useState(true);

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleColor = (key) => {
		let color;
		switch (key) {
			case 'pending':
				color = '#E78900';
				break;
			case 'analysed':
				color = '#7A670E';
				break;
			case 'rejected':
				color = '#cf1322';
				break;
			case 'successful':
				color = '#14804a';
				break;
			case 'allocated':
				color = '#7A0E27';
				break;
			default:
				break;
		}
		return color;
	};

	// use effects
	useEffect(() => {
		if (id) getCaseById({ id: id });
		getUserDropdown();
	}, [id]);

	useEffect(() => {
		if (id) getAnalysis({ id: id });
	}, [id]);

	useEffect(() => {
		if (getCaseByIdState.apiState === 'success') {
			setMainDataLoading(false);
			setFormData({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
				allocated_to: getCaseByIdState.data.allocated_to?.name
					? getCaseByIdState.data.allocated_to.name
					: '-',
				organization:
					getCaseByIdState.data?.organization_detail[0]?.name,
				hospital: getCaseByIdState.data?.hospital_detail[0]?.name,
				region: getCaseByIdState.data?.region_detail[0]?.name,
				insurance_company:
					getCaseByIdState.data?.insurance_company?.name,
			});

			const tempItem = getCaseByIdState.data.status_logs.map(
				(data, i) => {
					const previousStatus =
						i > 0
							? getCaseByIdState.data.status_logs[i - 1]
									.status_updated_to
							: null;

					return {
						color: handleColor(data.status_updated_to),
						children: (
							<>
								<p>
									{moment(data.updated_at).format(
										'MMM Do, YY [at] hh:mm A',
									)}{' '}
								</p>
								<p>
									{/* {i === 0
										? `${
												data.updated_by?.name
										  } changes status to ${toTitleCase(
												data.status_updated_to,
										  )}`
										: `${
												data.updated_by?.name
										  } changed status from ${toTitleCase(
												previousStatus,
										  )} to ${toTitleCase(
												data.status_updated_to,
										  )}`} */}
								</p>
							</>
						),
					};
				},
			);

			setItems(tempItem);
			getCaseByIdReset();
		}
		if (getCaseByIdState.apiState === 'error') {
			setMainDataLoading(false);
		}
		// else if (getCaseByIdState.apiState === 'error') {
		// 	notification.error({ message: getCaseByIdState.message });
		// 	getCaseByIdReset();
		// }
	}, [getCaseByIdState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			editCaseReset();
			navigate(-1);
		}
		// else if (editCaseState.apiState === 'error') {
		// 	notification.error({ message: editCaseState.message });
		// }
	}, [editCaseState]);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}

			<XContainer fluid>
				{checkModulePermissions('case-management').sub_modules.includes(
					'view-cases',
				) ? (
					<>
						<XCardBackBg className="mb-3">
							<Row>
								<Col xs={20}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>

									<XEditTitle>View Cases</XEditTitle>
								</Col>
								<Col
									xs={{ span: 1, offset: 1 }}
									md={{ span: 1, offset: 2 }}
									lg={{ span: 1, offset: 3 }}
									xl={{ span: 1, offset: 3 }}
								>
									<XButtonImg
										onClick={() =>
											navigate(`/case/edit/${id}`)
										}
										type="text"
									>
										<img src="/images/pencil.svg" alt="" />
									</XButtonImg>
								</Col>
							</Row>
						</XCardBackBg>

						<XCardsTransparent
							style={{ padding: '20px' }}
							loading={mainDataLoading}
						>
							<Row
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Col xl={18} lg={18}>
									<label className="headerStyle me-4">
										{formData.patient_first_name}{' '}
										{formData.patient_last_name}
									</label>

									<XTag className={formData.status}>
										{formData.status
											? toTitleCase(formData.status)
											: ''}
									</XTag>
									<br />
									<span className="mt-2">{formData.mrn}</span>
								</Col>
								<Col xl={6} lg={6}>
									<p
										style={{
											color: '#171C26',
											fontFamily: 'Poppins',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Treatment Date :&nbsp;
										<span style={{ fontWeight: 500 }}>
											{moment(
												formData.treatment_date,
											).format('MMMM Do, YYYY')}
										</span>
									</p>
								</Col>
							</Row>

							<Form layout="vertical" className="mt-5">
								<Row>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											label="Gender"
											className="labelStyle"
										>
											<span className="subLabelStyle">
												{formData.gender
													? toTitleCase(
															formData.gender,
													  )
													: ''}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Date Of Birth"
										>
											<span className="subLabelStyle">
												{moment(
													formData.date_of_birth,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Age"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.date_of_birth,
												).fromNow(true)}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Organization"
										>
											<span className="subLabelStyle">
												{formData.organization}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Hospital"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.hospital}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Region"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.region}
											</span>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											label="Visit Type"
											className="labelStyle"
										>
											<span className="subLabelStyle">
												{formData.visit_type}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="EOB Date"
										>
											<span className="subLabelStyle">
												{moment(
													formData.date_of_eob,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
									{/* //todo deadline date commented after amin told */}
									{/* <Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Deadline Date"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.deadline_date,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col> */}
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Admission Date"
										>
											<span className="subLabelStyle">
												{formData.date_of_admission
													? moment(
															formData.date_of_admission,
													  ).format('MMMM Do, YYYY')
													: '-'}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Discharge Date"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.date_of_discharge
													? moment(
															formData.date_of_discharge,
													  ).format('MMMM Do, YYYY')
													: '-'}
											</span>
										</Form.Item>
									</Col>

									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Hearing Date"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.hearing_date
													? moment(
															formData.hearing_date,
													  ).format('MMMM Do, YYYY')
													: '-'}
											</span>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="State"
										>
											<span className="subLabelStyle">
												{formData.state}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											label="Insurance Company"
											className="labelStyle"
										>
											<span className="subLabelStyle">
												{formData.insurance_company}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Allocated To"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.allocated_to
													? formData.allocated_to
													: ''}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Claim Amount"
										>
											<span className="subLabelStyle">
												{formData.claim_amount
													? '$'
													: ''}{' '}
												{formData.claim_amount
													? formData.claim_amount
													: '-'}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Case Amount"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.case_amount
													? '$'
													: ''}{' '}
												{formData.case_amount
													? formData.case_amount
													: '-'}
											</span>
										</Form.Item>
									</Col>

									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Process Type"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.process_type}
											</span>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											label="Revenue Cycle"
											className="labelStyle"
										>
											<span className="subLabelStyle">
												{formData.revenue_cycle &&
													revenueCycleOptions.map(
														(val, i) => {
															if (
																val.value ===
																formData.revenue_cycle
															) {
																return val.label;
															}
															return null;
														},
													)}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Revenue Cycle Stage"
										>
											<span className="subLabelStyle">
												{formData.revenue_cycle_stage &&
													revenueCycleStage.map(
														(val, i) => {
															if (
																val.value ===
																formData.revenue_cycle_stage
															) {
																return val.label;
															}
															return null;
														},
													)}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Created On"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.createdAt,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Last Date"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.updatedAt,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>

									<Col lg={4} xl={4} md={8} xs={12}>
										{/* <Form.Item
											className="labelStyle"
											label="State"
										>
											<span className="subLabelStyle">
												{formData.state}
											</span>
										</Form.Item> */}
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										{/* <Form.Item
											className="labelStyle"
											label="Allocated To"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.allocated_to
													? formData.allocated_to
													: ''}
											</span>
										</Form.Item> */}
									</Col>
								</Row>
								<Col span={24} className="pb-0">
									<Form.Item
										label="Clinical Summary"
										className="labelStyle"
									>
										<XCardBackBg
											className="mb-3"
											style={
												{
													// minHeight: '7rem',
													// width: 'fit-content',
												}
											}
										>
											{formData.clinical_docs &&
											formData.clinical_docs.length >
												0 ? (
												<>
													{formData.clinical_docs
														.length > 0 &&
														formData.clinical_docs.map(
															(ele) => {
																return (
																	<>
																		{ele.url.name
																			.split(
																				'.',
																			)
																			.includes(
																				'pdf',
																			) ? (
																			<>
																				<div className="image-container_case_filing">
																					<Card
																						hoverable
																						style={{
																							width: 75,
																							height: 70,
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																						}}
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						id="timeline-documents-hover"
																					>
																						<div
																							style={{
																								display:
																									'flex',
																								justifyContent:
																									'center',
																								alignItems:
																									'center',
																								flexDirection:
																									'column',
																								textAlign:
																									'center',
																							}}
																						>
																							<FilePdfOutlined
																								style={{
																									fontSize:
																										'30px',
																									border: 'none',
																									background:
																										'transparent',
																								}}
																							/>
																						</div>
																					</Card>
																				</div>
																			</>
																		) : (
																			<>
																				<div className="image-container_case_filing">
																					<Image
																						width={
																							75
																						}
																						height={
																							70
																						}
																						style={{
																							borderRadius:
																								'10px',
																						}}
																						className="supporting-docs-hover"
																						src={`${config.s3_url}${ele.url.url}`}
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																					/>
																					<div
																						className="overlay_case_filing"
																						style={{
																							height: '75%',
																						}}
																					>
																						<EyeOutlined
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																							className="icon eye-icon"
																						/>
																					</div>
																				</div>
																			</>
																		)}
																	</>
																);
															},
														)}
													{/* Modal for preview */}
													{/* <Modal
														open={previewOpen}
														title={previewTitle}
														footer={null}
														onCancel={handleCancel}
													>
														{previewImage ? (
															<img
																alt="example"
																style={{
																	width: '100%',
																}}
																src={
																	previewImage
																}
															/>
														) : null}
													</Modal>
													<Modal
														open={pdfPreviewOpen}
														title="Preview"
														footer={null}
														onCancel={() =>
															setPdfPreviewOpen(
																false,
															)
														}
														width={pdfWidth} // Set the width of the modal dynamically
													>
														<div>
															<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
																<div
																	style={{
																		height: '750px',
																	}}
																>
																	<Viewer
																		fileUrl={
																			previewImage
																		}
																	/>
																</div>
															</Worker>
														</div>
													</Modal> */}
												</>
											) : (
												<XPara1>
													{formData.clinical_summary}
												</XPara1>
											)}
										</XCardBackBg>
									</Form.Item>
								</Col>

								{/* supporting docs */}
								{formData.supporting_docs &&
									formData.supporting_docs.length > 0 && (
										<Col span={24} className="pb-0">
											<Form.Item
												label="Supporting Documents"
												className="labelStyle"
											>
												<XCardsCaseBg
													className="mb-3"
													style={
														{
															// minHeight: '7rem',
															// width: 'fit-content',
														}
													}
												>
													<Row>
														<Col>
															<Space
																size={[15, 15]}
																style={{
																	flexWrap:
																		'wrap',
																	alignItems:
																		'start',
																}}
															>
																{formData.supporting_docs &&
																	formData
																		.supporting_docs
																		.length >
																		0 &&
																	formData.supporting_docs.map(
																		(
																			ele,
																		) => {
																			return (
																				<>
																					{ele.url.name
																						.split(
																							'.',
																						)
																						.includes(
																							'pdf',
																						) ? (
																						<>
																							<div className="image-container_case_filing">
																								<Card
																									hoverable
																									style={{
																										width: 75,
																										height: 70,
																										display:
																											'flex',
																										justifyContent:
																											'center',
																										alignItems:
																											'center',
																										// margin: '20px',
																									}}
																									onClick={() =>
																										handlePreview(
																											ele.url,
																										)
																									}
																									id="timeline-documents-hover"
																								>
																									<div
																										style={{
																											display:
																												'flex',
																											justifyContent:
																												'center',
																											alignItems:
																												'center',
																											flexDirection:
																												'column',
																											textAlign:
																												'center',
																										}}
																									>
																										<FilePdfOutlined
																											style={{
																												fontSize:
																													'30px',
																												border: 'none',
																												background:
																													'transparent',
																											}}
																										/>
																									</div>
																								</Card>
																								<div
																									className="overlay_case_filing"
																									style={{
																										height: '75%',
																									}}
																								>
																									<EyeOutlined
																										onClick={() =>
																											handlePreview(
																												ele.url,
																											)
																										}
																										className="icon eye-icon"
																									/>
																								</div>
																								<Tooltip
																									title={
																										ele.document_name
																									}
																								>
																									<div
																										style={{
																											textAlign:
																												'center',
																											marginTop:
																												'5px',
																											fontSize:
																												'12px',
																											color: '#000',
																											whiteSpace:
																												'nowrap',
																											overflow:
																												'hidden',
																											textOverflow:
																												'ellipsis',
																											width: '75px', // or any other width as per requirement
																										}}
																									>
																										{
																											ele.document_name
																										}
																									</div>
																								</Tooltip>
																							</div>
																						</>
																					) : (
																						<>
																							<div className="image-container_case_filing">
																								<Image
																									width={
																										75
																									}
																									height={
																										70
																									}
																									style={{
																										borderRadius:
																											'10px',
																									}}
																									className="supporting-docs-hover"
																									src={`${config.s3_url}${ele.url.url}`}
																									onClick={() =>
																										handlePreview(
																											ele.url,
																										)
																									}
																								/>
																								<div
																									className="overlay_case_filing"
																									style={{
																										height: '75%',
																									}}
																								>
																									<EyeOutlined
																										onClick={() =>
																											handlePreview(
																												ele.url,
																											)
																										}
																										className="icon eye-icon"
																									/>
																								</div>
																								<Tooltip
																									title={
																										ele.document_name
																									}
																								>
																									<div
																										style={{
																											textAlign:
																												'center',
																											marginTop:
																												'5px',
																											fontSize:
																												'12px',
																											color: '#000',
																											whiteSpace:
																												'nowrap',
																											overflow:
																												'hidden',
																											textOverflow:
																												'ellipsis',
																											width: '75px', // or any other width as per requirement
																										}}
																									>
																										{
																											ele.document_name
																										}
																									</div>
																								</Tooltip>
																							</div>
																						</>
																					)}
																				</>
																			);
																		},
																	)}
															</Space>
														</Col>
													</Row>
												</XCardsCaseBg>
											</Form.Item>
										</Col>
									)}

								{/*  */}
							</Form>
						</XCardsTransparent>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
			{/* Modal for preview */}
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal>
			<Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
			>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div
							style={{
								height: '750px',
							}}
						>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUserDropdownState: state.getUserDropdown,
	getCaseByIdState: state.getCaseById,
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	getUserDropdown: () => dispatch(getUserDropdownApi()),
	getUserDropdownReset: () => dispatch(getUserDropdownReset()),
	getCaseById: (data) => dispatch(getCaseByIdApi(data)),
	getCaseByIdReset: () => dispatch(getCaseByIdReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
