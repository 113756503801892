import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_FEEDBACK = 'EDIT_FEEDBACK';
export const EDIT_FEEDBACK_SUCCESS = 'EDIT_FEEDBACK_SUCCESS';
export const EDIT_FEEDBACK_FAIL = 'EDIT_FEEDBACK_FAIL';
export const EDIT_FEEDBACK_RESET = 'EDIT_FEEDBACK_RESET';

export const editFeedback = (params) => {
	return {
		type: EDIT_FEEDBACK,
		params,
	};
};

export const editFeedbackSuccess = (response) => {
	return {
		type: EDIT_FEEDBACK_SUCCESS,
		response,
	};
};

export const editFeedbackFail = (response) => {
	return {
		type: EDIT_FEEDBACK_FAIL,
		response,
	};
};

export const editFeedbackReset = () => {
	return {
		type: EDIT_FEEDBACK_RESET,
	};
};

export const editFeedbackApi = (data) => {
	return (dispatch) => {
		dispatch(editFeedback());
		axios
			.patch(`${config.api.base_url}/feedback/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editFeedbackData = response.data;
				dispatch(editFeedbackSuccess(editFeedbackData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editFeedbackFail(errorMsg));
			});
	};
};
