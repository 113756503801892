import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ROLES_DROPDOWN = 'GET_ROLES_DROPDOWN';
export const GET_ROLES_DROPDOWN_SUCCESS = 'GET_ROLES_DROPDOWN_SUCCESS';
export const GET_ROLES_DROPDOWN_FAIL = 'GET_ROLES_DROPDOWN_FAIL';
export const GET_ROLES_DROPDOWN_RESET = 'GET_ROLES_DROPDOWN_RESET';

export const getRolesDropdown = (params) => {
	return {
		type: GET_ROLES_DROPDOWN,
		params,
	};
};

export const getRolesDropdownSuccess = (response) => {
	return {
		type: GET_ROLES_DROPDOWN_SUCCESS,
		response,
	};
};

export const getRolesDropdownFail = (response) => {
	return {
		type: GET_ROLES_DROPDOWN_FAIL,
		response,
	};
};

export const getRolesDropdownReset = () => {
	return {
		type: GET_ROLES_DROPDOWN_RESET,
	};
};

export const getRolesDropdownApi = (params) => {
	return (dispatch) => {
		dispatch(getRolesDropdown());
		axios
			.get(`${config.api.base_url}/settings/get-roles`, {
				params: params,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getRolesDropdownData = response.data;
				dispatch(getRolesDropdownSuccess(getRolesDropdownData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getRolesDropdownFail(errorMsg));
			});
	};
};
