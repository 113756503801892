import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Result,
	Tooltip,
	Tag,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { Router, useNavigate, useParams } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
// import ReloadingAnimation from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import Thanks from '../../json/thanks.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
} from '../CaseForm/CaseFormStyle';

import { XCardData } from './ReimbursementsStyle';

import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XDivider,
	XButtonLike,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonConfirm,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	CloseOutlined,
	ArrowLeftOutlined,
	WarningOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import moment from 'moment';
import Header from '../../components/Header/Header';
const { TextArea } = Input;

const CaseAnalysisView = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	const [form] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [summary, setSummary] = useState('');
	const [patientData, setPatientData] = useState({});
	const [modalTextArea, setModalTextArea] = useState('');
	const [coverage, setCoverage] = useState('Yes');
	const [feedbackId, setFeedbackId] = useState(null);
	const [matchDateOfBirth, setMatchDateOfbirth] = useState(true);
	const [matchGender, setMatchGender] = useState(true);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});

	const formatNumber = (cost) => {
		if (cost === 0) {
			return '0';
		}
		const costFloat = parseFloat(cost);
		const suffixes = ['K', 'M', 'B', 'T'];

		let formattedCost = costFloat;

		let suffix = '';
		for (const s of suffixes) {
			if (formattedCost < 1000) {
				break;
			}
			formattedCost /= 1000.0;
			suffix = s;
		}

		return `${formattedCost.toFixed(2)}${suffix}`;
	};

	const showDeleteConfirm = (title, id, content, item) => {
		XModal.confirm({
			title: `Delete ${title}?`,
			content: (
				<p className="modalPara1">
					Are you sure you want to delete - {content}?
				</p>
			),
			icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deletePageContent(item, id);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			setPageContent({
				...pageContent,
				[title]: [...pageContent[[title]], content],
			});
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	const deletePageContent = (item, id) => {
		const updatedPageContent = { ...pageContent };
		updatedPageContent[[item]].splice(id, 1);
		setPageContent(updatedPageContent);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	function compareDates(dateString, yearNumber) {
		// Parse the date string using Moment.js
		const dateOfBirth = moment(dateString);

		// Calculate the current year
		const currentYear = moment().year();

		// Extract the year from the parsed date of birth
		const birthYear = dateOfBirth.year();

		// Calculate the age by subtracting the birth year from the current year
		const age = currentYear - birthYear;
		// Extract the last two digits of the year number
		const yearDigits = parseInt(yearNumber.toString().slice(-2));
		// Compare the years
		return age === yearDigits;
	}
	function compareGender(patientGender, analysisGender) {
		// Get the first character of the analysisGender string and normalize to uppercase
		const firstCharAnalysis = analysisGender.charAt(0).toUpperCase();

		// Check if patientGender is "M" (Male) or "F" (Female)
		if (
			patientGender.toUpperCase() === 'MALE' ||
			patientGender.toUpperCase() === 'FEMALE'
		) {
			// Compare the first character of analysisGender with patientGender
			return firstCharAnalysis === patientGender.charAt(0).toUpperCase();
		} else {
			// Match patientGender with "O" (Other)
			return firstCharAnalysis === 'O';
		}
	}

	// use effects
	useEffect(() => {
		if (id) getAnalysis({ id: id });
	}, [id]);

	useEffect(() => {
		if (
			getAnalysisState.apiState === 'success' &&
			!Object.keys(getAnalysisState.data.coverage_feedback_response)
				.length > 0 &&
			patientData.clinical_summary
		) {
			if (
				patientData.allocated_to &&
				(!getAnalysisState.data.coverage_feedback_response[
					'original_query'
				] ||
					!getAnalysisState.data.coverage_feedback_response[
						'Past History'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					]?.length > 0 ||
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					] === 'No')
			) {
				setLoading(true);
				document
					.getElementById('notification')
					?.classList.add('d-none');
				analyseCoverage({
					clinicalSummary: patientData.clinical_summary,
					case_id: patientData._id,
					state: patientData.state,
					provider: patientData.insurance_company?.name,
				});
			}
		}
		getAnalysisReset();
	}, [patientData]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			if (
				getAnalysisState.data.case_details &&
				getAnalysisState.data.case_details.allocated_to === null
			) {
				notification.error({
					message: 'Case Not Allocated',
					description:
						"Oops! It seems like this case hasn't been allocated to anyone yet. Please allocate it first before proceeding with the analysis.",
				});
			}
			setPatientData(getAnalysisState.data.case_details);
			setPageContent({
				...pageContent,
				originalQuery:
					getAnalysisState.data.coverage_feedback_response[
						'original_query'
					] ?? '-',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.age
			)
				setMatchDateOfbirth(
					compareDates(
						getAnalysisState.data.case_details.date_of_birth,
						getAnalysisState.data.coverage_feedback_response.age,
					),
				);
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.gender
			)
				setMatchGender(
					compareGender(
						getAnalysisState.data.case_details.gender,
						getAnalysisState.data.coverage_feedback_response.gender,
					),
				);

			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
		}
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			setSummary('');
			summaryForm.resetFields();
			analyseCoverageReset();
			setLoading(false);
			if (
				analyseCoverageState.data &&
				analyseCoverageState.data.date_of_birth
			)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						analyseCoverageState.data.age,
					),
				);
			if (analyseCoverageState.data && analyseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						analyseCoverageState.data.gender,
					),
				);
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
		}

		if (analyseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			if (reviseCoverageState.data && reviseCoverageState.data.age)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						reviseCoverageState.data.age,
					),
				);
			if (reviseCoverageState.data && reviseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						reviseCoverageState.data.gender,
					),
				);
			reviseCoverageReset();
			setLoading(false);
		} else if (reviseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			reviseCoverageReset();
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			setModalOpen([false, '', '']);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			sendFeedbackReset();
			if (id) getAnalysis({ id: id });
		}
	}, [sendFeedbackState]);

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header className="mt-4" headerTag="Reimbursement" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="reimbursement" />
				</Col>
			</>
			{/* <div id="notification" className="d-none marginNone" ></div> */}
			<XContainer fluid>
				{checkModulePermissions('reimbursements').authorized ? (
					<>
						<XCardBackBg className="mb-3">
							<Row>
								<Col xs={24}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>

									<XEditTitle>View Reimbursement</XEditTitle>
								</Col>
							</Row>
						</XCardBackBg>
						<XCardBackBg className="mb-3">
							<Row
								justify={'start'}
								align={'middle'}
								style={{ rowGap: '10px' }}
							>
								<Col xs={24} lg={12} md={12} xl={6}>
									<XPara1>
										MRN:&nbsp;
										{patientData.mrn}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={8}>
									<XPara1>
										Name: {patientData.patient_name}
									</XPara1>
								</Col>
								{/* //todo age is redundant and commented after amin told */}
								{/* <Col xs={24} lg={12} md={12} xl={5}>
									<XPara1>
										Age:{' '}
										{moment(
											patientData.date_of_birth,
										).fromNow(true)}
										&nbsp;
										{matchDateOfBirth ? (
											<> </>
										) : (
											<Tooltip
												title="The age of the patient does not align with the age provided in the Clinical Summary."
												trigger={'hover'}
											>
												<WarningOutlined
													style={{ color: 'red' }}
												/>
											</Tooltip>
										)}
									</XPara1>
								</Col> */}
								<Col xs={24} lg={12} md={12} xl={5}>
									<XPara1>
										Gender:{' '}
										{patientData.gender
											? toTitleCase(patientData.gender)
											: ''}
										&nbsp;
										{matchGender ? (
											<> </>
										) : (
											<Tooltip
												title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
												trigger={'hover'}
											>
												<WarningOutlined
													style={{ color: 'red' }}
												/>
											</Tooltip>
										)}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={6}>
									<XPara1>
										DOB:{' '}
										{patientData.date_of_birth
											? dateOfBirth(
													patientData.date_of_birth,
											  )
											: ''}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={8}>
									<XPara1>
										Revenue Cycle:{' '}
										{patientData.revenue_cycle ===
											'pre_service' && 'Pre Service'}
										{patientData.revenue_cycle ===
											'post_claim' && 'Post Claim'}
										{patientData.revenue_cycle ===
											'part_b_drugs' && 'Part B Drugs'}
									</XPara1>
								</Col>
								<Col xs={24} lg={12} md={12} xl={10}>
									<XPara1>State: {patientData.state}</XPara1>
								</Col>
								<Col xs={24} lg={12} md={24} xl={24}>
									<XPara1>
										Insurance Company:{' '}
										{patientData.insurance_company?.name ??
											'-'}
									</XPara1>
								</Col>
							</Row>
							{patientData.status === 'letter_sent' ? (
								<Row className="mt-2">
									<Col xl={18} lg={18}>
										<XTag className={patientData.status}>
											{toTitleCase(patientData.status)}
										</XTag>
									</Col>
								</Row>
							) : (
								''
							)}
						</XCardBackBg>
						{loading && (
							<XLottieOverlay>
								<XLottie
									animationData={ReloadingAnimation}
									loop={true}
								/>
							</XLottieOverlay>
						)}

						<div
							id="getAns"
							className="customScroll slideUp top-100 mt-4"
						>
							<XCardsGrdBG>
								<Row className="align-items-center">
									<Col xs={20} lg={22}>
										<XPara1>
											{patientData.clinical_summary}
										</XPara1>
									</Col>
								</Row>
							</XCardsGrdBG>
							<XCardData className="mt-4">
								<Row>
									<Col xs={24} className="mb-3">
										<Row>
											<Col
												xs={17}
												sm={20}
												lg={21}
												xl={22}
											>
												<Space align="center">
													<XTitleHead>
														<span className="d-none d-sm-block">
															Reimbursements
														</span>

														<span className="d-block d-sm-none text-start">
															Reimbursements
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												sm={4}
												lg={2}
												xl={1}
												className="mt-3"
											>
												{patientData.is_claim_amount_settled ? (
													<XTag
														className={'successful'}
													>
														{toTitleCase(
															'successful',
														)}
													</XTag>
												) : (
													<XTag className={'pending'}>
														{toTitleCase('Pending')}
													</XTag>
												)}
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/case_amount.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Case Amount
												</div>
												<div className="headingMain">
													<strong>
														$
														{patientData.case_amount
															? formatNumber(
																	patientData.case_amount,
															  )
															: 0}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/claim_amount.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Claim Amount
												</div>
												<div className="headingMain">
													<strong>
														$
														{patientData.claim_amount
															? formatNumber(
																	patientData.claim_amount,
															  )
															: 0}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/claimed_at.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Claimed At
												</div>
												<div className="headingMain">
													<strong>
														{patientData.claim_amount_updated_at
															? moment(
																	patientData.claim_amount_updated_at,
															  ).format(
																	'DD-mm-yyyy',
															  )
															: '-'}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/payment_delay.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Payment Delay (days)
												</div>
												<div className="headingMain">
													<strong>
														{patientData.payment_delay ??
															'-'}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/interest_amount.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Interest
												</div>
												<div className="headingMain">
													<strong>
														$
														{patientData.interest_amount
															? formatNumber(
																	patientData.interest_amount,
															  )
															: 0}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
									<Col
										xs={24}
										sm={12}
										md={8}
										lg={7}
										className="mb-3"
									>
										<Row className="align-items-center">
											<Col xs={6}>
												<img
													className="iconResp"
													src="/images/reimbursed_at.svg"
													alt=""
												/>
											</Col>
											<Col xs={18}>
												<div className="subHeading">
													Reimbursed At
												</div>
												<div className="headingMain">
													<strong>
														{patientData.reimbursed_at
															? moment(
																	patientData.reimbursed_at,
															  ).format(
																	'DD-mm-yyyy',
															  )
															: '-'}
													</strong>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</XCardData>
							<XCardsCaseBg className="mt-4">
								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/past-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color1 d-none d-sm-block">
													Past Clinical History
												</span>

												<span className="color1 d-block d-sm-none text-start">
													Past Clinical <br /> History
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col xs={4} lg={4} className="text-end">
										<></>
									</Col>
								</Row>
								{pageContent.pastHistory.length > 0
									? pageContent.pastHistory.map(
											(row, index) => {
												const splitPastHistory =
													row.split('- ICD-10 Code:');

												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitPastHistory.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitPastHistory[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		ICD-10:
																		{
																			splitPastHistory[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														>
															<></>
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/present-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color2 d-none d-sm-block">
													Present Complaint
												</span>

												<span className="color2 d-block d-sm-none text-start">
													Present <br /> Complaint
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col xs={4} lg={4} className="text-end">
										<></>
									</Col>
								</Row>
								{pageContent.presentComplaint.length > 0
									? pageContent.presentComplaint.map(
											(row, index) => {
												const splitPresentComplaint =
													row.split('- ICD-10 Code:');

												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitPresentComplaint.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitPresentComplaint[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		ICD-10:
																		{
																			splitPresentComplaint[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														>
															<></>
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/procedure-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color3 d-none d-sm-block">
													Denial Procedure
												</span>

												<span className="color3 d-block d-sm-none text-start">
													Denial <br /> Procedure
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col xs={4} lg={4} className="text-end">
										<></>
									</Col>
								</Row>
								{pageContent.recommendedProcedure.length > 0
									? pageContent.recommendedProcedure.map(
											(row, index) => {
												const splitRecommendedProcedure =
													row.split('- CPT Code:');

												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															{splitRecommendedProcedure.length >
															1 ? (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{
																		splitRecommendedProcedure[0]
																	}
																	<span
																		style={{
																			padding:
																				'3px',
																			background:
																				'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																		}}
																	>
																		CPT:
																		{
																			splitRecommendedProcedure[1]
																		}
																	</span>
																</XPara1>
															) : (
																<XPara1
																	style={{
																		fontStyle:
																			'italic',
																	}}
																>
																	{row}
																</XPara1>
															)}
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														>
															<></>
														</Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={24}>
										<Space align="center">
											<img
												className="iconResp"
												src={
													pageContent
														.medicalCoverage[0] ===
													'Yes'
														? '/images/coverage-icon.svg'
														: '/images/cross-icon.svg'
												}
												alt=""
											/>
											<XTitleHead>
												<span
													className={
														pageContent
															.medicalCoverage[0] ===
														'Yes'
															? 'color4'
															: 'color5'
													}
												>
													Medicare Coverage
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								{pageContent.medicalCoverage.length > 0 ? (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24}>
											<XPara1>
												{pageContent.medicalCoverage[1]}
												{pageContent
													.medicalCoverage[2] &&
												Object.keys(
													pageContent
														.medicalCoverage[2],
												).length > 0 ? (
													<>
														<br />
														<br />
														<i>
															Reference:{' '}
															<a
																href={
																	pageContent
																		.medicalCoverage[2][
																		'target'
																	]
																}
																rel="noreferrer"
																target="_blank"
															>
																{
																	pageContent
																		.medicalCoverage[2][
																		'display_text'
																	]
																}
															</a>
														</i>
													</>
												) : (
													''
												)}
											</XPara1>
										</Col>
									</Row>
								) : (
									'-'
								)}
							</XCardsCaseBg>
							{pageContent.missing_points_for_coverage &&
								pageContent.missing_points_for_coverage.length >
									0 && (
									<XCardsCriteriaBg className="mt-4">
										<div className="criteria_list">
											<Col xs={24}>
												<Space align="center">
													<img
														src={
															'/images/criteria.svg'
														}
														alt=""
													/>
													<XTitleHead>
														<span className="criteria_list_title">
															Missing Inclusion
															Criteria
														</span>
														&nbsp;
														<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
															<Tag
																style={{
																	border: '1px solid #9B68BF',
																	color: '#9B68BF',
																	fontSize:
																		'11px',
																}}
															>
																Alpha
															</Tag>
														</Tooltip>
													</XTitleHead>
												</Space>
											</Col>
										</div>

										<Row
											gutter={(15, 15)}
											className="mt-3 align-items-center criteria_sub_list"
										>
											<Col xs={24}>
												<ul className="listCircule">
													{pageContent.missing_points_for_coverage?.map(
														(row, index) => {
															return (
																<li>{row}</li>
															);
														},
													)}
												</ul>
											</Col>
										</Row>
									</XCardsCriteriaBg>
								)}
						</div>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>

			<XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									status: 'pending_for_analysis',
									response: [coverage, modalTextArea],
								});
							} else {
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									modalTextArea,
								);
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<XForm.Item
									name="medicare_flag"
									label="Coverage"
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select a person"
										optionFilterProp="children"
										onChange={(e) => {
											setCoverage(e);
										}}
										options={[
											{
												value: 'Yes',
												label: 'YES',
											},
											{
												value: 'No',
												label: 'NO',
											},
										]}
									/>
								</XForm.Item>
							)}
							<XForm.Item
								name="modal_text_area"
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<TextArea
									name="modal_text_area"
									showCount
									style={{
										height: 120,
										marginBottom: 24,
									}}
									className="textArea mt-2"
									placeholder="Enter"
									onChange={(e) =>
										setModalTextArea(e.target.value)
									}
								>
									{/* modalOpen[2] */}
								</TextArea>
							</XForm.Item>
						</Col>
					</Row>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal[0]}
				onOk={() => setThanksModal([false, ''])}
				onCancel={() => {
					setThanksModal([false, '']);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">{thanksModal[1]}</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAnalysisView);
