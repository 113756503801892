
import { 
    GET_ANALYSIS_STATUS,
    GET_ANALYSIS_STATUS_SUCCESS,
    GET_ANALYSIS_STATUS_FAIL, 
    GET_ANALYSIS_STATUS_RESET,
} from "../actions/getAnalysisStatusAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getAnalysisStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANALYSIS_STATUS:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_ANALYSIS_STATUS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_ANALYSIS_STATUS_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_ANALYSIS_STATUS_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getAnalysisStatusReducer;
