import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Result, Radio, Tag } from 'antd';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XButton,
	FilterButton,
	XSearch,
} from '../../styles/GlobalStyle';
import { dateFilters } from '../../utils/constants';
import DataTable from '../../components/DataTable/DataTable';
import { XButtonImg } from './ManageStyle';

import moment from 'moment';
import CaseSearch from '../../components/CaseSearch/CaseSearch';

import { checkModulePermissions, urlPagination } from '../../utils/helper';
import { getModuleByIdApi } from '../../actions/getModuleByIdAction';
import {
	getModulesListApi,
	getModulesListReset,
} from '../../actions/getModulesListAction';
import { editModuleApi } from '../../actions/editModuleAction';
import { addModuleApi } from '../../actions/addModuleAction';
import {
	deleteModuleApi,
	deleteModuleReset,
} from '../../actions/deleteModuleAction';

const AdminConfigurations = (props) => {
	const {
		getModulesListState,
		deleteModuleState,
		getModulesList,
		deleteModuleReset,
	} = props;
	let modulePermissions = checkModulePermissions('module-management');
	const navigate = useNavigate();
	const defaultFilters = {
		search: '',
		page: 1,
		sort: 'updatedAt',
		sortDirection: 'descend',
		created_on: 'all',
		limit: 10,
	};
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');

	const [form2] = XForm.useForm();

	const [open, setOpen] = useState(false);
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const columns = [
		{
			title: 'Module Name',
			width: 200,
			dataIndex: 'module_name',
			fixed: 'left',
			render: (module_name) => (
				<div>
					<div>{module_name}</div>
				</div>
			),
		},
		{
			title: 'Sub Modules',
			width: 200,
			dataIndex: 'sub_modules',
			render: (sub_modules) => (
				<div>
					<div>{sub_modules}</div>
				</div>
			),
		},
		{
			title: 'Created On',
			width: 200,
			dataIndex: 'createdAt',
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortDirections: ['ascend', 'descend'],
			render: (createdAt) => (
				<div>
					<div>{createdAt}</div>
				</div>
			),
		},
		{
			title: 'Action',
			width: 100,
			dataIndex: 'action',
			fixed: 'right',
		},
	];

	// use effects

	useEffect(() => {
		if (getModulesListState.apiState === 'success') {
			if (getModulesListState.data.modules.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getModulesListState.data.total_count);
				getModulesListState.data.modules.map((item, index) => {
					updatedSearchOptions.push({ value: item.name });
					updatedData.push({
						key: item._id,
						_id: item._id,
						module_name: item.module_name,
						sub_modules: item.sub_modules.map((ele) => (
							<Tag style={{ margin: '2px' }}>
								{ele.sub_module_name}
							</Tag>
						)),
						createdAt: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),
						action: (
							<div className="text-center">
								{item.module_slug === 'module-management' ? (
									<></>
								) : (
									<Row>
										<Col xs={{ span: 6, offset: 6 }}>
											{modulePermissions.sub_modules.includes(
												'update-module',
											) ? (
												<XButtonImg
													onClick={() =>
														navigate(
															`/module/edit/${item._id}`,
														)
													}
													type="text"
												>
													<img
														src="./images/pencil.svg"
														alt=""
													/>
												</XButtonImg>
											) : (
												<></>
											)}
										</Col>
									</Row>
								)}
							</div>
						),
					});
				});
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getModulesListState]);

	useEffect(() => {
		if (deleteModuleState.apiState === 'success') {
			deleteModuleReset();
			getModulesList();
		}
	}, [deleteModuleState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });
		// Make API call with updated filters and pagination
		checkModulePermissions('module-management').sub_modules.includes(
			'list-modules',
		) && getModulesList({ ...updatedFilters });
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form2.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	return (
		<>
			{modulePermissions.authorized ? (
				<>
					<CaseSearch
						filters={[
							<XForm.Item
								name="created_on"
								colon={false}
								label="Date Within"
								className="removeFormMargin"
							>
								<Radio.Group
									onChange={onChange}
									name="created_on"
								>
									<Row>
										{dateFilters.map((item, index) => (
											<Col
												xs={12}
												lg={12}
												md={12}
												xl={12}
											>
												<Radio
													value={item.value}
													className="filter-sub-item"
												>
													{item.label}
												</Radio>
											</Col>
										))}
									</Row>
								</Radio.Group>
							</XForm.Item>,
						]}
						onClose={onClose}
						open={open}
						applyFilters={applyFilters}
						form={form2}
						clearFilters={clearFilters}
					/>
					<Row gutter={(15, 30)}>
						<Col
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
							xs={24}
							md={10}
							lg={3}
							xl={3}
							xxl={3}
						>
							<p className="tableHeader">
								Total {totalRecords} Modules
							</p>
						</Col>
						<Col
							xs={24}
							md={14}
							lg={{ span: 6 }}
							xl={{ span: 6 }}
							xxl={{ span: 6 }}
						>
							<XForm.Item>
								<XSearch
									placeholder="Search for Modules"
									allowClear
									enterButton="Search"
									size="large"
									onChange={(v) => {
										if (v === '') {
											urlPagination(
												filters,
												'search',
												'',
											);
											setFilters({
												...filters,
												search: '',
											});
										}
									}}
									onSearch={(v) => {
										setFilters({
											...filters,
											search: v,
										});
										getModulesList({
											...filters,
											search: v,
										});
									}}
								/>
							</XForm.Item>
						</Col>
						<Col
							xs={24}
							md={12}
							lg={{ span: 4, offset: 7 }}
							xl={{ span: 4, offset: 7 }}
							xxl={{ span: 4, offset: 7 }}
						>
							{modulePermissions.sub_modules.includes(
								'add-module',
							) ? (
								<XForm.Item className="text-right">
									<XButton
										onClick={() => navigate('/module/add')}
									>
										Add Module
									</XButton>
								</XForm.Item>
							) : (
								<></>
							)}
						</Col>
						<Col
							xs={24}
							md={12}
							lg={{ span: 4 }}
							xl={{ span: 4 }}
							xxl={{ span: 4 }}
						>
							<XForm.Item className="text-right">
								<FilterButton
									onClick={() => showDrawer()}
									icon={
										<img
											src="/images/filter-add.svg"
											alt="bell"
											height={'20px'}
										/>
									}
								>
									Filters
								</FilterButton>
							</XForm.Item>
						</Col>
					</Row>

					<DataTable
						tableData={tableData}
						columns={columns}
						totalRecords={totalRecords}
						setFilters={setFilters}
						filters={filters}
						state={getModulesListState.apiState}
						page={'module'}
					/>
				</>
			) : (
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={
						<Button type="primary" onClick={() => navigate('/')}>
							Back Home
						</Button>
					}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getModuleByIdState: state.getModuleById,
	getModulesListState: state.getModulesList,
	editModuleState: state.editModule,
	addModuleState: state.addModule,
	deleteModuleState: state.deleteModule,
});

const mapDispatchToProps = (dispatch) => ({
	getModuleById: (params) => dispatch(getModuleByIdApi(params)),
	getModulesList: (params) => dispatch(getModulesListApi(params)),
	editModule: (params) => dispatch(editModuleApi(params)),
	addModule: (params) => dispatch(addModuleApi(params)),
	deleteModule: (params) => dispatch(deleteModuleApi(params)),
	getModulesListReset: (params) => dispatch(getModulesListReset(params)),
	deleteModuleReset: (params) => dispatch(deleteModuleReset(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdminConfigurations);
