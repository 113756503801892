import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_TOTAL_CASE_AMOUNT = 'GET_TOTAL_CASE_AMOUNT';
export const GET_TOTAL_CASE_AMOUNT_SUCCESS = 'GET_TOTAL_CASE_AMOUNT_SUCCESS';
export const GET_TOTAL_CASE_AMOUNT_FAIL = 'GET_TOTAL_CASE_AMOUNT_FAIL';
export const GET_TOTAL_CASE_AMOUNT_RESET = 'GET_TOTAL_CASE_AMOUNT_RESET';

export const getTotalCaseAmount = (params) => {
	return {
		type: GET_TOTAL_CASE_AMOUNT,
		params,
	};
};

export const getTotalCaseAmountSuccess = (response) => {
	return {
		type: GET_TOTAL_CASE_AMOUNT_SUCCESS,
		response,
	};
};

export const getTotalCaseAmountFail = (response) => {
	return {
		type: GET_TOTAL_CASE_AMOUNT_FAIL,
		response,
	};
};

export const getTotalCaseAmountReset = () => {
	return {
		type: GET_TOTAL_CASE_AMOUNT_RESET,
	};
};

export const getTotalCaseAmountApi = (data) => {
	return (dispatch) => {
		dispatch(getTotalCaseAmount());
		axios
			.get(`${config.api.base_url}/dashboard/get-total-case-amount`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getTotalCaseAmountData = response.data;
				dispatch(getTotalCaseAmountSuccess(getTotalCaseAmountData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getTotalCaseAmountFail(errorMsg));
			});
	};
};
