import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_INSURANCE_COMPANIES = 'GET_INSURANCE_COMPANIES';
export const GET_INSURANCE_COMPANIES_SUCCESS =
	'GET_INSURANCE_COMPANIES_SUCCESS';
export const GET_INSURANCE_COMPANIES_FAIL = 'GET_INSURANCE_COMPANIES_FAIL';
export const GET_INSURANCE_COMPANIES_RESET = 'GET_INSURANCE_COMPANIES_RESET';

export const getInsuranceCompanies = (params) => {
	return {
		type: GET_INSURANCE_COMPANIES,
		params,
	};
};

export const getInsuranceCompaniesSuccess = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_SUCCESS,
		response,
	};
};

export const getInsuranceCompaniesFail = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_FAIL,
		response,
	};
};

export const getInsuranceCompaniesReset = () => {
	return {
		type: GET_INSURANCE_COMPANIES_RESET,
	};
};

export const getInsuranceCompaniesApi = (data) => {
	return (dispatch) => {
		dispatch(getInsuranceCompanies());
		axios
			.get(`${config.api.base_url}/insurance-companies/all`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getInsuranceCompaniesData = response.data;
				dispatch(
					getInsuranceCompaniesSuccess(getInsuranceCompaniesData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getInsuranceCompaniesFail(errorMsg));
			});
	};
};
