import {
	EDIT_CONFIGURATION,
	EDIT_CONFIGURATION_SUCCESS,
	EDIT_CONFIGURATION_FAIL,
	EDIT_CONFIGURATION_RESET,
} from '../actions/editConfigurationAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const editConfigurationReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_CONFIGURATION:
			return {
				...state,
				apiState: 'loading',
			};
		case EDIT_CONFIGURATION_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case EDIT_CONFIGURATION_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case EDIT_CONFIGURATION_RESET:
			return initialState;
		default:
			return state;
	}
};

export default editConfigurationReducer;
