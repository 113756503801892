import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { checkModulePermissions } from '../../utils/helper';
import {
	Card,
	Row,
	Col,
	Button,
	Input,
	Tag,
	theme,
	Space,
	Tooltip,
	notification,
} from 'antd';
import { getConfigurationByIdApi } from '../../actions/getConfigurationByIdAction';
import { getConfigurationsApi } from '../../actions/getConfigurationsAction';
import {
	editConfigurationApi,
	editConfigurationReset,
} from '../../actions/editConfigurationAction';
import { addConfigurationApi } from '../../actions/addConfigurationAction';
import { deleteConfigurationApi } from '../../actions/deleteConfigurationAction';
import {
	getConfigurationOptionsApi,
	getConfigurationOptionsReset,
} from '../../actions/getConfigurationOptionsAction';

const AdminConfigurations = (props) => {
	const {
		getConfigurationsState,
		editConfigurationState,
		getConfigurations,
		editConfiguration,
		editConfigurationReset,
	} = props;
	let modulePermissions = checkModulePermissions('configurations');

	const { token } = theme.useToken();

	const [optionsData, setOptionsData] = useState({});
	const [inputVisible, setInputVisible] = useState('');
	const [inputValue, setInputValue] = useState('');

	const handleClose = (removedTag, tagName, setTagName) => {
		const newOptionsData = optionsData.map((config) => {
			const newOptions = config.options.filter(
				(opt) => opt.value !== removedTag.value,
			);
			return { ...config, options: newOptions };
		});

		setOptionsData(newOptionsData);
	};
	const showInput = (value) => {
		setInputVisible(value);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = (optionsData, setOptionsData, dynamicKey) => {
		if (inputValue && dynamicKey) {
			setOptionsData((prevOptionsData) => {
				const updatedOptionsData = prevOptionsData.map((option) => {
					if (option.key === dynamicKey) {
						return {
							...option,
							options: [
								...option.options,
								{ value: inputValue, label: inputValue },
							],
						};
					}
					return option;
				});
				return updatedOptionsData;
			});
		}

		setInputVisible(false);
		setInputValue('');
	};

	const handleAddConfig = (_id) => {
		const foundConfig = optionsData.find((config) => config.id === _id);
		editConfiguration({ _id: _id, config: foundConfig });
		return foundConfig ? foundConfig.options : [];
	};

	const tagInputStyle = {
		width: 64,
		height: 22,
		marginInlineEnd: 8,
		verticalAlign: 'top',
	};
	const tagPlusStyle = {
		height: 22,
		background: token.colorBgContainer,
		borderStyle: 'dashed',
	};

	useEffect(() => {
		checkModulePermissions('configurations').sub_modules.includes(
			'view-configuration-options',
		) && getConfigurations({});
	}, []);

	useEffect(() => {
		if (getConfigurationsState.apiState === 'success') {
			if (getConfigurationsState.data.all_configurations.length > 0) {
				let updatedData = [];
				getConfigurationsState.data.all_configurations.map((option) => {
					updatedData.push({
						name: option.name,
						key: option.key,
						options: option.options,
						id: option._id,
					});
				});

				setOptionsData(updatedData);
			}
		}
	}, [getConfigurationsState]);

	useEffect(() => {
		if (editConfigurationState.apiState === 'success') {
			notification.success({
				message: editConfigurationState.message,
			});
		}
		if (editConfigurationState.apiState === 'error') {
			notification.error({
				message: editConfigurationState.message,
			});
		}
		editConfigurationReset();
	}, [editConfigurationState]);

	return (
		<>
			<Row className="my-4 text-end" gutter={[30, 30]}>
				{getConfigurationsState.apiState === 'success' &&
					optionsData.length > 0 &&
					optionsData.map((option, index) => {
						return (
							<Col xs={24} key={index}>
								<Card
									style={{
										borderRadius: '20px',
										border: '1px solid rgba(218, 222, 227, 0.50)',
										background: '#FFF',
										padding: '20px',
									}}
								>
									<Row>
										<Col
											xs={8}
											md={4}
											lg={3}
											xl={2}
											xxl={2}
											className="text-start"
										>
											<img
												src={`/images/${option.key}.svg`}
												alt=""
											/>
										</Col>

										<Col
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
											xs={16}
											md={18}
											lg={19}
											xl={20}
											xxl={20}
											className="text-start"
										>
											<Row>
												<Col
													xs={24}
													className="text-start"
												>
													<p className="config-text">
														{option.name}
													</p>
												</Col>
												<Col xs={24}>
													<Space size={[0, 8]} wrap>
														{option.options.map(
															(
																tags,
																configIndex,
															) => {
																let tag =
																	tags.label;

																const isLongTag =
																	tag?.length >
																	20;
																const tagElem =
																	(
																		<Tag
																			key={
																				tag
																			}
																			closable={
																				modulePermissions.sub_modules.includes(
																					'remove-configuration-options',
																				)
																					? true
																					: false
																			}
																			style={{
																				userSelect:
																					'none',
																			}}
																			onClose={() =>
																				handleClose(
																					tags,
																					optionsData,
																					setOptionsData,
																				)
																			}
																		>
																			<span
																				onDoubleClick={(
																					e,
																				) => {
																					e.preventDefault();
																				}}
																			>
																				{isLongTag
																					? `${tag.slice(
																							0,
																							20,
																					  )}...`
																					: tag}
																			</span>
																		</Tag>
																	);
																return isLongTag ? (
																	<Tooltip
																		title={
																			tag
																		}
																		key={
																			tag
																		}
																	>
																		{
																			tagElem
																		}
																	</Tooltip>
																) : (
																	tagElem
																);
															},
														)}
														{inputVisible ===
														option.name ? (
															<Input
																type="text"
																style={
																	tagInputStyle
																}
																value={
																	inputValue
																}
																onChange={
																	handleInputChange
																}
																onBlur={() =>
																	handleInputConfirm(
																		option.options,
																		setOptionsData,
																		option.key,
																	)
																}
																onPressEnter={() =>
																	handleInputConfirm(
																		option.options,
																		setOptionsData,
																		option.key,
																	)
																}
															/>
														) : (
															<>
																{modulePermissions.sub_modules.includes(
																	'add-configuration-options',
																) ? (
																	<Tag
																		style={
																			tagPlusStyle
																		}
																		icon={
																			<PlusOutlined />
																		}
																		onClick={() =>
																			showInput(
																				option.name,
																			)
																		}
																	>
																		{`New ${option.name}`}
																	</Tag>
																) : (
																	false
																)}
															</>
														)}
													</Space>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											md={2}
											lg={2}
											xl={2}
											xxl={2}
											className="text-start save-config"
										>
											<Button
												type="primary"
												shape="circle"
												icon={<SaveOutlined />}
												size={'large'}
												style={{
													background: '#212226',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
												onClick={() => {
													handleAddConfig(option.id);
												}}
											/>
										</Col>
									</Row>
								</Card>
							</Col>
						);
					})}
			</Row>
		</>

		// </XContainer>
		// </>
	);
};

const mapStateToProps = (state) => ({
	getConfigurationByIdState: state.getConfigurationById,
	getConfigurationsState: state.getConfigurations,
	editConfigurationState: state.editConfiguration,
	addConfigurationState: state.addConfiguration,
	deleteConfigurationState: state.deleteConfiguration,
	getConfigurationOptionsState: state.getConfigurationOptions,
});

const mapDispatchToProps = (dispatch) => ({
	getConfigurationById: (params) => dispatch(getConfigurationByIdApi(params)),
	getConfigurations: (params) => dispatch(getConfigurationsApi(params)),
	editConfiguration: (params) => dispatch(editConfigurationApi(params)),
	addConfiguration: (params) => dispatch(addConfigurationApi(params)),
	deleteConfiguration: (params) => dispatch(deleteConfigurationApi(params)),
	getConfigurationOptions: (params) =>
		dispatch(getConfigurationOptionsApi(params)),
	getConfigurationOptionsReset: (params) =>
		dispatch(getConfigurationOptionsReset(params)),
	editConfigurationReset: () => dispatch(editConfigurationReset()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdminConfigurations);
