import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAIL = 'DELETE_MODULE_FAIL';
export const DELETE_MODULE_RESET = 'DELETE_MODULE_RESET';

export const deleteModule = (params) => {
	return {
		type: DELETE_MODULE,
		params,
	};
};

export const deleteModuleSuccess = (response) => {
	return {
		type: DELETE_MODULE_SUCCESS,
		response,
	};
};

export const deleteModuleFail = (response) => {
	return {
		type: DELETE_MODULE_FAIL,
		response,
	};
};

export const deleteModuleReset = () => {
	return {
		type: DELETE_MODULE_RESET,
	};
};

export const deleteModuleApi = (data) => {
	return (dispatch) => {
		dispatch(deleteModule());
		axios
			.delete(`${config.api.base_url}/modules/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteModuleData = response.data;
				dispatch(deleteModuleSuccess(deleteModuleData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteModuleFail(errorMsg));
			});
	};
};
