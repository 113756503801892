import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Skeleton,
	Card,
	Tooltip,
	Space,
	Form,
	Flex,
	DatePicker,
	Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import DashboardJson from '../../json/Login.json';
import LoaderJson from '../../json/loader.json';
import HeaderFile from '../../components/Header/HeaderFile';
import { XContainer, XButton } from '../../styles/GlobalStyle';
import {
	XLottie,
	Title,
	XCardData,
	XCardData2,
	XCardData3,
	XCardBg,
	XLottieWrapp,
	XTableCharts,
	pieChartWrap,
	XCardNonBg,
	XSelectCategories,
	XAutoComplete,
	XCollapse,
	XButtonSeg,
	XReactSpeedometer,
	XCardData4,
	XSwitch,
	SwitchBg,
	XPyersCard,
	XModalAnimation,
	XLottieLoader,
} from './DashboardStyle';
import CaseManagement from '../Manage/CaseManagement';

import {
	checkModulePermissions,
	getDate,
	getUserName,
} from '../../utils/helper';
import moment from 'moment';
import CaseAnalysis from '../CaseAnalysis/CaseAnalysis';
import DonutChart from '../../components/Chart/DashboardChart';
import {
	getCaseChartDetailsApi,
	getCaseChartDetailsReset,
} from '../../actions/getCaseChartDetailsAction';
import {
	getTotalCaseAmountApi,
	getTotalCaseAmountReset,
} from '../../actions/getTotalCaseAmountAction';
import Header from '../../components/Header/Header';

import CaseClosure from '../CaseClosure/CaseClosure';
import FeedBackList from '../FeedBackAnalysis/FeedBackList';
import AppealLetter from '../AppealLetter/AppealLetter';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
	getInsuranceCompaniesStatisticsApi,
	getInsuranceCompaniesStatisticsReset,
} from '../../actions/getInsuranceCompaniesStatisticsAction';
import {
	getInsuranceCompaniesFilingStatusCountsApi,
	getInsuranceCompaniesFilingStatusCountsReset,
} from '../../actions/getInsuranceCompaniesFilingStatusCountsAction';
import {
	getInsuranceCompaniesBucketApi,
	getInsuranceCompaniesBucketReset,
} from '../../actions/getInsuranceCompaniesBucketAction';
import { XRadio } from '../CaseForm/CaseFormStyle';
import {
	getInsuranceDropdownApi,
	getInsuranceDropdownReset,
} from '../../actions/getInsuranceDropdownAction';
import {
	getCaseAmountBucketApi,
	getCaseAmountBucketReset,
} from '../../actions/getCaseAmountBucketAction';
import { filterByLabel } from '../../utils/formFunctions';
import config from '../../config';
import HalfDonutChart from '../../components/Chart/DashboardHalfDonutChart';

const Dashboard = (props) => {
	const {
		getCaseChartDetailsState,
		getCaseChartDetails,
		getCaseChartDetailsReset,
		getTotalCaseAmountState,
		getTotalCaseAmount,
		getTotalCaseAmountReset,
		getInsuranceCompaniesStatistics,
		getInsuranceCompaniesStatisticsState,
		getInsuranceCompaniesStatisticsReset,
		getInsuranceCompaniesFilingStatusCounts,
		getInsuranceCompaniesFilingStatusCountsState,
		getInsuranceCompaniesFilingStatusCountsReset,
		getInsuranceCompaniesBucket,
		getInsuranceCompaniesBucketState,
		getInsuranceCompaniesBucketReset,
		getInsuranceDropdown,
		getInsuranceDropdownState,
		getCaseAmountBucket,
		getCaseAmountBucketReset,
		getCaseAmountBucketState,
	} = props;
	// Initialize your default state values
	const defaultStatisticsFilters = {
		status: 'open',
	};
	const defaultTableFilters = {
		status: 'open',
	};
	const defaultScoreFilters = {
		status: 'open',
	};
	const navigate = useNavigate();
	const [chartData, setChartData] = useState({});
	const [caseAmount, setCaseAmount] = useState({});
	const [statisticsData, setStatisticsData] = useState({});
	const [statisticsTableData, setStatisticsTableData] = useState({});
	const [statisticsBucketData, setStatisticsBucketData] = useState({});
	const [totalCases, setTotalCases] = useState(0);
	const [lottieModalOpen, setLottieModalOpen] = useState(false);
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const speedometerWidth = windowSize.width < 575 ? 200 : 350; // Adjust width based on screen size
	const speedometerHeight = windowSize.width < 575 ? 100 : 220;
	const ringWidth = windowSize.width < 575 ? 20 : 50;
	const [statisticsFilters, setStatisticsFilters] = useState(
		defaultStatisticsFilters,
	);
	const [activeStatisticsTimeFilter, setActiveStatisticsTimeFilter] =
		useState('');
	const [statisticsDateRange, setStatisticsDateRange] = useState(null);
	const [tableFilters, setTableFilters] = useState(defaultTableFilters);
	const [activeTableTimeFilter, setActiveTableTimeFilter] = useState('');
	const [tableDateRange, setTableDateRange] = useState(null);
	const [scoreFilters, setScoreFilters] = useState(defaultScoreFilters);
	const [activeScoreTimeFilter, setActiveScoreTimeFilter] = useState('');
	const [scoreDateRange, setScoreDateRange] = useState(null);
	const [scoreCardLoading, setScoreCardLoading] = useState(false);
	const [insuranceTableLoading, setInsuranceTableLoading] = useState(false);
	const [statisticsGraphLoading, setStatisticsGraphLoading] = useState(false);

	const getGreeting = () => {
		const currentHour = moment().hour();

		if (currentHour >= 5 && currentHour < 12) {
			return 'Good Morning';
		} else if (currentHour >= 12 && currentHour < 17) {
			return 'Good Afternoon';
		} else {
			return 'Good Evening';
		}
	};

	const formatNumber = (cost) => {
		if (cost === 0) {
			return '0';
		}
		const costFloat = parseFloat(cost);
		const suffixes = ['K', 'M', 'B', 'T'];

		let formattedCost = costFloat;

		let suffix = '';
		for (const s of suffixes) {
			if (formattedCost < 1000) {
				break;
			}
			formattedCost /= 1000.0;
			suffix = s;
		}

		// Use parseFloat to remove trailing zeros after decimal
		return `${parseFloat(formattedCost.toFixed(2))}${suffix}`;
		//Old
		// return `${formattedCost.toFixed(2)}${suffix}`;
	};

	const renderComponentBasedOnPermission = () => {
		if (localStorage.getItem('role') === 'super-admin') return <></>;
		else if (checkModulePermissions('case-management').authorized) {
			return <CaseManagement displayHeader={true} />;
		} else if (checkModulePermissions('case-analysis').authorized) {
			return <CaseAnalysis displayHeader={true} />;
		} else if (checkModulePermissions('case-closure').authorized) {
			return <CaseClosure displayHeader={true} />;
		} else if (checkModulePermissions('appeal-letter').authorized) {
			return <AppealLetter displayHeader={true} />;
		} else if (checkModulePermissions('feedback-analysis').authorized) {
			return <FeedBackList displayHeader={true} />;
		} else {
			return <></>;
		}
	};

	useEffect(() => {
		getCaseChartDetails();
		getCaseAmountBucket();
		const hasPermission =
			checkModulePermissions('dashboard').sub_modules.includes(
				'view-case-amount',
			);
		if (hasPermission) {
			getTotalCaseAmount();
			// getInsuranceCompaniesStatistics();
			// getInsuranceCompaniesFilingStatusCounts();
			// getInsuranceCompaniesBucket();
		}
	}, []);

	useEffect(() => {
		if (getTotalCaseAmountState.apiState === 'success') {
			setCaseAmount((prevCaseAmount) => ({
				...prevCaseAmount,
				approvedAmount: getTotalCaseAmountState.data.approvedAmount,
				pendingAmount: getTotalCaseAmountState.data.pendingAmount,
				rejectedAmount: getTotalCaseAmountState.data.rejectedAmount,
			}));
			getTotalCaseAmountReset();
		}
	}, [getTotalCaseAmountState]);

	useEffect(() => {
		if (
			getCaseAmountBucketState.apiState === 'success' &&
			getCaseAmountBucketState.data
		) {
			setCaseAmount((prevCaseAmount) => ({
				...prevCaseAmount,
				...getCaseAmountBucketState.data,
			}));
			getCaseAmountBucketReset();
		}
	}, [getCaseAmountBucketState]);

	useEffect(() => {
		if (getCaseChartDetailsState.apiState === 'success') {
			setChartData(getCaseChartDetailsState.data.data.all_counts);
			setTotalCases(getCaseChartDetailsState.data.data.total);
		}
		getCaseChartDetailsReset();
	}, [getCaseChartDetailsState]);

	// Statistics Start

	// todo revert to original function when the database response will be fast
	// useEffect(() => {
	// 	if (Object.keys(statisticsFilters).length > 0) {
	// 		getInsuranceCompaniesStatistics(statisticsFilters);
	// 	}
	// }, [statisticsFilters]);

	const hardcodedOpenResponse = {
		open_cases: {
			amount: {
				totalAmount: 7673963,
				in_process: 7102773,
				at_risk: 169265,
				escalation: 15000,
				untimely: 386925,
			},
			filingStatusCounts: {
				in_process: 155,
				untimely: 27,
				escalation: 3,
				at_risk: 9,
			},
			filingStatusPercentages: {
				in_process: 79,
				untimely: 14,
				escalation: 2,
				at_risk: 5,
			},
			totalCases: 194,
		},
		close_cases: {
			approvedAndPaid: 0,
			approvedAndNotPaid: 0,
			rejectedAmount: 0,
			totalAmount: 7673963,
			approvedAndPaidCount: '0.00',
			approvedAndNotPaidCount: '0.00',
			rejectedCaseCount: '0.00',
		},
	};

	const hardcodedClosedResponses = {
		open_cases: {
			amount: {
				totalAmount: 793825,
				in_process: 353000,
				at_risk: 98000,
				escalation: 40000,
				untimely: 302825,
			},
			filingStatusCounts: {
				in_process: 9,
				untimely: 17,
				escalation: 4,
				at_risk: 8,
			},
			filingStatusPercentages: {
				in_process: 24,
				untimely: 44,
				escalation: 11,
				at_risk: 21,
			},
			totalCases: 38,
		},
		close_cases: {
			approvedAndPaid: 126857,
			approvedAndNotPaid: 15000,
			rejectedAmount: 577000,
			totalAmount: 793825,
			approvedAndPaidCount: '13.16',
			approvedAndNotPaidCount: '2.63',
			rejectedCaseCount: '81.58',
		},
	};

	useEffect(() => {
		if (Object.keys(statisticsFilters).length > 0) {
			// Check if only the status filter is present, and it's either "open" or "closed"
			if (
				Object.keys(statisticsFilters).length === 1 &&
				(statisticsFilters.status === 'open' ||
					statisticsFilters.status === 'closed')
			) {
				// Show loading state
				setStatisticsGraphLoading(true);
				const delay = setTimeout(() => {
					// Set hardcoded response based on status
					if (statisticsFilters.status === 'open') {
						setStatisticsGraphLoading(false);
						setStatisticsData(hardcodedOpenResponse);
					} else if (statisticsFilters.status === 'closed') {
						setStatisticsGraphLoading(false);
						setStatisticsData(hardcodedClosedResponses);
					}
				}, 1000); // 1 second delay
				// Clean up the timeout if the component unmounts or filters change
				return () => clearTimeout(delay);
			} else {
				// If there are additional filters, make the API call as usual
				getInsuranceCompaniesStatistics(statisticsFilters);
			}
		}
	}, [statisticsFilters]);

	const handleStatisticsFilterChange = (updatedFilter) => {
		setStatisticsFilters((prevFilters) => {
			const newFilters = { ...prevFilters, ...updatedFilter };

			// Remove keys with empty values
			Object.keys(newFilters).forEach((key) => {
				if (newFilters[key] === '') {
					delete newFilters[key];
				}
			});

			return newFilters;
		});
	};

	const handleStatisticsTimeFilterClick = (timeFilter) => {
		if (activeStatisticsTimeFilter === timeFilter) {
			return;
			// // If the same filter is clicked again, remove it
			// setActiveStatisticsTimeFilter('');
			// handleStatisticsFilterChange({
			// 	created_on: '',
			// 	year_to_date: '',
			// 	from: '',
			// 	to: '',
			// });
		} else {
			// If a different filter is clicked, activate it and deactivate others
			setActiveStatisticsTimeFilter(timeFilter);

			const updatedFilter = {
				created_on: '',
				year_to_date: '',
				from: '',
				to: '',
			};

			if (timeFilter === 'year_to_date') {
				updatedFilter.year_to_date = 'true';
			} else {
				updatedFilter.created_on = timeFilter;
			}

			handleStatisticsFilterChange(updatedFilter);
			setStatisticsDateRange(null); // Clear the date range selection
		}
	};

	const handleStatisticsDateRangeChange = (dates) => {
		if (dates && dates.length === 2) {
			setStatisticsDateRange(dates);
			handleStatisticsFilterChange({
				created_on: 'custom',
				from: dates[0],
				to: dates[1],
				year_to_date: '',
			});
			setActiveStatisticsTimeFilter('');
		} else {
			setStatisticsDateRange(null);
			handleStatisticsFilterChange({
				created_on: '',
				from: '',
				to: '',
			});
		}
	};

	const handleStatisticsClearFilters = () => {
		setStatisticsFilters({
			status: statisticsFilters.status,
		});
		setActiveStatisticsTimeFilter('');
		setStatisticsDateRange(null);
	};

	const statisticsFiltersApplied = Object.keys(statisticsFilters).some(
		(key) =>
			key !== 'status' &&
			statisticsFilters[key] &&
			statisticsFilters[key] !== defaultStatisticsFilters[key],
	);

	const statusSpeedometer = () => {
		const { in_process, at_risk, untimely } =
			statisticsData.filingStatusPercentages;
		const totalPercentages = in_process + at_risk + untimely;

		// Create segments array
		const segments = [
			{ key: 'in_process', value: in_process },
			{ key: 'at_risk', value: at_risk },
			{ key: 'untimely', value: untimely },
		];

		// Calculate custom segment stops
		const minWidth = 60; // Minimum width reserved for each segment
		const customSegmentStops = [
			0,
			...adjustSegmentStopsWithMinWidth(
				segments,
				totalPercentages,
				minWidth,
			),
		];

		const segmentColorsOpen = ['#1F9254', '#E5AA11', '#E53711'];
		const segmentColorsClosed = ['#2F8DFA', '#1FD0BF', '#D5001A'];
		const segmentColors =
			statisticsFilters.status === 'open'
				? segmentColorsOpen
				: segmentColorsClosed;

		// Update segment labels to have empty text if value is zero
		const customSegmentLabels = [
			{
				text: in_process !== 0 ? `${in_process}%` : '',
				position: 'INSIDE',
				color: '#FFF',
			},
			{
				text: at_risk !== 0 ? `${at_risk}%` : '',
				position: 'INSIDE',
				color: '#FFF',
			},
			{
				text: untimely !== 0 ? `${untimely}%` : '',
				position: 'INSIDE',
				color: '#FFF',
			},
		];

		return (
			<XReactSpeedometer
				forceRender={true}
				width={speedometerWidth}
				height={speedometerHeight}
				needleHeightRatio={''}
				customSegmentStops={customSegmentStops}
				segmentColors={segmentColors}
				currentValueText={' '}
				valueTextFontSize="20px"
				labelFontSize="13px"
				paddingHorizontal={0}
				paddingVertical={0}
				customSegmentLabels={customSegmentLabels}
				ringWidth={ringWidth}
				needleTransitionDuration={''}
				needleTransition="easeElastic"
				needleColor={'none'}
				textColor={'#000'}
			/>
		);
	};

	const adjustSegmentStopsWithMinWidth = (
		segments,
		totalPercentages,
		minWidth,
	) => {
		// Calculate total reserved width for segments with data
		const reservedWidth =
			minWidth * segments.filter((segment) => segment.value > 0).length;
		const remainingWidth = 1000 - reservedWidth;

		let cumulative = 0;
		return segments.map((segment, index) => {
			let allocatedWidth = 0;
			if (segment.value > 0) {
				allocatedWidth =
					(segment.value / totalPercentages) * remainingWidth +
					minWidth;
			} else {
				allocatedWidth =
					(segment.value / totalPercentages) * remainingWidth;
			}
			cumulative += allocatedWidth;
			return index === segments.length - 1 ? 1000 : cumulative;
		});
	};

	const amountSpeedometer = () => {
		const { in_process, at_risk, untimely } = statisticsData.amount;
		const totalPercentages = in_process + at_risk + untimely;

		// Create segments array
		const segments = [
			{ key: 'in_process', value: in_process },
			{ key: 'at_risk', value: at_risk },
			{ key: 'untimely', value: untimely },
		];

		// Calculate custom segment stops
		const minWidth = 150; // Minimum width reserved for each segment
		const customSegmentStops = [
			0,
			...adjustSegmentStopsWithMinWidth(
				segments,
				totalPercentages,
				minWidth,
			),
		];

		const segmentColorsOpen = ['#1F9254', '#E5AA11', '#E53711'];
		const segmentColorsClosed = ['#2F8DFA', '#1FD0BF', '#D5001A'];
		const segmentColors =
			statisticsFilters.status === 'open'
				? segmentColorsOpen
				: segmentColorsClosed;

		// Update segment labels to have empty text if value is zero
		const customSegmentLabels = [
			{
				text: in_process !== 0 ? formatNumber(in_process) : '',
				position: 'INSIDE',
				color: '#FFF',
			},
			{
				text: at_risk !== 0 ? formatNumber(at_risk) : '',
				position: 'INSIDE',
				color: '#FFF',
			},
			{
				text: untimely !== 0 ? formatNumber(untimely) : '',
				position: 'INSIDE',
				color: '#FFF',
			},
		];

		return (
			<XReactSpeedometer
				forceRender={true}
				width={speedometerWidth}
				height={speedometerHeight}
				needleHeightRatio={''}
				customSegmentStops={customSegmentStops}
				segmentColors={segmentColors}
				currentValueText={' '}
				labelFontSize="13px"
				valueTextFontSize="20px"
				paddingHorizontal={0}
				paddingVertical={0}
				customSegmentLabels={customSegmentLabels}
				ringWidth={ringWidth}
				needleTransitionDuration={''}
				needleTransition="easeElastic"
				needleColor={'none'}
				textColor={'#000'}
			/>
		);
	};

	const getStatisticsLegends = () => {
		if (statisticsFilters.status === 'open') {
			return [
				{ className: 'color1', text: 'In Process' },
				{ className: 'color2', text: 'At-Risk' },
				{ className: 'color3', text: 'Untimely' },
			];
		} else {
			return [
				{ className: 'color4', text: 'Approved and Paid' },
				{ className: 'color5', text: 'Approved but not Reimbursed' },
				{ className: 'color6', text: 'Rejected' },
			];
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [setWindowSize]);

	useEffect(() => {
		if (getInsuranceCompaniesStatisticsState.apiState === 'loading') {
			setStatisticsGraphLoading(true);
		}
		if (getInsuranceCompaniesStatisticsState.apiState === 'success') {
			setStatisticsData(getInsuranceCompaniesStatisticsState.data);
			setStatisticsGraphLoading(false);
		}
		if (getInsuranceCompaniesStatisticsState.apiState === 'error') {
			setStatisticsGraphLoading(false);
		}
		getInsuranceCompaniesStatisticsReset();
	}, [getInsuranceCompaniesStatisticsState]);
	//Statistics End

	// Table Start
	useEffect(() => {
		if (Object.keys(tableFilters).length > 0) {
			getInsuranceCompaniesFilingStatusCounts(tableFilters);
		}
	}, [tableFilters]);

	// function adjustTableGraphWidths(widths) {
	// 	const total = widths.reduce((sum, width) => sum + width, 0);
	// 	const roundedWidths = widths.map((width) => Math.round(width));
	// 	const roundedTotal = roundedWidths.reduce(
	// 		(sum, width) => sum + width,
	// 		0,
	// 	);

	// 	if (roundedTotal === 100) return roundedWidths;

	// 	const difference = 100 - roundedTotal;
	// 	roundedWidths[0] += difference; // Adjust the first item to ensure the total is 100
	// 	return roundedWidths;
	// }

	// function adjustTableGraphWidths(widths) {
	// 	let adjustedWidths = widths.map(width => {
	// 		if (width < 1) {
	// 			return 1; // Set width to 1% if it's less than 1%
	// 		} else {
	// 			return Math.floor(width); // Use only the integer part of the width
	// 		}
	// 	});

	// 	// Calculate the sum of the adjusted widths
	// 	const totalWidth = adjustedWidths.reduce((sum, width) => sum + width, 0);

	// 	// Calculate the difference to 100% and distribute it proportionally
	// 	const remainingWidth = 100 - totalWidth;

	// 	if (remainingWidth !== 0) {
	// 		const totalOriginalWidth = widths.reduce((sum, width) => sum + width, 0);
	// 		adjustedWidths = adjustedWidths.map((width, index) => {
	// 			const originalProportion = widths[index] / totalOriginalWidth;
	// 			return width + Math.round(originalProportion * remainingWidth);
	// 		});
	// 	}

	// 	return adjustedWidths;
	// }

	function adjustTableGraphWidths(widths) {
		let adjustedWidths = widths.map((width) => {
			if (width < 1) {
				return 1; // Set width to 1% if it's less than 1%
			} else {
				return Math.floor(width); // Use only the integer part of the width
			}
		});

		// Calculate the sum of the adjusted widths
		let totalWidth = adjustedWidths.reduce((sum, width) => sum + width, 0);

		// If there's a remaining percentage, distribute it
		if (totalWidth < 100) {
			const remainingWidth = 100 - totalWidth;

			// Distribute the remaining width proportionally
			adjustedWidths = adjustedWidths.map((width, index) => {
				if (index === 0) {
					return width + remainingWidth; // Add remainder to the first element
				}
				return width;
			});

			// Recalculate the total width to ensure it sums up to 100%
			totalWidth = adjustedWidths.reduce((sum, width) => sum + width, 0);
		}

		return adjustedWidths;
	}

	const handleTableFilterChange = (updatedFilter) => {
		setTableFilters((prevFilters) => {
			const newFilters = { ...prevFilters, ...updatedFilter };
			// Remove keys with empty values
			Object.keys(newFilters).forEach((key) => {
				if (newFilters[key] === '') {
					delete newFilters[key];
				}
			});
			return newFilters;
		});
	};

	const handleTableTimeFilterClick = (timeFilter) => {
		if (activeTableTimeFilter === timeFilter) {
			return;
			// // If the same filter is clicked again, remove it
			// setActiveTableTimeFilter('');
			// handleTableFilterChange({
			// 	created_on: '',
			// 	year_to_date: '',
			// 	from: '',
			// 	to: '',
			// });
		} else {
			// If a different filter is clicked, activate it and deactivate others
			setActiveTableTimeFilter(timeFilter);

			const updatedFilter = {
				created_on: '',
				year_to_date: '',
				from: '',
				to: '',
			};

			if (timeFilter === 'year_to_date') {
				updatedFilter.year_to_date = 'true';
			} else {
				updatedFilter.created_on = timeFilter;
			}

			handleTableFilterChange(updatedFilter);
			setTableDateRange(null); // Clear the date range selection
		}
	};

	const handleTableDateRangeChange = (dates) => {
		if (dates && dates.length === 2) {
			setTableDateRange(dates);
			handleTableFilterChange({
				created_on: 'custom',
				from: dates[0],
				to: dates[1],
				year_to_date: '',
			});
			setActiveTableTimeFilter('');
		} else {
			setTableDateRange(null);
			handleTableFilterChange({
				created_on: '',
				from: '',
				to: '',
			});
		}
	};

	const handleTableClearFilters = () => {
		setTableFilters({
			status: tableFilters.status,
		});
		setActiveTableTimeFilter('');
		setTableDateRange(null);
	};

	const tableFiltersApplied = Object.keys(tableFilters).some(
		(key) =>
			key !== 'status' &&
			tableFilters[key] &&
			tableFilters[key] !== defaultTableFilters[key],
	);

	const getGraphColorClassNames = () => {
		if (tableFilters.status === 'open') {
			return ['step1', 'step2', 'step3'];
		} else {
			return ['step4', 'step5', 'step6'];
		}
	};

	const getTableLegends = () => {
		if (tableFilters.status === 'open') {
			return [
				{ className: 'color1', text: 'In Process' },
				{ className: 'color2', text: 'At-Risk' },
				{ className: 'color3', text: 'Untimely' },
			];
		} else {
			return [
				{ className: 'color4', text: 'Approved and Paid' },
				{ className: 'color5', text: 'Approved but not Reimbursed' },
				{ className: 'color6', text: 'Rejected' },
			];
		}
	};

	const graphColor = getGraphColorClassNames();

	const columns = [
		{
			title: 'Insurance Payer',
			dataIndex: 'company_name',
		},
		{
			title: '% Rejected',
			dataIndex: 'rejection',
			width: '12%',
		},
		{
			title: '% Overturned',
			dataIndex: 'overturned',
			width: '12%',
		},
		{
			title: (
				<>
					<Space>
						case status
						<Tooltip
							id="tooltip-card"
							title={
								<Row
									gutter={(15, 15)}
									className="align-items-center "
								>
									<Col xs={24} className="text-center">
										<ul className="markData">
											{getTableLegends().map(
												(label, index) => (
													<li key={index}>
														<em
															className={
																label.className
															}
														>
															&nbsp;
														</em>{' '}
														{label.text}
													</li>
												),
											)}
										</ul>
									</Col>
								</Row>
							}
						>
							<InfoCircleOutlined />
						</Tooltip>
					</Space>
				</>
			),
			dataIndex: 'graph1',
			align: 'center',
			width: '30%',
		},
		{
			title: (
				<>
					<Space>
						Cases value
						<Tooltip
							id="tooltip-card"
							title={
								<Row
									gutter={(15, 15)}
									className="align-items-center "
								>
									<Col xs={24} className="text-center">
										<ul className="markData">
											{getTableLegends().map(
												(label, index) => (
													<li key={index}>
														<em
															className={
																label.className
															}
														>
															&nbsp;
														</em>{' '}
														{label.text}
													</li>
												),
											)}
										</ul>
									</Col>
								</Row>
							}
						>
							<InfoCircleOutlined />
						</Tooltip>
					</Space>
				</>
			),
			dataIndex: 'graph2',
			align: 'center',
			width: '30%',
		},
	];

	const data =
		statisticsTableData &&
		statisticsTableData.insuranceCompanies?.map((company, index) => {
			const totalCasesCount =
				company.filingStatusCounts.in_process +
				company.filingStatusCounts.at_risk +
				company.filingStatusCounts.untimely;

			const totalAmount =
				company.amount.in_process +
				company.amount.at_risk +
				company.amount.untimely;

			const caseWidths = adjustTableGraphWidths([
				(company.filingStatusCounts.in_process / totalCasesCount) * 100,
				(company.filingStatusCounts.at_risk / totalCasesCount) * 100,
				(company.filingStatusCounts.untimely / totalCasesCount) * 100,
			]);

			const amountWidths = adjustTableGraphWidths([
				(company.amount.in_process / totalAmount) * 100,
				(company.amount.at_risk / totalAmount) * 100,
				(company.amount.untimely / totalAmount) * 100,
			]);
			return {
				key: index.toString(),
				company_name: company.insuranceCompanyName,
				rejection:
					company.rejectedPercentage !== null
						? `${company.rejectedPercentage}%`
						: '-',
				overturned:
					company.overturnedPercentage !== null
						? `${company.overturnedPercentage}%`
						: '-',
				graph1: (
					<>
						<ul className="barGraph">
							{totalCasesCount ? (
								<>
									{company.filingStatusCounts.in_process >
										0 && (
										<Tooltip
											title={
												company.filingStatusCounts
													.in_process
											}
										>
											<li
												style={{
													width: `${caseWidths[0]}%`,
												}}
												className={graphColor[0]}
											>
												<span>
													{
														company
															.filingStatusCounts
															.in_process
													}
												</span>
											</li>
										</Tooltip>
									)}
									{company.filingStatusCounts.at_risk > 0 && (
										<Tooltip
											title={
												company.filingStatusCounts
													.at_risk
											}
										>
											<li
												style={{
													width: `${caseWidths[1]}%`,
												}}
												className={graphColor[1]}
											>
												<span>
													{
														company
															.filingStatusCounts
															.at_risk
													}
												</span>
											</li>
										</Tooltip>
									)}
									{company.filingStatusCounts.untimely >
										0 && (
										<Tooltip
											title={
												company.filingStatusCounts
													.untimely
											}
										>
											<li
												style={{
													width: `${caseWidths[2]}%`,
												}}
												className={graphColor[2]}
											>
												<span>
													{
														company
															.filingStatusCounts
															.untimely
													}
												</span>
											</li>
										</Tooltip>
									)}
								</>
							) : (
								<li className="w-100">0</li>
							)}
						</ul>
					</>
				),
				graph2: (
					<>
						<ul className="barGraph">
							{totalAmount ? (
								<>
									{company.amount.in_process > 0 && (
										<Tooltip
											title={`$${formatNumber(
												company.amount.in_process,
											)}`}
										>
											<li
												style={{
													width: `${amountWidths[0]}%`,
												}}
												className={graphColor[0]}
											>
												<span>
													$
													{formatNumber(
														company.amount
															.in_process,
													)}
												</span>
											</li>
										</Tooltip>
									)}
									{company.amount.at_risk > 0 && (
										<Tooltip
											title={`$${formatNumber(
												company.amount.at_risk,
											)}`}
										>
											<li
												style={{
													width: `${amountWidths[1]}%`,
												}}
												className={graphColor[1]}
											>
												<span>
													$
													{formatNumber(
														company.amount.at_risk,
													)}
												</span>
											</li>
										</Tooltip>
									)}
									{company.amount.untimely > 0 && (
										<Tooltip
											title={`$${formatNumber(
												company.amount.untimely,
											)}`}
										>
											<li
												style={{
													width: `${amountWidths[2]}%`,
												}}
												className={graphColor[2]}
											>
												<span>
													$
													{formatNumber(
														company.amount.untimely,
													)}
												</span>
											</li>
										</Tooltip>
									)}
								</>
							) : (
								<li className="w-100">0</li>
							)}
						</ul>
					</>
				),
			};
		});

	useEffect(() => {
		if (
			getInsuranceCompaniesFilingStatusCountsState.apiState === 'loading'
		) {
			setInsuranceTableLoading(true);
		}
		if (
			getInsuranceCompaniesFilingStatusCountsState.apiState === 'success'
		) {
			setStatisticsTableData(
				getInsuranceCompaniesFilingStatusCountsState.data,
			);
			setInsuranceTableLoading(false);
		}
		if (getInsuranceCompaniesFilingStatusCountsState.apiState === 'error') {
			setInsuranceTableLoading(false);
		}
		getInsuranceCompaniesFilingStatusCountsReset();
	}, [getInsuranceCompaniesFilingStatusCountsState]);
	// Table End

	// Score Start
	useEffect(() => {
		if (Object.keys(scoreFilters).length > 0) {
			getInsuranceCompaniesBucket(scoreFilters);
		}
	}, [scoreFilters]);

	const handleScoreFilterChange = (updatedFilter) => {
		setScoreFilters((prevFilters) => {
			const newFilters = { ...prevFilters, ...updatedFilter };

			// Remove keys with empty values
			Object.keys(newFilters).forEach((key) => {
				if (newFilters[key] === '') {
					delete newFilters[key];
				}
			});

			return newFilters;
		});
	};

	const handleScoreTimeFilterClick = (timeFilter) => {
		if (activeScoreTimeFilter === timeFilter) {
			return;
			// // If the same filter is clicked again, remove it
			// setActiveScoreTimeFilter('');
			// handleScoreFilterChange({
			// 	created_on: '',
			// 	year_to_date: '',
			// 	from: '',
			// 	to: '',
			// });
		} else {
			// If a different filter is clicked, activate it and deactivate others
			setActiveScoreTimeFilter(timeFilter);

			const updatedFilter = {
				created_on: '',
				year_to_date: '',
				from: '',
				to: '',
			};

			if (timeFilter === 'year_to_date') {
				updatedFilter.year_to_date = 'true';
			} else {
				updatedFilter.created_on = timeFilter;
			}

			handleScoreFilterChange(updatedFilter);
			setTableDateRange(null); // Clear the date range selection
		}
	};

	const handleScoreDateRangeChange = (dates) => {
		if (dates && dates.length === 2) {
			setScoreDateRange(dates);
			handleScoreFilterChange({
				created_on: 'custom',
				from: dates[0],
				to: dates[1],
				year_to_date: '',
			});
			setActiveScoreTimeFilter('');
		} else {
			setScoreDateRange(null);
			handleScoreFilterChange({
				created_on: '',
				from: '',
				to: '',
			});
		}
	};

	const handleScoreClearFilters = () => {
		setScoreFilters({
			status: scoreFilters.status,
		});
		setActiveScoreTimeFilter('');
		setScoreDateRange(null);
	};

	const scoreFiltersApplied = Object.keys(scoreFilters).some(
		(key) =>
			key !== 'status' &&
			scoreFilters[key] &&
			scoreFilters[key] !== defaultTableFilters[key],
	);

	const getScoreCardParameterClassName = (percentage, is_negative) => {
		if (is_negative) percentage = 100 - percentage;
		if (percentage <= 25) {
			return 'list2Color1'; // Red color class
		} else if (percentage > 25 && percentage <= 50) {
			return 'list2Color2'; // Orange color class
		} else if (percentage > 50 && percentage <= 75) {
			return 'list2Color3'; // Yellow color class
		} else if (percentage > 75) {
			return 'list2Color4'; // Green color class
		} else {
			return 'list2Color1'; // Red color class
		}
	};

	const getScoreCardHeaderClassName = (percentage) => {
		if (percentage < 20) {
			return 'headerColor1'; // Red color class
		} else if (percentage >= 20 && percentage < 50) {
			return 'headerColor2'; // Orange color class
		} else if (percentage >= 50 && percentage < 80) {
			return 'headerColor3'; // Yellow color class
		} else if (percentage > 80) {
			return 'headerColor4'; // Green color class
		} else {
			return 'headerColor1'; // Red color class
		}
	};

	const renderInsuranceCards = () => {
		if (!statisticsBucketData.insuranceCompanies && !scoreCardLoading) {
			return (
				<Skeleton
					active
					paragraph={{
						rows: 4,
					}}
				/>
			);
		}

		return (
			statisticsBucketData &&
			statisticsBucketData.insuranceCompanies &&
			statisticsBucketData.insuranceCompanies.map((company, index) => (
				<Col key={index} xs={24} sm={12} md={12} lg={8} xl={6}>
					<XPyersCard loading={scoreCardLoading}>
						<Space>
							<img
								src={`${config.s3_url}${company.insuranceCompanyLogo?.url}`}
								alt={'icon here'}
								style={{ width: '52px', height: '52px' }}
							/>
							<div className="ms-1 d-block headerData">
								<span>Overall Score</span>
								<br />
								<em
									className={getScoreCardHeaderClassName(
										company.overallScore || 0,
									)}
								>
									{company.overallScore || 0}{' '}
								</em>
							</div>
						</Space>
						<Row className="mt-3">
							<Col xs={24}>
								<h6
									style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{company.insuranceCompanyName}
								</h6>
							</Col>
						</Row>
						<Row>
							<Col xs={24}>
								<h6 className="overallScoreList2">
									% Timely Appeals Processing{' '}
									<Tooltip title="This is the percentage of cases that were processed on time.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>
									<span
										className={getScoreCardParameterClassName(
											company.timely_processing || 0,
											false,
										)}
									>
										{company.timely_processing || 0}%
									</span>
								</h6>
								<h6 className="overallScoreList2">
									% Paid Timely{' '}
									<Tooltip title="This is the percentage of cases that were paid on time.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>
									<span
										className={getScoreCardParameterClassName(
											company.timely_paid || 0,
											false,
										)}
									>
										{company.timely_paid || 0}%
									</span>
								</h6>
								<h6 className="overallScoreList2">
									% Upheld{' '}
									<Tooltip title="This is the percentage of cases that were rejected and remain rejected after review.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>{' '}
									<span
										className={getScoreCardParameterClassName(
											company.upheldCountPercentage || 0,
											true,
										)}
									>
										{company.upheldCountPercentage || 0}%
									</span>
								</h6>
								<h6 className="overallScoreList2">
									% Overturned{' '}
									<Tooltip title="This is the percentage of cases that were initially rejected but then approved at a later stage.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>{' '}
									<span
										className={getScoreCardParameterClassName(
											company.overturnedPercentage || 0,
											true,
										)}
									>
										{company.overturnedPercentage || 0}%
									</span>
								</h6>
								<h6 className="overallScoreList2">
									% Denied{' '}
									<Tooltip title="This is the percentage of cases that were rejected at any stage in the process, regardless of the final outcome.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>{' '}
									<span
										className={getScoreCardParameterClassName(
											company.rejectionPercentage || 0,
											true,
										)}
									>
										{company.rejectionPercentage || 0}%
									</span>
								</h6>
								<h6 className="overallScoreList2">
									Initial Denial %{' '}
									<Tooltip title="This is the percentage of cases that were rejected at the initial stage, known as the organization determination stage.">
										<InfoCircleOutlined className="dashboard-tooltip-css" />
									</Tooltip>{' '}
									<span
										className={getScoreCardParameterClassName(
											company.initialRejectionPercentage ||
												0,
											true,
										)}
									>
										{company.initialRejectionPercentage ||
											0}
										%
									</span>
								</h6>
							</Col>
						</Row>
						<Row className="my-3">
							<Col xs={24}>
								<h5>Pending by Number of Cases</h5>
							</Col>
						</Row>
						<Row>
							<Col xs={24}>
								<h6 className="overallScoreList listColor2 mb-1">
									Pre-service{' '}
									<span>
										{company.revenueCycleCount
											.pre_service_count || 0}
									</span>
								</h6>
								<h6 className="overallScoreList listColor2 mb-1">
									Post Claim{' '}
									<span>
										{company.revenueCycleCount
											.post_claim_count || 0}
									</span>
								</h6>
								<h6 className="overallScoreList listColor2 mb-1">
									Part B Drugs{' '}
									<span>
										{company.revenueCycleCount
											.part_b_drugs_count || 0}
									</span>
								</h6>
							</Col>
						</Row>
						<Row className="my-3">
							<Col xs={24}>
								<h5>Pending by Dollar Value</h5>
							</Col>
						</Row>
						<Row>
							<Col xs={24}>
								<h6 className="overallScoreList listColor3 mb-1">
									Pre-service{' '}
									<span>
										$
										{formatNumber(
											company.revenueCycleAmount
												.pre_service_amount || 0,
										)}
									</span>
								</h6>
								<h6 className="overallScoreList listColor3 mb-1">
									Post Claim{' '}
									<span>
										$
										{formatNumber(
											company.revenueCycleAmount
												.post_claim_amount || 0,
										)}
									</span>
								</h6>
								<h6 className="overallScoreList listColor3 mb-1">
									Part B Drugs{' '}
									<span>
										$
										{formatNumber(
											company.revenueCycleAmount
												.part_b_drugs_amount || 0,
										)}
									</span>
								</h6>
							</Col>
						</Row>
					</XPyersCard>
				</Col>
			))
		);
	};

	useEffect(() => {
		if (getInsuranceCompaniesBucketState.apiState === 'loading') {
			setScoreCardLoading(true);
		}
		if (getInsuranceCompaniesBucketState.apiState === 'success') {
			setStatisticsBucketData(getInsuranceCompaniesBucketState.data);
			setScoreCardLoading(false);
		}
		if (getInsuranceCompaniesBucketState.apiState === 'error') {
			setScoreCardLoading(false);
		}
		getInsuranceCompaniesBucketReset();
	}, [getInsuranceCompaniesBucketState]);
	// Score End

	const statusData =
		statisticsFilters.status === 'open'
			? statisticsData.open_cases?.filingStatusPercentages || {}
			: statisticsData.close_cases || {};

	const amountData =
		statisticsFilters.status === 'open'
			? statisticsData.open_cases?.amount || {}
			: statisticsData.close_cases || {};

	const statusChartData =
		statisticsFilters.status === 'open'
			? [statusData.in_process, statusData.at_risk, statusData.untimely]
			: [
					statusData.approvedAndPaidCount,
					statusData.approvedAndNotPaidCount,
					statusData.rejectedCaseCount,
			  ];

	const amountChartData =
		statisticsFilters.status === 'open'
			? [amountData.in_process, amountData.at_risk, amountData.untimely]
			: [
					amountData.approvedAndPaid,
					amountData.approvedAndNotPaid,
					amountData.rejectedAmount,
			  ];

	const statusColors =
		statisticsFilters.status === 'open'
			? ['#1F9254', '#E5AA11', '#E53711']
			: ['#2F8DFA', '#1FD0BF', '#D5001A'];

	const amountColors = statusColors;

	return (
		<>
			{/* Lottie */}
			<XModalAnimation
				//title=" "
				centered
				open={lottieModalOpen}
				onOk={() => setLottieModalOpen(false)}
				onCancel={() => setLottieModalOpen(false)}
				footer={false}
				maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
			>
				<XLottieLoader className="mb-4">
					<XLottie animationData={LoaderJson} loop={true} />
				</XLottieLoader>

				<Card>
					<ul>
						<li className="active">
							<span>
								<img
									width="15px"
									src="images/loader-icon1.svg"
								/>
							</span>
							Analysing Clinical Summary
						</li>
						<li className="active">
							<span>
								<img
									width="15px"
									src="images/loader-icon2.svg"
								/>
							</span>
							Mapping ICD 10 Codes
						</li>
						<li className="active">
							<span>
								<img
									width="15px"
									src="images/loader-icon3.svg"
								/>
							</span>
							Mapping CPT Codes
						</li>
						<li className="active">
							<span>
								<img
									width="15px"
									src="images/loader-icon4.svg"
								/>
							</span>
							Checking Coverage Determination
						</li>
						<li className="active">
							<span>
								<img
									width="15px"
									src="images/loader-icon5.svg"
								/>
							</span>
							Checking for Missing Inclusions
						</li>
						<li className="inprocess">
							<span>
								<img
									width="15px"
									src="images/loader-icon6.svg"
								/>
							</span>
							Generating Results
						</li>
					</ul>
					<div class="laser"></div>
				</Card>
			</XModalAnimation>

			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Dashboard" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="dashboard" />
				</Col>
			</>

			<XContainer fluid className="mb-4">
				<XCardBg>
					<Row gutter={(15, 15)} className="align-items-center">
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 24 }}
							lg={{ span: 24 }}
							xl={{ span: 6 }}
							className="mb-4  mb-lg-0 mt-3"
						>
							<XLottieWrapp className="mb-4">
								<XLottie
									animationData={DashboardJson}
									loop={true}
								/>
							</XLottieWrapp>
							<div className="date mt-2">{getDate()}</div>
							<Title>{`${getGreeting()} ${getUserName()}`}</Title>
							{/* <Title2>
								Hi, you have received{' '}
								<span>10 Notifications</span>
							</Title2> */}
						</Col>
						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={12}
							lg={12}
							xl={
								checkModulePermissions(
									'dashboard',
								).sub_modules.includes('view-case-amount')
									? 6
									: 12
							}
							className="mb-4 mb-lg-0 mt-3"
						>
							<XCardData3
								style={{
									height: '350px',
									textAlign: 'center',
								}}
							>
								{totalCases > 0 ? (
									<>
										<Col
											xs={24}
											className="mb-2 text-center"
										>
											<div className="headingMain">
												Total Cases
											</div>
										</Col>{' '}
										<div className="donut-amount">
											Total
											<br />
											{totalCases}
										</div>
										<DonutChart data={chartData} />
										<span
											style={{
												fontSize: '10px',
												color: 'grey',
											}}
										>
											Note: The displayed cases are based
											on your access privileges.
										</span>
									</>
								) : (
									<>
										<img
											className="img-fluid dashboard-img"
											src="./images/no_cases.svg"
											alt=""
										/>
										<div className="subHeading mt-4">
											No cases assigned to you at the
											moment.
										</div>
									</>
								)}
							</XCardData3>
						</Col>
						{checkModulePermissions(
							'dashboard',
						).sub_modules.includes('view-case-amount') ? (
							<Col
								xs={{ span: 24 }}
								sm={{ span: 12 }}
								md={{ span: 12 }}
								lg={{ span: 12 }}
								xl={{ span: 6 }}
								className="mb-4 mb-sm-0 mb-lg-0 mt-3"
							>
								<XCardData>
									{getTotalCaseAmountState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Approved Case Amount
												</div>
												<div className="headingMain">
													$
													{caseAmount.approvedAmount
														? formatNumber(
																caseAmount.approvedAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
								<XCardData className="my-4">
									{getTotalCaseAmountState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Rejected Case Amount
												</div>
												<div className="headingMain">
													$
													{caseAmount.rejectedAmount
														? formatNumber(
																caseAmount.rejectedAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/total-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
								<XCardData>
									{getTotalCaseAmountState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													In Progress Case Amount
												</div>
												<div className="headingMain">
													$
													{caseAmount.pendingAmount
														? formatNumber(
																caseAmount.pendingAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/pending-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>
						) : (
							<></>
						)}

						<Col
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							md={{ span: 12 }}
							lg={{ span: 12 }}
							xl={{ span: 6 }}
							className="mb-4  mb-lg-0 mt-3"
						>
							<XCardData2
								style={{
									height: '350px',
									textAlign: 'center',
								}}
							>
								<Row className="align-items-center">
									<Col xs={24} className="mb-4 text-center">
										<img
											className="img-fluid"
											src="./images/new-case.svg"
											alt=""
										/>
									</Col>
									{checkModulePermissions('case-management')
										.authorized ? (
										<Col xs={24} className="text-center">
											<div className="mb-2 headingMain">
												Create New Case
											</div>
											<div className="subHeading my-4">
												Open a Case to Begin Medical
												Coverage Eligibility Journey
											</div>
											<XButton
												onClick={() =>
													navigate('/case/add')
												}
											>
												Create Case
											</XButton>
										</Col>
									) : (
										<Col xs={24} className="text-center">
											<div className="mb-2 headingMain">
												Close Case
											</div>
											<div className="subHeading my-4">
												Conclude the Case for Completion
												of Medicare Coverage Eligibility
												Journey.
											</div>
											<XButton
												onClick={() =>
													navigate('/case-closure')
												}
											>
												Close Case
											</XButton>
										</Col>
									)}
								</Row>
							</XCardData2>
						</Col>
					</Row>
				</XCardBg>
			</XContainer>

			{/* Statistics */}
			{checkModulePermissions('dashboard').sub_modules.includes(
				'view-insurance-statistics',
			) ? (
				<XContainer fluid className="mb-4">
					<XCardBg>
						<Row gutter={[10]} className="align-items-center mb-3">
							<Col span={24}>
								<h1>Statistics</h1>
							</Col>
						</Row>
						<Row gutter={[10]} className="align-items-center">
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								xl={6}
								className="mb-4"
							>
								<SwitchBg>
									<XRadio.Group
										onChange={(e) =>
											handleStatisticsFilterChange({
												status: e.target.value,
											})
										}
										name="status"
										defaultValue={'open'}
									>
										<XRadio className="mt-0" value={'open'}>
											Open Cases
										</XRadio>
										<XRadio
											className="mt-0"
											value={'closed'}
										>
											Closed Cases
										</XRadio>
									</XRadio.Group>
								</SwitchBg>
							</Col>
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								xl={5}
								className="mb-4"
							>
								<XSelectCategories
									size="large"
									placeholder="Insurance Provider"
									showSearch={true}
									onFocus={() => {
										getInsuranceDropdown();
									}}
									value={statisticsFilters.insuranceCompany}
									onSearch={(search) => {
										if (search.length >= 3) {
											getInsuranceDropdown({
												search,
											});
										} else if (search.length === 0) {
											getInsuranceDropdown();
										}
									}}
									allowClear
									filterOption={filterByLabel}
									options={
										getInsuranceDropdownState.apiState ===
											'success' &&
										getInsuranceDropdownState.data?.length >
											0 &&
										getInsuranceDropdownState.data.map(
											(itm) => {
												return {
													label: itm.name,
													value: itm._id,
												};
											},
										)
									}
									onChange={(value) =>
										handleStatisticsFilterChange({
											insuranceCompany: value,
										})
									}
								/>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={13}
								className="mb-4 text-center text-lg-end d-flex flex-wrap row-gap-2"
							>
								<XButtonSeg
									size="large"
									style={{
										marginLeft: '10px',
										background: !statisticsFiltersApplied
											? 'rgba(0, 0, 0, 0.04)'
											: '#FFFFFF',
										color: !statisticsFiltersApplied
											? '#D3D3D3'
											: '#000',
									}}
									disabled={!statisticsFiltersApplied}
									onClick={handleStatisticsClearFilters}
								>
									Clear Filters
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeStatisticsTimeFilter ===
											'last_week'
												? '#000'
												: '#FFFFFF',
										color:
											activeStatisticsTimeFilter ===
											'last_week'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleStatisticsTimeFilterClick(
											'last_week',
										)
									}
								>
									Weekly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeStatisticsTimeFilter ===
											'last_month'
												? '#000'
												: '#FFFFFF',
										color:
											activeStatisticsTimeFilter ===
											'last_month'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleStatisticsTimeFilterClick(
											'last_month',
										)
									}
								>
									Monthly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeStatisticsTimeFilter ===
											'year_to_date'
												? '#000'
												: '#FFFFFF',
										color:
											activeStatisticsTimeFilter ===
											'year_to_date'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleStatisticsTimeFilterClick(
											'year_to_date',
										)
									}
								>
									Year to Date
								</XButtonSeg>
								<DatePicker.RangePicker
									size="large"
									className="dashboard-picker"
									allowClear
									value={statisticsDateRange}
									disabledDate={(current) =>
										current &&
										current > moment().endOf('day')
									}
									onChange={handleStatisticsDateRangeChange}
								/>
							</Col>
						</Row>
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24} sm={24} lg={12} className="mb-4">
								<XCardData4 loading={statisticsGraphLoading}>
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										{statisticsData &&
										statisticsData.open_cases
											?.filingStatusPercentages ? (
											<>
												<Col xs={24}>
													<h1>Case Status</h1>
												</Col>
												<Col xs={24}>
													<HalfDonutChart
														data={statusChartData}
														colors={statusColors}
														legends={getStatisticsLegends()}
														cutout="50%"
														type="cases"
													/>
													<div className="dataValue">
														Total
														<br />
														<span>
															{
																statisticsData
																	.open_cases
																	?.totalCases
															}
														</span>
													</div>
												</Col>
											</>
										) : (
											<Skeleton
												active
												paragraph={{
													rows: 3,
												}}
											/>
										)}
									</Row>
								</XCardData4>
							</Col>
							<Col xs={24} sm={24} lg={12} className="mb-4">
								<XCardData4 loading={statisticsGraphLoading}>
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										{statisticsData &&
										statisticsData.open_cases?.amount ? (
											<>
												<Col xs={24}>
													<h1>Case Value ($)</h1>
												</Col>
												<Col xs={24}>
													<HalfDonutChart
														data={amountChartData}
														colors={amountColors}
														legends={getStatisticsLegends()}
														type="amount"
													/>
													<div className="dataValue">
														Total
														<br />
														<span>
															{statisticsData
																.open_cases
																?.amount
																?.totalAmount &&
																formatNumber(
																	statisticsData
																		.open_cases
																		.amount
																		.totalAmount,
																)}
														</span>
													</div>
												</Col>
											</>
										) : (
											<Skeleton
												active
												paragraph={{
													rows: 3,
												}}
											/>
										)}
									</Row>
								</XCardData4>
							</Col>
						</Row>
					</XCardBg>
				</XContainer>
			) : (
				<></>
			)}
			{checkModulePermissions('dashboard').sub_modules.includes(
				'view-reimbursement',
			) ? (
				<XContainer fluid className="mb-4">
					<XCardBg>
						<Row gutter={[10]} className="align-items-center mb-3">
							<Col span={24}>
								<h1>Reimbursement</h1>
							</Col>
						</Row>
						<Row gutter={[10]}>
							<Col
								className="mb-4  mb-lg-0 mt-3"
								// style={{ width: '20%' }}
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={6}
								xxl={4}
							>
								<XCardData>
									{getCaseAmountBucketState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Case Amount
												</div>
												<div className="headingMain">
													$
													{caseAmount.totalCaseAmount
														? formatNumber(
																caseAmount.totalCaseAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>
							<Col
								className="mb-4  mb-lg-0 mt-3"
								// style={{ width: '20%' }}
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={6}
								xxl={4}
							>
								<XCardData>
									{getCaseAmountBucketState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Approved Claims
												</div>
												<div className="headingMain">
													$
													{caseAmount.totalClaimAmount
														? formatNumber(
																caseAmount.totalClaimAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>
							<Col
								className="mb-4  mb-lg-0 mt-3"
								// style={{ width: '20%' }}
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={6}
								xxl={4}
							>
								<XCardData>
									{getCaseAmountBucketState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Reimbursed
												</div>
												<div className="headingMain">
													$
													{caseAmount.approvedClaimAmountMatchingDates
														? formatNumber(
																caseAmount.approvedClaimAmountMatchingDates,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>
							<Col
								className="mb-4  mb-lg-0 mt-3"
								// style={{ width: '20%' }}
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={6}
								xxl={4}
							>
								<XCardData>
									{getCaseAmountBucketState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Pending Reimbursements
												</div>
												<div className="headingMain">
													$
													{caseAmount.approvedClaimAmountReimbursedNull
														? formatNumber(
																caseAmount.approvedClaimAmountReimbursedNull,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>

							<Col
								className="mb-4 mb-lg-0 mt-3"
								// style={{ width: '20%' }}
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={6}
								xxl={4}
							>
								<XCardData>
									{getCaseAmountBucketState.apiState ===
									'loading' ? (
										<Skeleton
											active
											avatar
											paragraph={{
												rows: 1,
											}}
										/>
									) : (
										<Row className="align-items-center">
											<Col xs={18}>
												<div className="subHeading">
													Pending Claims Interest
												</div>
												<div className="headingMain">
													$
													{caseAmount.totalInterestAmount
														? formatNumber(
																caseAmount.totalInterestAmount,
														  )
														: 0}
												</div>
											</Col>
											<Col xs={6} className="text-center">
												<img
													className="img-fluid"
													src="./images/complete-cases.svg"
													alt=""
												/>
											</Col>
										</Row>
									)}
								</XCardData>
							</Col>
						</Row>
					</XCardBg>
				</XContainer>
			) : (
				<></>
			)}
			{/* Table Data */}
			{checkModulePermissions('dashboard').sub_modules.includes(
				'view-insurance-table',
			) ? (
				<XContainer fluid className="mb-4">
					<XCardNonBg>
						<Row gutter={[10]} className="align-items-center">
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								xl={6}
								className="mb-4"
							>
								<SwitchBg>
									<XRadio.Group
										onChange={(e) =>
											handleTableFilterChange({
												status: e.target.value,
											})
										}
										name="status"
										defaultValue={'open'}
									>
										<XRadio className="mt-0" value={'open'}>
											Open Cases
										</XRadio>
										<XRadio
											className="mt-0"
											value={'closed'}
										>
											Closed Cases
										</XRadio>
									</XRadio.Group>
								</SwitchBg>
							</Col>
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								xl={5}
								className="mb-4"
							>
								<XSelectCategories
									size="large"
									placeholder="Insurance Provider"
									onChange={(value) =>
										handleTableFilterChange({
											insuranceCompany: value,
										})
									}
									onFocus={() => {
										getInsuranceDropdown();
									}}
									onSearch={(search) => {
										if (search.length >= 3) {
											getInsuranceDropdown({
												search,
											});
										} else if (search.length === 0) {
											getInsuranceDropdown();
										}
									}}
									value={tableFilters.insuranceCompany}
									allowClear
									filterOption={filterByLabel}
									options={
										getInsuranceDropdownState.apiState ===
											'success' &&
										getInsuranceDropdownState.data?.length >
											0 &&
										getInsuranceDropdownState.data.map(
											(itm) => {
												return {
													label: itm.name,
													value: itm._id,
												};
											},
										)
									}
								/>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={13}
								className="mb-4 text-center text-lg-end d-flex flex-wrap row-gap-2"
							>
								<XButtonSeg
									size="large"
									style={{
										marginLeft: '10px',
										background: !tableFiltersApplied
											? 'rgba(0, 0, 0, 0.04)'
											: '#FFFFFF',
										color: !tableFiltersApplied
											? '#D3D3D3'
											: '#000',
									}}
									disabled={!tableFiltersApplied}
									onClick={handleTableClearFilters}
								>
									Clear Filters
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeTableTimeFilter ===
											'last_week'
												? '#000'
												: '#FFFFFF',
										color:
											activeTableTimeFilter ===
											'last_week'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleTableTimeFilterClick('last_week')
									}
								>
									Weekly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeTableTimeFilter ===
											'last_month'
												? '#000'
												: '#FFFFFF',
										color:
											activeTableTimeFilter ===
											'last_month'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleTableTimeFilterClick('last_month')
									}
								>
									Monthly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeTableTimeFilter ===
											'year_to_date'
												? '#000'
												: '#FFFFFF',
										color:
											activeTableTimeFilter ===
											'year_to_date'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleTableTimeFilterClick(
											'year_to_date',
										)
									}
								>
									Year to Date
								</XButtonSeg>
								<DatePicker.RangePicker
									size="large"
									value={tableDateRange}
									className="dashboard-picker"
									disabledDate={(current) =>
										current &&
										current > moment().endOf('day')
									}
									onChange={handleTableDateRangeChange}
								/>
							</Col>
						</Row>
						<Row gutter={(15, 15)} className="align-items-center">
							<Col span={24}>
								<XTableCharts
									loading={insuranceTableLoading}
									columns={columns}
									dataSource={data}
									scroll={{ x: 1200 }}
									pagination={{
										pageSize: 50,
									}}
								/>
							</Col>
						</Row>
					</XCardNonBg>
				</XContainer>
			) : (
				<></>
			)}

			{/* Score Card */}
			{checkModulePermissions('dashboard').sub_modules.includes(
				'view-payer-score-card',
			) ? (
				<XContainer fluid className="mb-4">
					<XCardNonBg>
						<Row gutter={[10]} className="align-items-center mb-3">
							<Col span={24}>
								<h1>Payers Score Card</h1>
							</Col>
						</Row>
						<Row
							gutter={[10]}
							className="align-items-center justify-content-between "
						>
							{/* open and closed status */}
							{/* <Col xs={24} sm={12} md={8} lg={6} className="mb-4">
							<SwitchBg>
								<XRadio.Group
									onChange={(e) =>
										handleScoreFilterChange({
											status: e.target.value,
										})
									}
									name="status"
									defaultValue={'open'}
								>
									<XRadio className="mt-0" value={'open'}>
										Open Cases
									</XRadio>
									<XRadio className="mt-0" value={'closed'}>
										Closed Cases
									</XRadio>
								</XRadio.Group>
							</SwitchBg>
						</Col> */}
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								xl={6}
								className="mb-4"
							>
								<XSelectCategories
									size="large"
									placeholder="Insurance Provider"
									onChange={(value) =>
										handleScoreFilterChange({
											insuranceCompany: value,
										})
									}
									value={scoreFilters.insuranceCompany}
									onFocus={() => {
										getInsuranceDropdown();
									}}
									onSearch={(search) => {
										if (search.length >= 3) {
											getInsuranceDropdown({
												search,
											});
										} else if (search.length === 0) {
											getInsuranceDropdown();
										}
									}}
									allowClear
									filterOption={filterByLabel}
									options={
										getInsuranceDropdownState.apiState ===
											'success' &&
										getInsuranceDropdownState.data?.length >
											0 &&
										getInsuranceDropdownState.data.map(
											(itm) => {
												return {
													label: itm.name,
													value: itm._id,
												};
											},
										)
									}
								/>
							</Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={17}
								className="mb-4 text-center text-lg-end d-flex flex-wrap row-gap-2 justify-content-xl-end"
							>
								<XButtonSeg
									size="large"
									style={{
										marginLeft: '10px',
										background: !scoreFiltersApplied
											? 'rgba(0, 0, 0, 0.04)'
											: '#FFFFFF',
										color: !scoreFiltersApplied
											? '#D3D3D3'
											: '#000',
									}}
									disabled={!scoreFiltersApplied}
									onClick={handleScoreClearFilters}
								>
									Clear Filters
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeScoreTimeFilter ===
											'last_week'
												? '#000'
												: '#FFFFFF',
										color:
											activeScoreTimeFilter ===
											'last_week'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleScoreTimeFilterClick('last_week')
									}
								>
									Weekly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeScoreTimeFilter ===
											'last_month'
												? '#000'
												: '#FFFFFF',
										color:
											activeScoreTimeFilter ===
											'last_month'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleScoreTimeFilterClick('last_month')
									}
								>
									Monthly
								</XButtonSeg>
								<XButtonSeg
									size="large"
									style={{
										background:
											activeScoreTimeFilter ===
											'year_to_date'
												? '#000'
												: '#FFFFFF',
										color:
											activeScoreTimeFilter ===
											'year_to_date'
												? '#FFFFFF'
												: '#000',
									}}
									onClick={() =>
										handleScoreTimeFilterClick(
											'year_to_date',
										)
									}
								>
									Year to Date
								</XButtonSeg>
								<DatePicker.RangePicker
									value={scoreDateRange}
									size="large"
									className="dashboard-picker"
									disabledDate={(current) =>
										current &&
										current > moment().endOf('day')
									}
									onChange={handleScoreDateRangeChange}
								/>
							</Col>
						</Row>
						<Row gutter={(15, 15)}>{renderInsuranceCards()}</Row>
					</XCardNonBg>
				</XContainer>
			) : (
				<></>
			)}
			<div>{renderComponentBasedOnPermission()}</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCaseChartDetailsState: state.getCaseChartDetails,
	getTotalCaseAmountState: state.getTotalCaseAmount,
	getInsuranceCompaniesStatisticsState: state.getInsuranceCompaniesStatistics,
	getInsuranceCompaniesFilingStatusCountsState:
		state.getInsuranceCompaniesFilingStatusCounts,
	getInsuranceCompaniesBucketState: state.getInsuranceCompaniesBucket,
	getInsuranceDropdownState: state.getInsuranceDropdown,
	getCaseAmountBucketState: state.getCaseAmountBucket,
});

const mapDispatchToProps = (dispatch) => ({
	getCaseChartDetails: () => dispatch(getCaseChartDetailsApi()),
	getTotalCaseAmount: () => dispatch(getTotalCaseAmountApi()),
	getCaseChartDetailsReset: () => dispatch(getCaseChartDetailsReset()),
	getTotalCaseAmountReset: () => dispatch(getTotalCaseAmountReset()),
	getInsuranceCompaniesStatistics: (data) =>
		dispatch(getInsuranceCompaniesStatisticsApi(data)),
	getInsuranceCompaniesStatisticsReset: (data) =>
		dispatch(getInsuranceCompaniesStatisticsReset(data)),
	getInsuranceCompaniesFilingStatusCounts: (data) =>
		dispatch(getInsuranceCompaniesFilingStatusCountsApi(data)),
	getInsuranceCompaniesFilingStatusCountsReset: (data) =>
		dispatch(getInsuranceCompaniesFilingStatusCountsReset(data)),
	getInsuranceCompaniesBucket: (data) =>
		dispatch(getInsuranceCompaniesBucketApi(data)),
	getInsuranceCompaniesBucketReset: (data) =>
		dispatch(getInsuranceCompaniesBucketReset(data)),
	getInsuranceDropdown: (params) => dispatch(getInsuranceDropdownApi(params)),
	getCaseAmountBucket: (params) => dispatch(getCaseAmountBucketApi(params)),
	getCaseAmountBucketReset: (params) =>
		dispatch(getCaseAmountBucketReset(params)),
	getInsuranceDropdownReset: () => dispatch(getInsuranceDropdownReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
