import {
	EDIT_FEEDBACK,
	EDIT_FEEDBACK_SUCCESS,
	EDIT_FEEDBACK_FAIL,
	EDIT_FEEDBACK_RESET,
} from '../actions/editFeedbackAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const editFeedbackReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_FEEDBACK:
			return {
				...state,
				apiState: 'loading',
			};
		case EDIT_FEEDBACK_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case EDIT_FEEDBACK_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case EDIT_FEEDBACK_RESET:
			return initialState;
		default:
			return state;
	}
};

export default editFeedbackReducer;
