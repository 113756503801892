import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAIL = 'EDIT_ROLE_FAIL';
export const EDIT_ROLE_RESET = 'EDIT_ROLE_RESET';

export const editRole = (params) => {
	return {
		type: EDIT_ROLE,
		params,
	};
};

export const editRoleSuccess = (response) => {
	return {
		type: EDIT_ROLE_SUCCESS,
		response,
	};
};

export const editRoleFail = (response) => {
	return {
		type: EDIT_ROLE_FAIL,
		response,
	};
};

export const editRoleReset = () => {
	return {
		type: EDIT_ROLE_RESET,
	};
};

export const editRoleApi = (data) => {
	return (dispatch) => {
		dispatch(editRole());
		axios
			.put(
				`${config.api.base_url}/settings/update-role/${data.id}`,
				data,
				{
					headers: { Authorization: 'Bearer ' + getUserToken() },
				},
			)
			.then((response) => {
				const editRoleData = response.data;
				dispatch(editRoleSuccess(editRoleData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editRoleFail(errorMsg));
			});
	};
};
