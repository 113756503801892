import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';
export const DELETE_ROLE_RESET = 'DELETE_ROLE_RESET';

export const deleteRole = (params) => {
	return {
		type: DELETE_ROLE,
		params,
	};
};

export const deleteRoleSuccess = (response) => {
	return {
		type: DELETE_ROLE_SUCCESS,
		response,
	};
};

export const deleteRoleFail = (response) => {
	return {
		type: DELETE_ROLE_FAIL,
		response,
	};
};

export const deleteRoleReset = () => {
	return {
		type: DELETE_ROLE_RESET,
	};
};

export const deleteRoleApi = (data) => {
	return (dispatch) => {
		dispatch(deleteRole());
		axios
			.post(`${config.api.base_url}/settings/delete-role`, data, {
				headers: { Authorization: 'Bearer ' + getUserToken() },
			})
			.then((response) => {
				const deleteRoleData = response.data;
				dispatch(deleteRoleSuccess(deleteRoleData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteRoleFail(errorMsg));
			});
	};
};
