import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_PAYER_CONFIGURATIONS = 'GET_PAYER_CONFIGURATIONS';
export const GET_PAYER_CONFIGURATIONS_SUCCESS = 'GET_PAYER_CONFIGURATIONS_SUCCESS';
export const GET_PAYER_CONFIGURATIONS_FAIL = 'GET_PAYER_CONFIGURATIONS_FAIL';
export const GET_PAYER_CONFIGURATIONS_RESET = 'GET_PAYER_CONFIGURATIONS_RESET';

export const getPayerConfigurations = (params) => {
	return {
		type: GET_PAYER_CONFIGURATIONS,
		params,
	};
};

export const getPayerConfigurationsSuccess = (response) => {
	return {
		type: GET_PAYER_CONFIGURATIONS_SUCCESS,
		response,
	};
};

export const getPayerConfigurationsFail = (response) => {
	return {
		type: GET_PAYER_CONFIGURATIONS_FAIL,
		response,
	};
};

export const getPayerConfigurationsReset = () => {
	return {
		type: GET_PAYER_CONFIGURATIONS_RESET,
	};
};

export const getPayerConfigurationsApi = (data) => {
	return (dispatch) => {
		dispatch(getPayerConfigurations());
		axios
			.get(`${config.api.base_url}/payer-configuration`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getPayerConfigurationsData = response.data;
				dispatch(getPayerConfigurationsSuccess(getPayerConfigurationsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getPayerConfigurationsFail(errorMsg));
			});
	};
};
