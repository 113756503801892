import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGOUT_RESET = 'LOGOUT_RESET';

export const logout = (params) => {
	return {
		type: LOGOUT,
		params,
	};
};

export const logoutSuccess = (response) => {
	return {
		type: LOGOUT_SUCCESS,
		response,
	};
};

export const logoutFail = (response) => {
	return {
		type: LOGOUT_FAIL,
		response,
	};
};

export const logoutReset = () => {
	return {
		type: LOGOUT_RESET,
	};
};

export const logoutApi = (data) => {
	return (dispatch) => {
		dispatch(logout());
		axios
			.post(`${config.api.base_url}/auth/logout`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const logoutData = response.data;
				dispatch(logoutSuccess(logoutData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(logoutFail(errorMsg));
			});
	};
};
