import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CASE_COUNTS = 'GET_CASE_COUNTS';
export const GET_CASE_COUNTS_SUCCESS = 'GET_CASE_COUNTS_SUCCESS';
export const GET_CASE_COUNTS_FAIL = 'GET_CASE_COUNTS_FAIL';
export const GET_CASE_COUNTS_RESET = 'GET_CASE_COUNTS_RESET';

export const getCaseCounts = (params) => {
	return {
		type: GET_CASE_COUNTS,
		params,
	};
};

export const getCaseCountsSuccess = (response) => {
	return {
		type: GET_CASE_COUNTS_SUCCESS,
		response,
	};
};

export const getCaseCountsFail = (response) => {
	return {
		type: GET_CASE_COUNTS_FAIL,
		response,
	};
};

export const getCaseCountsReset = () => {
	return {
		type: GET_CASE_COUNTS_RESET,
	};
};

export const getCaseCountsApi = (data) => {
	return (dispatch) => {
		dispatch(getCaseCounts());
		axios
			.get(`${config.api.base_url}/dashboard/get-case-counts`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCaseCountsData = response.data;
				dispatch(getCaseCountsSuccess(getCaseCountsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCaseCountsFail(errorMsg));
			});
	};
};
